/*global $*/
/*global Option*/
/*global bootbox*/
/*global Rails*/
/*global yadcf*/
/*global globalObjects*/
/*global itemShowObjects*/

import 'stylesheets/products-global.scss';

$(document).on('turbolinks:load', function() {
  
//----------------------------------------------------------------------------//
//---------- PRODUCTS EDIT RELATED -------------------------------------------//
//----------------------------------------------------------------------------//

  if ($('.product-validate.edit').length > 0) {

    // This populates the 'Supplier Search' dropdown on the 'Edit' view
    var supplierSelect = $('.productSupplierSearch');
    $.ajax({
        url: '/suppliers/' + supplierSelect.data('selected') + '.json',
        dataType: 'json',
        type: 'GET',
    }).then(function (data) {
        var option = new Option(data.supplierSelect.brand_name, data.supplierSelect.id, true, true);
        supplierSelect.append(option).trigger('change');
        supplierSelect.trigger({
          type: 'select2:select',
          params: {
            data: data
          }
        });
    });
  
    $(document).on('change keyup', '.carton-description', function() {
      var new_description = $(this).val();
      $(this).closest('.edit-carton-fields').find('.carton-title').text(new_description);
    });
  
    $(document).on('change keyup', '.attachment-description', function() {
      var new_description = $(this).val();
      $(this).closest('.edit-attachment-fields').find('.attachment-title').text(new_description);
    });
  
    // monkeypatch to fix issue of search field not being selected on open.  https://github.com/select2/select2/issues/2311#issuecomment-822473913
    $(document).on('select2:open', e => {
      const id = e.target.id;
      const target = document.querySelector(`[aria-controls=select2-${id}-results]`);
      target.focus();
    });
  
  }

//----------------------------------------------------------------------------//
//---------- PRODUCTS FORM RELATED -------------------------------------------//
//----------------------------------------------------------------------------//

  if ($('.product-validate').length > 0) {

    $('.add_child').hide();
    $('label.required').append('<strong> *</strong>'); // adds a 'required' star next to each Inputs label
    $('label.required-s span').append('<strong> *</strong>'); // adds a 'required' star next to each Selects label
  
    $('.preference-select').select2({
      selectOnClose: true,
      placeholder: 'Select Display Preference...'
    }).on('change', function() {
        $(this).valid();
      });
  
    $('.default-ship-method-select').select2({
      selectOnClose: true,
      placeholder: 'Default Carrier Shipping Method...'
    }).on('change', function() {
        $(this).valid();
      });
  
    $('.item-type-select').select2({
      selectOnClose: true,
      placeholder: 'Select Item Type...'
    }).on('change', function() {
        $(this).valid();
      });
  
    $('.child_association_select_edit').select2({
      selectOnClose: true,
      allowClear: true,
      placeholder: 'Child Association...'
    });

    association_options($('.item-type-select'), $('.child_association_select_edit'));

    $('.productSupplierSearch').select2({
      selectOnClose: true,
      allowClear: true,
      placeholder: 'Select Supplier...',
      ajax: {
        url: '/json_dropdown_suppliers.json',
        dataType: 'json',
        type: 'GET',
        processResults: function (data, params) {
          params.page = data.page || 1;
          return {
            results: $.map(data.items, function (item) {
              return {
                text: item.brand_name,
                id: item.id
              };
            }),
            pagination: {
              more: data.current_page
            }
          };
        },
        cache: true
      }
    });
  
    // This populates the 'Supplier Search' dropdown on the 'Edit' view
    var supplierSelect = $('.productSupplierSearch');
    $.ajax({
      url: '/suppliers/' + supplierSelect.data('selected') + '.json',
      dataType: 'json',
      type: 'GET',
    }).then(function (data) {
      var option = new Option(data.supplierSelect.brand_name, data.supplierSelect.id, true, true);
      supplierSelect.append(option).trigger('change');
      supplierSelect.trigger({
        type: 'select2:select',
        params: {
          data: data
        }
      });
    });
  
  
    $('.product-validate').validate({
      ignore: '.ignore',
  		rules: {
  			'product[model_number]': {required: true, maxlength: 80},
  			'product[supplier_part_number]': {required: true, maxlength: 80},
  		},
  		messages: {
  			'product[supplier_part_number]': 'If the Manufacturer Part Number & the Model Number are the same, enter the same SKU in both fields.',
  			'product[model_number]': 'If the Model Number & the Manufacturer Part Number are the same, enter the same SKU in both fields.'
  		},
      onfocusout: function(element) {$(element).valid()},
      focusCleanup: true,
      errorClass: 'myErrorClass',
      errorPlacement: function (error, element) {
        error.insertAfter(element.parent());
      },
      highlight: function (element, errorClass, validClass) {
        var elem = $(element);
        var tabId = elem.closest('.tab-pane').attr('aria-labelledby');
        if (elem.hasClass('select2-hidden-accessible')) {
          elem.parent().find('.select2-selection').addClass(errorClass);
          elem.closest('.card-body').find(".nav-link[id='"+tabId+"']").addClass(errorClass);
        } 
        else {
          elem.addClass(errorClass);
          elem.closest('.card-body').find(".nav-link[id='"+tabId+"']").addClass(errorClass);
        }
      },    
      unhighlight: function (element, errorClass, validClass) {
        var elem = $(element);
        var tabId = elem.closest('.tab-pane').attr('aria-labelledby');
        if (elem.hasClass('select2-hidden-accessible')) {
          elem.parent().find('.select2-selection').removeClass(errorClass);
          elem.parent().next('label').remove();
          elem.closest('.card-body').find(".nav-link[id='"+tabId+"']").removeClass(errorClass);
        }
        else {
          elem.removeClass(errorClass);
          elem.parent().next('label').remove();
          elem.closest('.card-body').find(".nav-link[id='"+tabId+"']").removeClass(errorClass);
        }
      },
  		invalidHandler: function(e, validator) {
  			var errors = validator.numberOfInvalids();
  			if (errors) {
  				var message = errors == 1
  					? 'You missed 1 required field. Check below tabs for highlighted fields'
  					: 'You missed ' + errors + ' required fields.  Check below tabs for highlighted fields';
  				$('div.validatorErrorList span').html(message);
  				$('div.validatorErrorList').slideDown('slow');
  			}
  			else {
  				$('div.validatorErrorList').slideUp('slow');
  			}
  		}
    });
  
    $('#productFirstSave').on('click', function(e) {
      e.preventDefault();
      if ($('.product-validate').valid()) {
        $('.template').remove();
        $(".loading-overlay").show();
        $('#productSave').click();
      }
      else {
        return false;
      }
    });
  
    $('#productFirstSubmit').on('click', function(e) {
      e.preventDefault();
      if ($('.product-validate').valid()) {
        $('.template').remove();
        $(".loading-overlay").show();
        $('#productSubmit').click();
      }
      else {
        return false;
      }
    });
  
    $(document).on('change keyup', '.cube-calc', function() {
      var e,l,w,h,c;
      e = $(this).closest('.cube-calc-body');
      l = e.find('.depth-value').val();
      w = e.find('.width-value').val();
      h = e.find('.height-value').val();
      c = (l*w)*h;
      e.find('.cube-value').val(c.toFixed(2));
    });
    

  //---- Category Modal Related --------------------------------------------------//
  
  
    var selectizeCallback = null;
  
    $('.category-modal').on('hide.bs.modal', function(e) {
      if (selectizeCallback != null) {
        selectizeCallback();
        selectizeCallback = null;
      }
  
      $('#new_category').trigger('reset');
      Rails.enableElement($('#new_category')[0]);
    });
  
    $('#new_category').on('MODAL_SUBMIT', function(e) {
      e.preventDefault();
      $.ajax({
        method: 'POST',
        url: $(this).attr('action'),
        data: $(this).serialize(),
        success: function(response) {
          selectizeCallback({value: response.id, text: response.name});
          selectizeCallback = null;
  
          $('.category-modal').modal('toggle');
        }
      });
    });
  
    $('.productCategories').selectize({
      create: function(input, callback) {
        selectizeCallback = callback;
  
        $('.category-modal').modal();
        $('#category_name').val(input);
      },
    });
    
  //---- Package Variants Related ----------------------------------------------//
  
    // Child Fields Template Loader inherited from application_helper.rb for Shipping Cartons
    $(document).on('click', 'a.add_child', function() {
      var association, new_id, regexp, template, fields;
      association = $(this).attr('data-association');
      template = $('#' + association + '_fields_template').html();
      regexp = new RegExp('new_' + association, 'g');
      new_id = (new Date).getTime();
      $(this).parent().before(template.replace(regexp, new_id));
      fields = $(this).parent().prev('.fields');
      // The 'jstemplates' hidden field needs the '.ignore' class to ensure it
      // doesn't get picked up by Jquery Validator.  This line removes it.
      fields.find('input').removeClass('ignore');
      fields.hide();
      fields.slideDown('fast');
      return false;
    });
  
    $(document).on('click', 'a.remove_child', function() {
      var hidden_field = $(this).prev('input[type=hidden]')[0];
      var fields = $(this).parents('.fields');
      if(hidden_field) {
        hidden_field.value = '1';
      }
      $(this).parents('.fields').slideUp('slow', function() {
        fields.remove();
      });
      return false;
    });
  
    $('.add_shipping_carton_button').on('click', function() {
      $('.add_child').click();
    });
  
    $('.defaultCartonSelect').select2({
      minimumResultsForSearch: Infinity,
      selectOnClose: true,
      allowClear: true,
      placeholder: 'Select variant...'
    });
  
  //---- Attachments Related -----------------------------------------------------//
  
    // Child Fields Template Loader inherited from products_helper.rb for Associated Attachments
    $(document).on('click', 'a.add_child_product_attachments', function() {
      var association, new_id, regexp, template, fields;
      association = $(this).attr('data-association');
      template = $('#' + association + '_fields_template').html();
      regexp = new RegExp('new_' + association, 'g');
      new_id = (new Date).getTime();
      $(this).parent().before(template.replace(regexp, new_id));
      fields = $(this).parent().prev('.fields_product_attachments');
      // The 'jstemplates' hidden field needs the '.ignore' class to ensure it
      // doesn't get picked up by Jquery Validator.  This line removes it.
      fields.find('input').removeClass('ignore');
      fields.find("input[name$='[description]']").each(function() {
        // rules for nested file fields need to be generated after the object is created
        $(this).rules('add', {
          messages: {
            required: `A title is required. If you don't intend to create this attachment, please click the 'Remove' button below`
          }
        });
      });
      // ActionText Trix ID's need to be manually created, otherwise it will duplicate
      // itself as 'trix-toolbar-1' on all instances of a Trix Editor
      fields.find('trix-toolbar').attr('id', 'trix-toolbar-' + new_id);
      fields.find('trix-editor').attr('trix-id', new_id).attr('toolbar', 'trix-toolbar-' + new_id);
      fields.hide();
      fields.slideDown('fast');
      return false;
    });
  
    $(document).on('click', 'a.remove_child_product_attachments', function() {
      var hidden_field = $(this).prev('input[type=hidden]')[0];
      var fields = $(this).parents('.fields_product_attachments');
      if(hidden_field) {
        hidden_field.value = '1';
      }
      $(this).parents('.fields_product_attachments').slideUp('slow', function() {
        fields.remove();
      });
      return false;
    });
  
    $('.add_product_attachment_button').on('click', function() {
      $('.add_child_product_attachments').click();
    });
    
    // This facilitates deleting a file via AJAX
    $('.product-attachment-delete').on('ajax:success', function() {
      var parent = $(this).closest('li');
      parent.slideUp( 'slow', function() { parent.remove() } );
    });
  
//---- Item Associations Related -----------------------------------------------//
  
    $(document).on('click', '.item_associations_help_button', function() {
      $(this).hide();
      $('.item_associations_help_button_shown').show();
      $('.item_associations_help_alert').slideDown('slow');
    });
  
    $(document).on('click', '.item_associations_help_button_shown', function() {
      $(this).hide();
      $('.item_associations_help_button').show();
      $('.item_associations_help_alert').slideUp('slow');
    });
  
    // Child Fields Template Loader inherited from products_helper.rb for Product Associations
    $(document).on('click', 'a.add_child_product_ancestries', function() {
      var association, new_id, regexp, template, fields;
      association = $(this).attr('data-association');
      template = $('#' + association + '_fields_template').html();
      regexp = new RegExp('new_' + association, 'g');
      new_id = (new Date).getTime();
      $(this).parent().prev().append(template.replace(regexp, new_id));
      fields = $(this).parent().prev().find('.fields_item_associations').last();
      // The 'jstemplates' hidden field needs the '.ignore' class to ensure it
      // doesn't get picked up by Jquery Validator.  This line removes it.
      fields.find('input').removeClass('ignore');
      fields.find('select').removeClass('ignore');
      fields.find('.child_association_select').select2({
        selectOnClose: true,
        allowClear: true,
        placeholder: 'Child Association...'
      });
      fields.hide();
      fields.slideDown('fast');
      return false;
    });
  
    $(document).on('click', 'a.remove_child_product_ancestries', function() {
      var hidden_field = $(this).prev('input[type=hidden]')[0];
      var fields = $(this).parents('.fields_item_associations');
      if(hidden_field) {
        hidden_field.value = '1';
      }
      $(this).parents('.fields_item_associations').slideUp('slow', function() {
        fields.remove();
      });
      return false;
    });
  
    $('.add_item_association_button').on('click', function() {
      $('.add_child_product_ancestries').click();
      $('.child_association_select option').prop('disabled', false);
      association_options($('.item-type-select'), $('.child_association_select'));
    });

    // if the 'Item Type' from the General form is changed, this ensures
    // that any new associations are assigned properly
    $(document).on('change', '.item-type-select', function() {
      $('.child_association_select option').prop('disabled', false);
      association_options($(this), $('.child_association_select'));
    });

    function association_options(select, field) {
      var selected = select.find('option:selected').val();
      if (selected == '1') { // 1 = 'Collection'
        field.each(function() {
          $(this).find('option').each(function() {
            if ($(this).data('item_type') == '1') {
              $(this).prop('disabled', true);
            }
          });
        });
      }
      if (selected == '2') { // 2 = 'Product'
        field.each(function() {
          $(this).find('option').each(function() {
            if ($(this).data('item_type') == '2') {
              $(this).prop('disabled', true);
            }
            if ($(this).data('item_type') == '1') {
              $(this).prop('disabled', true);
            }
          });
        });
      }
      if (selected == '3') { // 3 = 'Part'
        field.each(function() {
          $(this).find('option').each(function() {
            if ($(this).data('item_type') == '1') {
              $(this).prop('disabled', true);
            }
            if ($(this).data('item_type') == '2') {
              $(this).prop('disabled', true);
            }
            if ($(this).data('item_type') == '3') {
              $(this).prop('disabled', true);
            }
          });
        });
      }
    }

    $(document).on('click', '.item_association_edit_remove_button', function() {
      var fields = $(this).closest('tr');
      bootbox.confirm('Are you sure you want to remove this entry?', function(result){
        if (result === true) {
          fields.find('.remove_child_edit_item_association').attr('value', 'true');
          fields.find('td').html('');
          fields.slideUp('slow');
        }
      });
    });

  //---- Item Availabilities Related ---------------------------------------------//
  
    $('.availability-status.present').select2({
      selectOnClose: true,
      allowClear: true,
      placeholder: 'Availability Status...'
    });
  
    // Child Fields Template Loader inherited from products_helper.rb for Product Associations
    $(document).on('click', 'a.add_child_item_availabilities', function() {
      var association, new_id, regexp, template, fields;
      association = $(this).attr('data-association');
      template = $('#' + association + '_fields_template').html();
      regexp = new RegExp('new_' + association, 'g');
      new_id = (new Date).getTime();
      $(this).parent().prev().append(template.replace(regexp, new_id));
      fields = $(this).parent().prev().find('.fields_item_availabilities').last();
      // The 'jstemplates' hidden field needs the '.ignore' class to ensure it
      // doesn't get picked up by Jquery Validator.  This line removes it.
      fields.find('input').removeClass('ignore');
      fields.find('select').removeClass('ignore');
  
      fields.find('.availability-status').select2({
        selectOnClose: true,
        allowClear: true,
        placeholder: 'Availability Status...'
      });
  
  
      // iterates through the Select to ensure each Retailer can only be selected once  
      let selects = $('.itemAvailabilityRetailerSearch');
      selects.change(function() {
        let selected = selects.map((i, s) => s.value).get();
        selects.find('option').each(function(i, o) {
          $(o).prop('disabled', o.value.length && selected.includes(o.value) && $(o).parent().val() !== o.value);
        });
        selects.find('option:disabled').each(function() {
          $(this).css('background-color', '#d3d3d3');
        });
        selects.find('option:not([disabled])').each(function() {
          $(this).css('background-color', '#fff');
        });
      });
  
      $('.scroll').bind('mousewheel', function() {});
  
      fields.hide();
      fields.slideDown('fast');
      return false;
    });
  
    $(document).on('click', 'a.remove_child_item_availabilities', function() {
      var hidden_field = $(this).prev('input[type=hidden]')[0];
      var fields = $(this).parents('.fields_item_availabilities');
      if(hidden_field) {
        hidden_field.value = '1';
      }
      $(this).parents('.fields_item_availabilities').slideUp('slow', function() {
        fields.remove();
        disable_options();
      });
      return false;
    });
  
    $('.add_item_availability_button').on('click', function() {
      $('.add_child_item_availabilities').click();
    });
  
    $(document).on('mousedown', '.availability-date', function() {
      if (event.which === 2) {
        $(this).val($(this).data('today'));
      } else if (event.which === 3) {
        $(this).val('');
      }
    });
  
    // iterates through the Select to ensure each Retailer can only be selected once  
    function disable_options() {
      let selects = $('.itemAvailabilityRetailerSearch');
      selects.each(function() {
        let selected = selects.map((i, s) => s.value).get();
        selects.find('option').each(function(i, o) {
          $(o).prop('disabled', o.value.length && selected.includes(o.value) && $(o).parent().val() !== o.value);
        });
        selects.find('option:disabled').each(function() {
          $(this).css('background-color', '#d3d3d3');
        });
        selects.find('option:not([disabled])').each(function() {
          $(this).css('background-color', '#fff');
        });
      });
      selects.change(function() {
        let selected = selects.map((i, s) => s.value).get();
        selects.find('option').each(function(i, o) {
          $(o).prop('disabled', o.value.length && selected.includes(o.value) && $(o).parent().val() !== o.value);
        });
        selects.find('option:disabled').each(function() {
          $(this).css('background-color', '#d3d3d3');
        });
        selects.find('option:not([disabled])').each(function() {
          $(this).css('background-color', '#fff');
        });
      });
    }
  
    disable_options();
  
    $(document).on('click', '.item_availability_edit_remove_button', function() {
      var fields = $(this).closest('tr');
      bootbox.confirm('Are you sure you want to remove this entry?', function(result){
        if (result === true) {
          fields.find('.remove_child_edit_item_availability').attr('value', 'true');
          fields.find('td').html('');
          fields.slideUp('slow');
  
          disable_options();
  
        }
      });
    });
  
  
  //---- Allotment Blocks Related ---------------------------------------------//
  
    $('.allotment_type_select.present').select2({
      selectOnClose: true,
      allowClear: true,
      placeholder: 'Allotment Type...'
    });
  
    $('.block_type_select.present').select2({
      selectOnClose: true,
      allowClear: true,
      placeholder: 'Block Type...'
    });
  
    // Child Fields Template Loader inherited from products_helper.rb for Product Associations
    $(document).on('click', 'a.add_child_allotment_blocks', function() {
      var association, new_id, regexp, template, fields;
      association = $(this).attr('data-association');
      template = $('#' + association + '_fields_template').html();
      regexp = new RegExp('new_' + association, 'g');
      new_id = (new Date).getTime();
      $(this).parent().prev().append(template.replace(regexp, new_id));
      fields = $(this).parent().prev().find('.fields_allotment_blocks').last();
      // The 'jstemplates' hidden field needs the '.ignore' class to ensure it
      // doesn't get picked up by Jquery Validator.  This line removes it.
      fields.find('input').removeClass('ignore');
      fields.find('select').removeClass('ignore');
  
      fields.find('.allotment_type_select').select2({
        selectOnClose: true,
        allowClear: true,
        placeholder: 'Allotment Type...'
      });
      fields.find('.block_type_select').select2({
        selectOnClose: true,
        allowClear: true,
        placeholder: 'Block Type...'
      });
  

      $('.scroll').bind('mousewheel', function() {});
  
      fields.hide();
      fields.slideDown('fast');
      return false;
    });
  
    $(document).on('click', 'a.remove_child_allotment_blocks', function() {
      var hidden_field = $(this).prev('input[type=hidden]')[0];
      var fields = $(this).parents('.fields_allotment_blocks');
      if(hidden_field) {
        hidden_field.value = '1';
      }
      $(this).parents('.fields_allotment_blocks').slideUp('slow', function() {
        fields.remove();
      });
      return false;
    });
  
    $('.add_allotment_block_button').on('click', function() {
      $('.add_child_allotment_blocks').click();
    });
  

    $(document).on('click', '.allotment_block_edit_remove_button', function() {
      var fields = $(this).closest('tr');
      bootbox.confirm('Are you sure you want to remove this entry?', function(result){
        if (result === true) {
          fields.find('.remove_child_edit_allotment_block').attr('value', 'true');
          fields.find('td').html('');
          fields.slideUp('slow');
        }
      });
    });
  
  
  //---- Item Costs Related ---------------------------------------------//

    // Child Fields Template Loader inherited from products_helper.rb for Item Costs
    $(document).on('click', 'a.add_child_item_costs', function() {
      var association, new_id, regexp, template, fields;
      association = $(this).attr('data-association');
      template = $('#' + association + '_fields_template').html();
      regexp = new RegExp('new_' + association, 'g');
      new_id = (new Date).getTime();
      $(this).parent().prev().append(template.replace(regexp, new_id));
      fields = $(this).parent().prev().find('.fields_item_costs').last();
      // The 'jstemplates' hidden field needs the '.ignore' class to ensure it
      // doesn't get picked up by Jquery Validator.  This line removes it.
      fields.find('input').removeClass('ignore');
      fields.find('select').removeClass('ignore');
  
      fields.find('.item_cost_type').select2({
        selectOnClose: true,
        placeholder: 'Cost Type...',
        minimumResultsForSearch: -1
      });

      fields.find('.item_cost_retailers').selectize();

      $('.scroll').bind('mousewheel', function() {});
      fields.hide();
      fields.slideDown('fast');
      return false;
    });
  
    $(document).on('click', '.remove_child_item_costs', function() {
      var hidden_field = $(this).prev('input[type=hidden]')[0];
      var fields = $(this).closest('.fields_item_costs');
      if(hidden_field) {
        hidden_field.value = '1';
      }
      $(this).closest('.fields_item_costs').slideUp('slow', function() {
        fields.remove();
      });
    });

    $('.add_item_cost_button').on('click', function() {
      $('.add_child_item_costs').click();
    });

    $('.item_cost_type_edit').select2({
      selectOnClose: true,
      placeholder: 'Cost Type...',
      minimumResultsForSearch: -1
    });

    $('.item_cost_retailers_edit').selectize();

  
    $(document).on('click', '.item_cost_edit_remove_button', function() {
      var fields = $(this).closest('tr');
      bootbox.confirm('Are you sure you want to remove this entry?', function(result){
        if (result === true) {
          fields.find('.remove_child_edit_item_cost').attr('value', 'true');
          fields.find('td').html('');
          fields.slideUp('slow');
        }
      });
    });

  //---- Revenue Reports Related ---------------------------------------------//

    // Child Fields Template Loader inherited from products_helper.rb for Item Costs
    $(document).on('click', 'a.add_child_item_revenue_report', function() {
      var association, new_id, regexp, template, fields;
      association = $(this).attr('data-association');
      template = $('#' + association + '_fields_template').html();
      regexp = new RegExp('new_' + association, 'g');
      new_id = (new Date).getTime();
      $(this).parent().prev().append(template.replace(regexp, new_id));
      fields = $(this).parent().prev().find('.fields_item_revenue_reports').last();
      // The 'jstemplates' hidden field needs the '.ignore' class to ensure it
      // doesn't get picked up by Jquery Validator.  This line removes it.
      fields.find('input').removeClass('ignore');
      fields.find('select').removeClass('ignore');
  
      fields.find('.revenue_type_select').select2({
        selectOnClose: true,
        placeholder: 'Revenue Type...',
        minimumResultsForSearch: -1
      });
      fields.find('.revenue_retailer_select').select2({
        selectOnClose: true,
        placeholder: 'Retailer...'
      });

      $('.scroll').bind('mousewheel', function() {});
      fields.hide();
      fields.slideDown('fast');
      return false;
    });
  
    $(document).on('click', '.remove_child_item_revenue_report', function() {
      var hidden_field = $(this).prev('input[type=hidden]')[0];
      var fields = $(this).closest('.fields_item_revenue_reports');
      if(hidden_field) {
        hidden_field.value = '1';
      }
      $(this).closest('.fields_item_revenue_reports').slideUp('slow', function() {
        fields.remove();
      });
    });

    $('.add_item_revenue_report_button').on('click', function() {
      $('.add_child_item_revenue_report').click();
    });

    $('.revenue_type_select.present').select2({
      selectOnClose: true,
      placeholder: 'Revenue Type...',
      minimumResultsForSearch: -1
    });
    $('.revenue_retailer_select.present').select2({
      selectOnClose: true,
      placeholder: 'Retailer...'
    });

  
    $(document).on('click', '.item_revenue_report_edit_remove_button', function() {
      var fields = $(this).closest('tr');
      bootbox.confirm('Are you sure you want to remove this entry?', function(result){
        if (result === true) {
          fields.find('.remove_child_edit_item_revenue_report').attr('value', 'true');
          fields.find('td').html('');
          fields.slideUp('slow');
        }
      });
    });

  //---- Retailer SKU's Related --------------------------------------------------//
  
    // Child Fields Template Loader inherited from products_helper.rb for Product Associations
    $(document).on('click', 'a.add_child_retailer_skus', function() {
      var association, new_id, regexp, template, fields;
      association = $(this).attr('data-association');
      template = $('#' + association + '_fields_template').html();
      regexp = new RegExp('new_' + association, 'g');
      new_id = (new Date).getTime();
      $(this).parent().prev().append(template.replace(regexp, new_id));
      fields = $(this).parent().prev().find('.fields_retailer_skus').last();
      // The 'jstemplates' hidden field needs the '.ignore' class to ensure it
      // doesn't get picked up by Jquery Validator.  This line removes it.
      fields.find('input').removeClass('ignore');
      fields.find('select').removeClass('ignore');
  
      $('.scroll').bind('mousewheel', function() {});
  
      fields.hide();
      fields.slideDown('fast');
      return false;
    });
  
    $(document).on('click', 'a.remove_child_retailer_skus', function() {
      var hidden_field = $(this).prev('input[type=hidden]')[0];
      var fields = $(this).parents('.fields_retailer_skus');
      if(hidden_field) {
        hidden_field.value = '1';
      }
      $(this).parents('.fields_retailer_skus').slideUp('slow', function() {
        fields.remove();
      });
      return false;
    });
  
    $('.add_retailer_sku_button').on('click', function() {
      $('.add_child_retailer_skus').click();
    });
  
  
    $(document).on('click', '.retailer_sku_edit_remove_button', function() {
      var fields = $(this).closest('tr');
      bootbox.confirm('Are you sure you want to remove this entry?', function(result){
        if (result === true) {
          fields.find('.remove_child_edit_retailer_sku').attr('value', 'true');
          fields.find('td').html('');
          fields.slideUp('slow');
  
          disable_options();
  
        }
      });
    });
  
    // This facilitates deleting the attached image via AJAX
    $('.product-image-attachment-delete').on('ajax:success', function() {
      var parent = $(this).closest('.image-body').find('img');
      parent.slideUp( 'slow', function() { parent.remove() } );
      $(this).slideUp( 'slow', function() { $(this).remove() } );
    });
  
    // monkeypatch to fix issue of search field not being selected on open.
    // https://github.com/select2/select2/issues/2311#issuecomment-822473913
    $(document).on('select2:open', e => {
      const id = e.target.id;
      const target = document.querySelector(`[aria-controls=select2-${id}-results]`);
      target.focus();
    });
  
    $('.scroll').bind('mousewheel', function() {});

  }

//----------------------------------------------------------------------------//
//---------- PRODUCTS INDEX RELATED ------------------------------------------//
//----------------------------------------------------------------------------//

  if ($('.products_index_div').length > 0) {
    
    $('.loading-overlay').show();

    var MyDate = new Date();
    var date;
    MyDate.setDate(MyDate.getDate());
    date = MyDate.getFullYear() + '-' + ('0' + (MyDate.getMonth() + 1)).slice(-2) + '-' + ('0' + MyDate.getDate()).slice(-2);
    var filename = 'Items Export, ' + date;
    var table = $('#productsDataTable').DataTable({
      'retrieve': true,
      'processing': true,
      'serverSide': true,
      'initComplete': function( settings, json ) {
        $('.loading-overlay').hide();
      },
      'ajax': {
        'url': $('#productsDatatable').data('source')
      },
      'columns': [
  /* 0*/{data: 'id', searchable: false, visible: false},
  /* 1*/{data: 'image', orderable: false, searchable: false},
  /* 2*/{data: 'supplier'},
  /* 3*/{data: 'item_type'},
  /* 4*/{data: 'supplier_part_number', visible: false},
  /* 5*/{data: 'model_number'},
  /* 6*/{data: 'upc', visible: false},
  /* 7*/{data: 'description'},
  /* 8*/{data: 'quantity_on_hand', orderable: false, searchable: false},
  /* 9*/{data: 'qty_last_updated', orderable: false, searchable: false},
  /*10*/{data: 'order_date_range', searchable: false},
  /*11*/{data: 'reorder_date', searchable: false},
  /*12*/{data: 'inbound_qty', searchable: false},
  /*13*/{data: 'reorder_qty', visible: false, searchable: false},
  /*14*/{data: 'max_qty', visible: false, searchable: false},
  /*15*/{data: 'weight', visible: false, searchable: false},
  /*16*/{data: 'dimensions', visible: false, searchable: false},
  /*17*/{data: 'categories', orderable: false},
  /*18*/{data: 'show_in_inventory', visible: false},
  /*19*/{data: 'actions', orderable: false, searchable: false}
      ],
      'order': [[ 1, 'asc' ], [ 2, 'asc' ], [ 5, 'asc' ]],
      'oLanguage': { 'sZeroRecords': 'No items to display for this filter' },
      'dom': '<"row"<"col-sm-10 dataTableToolbar"><"col-sm-2"B>>t<"row dataTableBottomRow"<"col-sm-12 col-md-5"i><"col-sm-12 col-md-6"p>>',
      'buttons': [
        {
          extend: 'excelHtml5',
          title: filename,
          // Only exports columns that are currently visible.  Adjusted by the 'Visibility' dropdown and Filtered text.
          exportOptions: {columns: ':visible'},
          className: 'excel-export'
        },
        {
          extend: 'csv',
          title: filename,
          exportOptions: {columns: ':visible'},
        },
        {
          extend: 'pdfHtml5',
          title: filename,
          orientation: 'landscape',
          exportOptions: {columns: ':visible'},
        },
        {
          extend: 'copyHtml5',
          title: filename,
          exportOptions: {columns: ':visible'},
        }, 
        {
          extend: 'print',
          autoPrint: false,
          title: filename,
          exportOptions: {columns: ':visible'},
          className: 'dtButtonHide',
          customize: function (win) {
              $(win.document.body).find('table').addClass('display').css('font-size', '12px');
              $(win.document.body).find('tr:nth-child(odd) td').each(function(index){
                  $(this).css('background-color','#D0D0D0');
              });
              $(win.document.body).find('h1').css('text-align','center');
          },
        },
        {
          extend: 'excelHtml5',
          title: filename,
          exportOptions: {
            columns: [2,5,7],
                      // rows: function ( idx, data, node ) {
                      //     return data[2] === 'Product' ?
                      //             true : false;
                      // }
          },
          className: 'quick-export-products'
        }
      ],
    });
  
    function dataTableToolbar(model) {
      return '' +
      '<ul class="nav" style="margin-bottom:10px">' +
      '  <li>' +
      '    <span class="options-label">Options:</span>' +
      '  </li>' +
      '  <li>' +
      '    <div class="dataTables_length" id="' + model + 'DataTable_length">' +
      '      <select name="' + model + 'DataTable_length" aria-controls="' + model + 'DataTable" id="' + model + 'TableLength" class="form-control form-control-sm tableLength" title="Number of records to show">' +
      '        <option value="5">5</option>' +
      '        <option value="10">10</option>' +
      '        <option selected="selected" value="25">25</option>' +
      '        <option value="50">50</option>' +
      '        <option value="100">100</option>' +
      '        <option value="250">250</option>' +
      '        <option value="-1">All</option>' +
      '      </select>' +
      '    </div>' +
      '  </li>' +
      '  <li>' +
      '    <div class="tableClickerDiv dropdown">' +
      '      <button class="btn btn-outline-primary btn-sm dropdown-toggle" type="button" id="tableClickerColvis" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="fas fa-columns"></i> Columns</button>' +
      '      <div class="dropdown-menu card-shadow" aria-labelledby="tableClickerColvis">' +
      '        <a class="dropdown-item button-highlight" id="colvisClicker-0">Record ID</a>' +
      '        <a class="dropdown-item" id="colvisClicker-1">Image</a>' +
      '        <a class="dropdown-item" id="colvisClicker-2">Supplier</a>' +
      '        <a class="dropdown-item" id="colvisClicker-3">Item Type</a>' +
      '        <a class="dropdown-item button-highlight" id="colvisClicker-4">Supplier Part #</a>' +
      '        <a class="dropdown-item" id="colvisClicker-5">Model #</a>' +
      '        <a class="dropdown-item button-highlight" id="colvisClicker-6">UPC</a>' +
      '        <a class="dropdown-item" id="colvisClicker-7">Description</a>' +
      '        <a class="dropdown-item" id="colvisClicker-8">Quantity On Hand</a>' +
      '        <a class="dropdown-item" id="colvisClicker-9">Qty Last Modified</a>' +
      '        <a class="dropdown-item" id="colvisClicker-10">Orders Last x Weeks</a>' +
      '        <a class="dropdown-item" id="colvisClicker-11">Reorder Date</a>' +
      '        <a class="dropdown-item" id="colvisClicker-12">Inbound Quantity</a>' +
      '        <a class="dropdown-item button-highlight" id="colvisClicker-13">Reorder Quantity</a>' +
      '        <a class="dropdown-item button-highlight" id="colvisClicker-14">Max Quantity</a>' +
      '        <a class="dropdown-item button-highlight" id="colvisClicker-15">Weight</a>' +
      '        <a class="dropdown-item button-highlight" id="colvisClicker-16">Dimensions</a>' +
      '        <a class="dropdown-item" id="colvisClicker-17">Categories</a>' +
      '        <a class="dropdown-item button-highlight" id="colvisClicker-18">Shown In Inventory?</a>' +
      '        <a class="dropdown-item" id="colvisClicker-19">Actions</a>' +
      '      </div>' +
      '    </div>' +
      '  </li>' +
      '  <li>' +
      '    <div class="tableClickerDiv dropdown">' +
      '      <button class="btn btn-outline-primary btn-sm dropdown-toggle" type="button" id="tableClickerExports" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="fas fa-file-export"></i> Export</button>' +
      '      <div class="dropdown-menu" aria-labelledby="tableClickerExports">' +
      '        <a class="dropdown-item tableClickerExcelP"><i class="fas fa-file-excel file-icon-color"></i> Excel <i class="fas fa-download text-primary"></i></a>' +
      '        <a class="dropdown-item tableClickerCsv"><i class="fas fa-file-csv file-icon-color"></i> CSV <i class="fas fa-download text-primary"></i></a>' +
      '        <a class="dropdown-item tableClickerPdf"><i class="fas fa-file-pdf file-icon-color"></i> PDF <i class="fas fa-download text-primary"></i></a>' +
      '        <a class="dropdown-item tableClickerCopy"><i class="fas fa-copy file-icon-color"></i> Copy</a>' +
      '        <a class="dropdown-item tableClickerPrint"><i class="fas fa-print file-icon-color"></i> Print/Preview</a>' +
      '        <div class="text-primary" style="font-size:11px;padding:0.25rem 1.5rem;">' +
      '          <i class="fas fa-exclamation-triangle text-danger"></i> Only the currently visible columns & rows will be exported for the above selections' +
      '        </div>' +
      '        <a class="dropdown-item" id="tableClickerQuickExportProducts"><i class="fas fa-print file-icon-color"></i> Quick Export Items</a>' +
      '      </div>' +
      '    </div>' +
      '  </li>' +
      '  <li class="filter-section mt-2" style="width:100%;">' +
      '    <span class="filter-label">Filters:</span>' +
      '    <div id="' + model + 'DataTable_filter" class="dataTables_filter mr-2">' +
      '      <input id="' + model + 'TableSearch" class="form-control form-control-sm tableSearch" title="right-click to clear" oncontextmenu="return false" aria-controls="' + model + 'DataTable" placeholder="Search Entire Table...">' +
      '    </div>' +
      '    <span id="supplier-select-dropdown" class="filter-span-select colvisSearch-2"></span>' +
      '    <span id="item-type-dropdown" class="filter-span-select colvisSearch-3"></span>' +
      '    <span id="supplier-part-number-select-input" class="filter-span-input colvisSearch-4" style="display:none;"></span>' +
      '    <span id="model-number-select-input" class="filter-span-input colvisSearch-5"></span>' +
      '    <form action="/products" accept-charset="UTF-8" method="get">' +
      '      <span class="filter-span-input colvisSearch-10" title="Select the number of weeks worth of orders to show">' +
      '        <div>' +
      '          <div class="yadcf-filter-wrapper">' +
      '            <div class="yadcf-filter-wrapper-inner">' +
      '              <input type="number" name="order_date_range" id="order_date_range" title="This updates the quantity sold over the last X weeks in the `Orders Last 8 Weeks` column" placeholder="Order Range (in weeks)" class="filter-span-input">' +
      '            </div>' +
      '            <button type="submit" name="commit" class="yadcf-filter-reset-button" title="Note that Order Range search will clear all other filters">Go</button>' +
      '          </div>' +
      '        </div>' +
      '      </span>' +
      '    </form>' +
      '  </li>' +
      '</ul>' +
      '';
    }
  
    table.page.len(25).draw();
    
    $('div.dataTableToolbar').html(dataTableToolbar('products'));
  
    $('.tableLength').on('change', function () {
      table.page.len($(this).find('option:selected').attr('value')).draw();
    });
  
    function colVisButtons(index) {
      var button = $('#colvisClicker-'+index);
      button.on('click', function(e) {
        e.stopPropagation();
        if (table.column(index).visible() === true) {
          table.column(index).visible(false);
          button.addClass('button-highlight');
          $('.colvisSearch-'+index).hide();
        }
        else {
          table.column(index).visible(true);
          button.removeClass('button-highlight');
          $('.colvisSearch-'+index).show();
        }
      });
    }
    let colVisIndex = -1;
    do { colVisIndex += 1;
         colVisButtons(colVisIndex);
    } while (colVisIndex < 19);
  
    $('.buttons-html5').hide(); // Hides default Colvis & Export buttons

    $(document).on('click', '.tableClickerExcelP', function() {
      $(this).closest('.row').find('.excel-export').click();
    });
    $('#tableClickerQuickExportProducts').on('click', function() {
      table.page.len(-1).draw();
      $('.loading-overlay').show();
      setTimeout(function() {$('.quick-export-products').click()}, 5000);
      setTimeout(function() {$('.loading-overlay').hide()}, 5000);
    });
  
    var search = $.fn.dataTable.util.throttle(
      function (val) {
        table.search(val).draw();
      }, 1000);
     
    $('.tableSearch').on('keyup', function () {
      search( this.value );
    });
  
    yadcf.init(table,
      [
  
        { column_number : 2, // Supplier
          filter_container_id : "supplier-select-dropdown",
          filter_default_label : "Suppliers... ↓↓↓",
          filter_match_mode : "exact",
          filter_type: 'multi_select',
          select_type: 'select2',
          data: $('#params_span').data('suppliers_dropdown')
        },
  
        { column_number : 3, // Item Type
          filter_container_id : 'item-type-dropdown',
          style_class : 'form-control form-control-sm',
          filter_default_label : 'Select Item Type...',
          data: [
            {value: '1', label : 'Collection'},
            {value: '2', label : 'Product'},
            {value: '3', label : 'Part'},
          ]
        },
  
        { column_number : 4, // Supplier Part Number
          filter_type: 'text',
          filter_container_id : 'supplier-part-number-select-input',
          style_class : 'form-control form-control-sm',
          filter_default_label : 'Search Supplier Part #...',
          filter_delay: 500
        },
  
        { column_number : 5, // Model #
          filter_type: 'text',
          filter_container_id : 'model-number-select-input',
          style_class : 'form-control form-control-sm',
          filter_default_label : 'Search Model #...',
          filter_delay: 500
        },
  
        // { column_number : 8, // Qty Last Modified
        //   filter_type : 'range_date',
        //   filter_container_id : 'qty-last-modified-date-range',
        //   date_format : 'yy-mm-dd'
        // },
  
      ],
      { filters_position : 'footer' },
      { externally_triggered : true }
    );

    // This block executes any query string searches that may be present in the URL
    function filterTable(columnIndex, filterValue){
      yadcf.exFilterColumn(table, [
        [columnIndex, filterValue],
      ], true);
    }
    const queryStringParams = window.location.search.substring(1).split('&');

    queryStringParams.forEach(param => {

      if (param.indexOf('supplier') !== -1) {
        filterTable(2, $('#params_span').data('supplier'));
      } else if (param.indexOf('item_type') !== -1) {
        filterTable(3, [$('#params_span').data('item_type')]);
      } else if (param.indexOf('model_number') !== -1) {
        filterTable(5, [$('#params_span').data('model_number')]);
      } else if (param.indexOf('supplier_part_number') !== -1) {
        filterTable(4, [$('#params_span').data('supplier_part_number')]);
      }

    });

    // Text column searches need to be executed a second time to trigger the actual search
    yadcf.exFilterColumn(table, [
      [5, yadcf.exGetColumnFilterVal(table,5)],
      [4, yadcf.exGetColumnFilterVal(table,4)]
    ], true);

    $('body').tooltip({selector: '[data-toggle="tooltip"]'});

  }

//----------------------------------------------------------------------------//
//---------- PRODUCTS ITEM SEARCH RELATED ------------------------------------//
//----------------------------------------------------------------------------//

  if ($('.item_search_div').length > 0) {
    
    var MyDate = new Date();
    var date;
    MyDate.setDate(MyDate.getDate());
    date = MyDate.getFullYear() + '-' + ('0' + (MyDate.getMonth() + 1)).slice(-2) + '-' + ('0' + MyDate.getDate()).slice(-2);
    var filename = 'Items Export, ' + date;
    var table = $('#itemSearchDataTable').DataTable({
      'retrieve': true,
      'processing': true,
      'serverSide': true,
      'scrollY':        '65vh',
      'scrollCollapse': true,
      'ajax': {
        'url': $('#itemSearchDataTable').data('source')
      },
      'columns': [
  /* 0*/{data: 'id', searchable: false, visible: false},
  /* 1*/{data: 'supplier'},
  /* 2*/{data: 'image', searchable: false, orderable: false},
  /* 3*/{data: 'model_number'},
  /* 4*/{data: 'associated_items', searchable: false, orderable: false},
  /* 5*/{data: 'actions', searchable: false, orderable: false, visible: false}
      ],
      'order': [[ 1, 'asc' ], [ 3, 'asc' ]],
      'oLanguage': { 'sZeroRecords': 'No items to display for this filter' },
      'dom': '<"row"<"col-sm-10 dataTableToolbar"><"col-sm-2"B>>t<"row dataTableBottomRow"<"col-sm-12 col-md-5"i><"col-sm-12 col-md-6"p>>',
      'buttons': [
        {
          extend: 'excelHtml5',
          title: filename,
          // Only exports columns that are currently visible.  Adjusted by the 'Visibility' dropdown and Filtered text.
          exportOptions: {columns: ':visible'},
          className: 'excel-export'
        },
        {
          extend: 'csv',
          title: filename,
          exportOptions: {columns: ':visible'},
        },
        {
          extend: 'pdfHtml5',
          title: filename,
          orientation: 'landscape',
          exportOptions: {columns: ':visible'},
        },
        {
          extend: 'copyHtml5',
          title: filename,
          exportOptions: {columns: ':visible'},
        }, 
        {
          extend: 'print',
          autoPrint: false,
          title: filename,
          exportOptions: {columns: ':visible'},
          className: 'dtButtonHide',
          customize: function (win) {
              $(win.document.body).find('table').addClass('display').css('font-size', '12px');
              $(win.document.body).find('tr:nth-child(odd) td').each(function(index){
                  $(this).css('background-color','#D0D0D0');
              });
              $(win.document.body).find('h1').css('text-align','center');
          },
        },
      ],
    });
  
    function dataTableToolbar() {
      return '' +
      '<ul class="nav" style="margin-bottom:10px">' +
      '  <li>' +
      '    <span class="options-label">Options:</span>' +
      '  </li>' +
      '  <li>' +
      '    <div class="dataTables_length" id="itemSearchDataTable_length">' +
      '      <select name="itemSearchDataTable_length" aria-controls="itemSearchDataTable" id="itemSearchTableLength" class="form-control form-control-sm tableLength" title="Number of records to show">' +
      '        <option value="5">5</option>' +
      '        <option value="10">10</option>' +
      '        <option selected="selected" value="25">25</option>' +
      '        <option value="50">50</option>' +
      '        <option value="100">100</option>' +
      '        <option value="-1">All</option>' +
      '      </select>' +
      '    </div>' +
      '  </li>' +
      '  <li>' +
      '    <div class="tableClickerDiv dropdown">' +
      '      <button class="btn btn-outline-primary btn-sm dropdown-toggle" type="button" id="tableClickerColvis" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="fas fa-columns"></i> Columns</button>' +
      '      <div class="dropdown-menu card-shadow" aria-labelledby="tableClickerColvis">' +
      '        <a class="dropdown-item button-highlight" id="colvisClicker-0">Record ID</a>' +
      '        <a class="dropdown-item" id="colvisClicker-1">Supplier</a>' +
      '        <a class="dropdown-item" id="colvisClicker-2">Image</a>' +
      '        <a class="dropdown-item" id="colvisClicker-3">Model #</a>' +
      '        <a class="dropdown-item" id="colvisClicker-4">Associated Items</a>' +
      '        <a class="dropdown-item button-highlight" id="colvisClicker-5">Actions</a>' +
      '      </div>' +
      '    </div>' +
      '  </li>' +
      '  <li class="filter-section ml-2">' +
      '    <span id="supplier-select-dropdown" class="filter-span-select colvisSearch-1"></span>' +
      '    <div id="itemSearchDataTable_filter" class="dataTables_filter mr-2">' +
      '      <input id="itemSearchTableSearch" class="form-control form-control-sm tableSearch" style="width: 200%" title="right-click to clear" oncontextmenu="return false" aria-controls="itemSearchDataTable" placeholder="Search for SKU...">' +
      '    </div>' +
      '  </li>' +
      '</ul>' +
      '';
    }
  
    table.page.len(25).draw();
    
    $('div.dataTableToolbar').html(dataTableToolbar());
  
    $('.tableLength').on('change', function () {
      table.page.len($(this).find('option:selected').attr('value')).draw();
    });
  
    var search = $.fn.dataTable.util.throttle(
      function (val) {
        table.search(val).draw();
      }, 1000);
     
    $('.tableSearch').on('keyup', function () {
      search( this.value );
    });
  
    function colVisButtons(index) {
      var button = $('#colvisClicker-'+index);
      button.on('click', function(e) {
        e.stopPropagation();
        if (table.column(index).visible() === true) {
          table.column(index).visible(false);
          button.addClass('button-highlight');
          $('.colvisSearch-'+index).hide();
        }
        else {
          table.column(index).visible(true);
          button.removeClass('button-highlight');
          $('.colvisSearch-'+index).show();
        }
      });
    }
    let colVisIndex = -1;
    do { colVisIndex += 1;
         colVisButtons(colVisIndex);
    } while (colVisIndex < 5);

    yadcf.init(table,
      [
        { column_number : 1, // Supplier
          filter_container_id : "supplier-select-dropdown",
          filter_default_label : "Suppliers... ↓↓↓",
          filter_match_mode : "exact",
          filter_type: 'multi_select',
          select_type: 'select2',
          data: $('#params_span').data('suppliers_dropdown')
        },
      ],
      { filters_position : 'footer' },
      { externally_triggered : true }
    );

    // This block executes any query string searches that may be present in the URL
    function filterTable(columnIndex, filterValue){
      yadcf.exFilterColumn(table, [
        [columnIndex, filterValue],
      ]);
    }
    const queryStringParams = window.location.search.substring(1).split('&');

    queryStringParams.forEach(param => {
      if (param.indexOf('supplier') !== -1) {
        filterTable(1, $('#params_span').data('supplier'));
      }
    });

  }

//----------------------------------------------------------------------------//
//---------- PRODUCTS NEW SHOW RELATED ---------------------------------------//
//----------------------------------------------------------------------------//

  if ($('.product_new_show_div').length > 0) {

    $(document).on('click', '.tab_collapse.up', function() {
      var div = $(this).closest('.card-body');
      div.find('.header-not-collapsed').hide();
      div.find('.header-collapsed').show();
      div.find('.tab_collapse_body').slideUp('fast');
      $(this).hide();
      $(this).next('.tab_collapse.down').show();
      $('.expand_all_tabs').show();
      $('.hide_all_tabs').hide();
    });

    $(document).on('click', '.tab_collapse.down', function() {
      $(this).closest('.card-body').find('.tab_collapse_body').slideDown('fast');
      $(this).hide();
      $(this).prev('.tab_collapse.up').show();
      $('.expand_all_tabs').hide();
      $('.hide_all_tabs').show();
    });

    $(document).on('click', '.header-collapsed', function() {
      var div = $(this).closest('.card-body');
      div.find('.tab_collapse.down').click();
      div.find('.header-not-collapsed').show();
      $(this).hide();
    });

    $(document).on('click', '.hide_all_tabs', function() {
      $('.tab_collapse.up').click();
      $(this).hide();
      $('.expand_all_tabs').show();
    });

    $(document).on('click', '.expand_all_tabs', function() {
      $('.tab_collapse.down').click();
      $(this).hide();
      $('.hide_all_tabs').show();
    });

    // Since the tables are loaded asynchronously, 'order_tables_loaded' is called
    // once they're loaded so the JS below can be called on it.
    $(document).on('order_tables_loaded', function() {
      itemShowObjects.dataTable('customer_orders');
      itemShowObjects.dataTable('transfer_orders');
      itemShowObjects.dataTable('other_orders');
      itemShowObjects.dataTable('future_orders');
      
      var customer_orders_count = $('.customer_orders_count').data('order_count');
      var transfer_orders_count = $('.transfer_orders_count').data('order_count');
      var other_orders_count = $('.other_orders_count').data('order_count');
      var future_orders_count = $('.future_orders_count').data('order_count');
      
      $('.order_pill_count.customer_orders').text(customer_orders_count || '0');
      $('.order_pill_count.transfer_orders').text(transfer_orders_count || '0');
      $('.order_pill_count.other_orders').text(other_orders_count || '0');
      $('.order_pill_count.future_orders').text(future_orders_count || '0');
      
    });

    $(document).on('item_revenue_table_loaded', function() {
      itemShowObjects.dataTable('item_revenue_table', 25);
      itemShowObjects.dataTable('inbound_shipments');
    });

    $(document).on('inventory_adjustments_loaded', function() {
      itemShowObjects.dataTable('inventory_adjustments', 25);
      itemShowObjects.dataTable('inbound_shipments');
    });

    $('#retailer_select').selectize({
      placeholder: 'Retailers...'
    });

    // Collects the values from Retailer select and converts them to a simple array for query parameter filtering
    $(document).on('change', '#retailer_select', function() {
      $('#retailers').val($(this).find('option:selected').toArray().map(item => item.value).join());
    });

  }

});
