/* global $ */
/* global Turbolinks */
import "bootstrap/js/dist/tab";

$(document).on("turbolinks:load", function() {

  $(".searchCategories").find("option:first-child").attr("disabled", true);
  

  $(document).on("change keyup", ".navbar-search", function() {
    var submit = $("#navbarSearchSubmit");
    if ($(this).val().length == 0) {
      submit.attr("disabled", "disabled");
    } else {
      submit.removeAttr("disabled");
    }

  });

  function searchOptions(element, model, name, options) {
    if (element.find("option:selected").val() === model) {
      $(".navbar-search").attr("data-behavior", "autocomplete-"+model).val("").attr("placeholder", "Search "+name+"...");
      $(".navbar-search").easyAutocomplete(options);
    }
  }

  $(document).on("change", ".searchCategories", function() {
    // searchOptions($(this), 'menards_stores', 'Menards Stores');
    searchOptions($(this), 'users', 'Users', user_options);
    searchOptions($(this), 'items', 'Items', item_options);
    searchOptions($(this), 'categories', 'Categories', category_options);
    searchOptions($(this), 'suppliers', 'Suppliers', supplier_options);
    searchOptions($(this), 'inbound_shipments', 'Inbound Shipments', inbound_shipment_options);
    searchOptions($(this), 'orders', 'Orders', order_options);
    searchOptions($(this), 'addresses', 'Addresses', address_options);
  });


  // var menards_store_options = {
  //   highlightPhrase: false,
  //   adjustWidth: false,
  //   getValue: function (element) {
  //     return "<div class='media d-flex align-items-center border-bottom pt-2 pb-2'>" +
  //           "  <div class='media-body ml-3'>" +
  //           "    <h4 class='mb-2'>" + $(element).prop("name") + "</h4>" +
  //           "    <h6 class='mb-0'>Store #" + $(element).prop("yard") + " (" + $(element).prop("abbrv") + ")</h6>" +
  //           "    <i class='mdi mdi-account text-primary tx-12'></i> <span class='text-muted tx-12'>Service Rep: " + $(element).prop("service_rep") + "</span>" +
  //           "  </div>" +
  //           "</div>";
  //   },
  //   url: function(phrase) {
  //     return "/autocomplete_menards_stores.json?q=" + phrase;
  //   },
  //   categories: [
  //     {
  //       listLocation: "menards_store_search",
  //       header: "<div class='font-weight-bold nav-title pl-3 pt-1 pb-1 tx-16 ml-n2 mr-n2'>Menards Stores</div>"
  //     }
  //   ],
  //   list: {
  //     onChooseEvent: function() {
  //       var url =  $(".navbar-search").getSelectedItemData().url;
  //       $(".navbar-search").val("");
  //       window.open(url, '_blank').focus();
  //     }
  //   },
  //   requestDelay: 500
  // };

  var user_options = {
    highlightPhrase: false,
    adjustWidth: false,
    getValue: function (element) {
      return "<div class='media d-flex align-items-center border-bottom pt-2 pb-2'>" +
             "  <img src='" + $(element).prop("profile_image") + "'>" +
             "  <div class='media-body ml-3'>" +
             "    <h4 class='mb-2'>" + $(element).prop("fullname") + "</h4>" +
             "    <h6 class='mb-0'><i class='mdi mdi-email text-primary'></i> " + $(element).prop("email") + "</h6>" +
             "    <i class='mdi mdi-cellphone-iphone text-primary tx-12'></i><span class='mr-2 tx-12'> " + $(element).prop("phone") + "</span>" +
             "  </div>" +
             "</div>";
    },
    url: function(phrase) {
      return "/autocomplete_users.json?q=" + phrase;
    },
    categories: [
      {
        listLocation: "user_search",
        header: "<div class='font-weight-bold nav-title pl-3 pt-1 pb-1 tx-16 ml-n2 mr-n2'>Users</div>"
      }
    ],
    list: {
      onChooseEvent: function() {
        var url =  $(".navbar-search").getSelectedItemData().url;
        $(".navbar-search").val("");
        window.open(url, '_blank').focus();
      }
    },
    requestDelay: 500
  };

  var item_options = {
    highlightPhrase: false,
    adjustWidth: false,
    getValue: function (element) {
      return "<div class='media d-flex align-items-center border-bottom pt-2 pb-2'>" +
             "  <img src='" + $(element).prop("product_image") + "'>" +
             "  <div class='media-body ml-3'>" +
             "    <h4 class='mb-2' title='" + $(element).prop("nonpreferred_sku") + "'>" + $(element).prop("preferred_sku") + "</h4>" +
             "    <span>" +
             "      <h6 class='mb-0'>" + $(element).prop("supplier_brand_name") +
             "      <i class='mdi mdi-dots-vertical'></i>" +
             "      <span title='" + $(element).prop("description") + "'>" + $(element).prop("description_trunc") + "</span></h6>" +
             "    </span>" +
             "    <i class='mdi mdi-arrange-send-to-back text-primary tx-11' title='Item Type'></i><span class='text-muted tx-11' title='Item Type'> " + $(element).prop("item_type") + "</span>" +
             "    <i class='mdi mdi-dots-vertical tx-11'></i>" +
             "    <i class='mdi mdi-autorenew text-primary tx-11' title='Quantity on Hand'></i><span class='text-muted tx-11'> " + $(element).prop("collection_inventory_autocomplete") + "</span>" +
             "  </div>" +
             "</div>";
    },
    url: function(phrase) {
      return "/autocomplete_items.json?q=" + phrase;
    },
    categories: [
      {
        listLocation: "item_search",
        header: "<div class='font-weight-bold nav-title pl-3 pt-1 pb-1 tx-16 ml-n2 mr-n2'>Items</div>"
      }
    ],
    list: {
      onChooseEvent: function() {
        var url = $(".navbar-search").getSelectedItemData().url;
        $(".navbar-search").val("");
        window.open(url, '_blank').focus();
      }
    },
    requestDelay: 500
  };

  var supplier_options = {
    highlightPhrase: false,
    adjustWidth: false,
    getValue: function (element) {
      return "<div class='media d-flex align-items-center border-bottom pt-2 pb-2'>" +
             "  <img src='" + $(element).prop("brand_image") + "'>" +
             "  <div class='media-body ml-3'>" +
             "    <h4 class='mb-2'>" + $(element).prop("brand_name") + "</h4>" +
             "    <p class='mb-0 text-muted' title='Associated Items'>" + $(element).prop("product_count") + " associated items</p>" +
             "  </div>" +
             "</div>";
    },
    url: function(phrase) {
      return "/autocomplete_suppliers.json?q=" + phrase;
    },
    categories: [
      {
        listLocation: "supplier_search",
        header: "<div class='font-weight-bold nav-title pl-3 pt-1 pb-1 tx-16 ml-n2 mr-n2'>Suppliers</div>"
      }
    ],
    list: {
      onChooseEvent: function() {
        var url =  $(".navbar-search").getSelectedItemData().url;
        $(".navbar-search").val("");
        window.open(url, '_blank').focus();
      }
    },
    requestDelay: 500
  };

  var category_options = {
    highlightPhrase: false,
    adjustWidth: false,
    getValue: function (element) {
      return "<div class='media d-flex align-items-center border-bottom pt-2 pb-2'>" +
             "  <img src='" + $(element).prop("product_image") + "'>" +
             "  <div class='media-body ml-3'>" +
             "    <h4 class='mb-1'>" + $(element).prop("name") + "</h4>" +
             "    <span>" +
             "      <h6 class='mb-2'><span title='" + $(element).prop("description") + "'>" + $(element).prop("description_trunc") + "</span></h6>" +
             "    </span>" +
             "    <p class='mb-0 text-muted' title='Associated Items'>" + $(element).prop("item_count") + " associated items</p>" +
             "  </div>" +
             "</div>";
    },
    url: function(phrase) {
      return "/autocomplete_categories.json?q=" + phrase;
    },
    categories: [
      {
        listLocation: "category_search",
        header: "<div class='font-weight-bold nav-title pl-3 pt-1 pb-1 tx-16 ml-n2 mr-n2'>Categories</div>"
      }
    ],
    list: {
      onChooseEvent: function() {
        var url =  $(".navbar-search").getSelectedItemData().url;
        $(".navbar-search").val("");
        window.open(url, '_blank').focus();
      }
    },
    requestDelay: 500
  };

  var inbound_shipment_options = {
    highlightPhrase: false,
    adjustWidth: false,
    getValue: function (element) {
      return "<div class='media d-flex align-items-center border-bottom pt-2 pb-2'>" +
             "  <div class='media-body ml-3'>" +
             "    <h4 class='mb-1'>PO # " + $(element).prop("po_number") + "</h4>" +
             "    <h6 class='mb-2'>" +
             "      <span class='tx-11 text-muted'>Container # " + $(element).prop("container_number") + "</span>" +
             "      <i class='mdi mdi-dots-vertical tx-11'></i>" +
             "      <span class='tx-11 text-muted'>Status: " + $(element).prop("status_name") + "</span>" +
             "    </h6>" +
             "  </div>" +
             "</div>";
    },
    url: function(phrase) {
      return "/autocomplete_inbound_shipments.json?q=" + phrase;
    },
    categories: [
      {
        listLocation: "inbound_shipment_search",
        header: "<div class='font-weight-bold nav-title pl-3 pt-1 pb-1 tx-16 ml-n2 mr-n2'>Inbound Shipments</div>"
      }
    ],
    list: {
      onChooseEvent: function() {
        var url =  $(".navbar-search").getSelectedItemData().url;
        $(".navbar-search").val("");
        window.open(url, '_blank').focus();
      }
    },
    requestDelay: 500
  };

  var order_options = {
    highlightPhrase: false,
    adjustWidth: false,
    getValue: function (element) {
      return "<div class='media d-flex align-items-center border-bottom pt-2 pb-2'>" +
             "  <div class='media-body ml-3'>" +
             "    <h4 class='mb-1'>PO # " + $(element).prop("po_number") + " (" + $(element).prop("supplier") + ")</h4>" +
             "    <h6 class='mb-2'>" +
             "      <span class='tx-11 text-muted'>Customer: " + $(element).prop("customer_name") + "</span>" +
             "      <i class='mdi mdi-dots-vertical tx-11'></i>" +
             "      <span class='tx-11 text-muted'>Retailer: " + $(element).prop("retailer") + "</span>" +
             "      <i class='mdi mdi-dots-vertical tx-11'></i>" +
             "      <span class='tx-11 text-muted'>Order Status: " + $(element).prop("order_status") + "</span>" +
             "    </h6>" +
             "    <h6 class='mb-2'>" +
             "      <span class='tx-11 text-muted'>Ship Date: " + $(element).prop("ship_date") + "</span>" +
             "      <i class='mdi mdi-dots-vertical tx-11'></i>" +
             "      <span class='tx-11 text-muted'>Master Tracking: " + $(element).prop("master_tracking") + "</span>" +
             "    </h6>" +
             "  </div>" +
             "</div>";
    },
    url: function(phrase) {
      return "/autocomplete_orders.json?q=" + phrase;
    },
    categories: [
      {
        listLocation: "order_search",
        header: "<div class='font-weight-bold nav-title pl-3 pt-1 pb-1 tx-16 ml-n2 mr-n2'>Orders</div>"
      }
    ],
    list: {
      onChooseEvent: function() {
        var url =  $(".navbar-search").getSelectedItemData().url;
        $(".navbar-search").val("");
        window.open(url, '_blank').focus();
      }
    },
    requestDelay: 500
  };

  var address_options = {
    highlightPhrase: false,
    adjustWidth: false,
    getValue: function (element) {
      return
        "<div class='media d-flex align-items-center border-bottom pt-1 pb-2'>" +
        "  <div class='media-body ml-3'>" +
        "    <h4 class='mb-2' title='" + $(element).prop("title") + "'>" + $(element).prop("title_trunc") + "</h4>" +
        "    <h6 class='mb-0' title='Address Line 1'>" + $(element).prop("address_line_1") + "</h6>" +
        "    <h6 class='mb-0' title='Address Line 2 & 3'>" + $(element).prop("address_line_2") + ", " + $(element).prop("address_line_3") + "</h6>" +
        "    <h6 class='mb-0'>" + $(element).prop("city_state_zip_country") + "</h6>" +
        "    <div>" +
        "      <i class='mdi mdi-cellphone-iphone text-primary tx-12' title='Phone Number'></i><span class='mr-2 text-muted tx-12' title='Phone Number'> " + $(element).prop("phone") + "</span>" +
        "      <i class='mdi mdi-filter-variant text-primary tx-11' title='Address Association'></i><span class='text-muted tx-11' title='Address Association'> " + $(element).prop("association") + " (" + $(element).prop("association_type") + ")</span>" +
        "    </div>" +
        "  </div>" +
        "</div>";
    },
    url: function(phrase) {
      return "/autocomplete_addresses.json?q=" + phrase;
    },
    categories: [
      {
        listLocation: "address_search",
        header: "<div class='font-weight-bold nav-title pl-3 pt-1 pb-1 tx-16 ml-n2 mr-n2'>Addresses</div>"
      }
    ],
    list: {
      onChooseEvent: function() {
        var url =  $(".navbar-search").getSelectedItemData().url;
        $(".navbar-search").val("");
        window.open(url, '_blank').focus();
      }
    },
    requestDelay: 500
  };

});
