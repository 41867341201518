/*global $*/
/*global bootbox*/

import 'stylesheets/employees-global.scss';

$(document).on("turbolinks:load", function() {

//----------------------------------------------------------------------------//
//---------- EMPLOYEES FORM RELATED ------------------------------------------//
//----------------------------------------------------------------------------//

  if ($('.employee-validate').length > 0) {

    $(".add_child").hide(); // hides main button for adding additional address slots since it's handled by a static 'add_file_button' button on top of the div
    $('label.required').append('<strong> *</strong>'); // adds a "required" star next to each Inputs label
    $('label.required-s span').append('<strong> *</strong>'); // adds a "required" star next to each Selects label
  
    $(".employee-validate").validate({
      ignore: ".ignore",
  		rules: {
  		// 	"supplier[brand_name]": {required: true, maxlength: 80},
  		},
  		messages: {
  		// 	"supplier[brand_name]": "Brand name is required",
  		},
      onfocusout: function(element) {$(element).valid()},
      focusCleanup: true,
      errorClass: "myErrorClass",
      errorPlacement: function (error, element) {
        error.insertAfter(element.parent());
      },
      highlight: function (element, errorClass, validClass) {
        var elem = $(element);
        if (elem.hasClass("select2-hidden-accessible")) {
          elem.parent().find(".select2-selection").addClass(errorClass);
        } 
        else {
          elem.addClass(errorClass);
        }
      },    
      unhighlight: function (element, errorClass, validClass) {
        var elem = $(element);
        if (elem.hasClass("select2-hidden-accessible")) {
          elem.parent().find(".select2-selection").removeClass(errorClass);
          elem.parent().next("label").remove();
        }
        else {
          elem.removeClass(errorClass);
          elem.parent().next("label").remove();
        }
      },
  		invalidHandler: function(e, validator) {
  			var errors = validator.numberOfInvalids();
  			if (errors) {
  				var message = errors == 1
  					? 'You missed 1 required field. Check below tabs for highlighted fields'
  					: 'You missed ' + errors + ' required fields.  Check below tabs for highlighted fields';
  				$("div.validatorErrorList span").html(message);
  				$("div.validatorErrorList").show();
  			}
  			else {
  				$("div.validatorErrorList").hide();
  			}
  		}
    });
  
    // Monkeypatch so all select2 fields actually clear to 'nil' when the 'X' is clicked
    $(document).on('select2:clear', '.select2-hidden-accessible', function (e) {
      $(this).append('<option value="" selected="selected"></option>');
    });
  
    $("#employeeFirstSubmit").on("click", function(e) {
      e.preventDefault();
      if ($(".employee-validate").valid()) {
        $("#jstemplates_employee_pay_rates").html("");
        $("#jstemplates_employee_addresses").html("");
        $("#jstemplates_employee_attachments").html("");
        $("#employeeSubmit").click();
      }
      else {
        return false;
      }
    });
  
    // This facilitates deleting the attached photo via AJAX
    $(".employee-photo-attachment-delete").on("ajax:success", function() {
      var parent = $(this).closest(".image-body").find("img");
      parent.slideUp( "slow", function() { parent.remove() } );
      $(this).slideUp( "slow", function() { $(this).remove() } );
    });

  //---- Attachments Related -----------------------------------------------------//
  
    // Child Fields Template Loader inherited from suppliers_helper.rb for Associated Attachments
    $(document).on('click', 'a.add_child_employee_attachment', function() {
      var association, new_id, regexp, template, fields;
      association = $(this).attr('data-association');
      template = $('#' + association + '_fields_template').html();
      regexp = new RegExp('new_' + association, 'g');
      new_id = (new Date).getTime();
      $(this).parent().before(template.replace(regexp, new_id));
      fields = $(this).parent().prev(".fields_employee_attachment");
      // The "jstemplates" hidden field needs the ".ignore" class to ensure it
      // doesn't get picked up by Jquery Validator.  This line removes it.
      fields.find("input").removeClass("ignore");
      fields.find("input[name$='[description]']").each(function() {
        // rules for nested file fields need to be generated after the object is created
        $(this).rules("add", {
          messages: {
            required: `A title is required. If you don't intend to create this attachment, please click the "Remove" button below`
          }
        });
      });
      // ActionText Trix ID's need to be manually created, otherwise it will duplicate
      // itself as "trix-toolbar-1" on all instances of a Trix Editor
      fields.find("trix-toolbar").attr("id", "trix-toolbar-" + new_id);
      fields.find("trix-editor").attr("trix-id", new_id).attr("toolbar", "trix-toolbar-" + new_id);
      fields.hide();
      fields.slideDown("fast");
      return false;
    });
  
    $(document).on("click", 'a.remove_child_employee_attachment', function() {
      var hidden_field = $(this).prev('input[type=hidden]')[0];
      var fields = $(this).parents('.fields_employee_attachment');
      if(hidden_field) {
        hidden_field.value = '1';
      }
      $(this).parents('.fields_employee_attachment').slideUp("slow", function() {
        fields.remove();
      });
      return false;
    });
  
    $(document).on("change keyup", ".attachment-description", function() {
      var new_description = $(this).val();
      $(this).closest(".edit-attachment-fields").find(".attachment-title").text(new_description);
    });
  
    $(".add_attachment_button").on("click", function() {
      $(".add_child_employee_attachment").click();
    });
    
    // This facilitates deleting a file via AJAX
    $(".employee-attachment-delete").on("ajax:success", function() {
      var parent = $(this).closest("li");
      parent.slideUp( "slow", function() { parent.remove() } );
    });
  
  //---- Addresses Related -----------------------------------------------------//
  
    $(".existingAddress").find(".addressState").select2({
      placeholder: "State",
      allowClear: true,
    }).on("change", function() {
      $(this).valid();
    });
    
    $(".existingAddress").find(".addressCountry").select2({
      placeholder: "Country",
      allowClear: true
    }).on("change", function() {
      $(this).valid();
    });
    
    $(".existingAddress").find(".select2-basic-nosearch").select2({
      minimumResultsForSearch: Infinity, // No search box visible
      // allowClear: true
    }).on("change", function() {
      $(this).valid();
    });
    
    $(".add_address_button").on("click", function() {
      $(".add_child_employee_address").click();
    });

    // Child Fields Template Loader inherited from application_helper.rb for Associated Addresses
    $(document).on('click', 'a.add_child_employee_address', function() {
      var association, new_id, regexp, template, fields;
      association = $(this).attr('data-association');
      template = $('#' + association + '_fields_template').html();
      regexp = new RegExp('new_' + association, 'g');
      new_id = (new Date).getTime();
      $(this).parent().before(template.replace(regexp, new_id));
      // The "jstemplates" hidden field needs the ".ignore" class to ensure it
      // doesn't get picked up by Jquery Validator.  This line removes it.
      fields = $(this).parent().prev(".fields_employee_address");
      fields.find("input").removeClass("ignore");
      fields.find(".addressState").select2({
        placeholder: "State",
        allowClear: true,
      }).on("change", function() {
        $(this).valid();
      });
      
      fields.find(".select2-basic").select2({
        allowClear: true
      }).on("change", function() {
        $(this).valid();
      });
      
      fields.find(".select2-basic-nosearch").select2({
        minimumResultsForSearch: Infinity, // No search box visible
        // allowClear: true
      }).on("change", function() {
        $(this).valid();
      });
  
      fields.find(".phone_mask").inputmask({ mask: "(999) 999-9999[-999999]", greedy: false });
      fields.find(".zip_mask").inputmask({ mask: "99999[-9999]", greedy: false });
      // ActionText Trix ID's need to be manually created, otherwise it will duplicate
      // itself as "trix-toolbar-1" on all instances of a Trix Editor
      fields.find("trix-toolbar").attr("id", "trix-toolbar-" + new_id);
      fields.find("trix-editor").attr("trix-id", new_id).attr("toolbar", "trix-toolbar-" + new_id);
      fields.hide();
      fields.slideDown("fast");
      return false;
    });
  
    $(document).on("click", 'a.remove_child_employee_address', function() {
      var hidden_field = $(this).prev('input[type=hidden]')[0];
      var fields = $(this).parents('.fields_employee_address');
      if(hidden_field) {
        hidden_field.value = '1';
      }
      $(this).parents('.fields_employee_address').slideUp("slow", function() {
        fields.remove();
      });
      return false;
    });
  
    $(document).on("change keyup", ".addressState", function() {
      // This populates the hidden "State" field since a select_tag is used 
      var state = $(this).find("option:selected").val();
      $(this).closest(".fields_employee_address").find(".addressStateHidden").val(state);
    });

    $(document).on("click", ".address_edit_remove_button", function() {
      var fields = $(this).closest(".existingAddress");
      bootbox.confirm("Are you sure you want to remove this entry?", function(result){
        if (result === true) {
          fields.find(".remove_child_edit_address").attr("value", "true");
          fields.find("row").html("");
          fields.slideUp("slow");
        }
      });
    });

  //---- Pay Rates Related ---------------------------------------------//

    // Child Fields Template Loader inherited from products_helper.rb for Item Costs
    $(document).on('click', 'a.add_child_pay_rate', function() {
      var association, new_id, regexp, template, fields;
      association = $(this).attr('data-association');
      template = $('#' + association + '_fields_template').html();
      regexp = new RegExp('new_' + association, 'g');
      new_id = (new Date).getTime();
      // $(this).parent().prev().find('.itemCostSelectTable').after(template.replace(regexp, new_id));
      // fields = $(this).parent().prev(".fields_item_costs");
      $(this).parent().prev().append(template.replace(regexp, new_id));
      fields = $(this).parent().prev().find(".fields_employee_pay_rate").last();
      // The "jstemplates" hidden field needs the ".ignore" class to ensure it
      // doesn't get picked up by Jquery Validator.  This line removes it.
      fields.find("input").removeClass("ignore");
      fields.find("select").removeClass("ignore");
  
      $(".scroll").bind('mousewheel', function() {});
      fields.hide();
      fields.slideDown("fast");
      return false;
    });
  
    $(document).on("click", '.remove_child_pay_rate', function() {
      var hidden_field = $(this).prev('input[type=hidden]')[0];
      var fields = $(this).closest('.fields_employee_pay_rate');
      if(hidden_field) {
        hidden_field.value = '1';
      }
      $(this).closest('.fields_employee_pay_rate').slideUp("slow", function() {
        fields.remove();
      });
    });

    $(".add_pay_rate_button").on("click", function() {
      $(".add_child_pay_rate").click();
    });

    $(document).on("click", ".pay_rate_edit_remove_button", function() {
      var fields = $(this).closest("tr");
      bootbox.confirm("Are you sure you want to remove this entry?", function(result){
        if (result === true) {
          fields.find(".remove_child_edit_pay_rate").attr("value", "true");
          fields.find("td").html("");
          fields.slideUp("slow");
        }
      });
    });

  }

//----------------------------------------------------------------------------//
//---------- EMPLOYEES INDEX RELATED -----------------------------------------//
//----------------------------------------------------------------------------//

//----------------------------------------------------------------------------//
//---------- EMPLOYEES SHOW RELATED ------------------------------------------//
//----------------------------------------------------------------------------//

});
