/*global $*/
/*global Option*/
/*global bootbox*/
/*global yadcf*/
/*global toastr*/
/*global clipboardData*/

import 'stylesheets/orders-global.scss';

$(document).on('turbolinks:load', function() {

//----------------------------------------------------------------------------//
//---------- ORDER BATCHES FORM RELATED --------------------------------------//
//----------------------------------------------------------------------------//

  if ($('.order_batch-validate').length > 0) {
  
    $('.add_child').hide();
    $('label.required').append('<strong> *</strong>'); // adds a 'required' star next to each Inputs label
    $('label.required-s span').append('<strong> *</strong>'); // adds a 'required' star next to each Selects label
    $('.order-batch-default-select option:first-child').attr('disabled', true);

    $('.field-highlightable').each(function() {
      if ($(this).val().length > 0) {
        $(this).removeClass('field-highlight');
      } else {
        $(this).addClass('field-highlight');
      }
    });

    $('.order_batch-validate').validate({
      ignore: '.ignore',
  		rules: {
  		// 	'product[model_number]': {required: true, maxlength: 80},
  		// 	'product[supplier_part_number]': {required: true, maxlength: 80},
  		},
  		messages: {
  		// 	'product[supplier_part_number]': 'If the Manufacturer Part Number & the Model Number are the same, enter the same SKU in both fields.',
  		// 	'product[model_number]': 'If the Model Number & the Manufacturer Part Number are the same, enter the same SKU in both fields.'
  		},
      onfocusout: function(element) {$(element).valid()},
      focusCleanup: true,
      errorClass: 'myErrorClass',
      errorPlacement: function (error, element) {
        error.insertAfter(element.parent());
      },
      highlight: function (element, errorClass, validClass) {
        var elem = $(element);
        var tabId = elem.closest('.tab-pane').attr('aria-labelledby');
        if (elem.hasClass('select2-hidden-accessible')) {
          elem.parent().find('.select2-selection').addClass(errorClass);
          elem.closest('.card-body').find('.nav-link[id="'+tabId+'"]').addClass(errorClass);
        } 
        else {
          elem.addClass(errorClass);
          elem.closest('.card-body').find('.nav-link[id="'+tabId+'"]').addClass(errorClass);
        }
      },    
      unhighlight: function (element, errorClass, validClass) {
        var elem = $(element);
        var tabId = elem.closest('.tab-pane').attr('aria-labelledby');
        if (elem.hasClass('select2-hidden-accessible')) {
          elem.parent().find('.select2-selection').removeClass(errorClass);
          elem.parent().next('label').remove();
          elem.closest('.card-body').find('.nav-link[id="'+tabId+'"]').removeClass(errorClass);
        }
        else {
          elem.removeClass(errorClass);
          elem.parent().next('label').remove();
          elem.closest('.card-body').find('.nav-link[id="'+tabId+'"]').removeClass(errorClass);
        }
      },
  		invalidHandler: function(e, validator) {
  			var errors = validator.numberOfInvalids();
  			if (errors) {
  				var message = errors == 1
  					? 'You missed 1 required field. Check below tabs for highlighted fields'
  					: 'You missed ' + errors + ' required fields.  Check below tabs for highlighted fields';
  				$('div.validatorErrorList span').html(message);
  				$('div.validatorErrorList').slideDown('fast');
  			}
  			else {
  				$('div.validatorErrorList').slideUp('slow');
  			}
  		}
    });
  
    $('#orderBatchFirstSave').on('click', function(e) {
      e.preventDefault();
      if ($('.order_batch-validate').valid()) {
        window.onbeforeunload = null;
        $('.template').remove();
        // $('#jstemplates_order_batch_attachments').html('');
        // $('#jstemplates_order').html('');
        $('.jstemplates_order_line_item').html('');
        $('.jstemplates_line_item_tracking_numbers').html('');
        // $('#edit_line_item_template').html('');
        // $('#edit_tracking_number_template').html('');
        $('.loading-overlay').show();
        $('#orderBatchSave').click();
      }
      else {
        return false;
      }
    });
  
    $('#orderBatchFirstSubmit').on('click', function(e) {
      e.preventDefault();
      if ($('.order_batch-validate').valid()) {
        window.onbeforeunload = null;
        $('.template').remove();
        // $('#jstemplates_order_batch_attachments').html('');
        // $('#jstemplates_order').html('');
        $('.jstemplates_order_line_item').html('');
        $('.jstemplates_line_item_tracking_numbers').html('');
        // $('#edit_line_item_template').html('');
        // $('#edit_tracking_number_template').html('');
        $('.loading-overlay').show();
        $('#orderBatchSubmit').click();
      }
      else {
        return false;
      }
    });
  
    // on first focus (bubbles up to document), open the menu
    $(document).on('focus', '.select2-selection.select2-selection--single', function (e) {
      $(this).closest('.select2-container').siblings('select:enabled').select2('open');
    });
  
    // steal focus during close - only capture once and stop propogation
    $('select.select2').on('select2:closing', function (e) {
      $(e.target).data('select2').$selection.one('focus focusin', function (e) {
        e.stopPropagation();
      }).removeClass('field-highlight');
    });
    
    $(document).on('change keyup', '.field-highlightable', function() {
      if ($(this).val().length > 0) {
        $(this).removeClass('field-highlight');
        $(this).closest('td').find('.select2-selection').removeClass('field-highlight');
      } else if ($(this).val().length == 0){
        $(this).addClass('field-highlight');
        $(this).closest('td').find('.select2-selection').addClass('field-highlight');
      }
    });

  //---- Addresses Related -----------------------------------------------------//
  
    $(document).on('change keyup', '.addressState', function() {
      // This populates the hidden 'State' field in lieu of the select_tag
      var state = $(this).find('option:selected').val();
      $(this).closest('.order-batch-order-address-fields').find('.addressStateHidden').val(state);
    });
    
    $(document).on('change keyup', '.addressCountry', function() {
      var fields = $(this).closest('.order-batch-order-address-fields');
      if ($(this).find('option:selected').val() == 'US') {
        fields.find('.phone_mask').inputmask({ mask: '(999) 999-9999[-999999]', greedy: false, showMaskOnHover: true });
        fields.find('.zip_mask').inputmask({ mask: '99999[-9999]', greedy: false, showMaskOnHover: true });
      }
      else {
        fields.find('.phone_mask').inputmask('remove');
        fields.find('.zip_mask').inputmask('remove');
      }
    });
    
  
  //---- Attachments Related -----------------------------------------------------//
  
    // Child Fields Template Loader inherited from inbound_shipments_helper.rb for Associated Attachments
    $(document).on('click', 'a.add_child_order_batch_attachments', function() {
      var association, new_id, regexp, template, fields;
      association = $(this).attr('data-association');
      template = $('#' + association + '_fields_template').html();
      regexp = new RegExp('new_' + association, 'g');
      new_id = (new Date).getTime();
      $(this).parent().before(template.replace(regexp, new_id));
      fields = $(this).parent().prev('.fields_order_batch_attachments');
      // The 'jstemplates' hidden field needs the '.ignore' class to ensure it
      // doesn't get picked up by Jquery Validator.  This line removes it.
      fields.find('input').removeClass('ignore');
      fields.find('input[name$="[description]"]').each(function() {
        // rules for nested file fields need to be generated after the object is created
        $(this).rules('add', {
          messages: {
            required: `A title is required. If you don't intend to save this attachment, please click the 'Remove' button below`
          }
        });
      });
      // ActionText Trix ID's need to be manually created, otherwise it will duplicate
      // itself as 'trix-toolbar-1' on all instances of a Trix Editor
      fields.find('trix-toolbar').attr('id', 'trix-toolbar-' + new_id);
      fields.find('trix-editor').attr('trix-id', new_id).attr('toolbar', 'trix-toolbar-' + new_id);
      fields.hide();
      fields.slideDown('fast');
      return false;
    });
  
    $(document).on('click', 'a.remove_child_order_batch_attachments', function() {
      var hidden_field = $(this).prev('input[type=hidden]')[0];
      var fields = $(this).parents('.fields_order_batch_attachments');
      if(hidden_field) {
        hidden_field.value = '1';
      }
      $(this).parents('.fields_order_batch_attachments').slideUp('slow', function() {
        fields.remove();
      });
      return false;
    });
  
    $('.add_order_batch_attachment_button').on('click', function() {
      $('.add_child_order_batch_attachments').click();
    });
    
    // This facilitates deleting a file via AJAX
    $('.order_batch-attachment-delete').on('ajax:success', function() {
      var parent = $(this).closest('li');
      parent.slideUp( 'slow', function() {
        parent.remove();
      });
    });
  
  //---- New Order Related -----------------------------------------------------//
  
    // Child Fields Template Loader inherited from order_batches_helper.rb for Associated Attachments
    $(document).on('click', 'a.add_child_order', function() {
      var association, new_id, regexp, template, fields;
      association = $(this).attr('data-association');
      template = $('#' + association + '_fields_template').html();
      regexp = new RegExp('new_' + association, 'g');
      new_id = (new Date).getTime();
      $(this).parent().before(template.replace(regexp, new_id));
      fields = $(this).parent().prev('.fields_order');
      // The 'jstemplates' hidden field needs the '.ignore' class to ensure it
      // doesn't get picked up by Jquery Validator.  This line removes it.
      fields.find('input').removeClass('ignore');

      fields.find('.orderTypeSelect').select2({
        selectOnClose: true,
        placeholder: 'Order Type...',
      });
      fields.find('.supplierSearch').select2({
        selectOnClose: true,
        allowClear: true,
        placeholder: 'Supplier...',
      }).val($('#supplier-default-select').find('option:selected').val()).trigger('change');
  
      fields.find('.retailerSearch').select2({
        selectOnClose: true,
        allowClear: true,
        placeholder: 'Retailer...',
      }).val($('#retailer-default-select').find('option:selected').val()).trigger('change');
  
      fields.find('.carrierSearch').select2({
        selectOnClose: true,
        allowClear: true,
        placeholder: 'Carrier...',
      }).val($('#carrier-default-select').find('option:selected').val()).trigger('change').children().removeClass('field-highlight');
      
      fields.find('.field-highlightable').each(function() {
        var hf = $(this).closest('td');
        hf.find('.select2-selection').addClass('field-highlightable').addClass('field-highlight');
        if ($(this).val().length > 0) {
          $(this).next().find('.field-highlight').removeClass('field-highlight');
        }
      });
      
      fields.find('.orderStatusSelect').select2({
        selectOnClose: true,
        placeholder: 'Order Status...',
      }).val($('#order-status-default-select').find('option:selected').val()).trigger('change');
    
      // if the Default 'Invoiced' box is ticked, the 'Invoiced' box in each order will also be ticked
      fields.find('.invoiced-check').prop('checked', $('#invoiced_default').is(':checked'));
      
      // if 'Order Status' default is set to 2 (Shipped), automatically mark the ship date as today
      if ($('#order-status-default-select').val() == '2') {
        fields.find('.ship-date-select').val(fields.find('.ship-date-select').data('today')).removeClass('field-highlight');
      }
      
      // monkeypatch to fix issue of search field not being selected on open.  https://github.com/select2/select2/issues/2311#issuecomment-822473913
      $(document).on('select2:open', e => {
        const id = e.target.id;
        const target = document.querySelector(`[aria-controls=select2-${id}-results]`);
        target.focus();
      });
  
      // on first focus (bubbles up to document), open the menu
      $(document).on('focus', '.select2-selection.select2-selection--single', function (e) {
        $(this).closest('.select2-container').siblings('select:enabled').select2('open');
      });
    
      // steal focus during close - only capture once and stop propogation
      $('select.select2').on('select2:closing', function (e) {
        $(e.target).data('select2').$selection.one('focus focusin', function (e) {
          e.stopPropagation();
        });
      });
  
      fields.find('[data-toggle="tooltip"]').tooltip({trigger: 'hover'});
  
      // ActionText Trix ID's need to be manually created, otherwise it will duplicate
      // itself as 'trix-toolbar-1' on all instances of a Trix Editor
      fields.find('trix-toolbar').attr('id', 'trix-toolbar-' + new_id);
      fields.find('trix-editor').attr('trix-id', new_id).attr('toolbar', 'trix-toolbar-' + new_id);
      
      if ($('#warranty_order').is(':checked')) {
        fields.find('.add_order_custom_item_button').click();
        fields.find('.invoiced-check').prop('checked', 'checked');
        fields.find('.add_order_address_button').click();
        fields.find('.orderTypeSelect').val('2').trigger('change');
        fields.find('input[name$="[po_number]"]').val('N/A').removeClass('field-highlight');
        fields.find('.add_ship_charges_button').click();
      } else {
        fields.find('.add_order_line_item_button').click();
      }

      fields.hide().slideDown('fast');
  
      return false;
    });
  
    // Makes the padding around the Invoiced checkbox less difficult to click
    $(document).on('click', '.order_batch_invoiced_button, .invoiced-check-label', function () {
        var cb = $(this).closest('.expandCheck').find(":checkbox");
        cb.prop("checked", !cb.prop("checked"));
    });
    
    $(document).on('click', '.ship_date_add_today', function() {
      var ship_date = $(this).closest('.row').find('.ship-date-select');
      ship_date.each(function() {
        if ($(this).val() == '') {
          // only adds todays date to Ship Date fields that are blank
          $(this).val($(this).data('today'));
          $(this).removeClass('field-highlight');
          $(this).closest('.orderTable').find('.orderStatusSelect').val('2').trigger('change');
          $(this).closest('.orderTable').find('.orderStatusEditSelect').val('2').trigger('change');
        }
      });
    });
  
    // sets the Order Status to 'Shipped' if a Ship Date is middle clicked, removed if right clicked
    $(document).on('change mousedown', '.ship-date-select', function() {
      if (event.which === 2) {
        $(this).val($(this).data('today'));
        $(this).removeClass('field-highlight');
        $(this).closest('.orderTable').find('.orderStatusSelect').val('2').trigger('change');
        $(this).closest('.orderTable').find('.orderStatusEditSelect').val('2').trigger('change');
      } else if (event.which === 3) {
        $(this).val('');
        $(this).addClass('field-highlight');
        $(this).closest('.orderTable').find('.orderStatusSelect').val('1').trigger('change');
        $(this).closest('.orderTable').find('.orderStatusEditSelect').val('1').trigger('change');
      }
    });
  
    // sets the Order Status to 'Shipped' if a Ship Date is entered manually
    $(document).on('change', '.ship-date-select', function() {
      if ($(this).val()) {
        $(this).closest('.orderTable').find('.orderStatusSelect').val('2').trigger('change');
        $(this).closest('.orderTable').find('.orderStatusEditSelect').val('2').trigger('change');
      }
    });
  
    $(document).on('mousedown', '.add_line_item_edit_custom_cost_button, .add_line_item_custom_cost_button', function() {
      if (event.which === 2) {
        $('.add_line_item_edit_custom_cost_button').click();
        $('.add_line_item_custom_cost_button').click();
      }
    });
  
    $(document).on('click', '.hide-order-button', function() {
      $(this).closest('.fields_order').slideUp('fast');
    });
  
    $(document).on('click', '.show_all_order_fields_button', function() {
      $('.fields_order').slideDown('fast');
    });
  
    $(document).on('click', '.hide_shipped_fields_button', function() {
      $('.orderStatusSelect').each(function() {
        if ($(this).val() == '2') {
          $(this).closest('.fields_order').slideUp('fast');
        }
      });
      $('.orderStatusEditSelect').each(function() {
        if ($(this).val() == '2') {
          $(this).closest('.fields_order').slideUp('fast');
        }
      });
    });

    // When pasting a copied array of comma separated tracking numbers,
    // disperse them into each tracking number field.
    // This is for the order-specific "Bulk Tracking..." import field.
    $(document).on('paste', '.bulk_import_tracking', function(e) {
      // Get the pasted data as text
      const pastedData = e.originalEvent.clipboardData.getData('text');
      // Split the pasted data using comma or new line
      const trackingNumbers = pastedData.split(/,|\r\n|\r|\n/);
      // Find the associated 'td' container
      const $parentTd = $(this).closest('.fields_order');
      // Filter out input fields with ID containing 'new_tracking_numbers'
      const $filteredInputs = $parentTd.find('input[name*="[tracking_number]"]').filter(function() {
        return !this.id.includes('new_tracking_numbers');
      });
      // Iterate through the filtered tracking number input fields within the same parent 'td' container
      $filteredInputs.each(function(index) {
        if (trackingNumbers[index]) {
          $(this).val(trackingNumbers[index].trim());
          $(this).removeClass('field-highlight');
        }
      });
      // Clear the input field on mouseout
      $(this).on('blur', function() {
        $(this).val('');
      });
    });

    // Same block as above, but this is for the global BULK_LIST_IMPORT import field.
    $(document).on('paste', '#bulk_list_import', function(e) {
      // Get the pasted data as text
      const pastedData = e.originalEvent.clipboardData.getData('text');
      // Split the pasted data using comma or new line
      const trackingNumbers = pastedData.split(/,|\r\n|\r|\n/);
      // Find the associated 'td' container
      const $parentTd = $(this).closest('#general-1');
      // Filter out input fields with ID containing 'new_tracking_numbers'
      const $filteredInputs = $parentTd.find('input[name*="[tracking_number]"]').filter(function() {
        return !this.id.includes('new_tracking_numbers');
      });
      // Iterate through the filtered tracking number input fields within the same parent 'td' container
      $filteredInputs.each(function(index) {
        if (trackingNumbers[index]) {
          $(this).val(trackingNumbers[index].trim());
          $(this).removeClass('field-highlight');
        }
      });
      // Clear the input field on mouseout
      $(this).on('blur', function() {
        $(this).val('');
      });
    });

    // Pasted tracking number data from the generated output from UPS Worldship
    // Gets automatically populated into each associated order
    $(document).on('paste', '#ups_worldship_import', function(e) {
      // Get the pasted data as text
      const pastedData = e.originalEvent.clipboardData.getData('text');
      // Split the pasted data by newline to get the rows
      const rows = pastedData.split('\n');
      // Initialize an empty object to store tracking numbers grouped by PO number
      let trackingNumbersByPoNumber = {};
      // Loop through each row (skipping the first one, as it's the header)
      for (let i = 1; i < rows.length; i++) {
        // Skip if the row is empty
        if (rows[i].trim() === '') {
          continue;
        }
        // Split the row by comma to get the fields
        const fields = rows[i].split(',');
        // Extract the PackageReference1 and PackageTrackingNumber (removing quotes)
        const packageReference1 = fields[0].replace(/"/g, '');
        const packageTrackingNumber = fields[2].replace(/"/g, '');
        // If there are no tracking numbers for this PO number yet, initialize an empty array
        if (!trackingNumbersByPoNumber[packageReference1]) {
          trackingNumbersByPoNumber[packageReference1] = [];
        }
        // Add the tracking number to the array for this PO number
        trackingNumbersByPoNumber[packageReference1].push(packageTrackingNumber);
      }
      // Now that we have the tracking numbers grouped by PO number, populate the inputs
      for (let poNumber in trackingNumbersByPoNumber) {
        // Find the matching Order by po_number
        const $order = $('.fields_order').filter(function() {
          return $(this).find('input[name*="[po_number]"]').val() == poNumber;
        });
        // If an Order is found
        if ($order.length > 0) {
          // Get the tracking numbers for this PO number
          const trackingNumbers = trackingNumbersByPoNumber[poNumber];
          // Find the Tracking Number inputs within the Order, excluding those whose id includes 'new_tracking_numbers'
          const $trackingNumbersInputs = $order.find('input[name*="[tracking_number]"]:not([id*="new_tracking_numbers"])');
          // Loop through the Tracking Number inputs
          $trackingNumbersInputs.each(function(index) {
            // If there's a tracking number for this input, set its value
            if (trackingNumbers[index]) {
              $(this).val(trackingNumbers[index]);
              $(this).removeClass('field-highlight');
            }
          });
        }
      }
      // Clear the input field on mouseout
      $(this).on('blur', function() {
        $(this).val('');
      });
    });

    // Automatically chooses selected attributes when adding a new order using these buttons
    function order_quick_add(selector, supplier, retailer, carrier, order_status) {
      $(document).on('click', selector, function() {
        $('#supplier-default-select').val(supplier);
        $('#retailer-default-select').val(retailer);
        $('#carrier-default-select').val(carrier);
        $('#order-status-default-select').val(order_status);
        $('#invoiced_default').prop('checked', 'checked');
        $('.add_order_button').click();
      });
    }
    const configurations = [
      {selector: '.lift_bridge_home_depot_ups', supplier: '5', retailer: '3',  carrier: '1',  orderStatus: '2'},
      {selector: '.lift_bridge_home_depot_ltl', supplier: '5', retailer: '3',  carrier: '4',  orderStatus: '1'},
      {selector: '.lift_bridge_lowes_fedex',    supplier: '5', retailer: '6',  carrier: '2',  orderStatus: '2'},
      {selector: '.lift_bridge_wayfair_fedex',  supplier: '5', retailer: '5',  carrier: '2',  orderStatus: '2'},
      {selector: '.lift_bridge_wayfair_ltl',    supplier: '5', retailer: '5',  carrier: '4',  orderStatus: '1'},
      {selector: '.lift_bridge_amazon_df',      supplier: '5', retailer: '1',  carrier: '1',  orderStatus: '2'},
      {selector: '.lift_bridge_amazon_fbm_ups', supplier: '5', retailer: '12', carrier: '1',  orderStatus: '2'},
      {selector: '.lift_bridge_amazon_fbm_ltl', supplier: '5', retailer: '12', carrier: '11', orderStatus: '1'},

      {selector: '.interior_innovations_home_depot_ups', supplier: '9', retailer: '3',  carrier: '1', orderStatus: '2'},
      {selector: '.interior_innovations_wayfair_fedex',  supplier: '9', retailer: '5',  carrier: '2', orderStatus: '2'},
      {selector: '.interior_innovations_etsy_ups',       supplier: '9', retailer: '14', carrier: '1', orderStatus: '2'},
      {selector: '.interior_innovations_amazon_fbm_ups', supplier: '9', retailer: '12', carrier: '1', orderStatus: '2'},

      {selector: '.bodo_home_depot_ups', supplier: '2', retailer: '3', carrier: '1', orderStatus: '2'},
      {selector: '.bodo_home_depot_ltl', supplier: '2', retailer: '3', carrier: '4', orderStatus: '1'},
      {selector: '.bodo_wayfair_fedex',  supplier: '2', retailer: '5', carrier: '2', orderStatus: '2'},
      {selector: '.bodo_wayfair_ltl',    supplier: '2', retailer: '5', carrier: '4', orderStatus: '1'}
    ];
    
    configurations.forEach(config => {
      order_quick_add(config.selector, config.supplier, config.retailer, config.carrier, config.orderStatus);
    });



    $(document).on('click', 'a.remove_child_order', function() {
      var hidden_field = $(this).prev('input[type=hidden]')[0];
      var fields = $(this).parents('.fields_order');
      if(hidden_field) {
        hidden_field.value = '1';
      }
      $(this).parents('.fields_order').slideUp('slow', function() {
        fields.remove();
      });
      return false;
    });
  
    // Monkeypatch so all select2 fields actually clear to 'nil' when the 'X' is clicked
    $(document).on('select2:clear', '.select2-hidden-accessible', function (e) {
      $(this).append('<option value="" selected="selected"></option>');
    });
  
    $(document).on('click', '.add_order_button', function() {
      $('.add_child_order').click();
      if ( $('.fields_container').find('.offscreen_check:last').is(':off-screen') ) {
        toastr.options = {
          "closeButton": false,
          "debug": false,
          "newestOnTop": false,
          "progressBar": true,
          "positionClass": "toast-bottom-center",
          "preventDuplicates": false,
          "onclick": null,
          "showDuration": "5",
          "hideDuration": "5",
          "timeOut": "5",
          "extendedTimeOut": "5",
          "showEasing": "linear",
          "hideEasing": "swing",
          "showMethod": "fadeIn",
          "hideMethod": "slideUp"
        };
        toastr.info('Order Added Below');
      }
    });

    $('.add_tracking_fields_button').on('click', function() {
      $('.add_line_item_edit_tracking_number_button').each(function() {
        // 'existing_fields' counts the number of tracking fields that are already visible
        var existing_fields = $(this).closest('.tracking-number-fields').find('.trackingNumberTable').length;
        // 'order_qty' grabs the number of tracking fields that should be populated based on the order quantity
        var order_qty = $(this).attr('data-quantity');
        // 'child_count' parses the order quantity & the child count of the item
        var child_count = $(this).attr('data-child_count');
        // if the carrier_type is Small Parcel (1), add the number of new fields minus existing fields
        if ($(this).attr('data-carrier_type') == 1) {
          for (var i = 0; i < order_qty * child_count - existing_fields; i++){
            $(this).click();
          }
        // if the carrier_type is LTL, use the child_count quantity instead
        } else {
          for (var i = existing_fields; i < 1; i++){
            $(this).click();
          }
        }
      });
    });
  
    $(document).on('click', '.add_order_reference_button', function() {
      $(this).closest('.row').find('.reference_field_div').hide().slideDown('fast');
    });
  
    $(document).on('click', '.add_order_notes_button', function() {
      $(this).closest('.fields_order').find('.orderNotesTable').hide().slideDown('fast');
    });
  
    $(document).on('click', '.add_order_address_button', function() {
      var address_fields = $(this).closest('.fields_order').find('.order-batch-order-address-fields');
      address_fields.slideDown('fast');
      address_fields.find('.addressState').select2({
        placeholder: 'State',
        selectOnClose: true,
        allowClear: true,
      }).on('change', function() {
        $(this).valid();
      });
      
      address_fields.find('.addressCountry').select2({
        placeholder: 'Country',
        allowClear: true
      }).on('change', function() {
        $(this).valid();
      });
  
      if (address_fields.find('.addressCountry').find('option:selected').val() == 'US') {
        address_fields.find('.phone_mask').inputmask({ mask: '(999) 999-9999[-999999]', greedy: false, showMaskOnHover: true });
        address_fields.find('.zip_mask').inputmask({ mask: '99999[-9999]', greedy: false, showMaskOnHover: true });
      }
      else {
        address_fields.find('.phone_mask').inputmask('remove');
        address_fields.find('.zip_mask').inputmask('remove');
      }
    });
  
    $(document).on('click', '.add_order_line_item_button', function() {
      $(this).closest('.fields_order').find('.lineItemsTableHead').show();
      $(this).closest('.fields_order').find('.add_child_order_line_item').click();
    });
  
    $(document).on('click', '.add_order_custom_item_button', function() {
      $(this).closest('.fields_order').find('.lineItemsTableHead').show();
      $(this).closest('.fields_order').find('.add_child_order_line_item').click();
      var fields = $(this).closest('.fields_order').find('.lineItemsTableHead').find('.fields_order_line_item').last();
      fields.find('.select2').hide();
      fields.find('.order-quantity').attr('readonly', false).val('1');
      fields.find('.current-quantity').hide();
      fields.find('.custom-item-field').show();
      fields.find('.custom-item-field').parent().addClass('order-batch-custom-item-width');
      fields.find('.custom-description-field').show();
      fields.find('.custom-description-field').parent().addClass('order-batch-custom-description-width');
    });
  
    $(document).on('click', '.add_line_item_tracking_number_button', function() {
      $(this).closest('.fields_order_line_item').find('.add_child_line_item_tracking_number').click();
    });
  
    $(document).on('click', '.add_line_item_custom_cost_button', function() {
      $(this).closest('.fields_order_line_item').find('.custom-cost-overwrite-check').prop('checked', 'checked');
      $(this).closest('.fields_order_line_item').find('.custom-cost-fields').slideDown('fast');
    });

    // This facilitates deleting a file via AJAX
    $('.order-attachment-delete').on('ajax:success', function() {
      var parent = $(this).closest('li');
      parent.slideUp( 'slow', function() {
        parent.remove();
      });
    });
  
  //---- New Order Line Item -----------------------------------------------------//
  
    // Child Fields Template Loader inherited from order_batches_helper.rb for Line Items
    $(document).on('click', 'a.add_child_order_line_item', function() {
      var association, new_id, regexp, template, fields;
      association = $(this).attr('data-association');
      template = $(this).closest('.fields_order').find('#' + association + '_fields_template').html();
      regexp = new RegExp('new_' + association, 'g');
      new_id = (new Date).getTime();
      $(this).parent().before(template.replace(regexp, new_id));
      // The 'jstemplates' hidden field needs the '.ignore' class to ensure it
      // doesn't get picked up by Jquery Validator.  This line removes it.
      fields = $(this).parent().prev('.fields_order_line_item');
      fields.find('select').removeClass('ignore');
      fields.find('.productSearch').select2({
        selectOnClose: true,
        allowClear: true,
        placeholder: 'Select Item...',
        ajax: {
          url: '/json_dropdown_products_all.json',
          dataType: 'json',
          type: 'GET',
          processResults: function (data, params) {
            params.page = data.page || 1;
            return {
              results: $.map(data.items, function (item) {
                return {
                  text: item.sku + ' | ' + item.desc,
                  id: item.id,
                  quantity_on_hand: item.qty,
                  child_count: item.child_count
                };
              }),
              pagination: {
                more: data.current_page
              }
            };
          },
          cache: true
        },
        templateSelection: function(container) { // adds the current quantity on hand to the items 'data-quantity' attribute
            $(container.element).attr('data-quantity', container.quantity_on_hand).attr('data-child_count', container.child_count);
            return container.text;
        }
      });
  
      // monkeypatch to re-enable scrollwheel on number fields
      fields.find('[data-toggle="tooltip"]').tooltip({trigger: 'hover'});
      fields.find('.add_line_item_tracking_number_button').click();
      fields.hide();
      fields.slideDown('fast');
      $('.scroll').bind('mousewheel', function() {});
  
      return false;
    });
  
    $(document).on('click', 'a.remove_child_order_line_item', function() { // Triggers the removal of the relevant 'Item Select' table row when clicked
      var tr = $(this).closest('tr');
      var hidden_field = $(this).prev('input[type=hidden]')[0];
      tr.find('.productSearch').val([]).trigger('change');
      if(hidden_field) {
        hidden_field.value = '1';
      }
      $(this).parents('.fields_order_line_item').slideUp('fast', function() {
        $(this).remove();
      });
      return false;
    });
  
    $(document).on('change', '.productSearch', function() {
      var tr = $(this).closest('tr');
      var carrier = $(this).closest('.fields_order').find('select[id$="carrier_id"]').find('option:selected').val();
      tr.find('.order-quantity').attr('readonly', false).val('1'); // Enables the quantity fields for this given field
      tr.find('.received-quantity').attr('readonly', false); // Enables the quantity fields for this given field
      tr.find('.current-quantity').val($(this).find('option:selected').data('quantity')); // imports the quantity from the 'data-quantity' attribute of the selected option
      tr.find('.add_line_item_edit_tracking_number_button')
          .attr('data-child_count', $(this).find('option:selected').data('child_count')) // Imports the number of child items the item has
          .attr('data-quantity', tr.find('.order-quantity').val())
          .attr('data-carrier_type', carrier);
  
    });
  
  //---- Line Item Tracking Number ------------------------------------------------//
  
    // Child Fields Template Loader inherited from order_batches_helper.rb for Line Items
    $(document).on('click', 'a.add_child_line_item_tracking_number', function() {
      var association, new_id, regexp, template, fields;
      association = $(this).attr('data-association');
      template = $(this).closest('.fields_order').find('#' + association + '_fields_template').html();
      template = $(this).closest('.fields_order_line_item').find('#' + association + '_fields_template').html();
      regexp = new RegExp('new_' + association, 'g');
      new_id = (new Date).getTime();
      $(this).before(template.replace(regexp, new_id));
      // The 'jstemplates' hidden field needs the '.ignore' class to ensure it
      // doesn't get picked up by Jquery Validator.  This line removes it.
      fields = $(this).prev('.fields_order_line_item_tracking_number');
      fields.find('select').removeClass('ignore');
      // monkeypatch to re-enable scrollwheel on number fields
      $('.scroll').bind('mousewheel', function() {});
      fields.find('[data-toggle="tooltip"]').tooltip({trigger: 'hover'});
      fields.find('.shipping-cost-select').addClass('ignore');
  
      fields.hide();
      fields.slideDown('fast');
  
      $('.add_ship_charges_button').on('click', function() {
        $(this).hide();
        $(this).closest('tr').find('.cost_fields').show();
      });
  
      return false;
    });
  
    $(document).on('change', '.returned-check', function() {
      var fields = $(this).closest('.tracking-number-fields');
      if(this.checked) {
        fields.find('.returned-fields').slideDown('fast');
      } else {
        fields.find('.returned-quantity').val('0');
        fields.find('.defective-quantity').val('0');
        fields.find('.returned-fields').slideUp('fast');
        fields.find('.return-status').val('not_returned');
      }
    });
  
    $(document).on('click', '.shipping-cost-select', function() {
      if ($(this).val() == '0.00') {
        $(this).select();
      }
    });
  
    $(document).on('change keyup', '.shipping-cost-select', function() {
      if ($(this).val() > 0) {
        $(this).next('.invoiced_status').val('pending');
      } else {
        $(this).next('.invoiced_status').val('not_applicable');
      }
    });
  
    $(document).on('click', 'a.remove_child_line_item_tracking_number', function() { // Triggers the removal of the relevant 'Item Select' table row when clicked
      var hidden_field = $(this).prev('input[type=hidden]')[0];
      if(hidden_field) {
        hidden_field.value = '1';
      }
      $(this).parents('.fields_order_line_item_tracking_number').slideUp('fast', function() {
        $(this).remove();
      });
      return false;
    });
  
  //---- Category Modal Related --------------------------------------------------//
  
    // monkeypatch to fix issue of search field not being selected on open.  https://github.com/select2/select2/issues/2311#issuecomment-822473913
    $(document).on('select2:open', e => {
      const id = e.target.id;
      const target = document.querySelector(`[aria-controls=select2-${id}-results]`);
      target.focus();
    });
  
  //---- Edit View Related -------------------------------------------------------//
  
    $('#orderBatchEditFirstSave').on('click', function(e) {
      e.preventDefault();
      if ($('.order_batch-validate').valid()) {
        window.onbeforeunload = null;
        $('.template').remove();
        // $('#jstemplates_order_batch_attachments').html('');
        // $('#jstemplates_order').html('');
        $('.jstemplates_order_line_item').html('');
        $('.jstemplates_line_item_tracking_numbers').html('');
        // $('#edit_line_item_template').html('');
        // $('#edit_tracking_number_template').html('');
        $('.loading-overlay').show();
        $('#orderBatchEditSave').click();
      }
      else {
        return false;
      }
    });
  
    $('#orderBatchEditFirstSubmit').on('click', function(e) {
      e.preventDefault();
      if ($('.order_batch-validate').valid()) {
        window.onbeforeunload = null;
        $('.template').remove();
        // $('#jstemplates_order_batch_attachments').html('');
        // $('#jstemplates_order').html('');
        $('.jstemplates_order_line_item').html('');
        $('.jstemplates_line_item_tracking_numbers').html('');
        // $('#edit_line_item_template').html('');
        // $('#edit_tracking_number_template').html('');
        $('.loading-overlay').show();
        $('#orderBatchEditSubmit').click();
      }
      else {
        return false;
      }
    });
  
    $('.orderTypeSelect').select2({
      selectOnClose: true,
      placeholder: 'Order Type...',
    });
  
    $('.orderEditSupplierSearch').select2({
      selectOnClose: true,
      allowClear: true,
      placeholder: 'Select Supplier...',
      ajax: {
        url: '/json_dropdown_suppliers.json',
        dataType: 'json',
        type: 'GET',
        processResults: function (data, params) {
          params.page = data.page || 1;
          return {
            results: $.map(data.items, function (item) {
              return {
                text: item.brand_name,
                id: item.id
              };
            }),
            pagination: {
              more: data.current_page
            }
          };
        },
        cache: true
      }
    });
  
    $('.orderEditRetailerSearch').select2({
      selectOnClose: true,
      allowClear: true,
      placeholder: 'Select Retailer...',
      ajax: {
        url: '/json_dropdown_retailers.json',
        dataType: 'json',
        type: 'GET',
        processResults: function (data, params) {
          params.page = data.page || 1;
          return {
            results: $.map(data.items, function (item) {
              return {
                text: item.name,
                id: item.id
              };
            }),
            pagination: {
              more: data.current_page
            }
          };
        },
        cache: true
      }
    });
  
    $('.orderEditCarrierSearch').select2({
      selectOnClose: true,
      allowClear: true,
      placeholder: 'Select Carrier...',
      ajax: {
        url: '/json_dropdown_carriers.json',
        dataType: 'json',
        type: 'GET',
        processResults: function (data, params) {
          params.page = data.page || 1;
          return {
            results: $.map(data.items, function (item) {
              return {
                text: item.name,
                id: item.id,
                carrier_type: item.carrier_type
              };
            }),
            pagination: {
              more: data.current_page
            }
          };
        },
        cache: true
      },
      templateSelection: function(container) { // adds the current quantity on hand to the items 'data-quantity' attribute
          $(container.element).attr('data-carrier_type', container.carrier_type);
          return container.text;
      }
    });
  
    $('.orderEditProductSearch').select2({
      selectOnClose: true,
      allowClear: true,
      placeholder: 'Select Item...',
      ajax: {
        url: '/json_dropdown_products_all.json',
        dataType: 'json',
        type: 'GET',
        processResults: function (data, params) {
          params.page = data.page || 1;
          return {
            results: $.map(data.items, function (item) {
              return {
                  text: item.sku + ' | ' + item.desc,
                  id: item.id,
                  quantity_on_hand: item.qty,
                  child_count: item.child_count                
              };
            }),
            pagination: {
              more: data.current_page
            }
          };
        },
        cache: true
      },
      templateSelection: function(container) { // adds the current quantity on hand to the items 'data-quantity' attribute
          $(container.element).attr('data-quantity', container.quantity_on_hand).attr('data-child_count', container.child_count);
          return container.text;
      }
    });
  
    $('.orderStatusEditSelect').select2({
      selectOnClose: true,
      placeholder: 'Order Status...',
    });
  
  
    // This populates the 'Supplier Search' dropdown on the 'Edit' view
    var supplierSelect = $('.orderEditSupplierSearch');
    supplierSelect.each(function() {
      var t = $(this);
      $.ajax({
        url: '/suppliers/' + t.data('selected') + '.json',
        dataType: 'json',
        type: 'GET',
      }).then(function (data) {
        var option = new Option(data.supplierSelect.brand_name, data.supplierSelect.id, true, true);
        t.append(option).trigger('change');
        t.trigger({
          type: 'select2:select',
          params: {
            data: data
          }
        });
      });
    });
  
    // This populates the 'Retailer Search' dropdown on the 'Edit' view
    var retailerSelect = $('.orderEditRetailerSearch');
    retailerSelect.each(function() {
      var t = $(this);
      $.ajax({
        url: '/retailers/' + t.data('selected') + '.json',
        dataType: 'json',
        type: 'GET',
      }).then(function (data) {
        var option = new Option(data.retailerSelect.name, data.retailerSelect.id, true, true);
        t.append(option).trigger('change');
        t.trigger({
          type: 'select2:select',
          params: {
            data: data
          }
        });
      });
    });
  
    // This populates the 'Carrier Search' dropdown on the 'Edit' view
    var carrierSelect = $('.orderEditCarrierSearch');
    carrierSelect.each(function() {
      var t = $(this);
      $.ajax({
        url: '/carriers/' + t.data('selected') + '.json',
        dataType: 'json',
        type: 'GET',
      }).then(function (data) {
        var option = $('<option />')
                      .val(data.carrierSelect.id)
                      .text(data.carrierSelect.name)
                      .prop('selected', true)
                      .attr('data-carrier_type', data.carrierSelect.carrier_type);
        t.append(option).trigger('change');
        t.trigger({
          type: 'select2:select',
          params: {
            data: data
          }
        });
        t.closest('.lineItemTable').find('.add_line_item_edit_tracking_number_button').attr('data-carrier_type', t.find('option:selected').attr('data-carrier_type'));
      });
    });
  
    // This populates the 'Product Search' dropdown on the 'Edit' view
    var productSelect = $('.orderEditProductSearch');
    productSelect.each(function() {
      var t = $(this);
      $.ajax({
        url: '/products/' + t.data('selected') + '.json',
        dataType: 'json',
        type: 'GET',
      }).then(function (data) {
        var option = $('<option />')
                      .val(data.productSelect.id)
                      .text(data.productSelect.sku + ' | ' + data.productSelect.desc)
                      .prop('selected', true)
                      .attr('data-quantity', data.productSelect.qty)
                      .attr('data-child_count', data.productSelect.child_count);
        t.append(option).trigger('change');
        t.trigger({
          type: 'select2:select',
          params: {
            data: data
          }
        });
        t.closest('.lineItemTable').find('.add_line_item_edit_tracking_number_button').attr('data-child_count', t.find('option:selected').attr('data-child_count'));
        t.closest('.lineItemTable').find('.current-quantity').val(t.find('option:selected').attr('data-quantity'));
      });
    });
  
    $(document).on('click', '.order_edit_remove_button', function() {
      var fields = $(this).closest('.fields_order');
      bootbox.confirm('Are you sure you want to remove this order?', function(result){
        if (result === true) {
          fields.find('.remove_child_edit_order').attr('value', 'true');
          fields.slideUp('slow');
        }
      });
    });
  
    $(document).on('click', '.line_item_edit_remove_button', function() {
      var fields = $(this).closest('.lineItemTable');
      bootbox.confirm('Are you sure you want to remove this line item?', function(result){
        if (result === true) {
          fields.find('.remove_child_edit_line_item').attr('value', 'true');
          fields.slideUp('slow');
        }
      });
    });
  
    $(document).on('click', '.tracking_number_edit_remove_button', function() {
      var fields = $(this).closest('.trackingNumberTable');
      bootbox.confirm('Are you sure you want to remove this tracking number?', function(result){
        if (result === true) {
          fields.find('.remove_child_edit_tracking_number').attr('value', 'true');
          fields.slideUp('slow');
        }
      });
    });
  
    $(document).on('click', '.add_order_edit_line_item_button', function() {
      $(this).closest('.row').find('.add_child_order_edit_line_item').click();
    });
  
    $(document).on('click', '.add_order_edit_custom_item_button', function() {
      $(this).closest('.row').find('.add_child_order_edit_line_item').click();
      var fields = $(this).closest('.row').find('.fields_order_line_item').last();
      fields.find('.select2').hide();
      fields.find('.order-quantity').attr('readonly', false).val('1');
      fields.find('.current-quantity').hide();
      fields.find('.custom-item-field').show();
      fields.find('.custom-item-field').parent().addClass('order-batch-custom-item-width');
      fields.find('.custom-description-field').show();
      fields.find('.custom-description-field').parent().addClass('order-batch-custom-description-width');
    });
  
  
    $(document).on('click', 'a.add_child_order_edit_line_item', function() {
      var association, new_id, regexp, regexp2, grabnum, template, fields;
      association = $(this).attr('data-association');
      template = $(this).closest('form').find('#edit_line_item_fields_template').html();
      regexp = new RegExp('new_' + association, 'g'); // 'new_line_items'
      regexp2 = new RegExp('new_order', 'g');
      new_id = (new Date).getTime();
      grabnum = $(this).closest('.fields_order').next().attr('id');
      $(this).before(template.replace(regexp, new_id).replace(regexp2, grabnum.match(/\d+/)));
      fields = $(this).prev('.fields_order_line_item');
      fields.find('.productSearch').select2({
        selectOnClose: true,
        allowClear: true,
        placeholder: 'Select Item...',
        ajax: {
          url: '/json_dropdown_products_all.json',
          dataType: 'json',
          type: 'GET',
          processResults: function (data, params) {
            params.page = data.page || 1;
            return {
              results: $.map(data.items, function (item) {
                return {
                    text: item.sku + ' | ' + item.desc,
                    id: item.id,
                    quantity_on_hand: item.qty,
                    child_count: item.child_count
                };
              }),
              pagination: {
                more: data.current_page
              }
            };
          },
          cache: true
        },
        templateSelection: function(container) { // adds the current quantity on hand to the items 'data-quantity' attribute
            $(container.element).attr('data-quantity', container.quantity_on_hand).attr('data-child_count', container.child_count);
            return container.text;
        }
      });
      fields.find('[data-toggle="tooltip"]').tooltip({trigger: 'hover'});
      fields.find('.add_line_item_edit_tracking_number_button').click();
      fields.hide();
      fields.slideDown('fast');
      $('.scroll').bind('mousewheel', function() {});
      
      return false;
    });
  
    $(".fields_order").each(function() {
      $(this).next('input').attr('data-customer', $(this).data('customer')).addClass('order_sort');
    });
  
    // grabs the 'Customer Name' field from each order, then sorts alphabetically.
    // Used mostly for help in processing Amazon DC orders where different PO's can be joined by Customer.
    $(document).on('click', '.sort_by_customer_button', function() {
      var mylist = $('.order_fields');
      var listitems = mylist.children('.order_sort').get();
      listitems.sort(function(a, b) {
         return $(a).data('customer').toUpperCase().localeCompare($(b).data('customer').toUpperCase());
      });
      $.each(listitems, function(idx, itm) { mylist.append(itm); });
    });
  
    $(document).on('click', '.add_line_item_edit_tracking_number_button', function() {
      $(this).closest('.tracking-number-fields').find('.add_child_line_item_edit_tracking_number').click();
    });
  
    $(document).on('click', '.add_line_item_edit_custom_cost_button', function() {
      $(this).closest('.tracking-number-fields').find('.custom-cost-overwrite-check').prop('checked', 'checked');
      $(this).closest('.tracking-number-fields').find('.custom-cost-fields').slideDown('fast');
    });
  
    $(document).on('click', 'a.add_child_line_item_edit_tracking_number', function() {
      var association, new_id, regexp, regexp2, regexp3, grabnum, grabnum2, template, fields;
      association = $(this).attr('data-association');
      template = $(this).closest('form').find('#edit_tracking_number_fields_template').html();
      regexp = new RegExp('new_' + association, 'g'); // 'new_tracking_numbers'
      regexp2 = new RegExp('new_order', 'g');
      regexp3 = new RegExp('new_line_item', 'g');
      new_id = (new Date).getTime();
      grabnum = $(this).closest('.fields_order').next().attr('id');
      grabnum2 = $(this).closest('.lineItemTable').find('.order-quantity').attr('id');
      $(this).before(template.replace(regexp, new_id).replace(regexp2, grabnum.match(/\d+/)).replace(regexp3, grabnum2.match(/\d+/g)[1]));
      fields = $(this).prev('.fields_order_line_item_tracking_number');
  
      fields.find('[data-toggle="tooltip"]').tooltip({trigger: 'hover'});
      fields.hide();
      fields.slideDown('fast');
      $('.scroll').bind('mousewheel', function() {});
      
      $('.add_ship_charges_button').on('click', function() {
        $(this).hide();
        $(this).closest('tr').find('.cost_fields').show();
      });
  
      return false;
    });
  
    $('.custom-item-field').each(function() {
      if ($(this).val()) {
        $(this).closest('tr').find('.current-quantity').hide();
        $(this).parent().addClass('order-batch-custom-item-width');
        $(this).closest('tr').find('td:nth-child(2)').addClass('order-batch-custom-description-width');
      }
    });
  
    $('.add_line_item_edit_tracking_number_button').each(function() {
      $(this).attr('data-quantity', $(this).closest('.lineItemTable').find('.order-quantity').val());
      $(this).attr('data-carrier_type', $(this).closest('.fields_order').find('.orderEditCarrierSearch').attr('data-carrier_type'));
      $(this).attr('data-child_count', $(this).closest('tr').find('.orderEditProductSearch').find('option:selected').attr('data-child_count'));
    });
  
    $('.orderEditCarrierSearch').each(function() {
      $(this).closest('.fields_order').find('.add_line_item_edit_tracking_number_button').attr('data-carrier_type', $(this).attr('data-carrier_type'));
    });
  
    $(document).on('change keyup', '.order-quantity', function() { // helper for auto-generating the correct number of Tracking Number fields
      $(this).closest('.lineItemTable').find('.add_line_item_edit_tracking_number_button').attr('data-quantity', $(this).val());
    });
  
    $(document).on('change keyup', '.orderEditCarrierSearch', function() { // helper for auto-generating the correct number of Tracking Number fields
      $(this).attr('data-carrier_type', $(this).find('option:selected').attr('data-carrier_type'));
      $(this).closest('.fields_order').find('.add_line_item_edit_tracking_number_button').attr('data-carrier_type', $(this).find('option:selected').attr('data-carrier_type'));
    });
  
    $(document).on('change keyup', '.orderCarrierSearch', function() { // helper for auto-generating the correct number of Tracking Number fields
      $(this).attr('data-carrier_type', $(this).find('option:selected').attr('data-carrier_type'));
      $(this).closest('.fields_order').find('.add_line_item_tracking_number_button').attr('data-carrier_type', $(this).find('option:selected').attr('data-carrier_type'));
    });
  
    $('.add_ship_charges_button').on('click', function() {
      $(this).hide();
      $(this).closest('tr').find('.cost_fields').show();
    });
  
    $('.shipping-cost-select').each(function() {
      if ($(this).attr('value') > 0) {
        $(this).closest('td').show();
        $(this).closest('td').next().show();
        $(this).closest('tr').find('.add_ship_charges_button').hide();
      }
    });

    $(document).on('click', '.change_batch_id_button', function() {
      $(this).closest('.row').find('.batch_id_div').slideDown('fast').addClass('open').removeClass('closed');
    });

    $(document).on('click', '.show_all_button', function() {
      $('.batch_id_div').slideDown('fast').addClass('open').removeClass('closed');
    });

    $(document).on('click', '.apply_to_all_button', function() {
      var this_id = $(this).closest('.batch_id_div').find('.order_batch_id_field').attr('value');
      $('.batch_id_div.open').find('.order_batch_id_field').val(this_id).attr('value', this_id);
    });

    $(document).on('click', '.hide_div_button', function() {
      $(this).closest('.batch_id_div').removeClass('open').addClass('closed').slideUp('fast');
    });

    $(document).on('change keyup', '.order_batch_id_field', function() {
      $(this).attr('value', $(this).val());
    });
  
    $('.scroll').bind('mousewheel', function() {});

    window.onbeforeunload = function() {
    	return 'Are you sure you want to leave?';
    };

  }

//----------------------------------------------------------------------------//
//---------- ORDER BATCHES SHOW RELATED --------------------------------------//
//----------------------------------------------------------------------------//

  if ($('.order_batch_show_div').length > 0) {

    $(document).on('click', '.ots-clicker', function() {
      $(this).closest('span').find('.on_the_spot_editing').click();
    });
  
    $(document).on('click', '.order-ship-date', function() {
      $(this).hide();
      $(this).parent().find('.ots-ship-date').show();
      $(this).parent().find('.on_the_spot_editing').click();
    });
  
    $(document).on('focus', '.ship-date', function() {
      var raw_date = $(this).closest('.ship-date-container').find('.ship-date-trunc').data('rawdate');
      $(this).attr('type', 'date');
      $(this).val(raw_date);
    });
  
    $(document).on('click', 'button:contains("Ok")', function() {
      $('.ots-ship-date').hide();
      $('.order-ship-date').show();
      $('#ship_date_async_refresh').click();
    });
  
    $(document).on('click', '.sort_by_customer_button', function() {
      var mylist = $('.order_fields');
      var listitems = mylist.children('.order_sort').get();
      listitems.sort(function(a, b) {
         return $(a).data('customer').toUpperCase().localeCompare($(b).data('customer').toUpperCase());
      });
      $.each(listitems, function(idx, itm) { mylist.append(itm); });
    });

//---------- Exports Section ----------//

    $(".shipping_export_table").DataTable({
      "retrieve": true,
      "processing": true,
      "dom": "<'row'<'col-sm-12 dataTableToolbar'><'col-sm-2 hidden'B>>t",
      "buttons": [
        {
          extend: "excelHtml5",
          title: '',
        },
      ]
    });
  
    $("div.dataTableToolbar").html("<div id='tableClickerExcel' class='pirateship_export hidden'>PirateShip Export</div>");
  
    $("#tableClickerExcel").on("click", function() {
      $(".buttons-excel").click();
    });

    $('.pirateship_export_button').on('click', function() {
      $('.pirateship_export').click();
    });

  }

//----------------------------------------------------------------------------//
//---------- ORDERS FORM RELATED ---------------------------------------------//
//----------------------------------------------------------------------------//

  if ($('.order-validate').length > 0) {

    $('.add_child_line_item').hide();
    $('label.required').append('<strong> *</strong>'); // adds a 'required' star next to each Inputs label
    $('label.required-s span').append('<strong> *</strong>'); // adds a 'required' star next to each Selects label
  
    $('.add_line_item_button').on('click', function() {
      $('.add_child_order_line_item').click();
    });
    $('.add_5_line_items_button').on('click', function() {
      for (var i = 0; i < 5; i++){
        $('.add_child_order_line_item').click();
      }
    });
  
    $('.order-type-select').select2({
      selectOnClose: true,
      placeholder: 'Order Type...'
    }).on('change', function() {
        $(this).valid();
      });
  
    $('.order-status-select').select2({
      selectOnClose: true,
      placeholder: 'Order Status...'
    }).on('change', function() {
        $(this).valid();
      });
  
    $('.orderSupplierSearch').select2({
      selectOnClose: true,
      placeholder: 'Supplier...'
    }).on('change', function() {
        $(this).valid();
      });
  
    $('.orderRetailerSearch').select2({
      selectOnClose: true,
      placeholder: 'Retailer...'
    }).on('change', function() {
        $(this).valid();
      });
  
    $('.orderCarrierSearch').select2({
      selectOnClose: true,
      placeholder: 'Carrier...'
    }).on('change', function() {
        $(this).valid();
      });
  
    $('.addressState').select2({
      selectOnClose: true,
      placeholder: 'State...'
    }).on('change', function() {
        $(this).valid();
      });
  
    $('.addressCountry').select2({
      selectOnClose: true,
      placeholder: 'Country...'
    }).on('change', function() {
        $(this).valid();
      });
  
  
    $('.add_custom_item_button').on('click', function() {
      $('.add_child_order_line_item').click();
      var fields = $(this).closest('div').find('.fields_order_line_item').last();
      fields.find('.productSearch').next().hide();
      fields.find('.order-quantity').attr('readonly', false).val('1');
      fields.find('.current-quantity').hide();
      fields.find('.custom-item-field').show().parent().addClass('order-custom-item-width');
      fields.find('.custom-description-field').show().parent().addClass('order-custom-description-width');
    });
  
    $(document).on('click', '.add_line_item_tracking_number_button', function() {
      $(this).closest('.fields_order_line_item').find('.add_child_line_item_tracking_number').click();
    });
  
    $(document).on('click', '.order_address_edit_remove_button', function() {
      var fields = $(this).closest('.order-address-fields');
      bootbox.confirm('Are you sure you want to remove this Address?', function(result){
        if (result === true) {
          fields.find('.remove_address').attr('value', 'true');
          fields.hide();
        }
      });
    });
  
    $(document).on('click', '.line_item_edit_remove_button', function() {
      var fields = $(this).closest('.lineItemTable');
      bootbox.confirm('Are you sure you want to remove this line item?', function(result){
        if (result === true) {
          fields.find('.remove_child_edit_line_item').attr('value', 'true');
          fields.hide();
        }
      });
    });
  
    $(document).on('click', '.tracking_number_edit_remove_button', function() {
      var fields = $(this).closest('.trackingNumberTable');
      bootbox.confirm('Are you sure you want to remove this tracking number?', function(result){
        if (result === true) {
          fields.find('.remove_child_edit_tracking_number').attr('value', 'true');
          fields.hide();
        }
      });
    });
  
    $(document).on('mousedown', '.ship-date-select', function() {
      if (event.which === 2) {
        $(this).val($(this).data('today'));
        $(this).closest('.row').find('.order-status-select').val('2').trigger('change');
      } else if (event.which === 3) {
        $(this).val('');
        $(this).closest('.row').find('.order-status-select').val('1').trigger('change');
      }
    });
  
    $('.add_notes_button').on('click', function() {
      $(this).closest('.tab-content').find('.notes-div').slideDown('slow');
      $(this).addClass('hidden_preserve_position');
    });
  
    $('.add_address_button').on('click', function() {
      $(this).closest('.tab-content').find('.order-address-fields').slideDown('slow');
      $(this).addClass('hidden_preserve_position');
    });
  
    $('.order-validate').validate({
      ignore: '.ignore',
  		rules: {
  		// 	'product[model_number]': {required: true, maxlength: 80},
  		// 	'product[supplier_part_number]': {required: true, maxlength: 80},
  		},
  		messages: {
  			'order[po_number]': 'If no PO Number is available, enter "N/A" into the field.',
  		// 	'product[model_number]': 'If the Model Number & the Manufacturer Part Number are the same, enter the same SKU in both fields.'
  		},
      onfocusout: function(element) {$(element).valid()},
      focusCleanup: true,
      errorClass: 'myErrorClass',
      errorPlacement: function (error, element) {
        error.insertAfter(element.parent());
      },
      highlight: function (element, errorClass, validClass) {
        var elem = $(element);
        var tabId = elem.closest('.tab-pane').attr('aria-labelledby');
        if (elem.hasClass('select2-hidden-accessible')) {
          elem.parent().find('.select2-selection').addClass(errorClass);
          elem.closest('.card-body').find('.nav-link[id="'+tabId+'"]').addClass(errorClass);
        } 
        else {
          elem.addClass(errorClass);
          elem.closest('.card-body').find('.nav-link[id="'+tabId+'"]').addClass(errorClass);
        }
      },    
      unhighlight: function (element, errorClass, validClass) {
        var elem = $(element);
        var tabId = elem.closest('.tab-pane').attr('aria-labelledby');
        if (elem.hasClass('select2-hidden-accessible')) {
          elem.parent().find('.select2-selection').removeClass(errorClass);
          elem.parent().next('label').remove();
          elem.closest('.card-body').find('.nav-link[id="'+tabId+'"]').removeClass(errorClass);
        }
        else {
          elem.removeClass(errorClass);
          elem.parent().next('label').remove();
          elem.closest('.card-body').find('.nav-link[id="'+tabId+'"]').removeClass(errorClass);
        }
      },
  		invalidHandler: function(e, validator) {
  			var errors = validator.numberOfInvalids();
  			if (errors) {
  				var message = errors == 1
  					? 'You missed 1 required field. Check below tabs for highlighted fields'
  					: 'You missed ' + errors + ' required fields.  Check below tabs for highlighted fields';
  				$('div.validatorErrorList span').html(message);
  				$('div.validatorErrorList').slideDown('slow');
  			}
  			else {
  				$('div.validatorErrorList').slideUp('slow');
  			}
  		}
    });
  
    // Monkeypatch so all select2 fields actually clear to 'nil' when the 'X' is clicked
    $(document).on('select2:clear', '.select2-hidden-accessible', function (e) {
      $(this).append('<option value="" selected="selected"></option>');
    });
  
    $('#orderFirstSubmit').on('click', function(e) {
      e.preventDefault();
      if ($('.order-validate').valid()) {
        $('#jstemplates_line_items').html('');
        $('.jstemplates_line_item_tracking_numbers').html('');
        $('.line_item_edit_tracking_number').html('');
        $(".loading-overlay").show();
        $('#orderSubmit').click();
      }
      else {
        return false;
      }
    });
  
    $('#orderFirstSave').on('click', function(e) {
      e.preventDefault();
      if ($('.order-validate').valid()) {
        $('#jstemplates_line_items').html('');
        $('.jstemplates_line_item_tracking_numbers').html('');
        $('.line_item_edit_tracking_number').html('');
        $(".loading-overlay").show();
        $('#orderSave').click();
      }
      else {
        return false;
      }
    });
  
    $(document).on('click', '.shipping-cost-select', function() {
      if ($(this).val() == '0.0') {
        $(this).select();
      }
    });
  
  //---- Addresses Related -----------------------------------------------------//
  
    $(document).on('change keyup', function() {
      // This populates the hidden 'State' field since a select_tag is used 
      var state = $(this).find('.addressState').find('option:selected').val();
      $(this).find('.addressStateHidden').val(state);
    
      if ($('.addressCountry').find('option:selected').val() == 'US') {
        $('.phone_mask').inputmask({ mask: '(999) 999-9999[-999999]', greedy: false, showMaskOnHover: true });
        $('.zip_mask').inputmask({ mask: '99999[-9999]', greedy: false, showMaskOnHover: true });
      }
      else {
        $('.phone_mask').inputmask('remove');
        $('.zip_mask').inputmask('remove');
      }
    });
  
  //---- Line Items Related ------------------------------------------------------//
  
  
    // Child Fields Template Loader inherited from inbound_shipments_helper.rb for Line Items
    $(document).on('click', 'a.add_child_order_line_item', function() {
      var association, new_id, regexp, template, fields;
      association = $(this).attr('data-association');
      template = $('#' + association + '_fields_template').html();
      regexp = new RegExp('new_' + association, 'g');
      new_id = (new Date).getTime();
      $(this).parent().before(template.replace(regexp, new_id));
      // The 'jstemplates' hidden field needs the '.ignore' class to ensure it
      // doesn't get picked up by Jquery Validator.  This line removes it.
      fields = $(this).parent().prev('.fields_order_line_item');
      fields.find('[data-toggle="tooltip"]').tooltip({trigger: 'hover'});
      fields.find('select').removeClass('ignore');
      fields.find('.productSearch').select2({
        selectOnClose: true,
        allowClear: true,
        placeholder: 'Select Item...',
        ajax: {
          url: '/json_dropdown_products_all.json',
          dataType: 'json',
          type: 'GET',
          processResults: function (data, params) {
            params.page = data.page || 1;
            return {
              results: $.map(data.items, function (item) {
                return {
                  text: item.sku + ' | ' + item.desc,
                  id: item.id,
                  quantity_on_hand: item.qty
                };
              }),
              pagination: {
                more: data.current_page
              }
            };
          },
          cache: true
        },
        templateSelection: function(container) { // adds the current quantity on hand to the items 'data-quantity' attribute
            $(container.element).attr('data-quantity', container.quantity_on_hand);
            return container.text;
        }
      });
      fields.find('.add_child_line_item_tracking_number').click();
      fields.hide();
      fields.slideDown('fast');
  
      // monkeypatch to re-enable scrollwheel on number fields
      $('.scroll').bind('mousewheel', function() {});
  
      return false;
    });
  
    $(document).on('click', 'a.remove_child_order_line_item', function() { // Triggers the removal of the relevant 'Item Select' table row when clicked
      var tr = $(this).closest('tr');
      var hidden_field = $(this).prev('input[type=hidden]')[0];
      tr.find('.productSearch').val([]).trigger('change');
      if(hidden_field) {
        hidden_field.value = '1';
      }
      $(this).parents('.fields_order_line_item').slideUp('fast', function() {
        $(this).remove();
      });
      return false;
    });
  
    $(document).on('change', '.productSearch', function() {
      $(this).next().removeClass('myErrorClass');
      var tr = $(this).closest('tr');
      tr.find('.order-quantity').attr('readonly', false).val('1'); // Enables the quantity fields for this given field
      tr.find('.current-quantity').val($(this).find('option:selected').data('quantity')); // imports the quantity from the 'data-quantity' attribute of the selected option
      var arr = [];
      $('.productSearch').each(function(){
        $(this).next().removeClass('myErrorClass');
        var value = $(this).find('option:selected').val();
        if (arr.indexOf(value) == -1) {
          $(this).next().removeClass('myErrorClass');
          arr.push(value);
        } else {
          if ($(this).next().text() == 'Select Item...') {
            $(this).next().removeClass('myErrorClass');
          } else {
            $(this).next().addClass('myErrorClass');
          }
        }
      });
  
    });

    $(document).on('click', '.add_line_item_custom_cost_button', function() {
      $(this).closest('.fields_order_line_item').find('.custom-cost-fields').slideDown('fast');
    });
  
    $(document).on('click', '.add_line_item_edit_custom_cost_button', function() {
      $(this).closest('.tracking-number-fields').find('.custom-cost-fields').slideDown('fast');
    });
  
  //---- Line Item Tracking Number ------------------------------------------------//
  
    // Child Fields Template Loader inherited from order_batches_helper.rb for Line Items
    $(document).on('click', 'a.add_child_line_item_tracking_number', function() {
      var association, new_id, regexp, template, fields;
      association = $(this).attr('data-association');
      template = $(this).closest('.fields_order_line_item').find('#' + association + '_fields_template').html();
      regexp = new RegExp('new_' + association, 'g');
      new_id = (new Date).getTime();
      $(this).before(template.replace(regexp, new_id));
      // The 'jstemplates' hidden field needs the '.ignore' class to ensure it
      // doesn't get picked up by Jquery Validator.  This line removes it.
      fields = $(this).prev('.fields_order_line_item_tracking_number');
      fields.find('select').removeClass('ignore');
      fields.find('[data-toggle="tooltip"]').tooltip({trigger: 'hover'});
      fields.find('.shipping-cost-select').addClass('ignore');
  
      fields.hide();
      fields.slideDown('fast');
      // monkeypatch to re-enable scrollwheel on number fields
      $('.scroll').bind('mousewheel', function() {});
  
      return false;
    });
  
    $(document).on('click', 'a.remove_child_line_item_tracking_number', function() { // Triggers the removal of the relevant 'Item Select' table row when clicked
      var hidden_field = $(this).prev('input[type=hidden]')[0];
      if(hidden_field) {
        hidden_field.value = '1';
      }
      $(this).parents('.fields_order_line_item_tracking_number').slideUp('fast', function() {
        $(this).remove();
      });
      return false;
    });
  
    $(document).on('change', '.returned-check', function() {
      var fields = $(this).closest('.tracking-number-fields');
      if(this.checked) {
        fields.find('.returned-fields').slideDown('fast');
      } else {
        fields.find('.returned-fields').slideUp('fast');
        fields.find('.returned-quantity').val('0');
        fields.find('.defective-quantity').val('0');
        fields.find('.return-status').val('not_returned');
      }
    });
  
    // monkeypatch to fix issue of search field not being selected on open.  https://github.com/select2/select2/issues/2311#issuecomment-822473913
    $(document).on('select2:open', e => {
      const id = e.target.id;
      const target = document.querySelector(`[aria-controls=select2-${id}-results]`);
      target.focus();
    });
  
//----------------------------------------------------------------------------//
//---------- ORDERS EDIT RELATED ---------------------------------------------//
//----------------------------------------------------------------------------//

    $('.orderEditProductSearch').select2({
      selectOnClose: true,
      allowClear: true,
      placeholder: 'Select Item...',
      ajax: {
        url: '/json_dropdown_products_all.json',
        dataType: 'json',
        type: 'GET',
        processResults: function (data, params) {
          params.page = data.page || 1;
          return {
            results: $.map(data.items, function (item) {
              return {
                  text: item.sku + ' | ' + item.desc,
                  id: item.id,
                  quantity_on_hand: item.qty,
                  child_count: item.child_count                
              };
            }),
            pagination: {
              more: data.current_page
            }
          };
        },
        cache: true
      },
      templateSelection: function(container) { // adds the current quantity on hand to the items 'data-quantity' attribute
          $(container.element).attr('data-quantity', container.quantity_on_hand).attr('data-child_count', container.child_count);
          return container.text;
      }
    });
  
    // This populates the 'Product Search' dropdown on the 'Edit' view
    var productSelect = $('.orderEditProductSearch');
    productSelect.each(function() {
      var t = $(this);
      $.ajax({
        url: '/products/' + t.data('selected') + '.json',
        dataType: 'json',
        type: 'GET',
      }).then(function (data) {
        var option = $('<option />')
                      .val(data.productSelect.id)
                      .text(data.productSelect.sku + ' | ' + data.productSelect.desc)
                      .prop('selected', true)
                      .attr('data-quantity', data.productSelect.qty)
                      .attr('data-child_count', data.productSelect.child_count);
        t.append(option).trigger('change');
        t.trigger({
          type: 'select2:select',
          params: {
            data: data
          }
        });
        t.closest('.lineItemTable').find('.add_line_item_edit_tracking_number_button').attr('data-child_count', t.find('option:selected').attr('data-child_count'));
        t.closest('.lineItemTable').find('.current-quantity').val(t.find('option:selected').attr('data-quantity'));
      });
    });
  
    // This populates the 'Supplier Search' dropdown on the 'Edit' view
    var supplierSelect = $('.orderSupplierSearch');
    $.ajax({
      url: '/suppliers/' + supplierSelect.data('selected') + '.json',
      dataType: 'json',
      type: 'GET',
    }).then(function (data) {
      var option = new Option(data.supplierSelect.brand_name, data.supplierSelect.id, true, true);
      supplierSelect.append(option).trigger('change');
      supplierSelect.trigger({
        type: 'select2:select',
        params: {
          data: data
        }
      });
    });
  
    // This populates the 'Retailer Search' dropdown on the 'Edit' view
    var retailerSelect = $('.orderRetailerSearch');
    $.ajax({
      url: '/retailers/' + retailerSelect.data('selected') + '.json',
      dataType: 'json',
      type: 'GET',
    }).then(function (data) {
      var option = new Option(data.retailerSelect.name, data.retailerSelect.id, true, true);
      retailerSelect.append(option).trigger('change');
      retailerSelect.trigger({
        type: 'select2:select',
        params: {
          data: data
        }
      });
    });
  
    // This populates the 'Retailer Search' dropdown on the 'Edit' view
    var carrierSelect = $('.orderCarrierSearch');
    $.ajax({
      url: '/carriers/' + carrierSelect.data('selected') + '.json',
      dataType: 'json',
      type: 'GET',
    }).then(function (data) {
      var option = new Option(data.carrierSelect.name, data.carrierSelect.id, true, true);
      carrierSelect.append(option).trigger('change');
      carrierSelect.trigger({
        type: 'select2:select',
        params: {
          data: data
        }
      });
    });
  
    $(document).on('change keyup', '.po-number', function() {
      var new_description = $(this).val();
      $(this).closest('#general-1').find('.po-number-header').text(new_description);
    });
  
  
    $(document).on('click', '.add_line_item_edit_tracking_number_button', function() {
      $(this).closest('.tracking-number-fields').find('.add_child_line_item_edit_tracking_number').click();
    });
  
    $(document).on('click', 'a.add_child_line_item_edit_tracking_number', function() {
      var association, new_id, regexp, regexp2, grabnum, template, fields;
      association = $(this).attr('data-association');
      template = $(this).closest('#general-1').find('#tracking_numbers_edit_fields_template').html();
      regexp = new RegExp('new_' + association, 'g');
      regexp2 = new RegExp('new_line_items', 'g');
      new_id = (new Date).getTime();
      grabnum = $(this).closest('.lineItemTable').next().attr('id');
      $(this).before(template.replace(regexp, new_id).replace(regexp2, grabnum.match(/\d+/)));
      fields = $(this).prev('.fields_order_line_item_tracking_number');
      fields.find('[data-toggle="tooltip"]').tooltip({trigger: 'hover'});
  
      fields.hide();
      fields.slideDown('fast');
  
      $('.scroll').bind('mousewheel', function() {});
      
      return false;
    });
  
    $('.scroll').bind('mousewheel', function() {});

  }

//----------------------------------------------------------------------------//
//---------- ORDERS EDIT MULTIPLE RELATED ------------------------------------//
//----------------------------------------------------------------------------//

  if ($('.update_multiple_orders').length > 0) {

    $('.orderStatusSelect').select2({
      selectOnClose: true,
      placeholder: 'Order Status...',
    });

    $(document).on('click', '.invoiced_check_all', function() {
      $(this).closest('.fields_container').find('.invoiced-check').prop('checked', true);
    });

    // Makes the padding around the Invoiced checkbox less difficult to click
    $(document).on('click', '.order_batch_invoiced_button, .invoiced-check-label', function () {
        var cb = $(this).closest('.expandCheck').find(":checkbox");
        cb.prop("checked", !cb.prop("checked"));
    });
    
    $(document).on('change keyup', '.field-highlightable', function() {
      if ($(this).val().length > 0) {
        $(this).removeClass('field-highlight');
        $(this).closest('td').find('.select2-selection').removeClass('field-highlight');
      } else if ($(this).val().length == 0){
        $(this).addClass('field-highlight');
        $(this).closest('td').find('.select2-selection').addClass('field-highlight');
      }
    });

    // sets the Order Status to 'Shipped' if a Ship Date is middle clicked, removed if right clicked
    $(document).on('mousedown', '.ship-date-select', function() {
      if (event.which === 2) {
        $(this).val($(this).data('today'));
        $(this).removeClass('field-highlight');
        $(this).closest('td').removeClass('field-highlight');
        $(this).closest('.editMultipleShipDateTable').find('.orderStatusSelect').val('2').trigger('change');
      } else if (event.which === 3) {
        $(this).val('');
        $(this).addClass('field-highlight');
        $(this).closest('td').addClass('field-highlight');
        $(this).closest('.editMultipleShipDateTable').find('.orderStatusSelect').val('1').trigger('change');
      }
    });

    // sets the Order Status to 'Shipped' if a Ship Date is entered manually
    $(document).on('change', '.ship-date-select', function() {
      if ($(this).val()) {
        $(this).closest('.editMultipleShipDateTable').find('.orderStatusSelect').val('2').trigger('change');
        $(this).removeClass('field-highlight');
        $(this).closest('td').removeClass('field-highlight');
      }
    });

    $(document).on('click', '.ship_date_add_today', function() {
      var ship_date = $(this).closest('.row').find('.ship-date-select');
      ship_date.each(function() {
        if ($(this).val() == '') {
          $(this).val($(this).data('today'));
          $(this).removeClass('field-highlight');
          $(this).closest('td').removeClass('field-highlight');
          $(this).closest('.editMultipleShipDateTable').find('.orderStatusSelect').val('2').trigger('change');
        }
      });
    });

    $(document).on('click', '.clear_selection', function() {
      var tr = $(this).closest('tr');
      tr.removeClass('highlight-row');
      tr.find('.tracking-badge').removeClass('tracking-background');
      tr.find('.copy-cut').removeClass('highlight-selected');
    });

    $(document).on('click', '.copy_po_number', function() {
      var po_number = $(this).closest('.copy-body').find('.copy-text').text();
      copyToClipboard(po_number + ',');
      $(this).addClass('highlight-selected');
      $(this).closest('tr').addClass('highlight-row');
    });
  
    $(document).on('click', '.copy-cut.tracking_number', function() {
      var tracking = $(this).closest('.copy-body').find('.copy-text').text();
      copyToClipboard(tracking);
      $(this).closest('.tracking-badge').addClass('tracking-background');
    });

    function copyToClipboard(text) {
      if (window.clipboardData && window.clipboardData.setData) {
        // IE specific code path to prevent textarea being shown while dialog is visible.
        return clipboardData.setData('Text', text); 
      } else if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
        var textarea = document.createElement('textarea');
        textarea.textContent = text;
        textarea.style.position = 'fixed';  // Prevent scrolling to bottom of page in MS Edge.
        document.body.appendChild(textarea);
        textarea.select();
        try {
          return document.execCommand('copy');  // Security exception may be thrown by some browsers.
        } catch (ex) {
          console.warn('Copy to clipboard failed.', ex);
          return false;
        } finally {
          document.body.removeChild(textarea);
        }
      }
    }
  
    window.onbeforeunload = function() {
    	return 'Are you sure you want to leave?';
    };

  }

//----------------------------------------------------------------------------//
//---------- ORDERS IMPORT RELATED -------------------------------------------//
//----------------------------------------------------------------------------//

  if ($('.order_import_div').length > 0) {

    $(document).on('mousedown', '#ship_date_select:not(:disabled)', function() {
      if (event.which === 2) {
        $(this).val($(this).data('today'));
      } else if (event.which === 3) {
        $(this).val('');
      }
    });
  
    // monkeypatch to fix issue of search field not being selected on open.  https://github.com/select2/select2/issues/2311#issuecomment-822473913
    $(document).on('select2:open', e => {
      const id = e.target.id;
      const target = document.querySelector(`[aria-controls=select2-${id}-results]`);
      target.focus();
    });
  
    // on first focus (bubbles up to document), open the menu
    $(document).on('focus', '.select2-selection.select2-selection--single', function (e) {
      $(this).closest('.select2-container').siblings('select:enabled').select2('open');
    });
  
    // steal focus during close - only capture once and stop propogation
    $('select.select2').on('select2:closing', function (e) {
      $(e.target).data('select2').$selection.one('focus focusin', function (e) {
        e.stopPropagation();
      });
    });
  
    $(document).on('click', '.orderImportSubmit', function() {
      $('.loading-overlay').show();
    });
  
    $(document).on('change keyup', '#import_type_select', function() {
      if ($(this).find('option:selected').text() == 'CommerceHub')  {
        $('#file').attr('disabled', false);
        $('#invoiced_select').prop('checked', 'checked');
        $('#order_status_select').val('2');
        $('#ship_date_select').val($('#ship_date_select').data('today')).prop('disabled', false);
        $('.import-warning-text').hide();
      }
      else if ($(this).find('option:selected').text() == 'Wayfair')  {
        $('#file').attr('disabled', false);
        $('#invoiced_select').prop('checked', 'checked');
        $('#order_status_select').val('2');
        $('#ship_date_select').val($('#ship_date_select').data('today')).prop('disabled', false);
        $('.import-warning-text').hide();
      }
      else if ($(this).find('option:selected').text() == 'Amazon DC')  {
        $('#file').attr('disabled', false);
        $('#invoiced_select').prop('checked', false);
        $('#order_status_select').val('1');
        $('#ship_date_select').val('').prop('disabled', false);
        $('.import-warning-text').hide();
      }
      else if ($(this).find('option:selected').text() == 'Amazon DF')  {
        $('#file').attr('disabled', false);
        $('#invoiced_select').prop('checked', true);
        $('#order_status_select').val('2');
        $('#ship_date_select').val($('#ship_date_select').data('today')).prop('disabled', false);
        $('.import-warning-text').hide();
      }
      else if ($(this).find('option:selected').text() == 'Amazon FBA')  {
        $('#file').attr('disabled', false);
        $('#order_status_select').val('2');
        $('#invoiced_select').prop('checked', 'checked');
        $('#ship_date_select').val('').prop('disabled', true);
        $('.import-warning-text').hide();
      }
      else if ($(this).find('option:selected').text() == 'Shopify')  {
        $('#file').attr('disabled', false);
      }
      else {
        $('#file').attr('disabled', true);
        $('.import-warning-text').show();
      }
    });

    $(document).on('change', '#file', function() {
      $('.filename').trigger('change');
    });

    // parses common filenames to determine import type
    $(document).on('change', '.filename', function() {
      if ($(this).val().indexOf('search111542003') != -1) { // SRS CommerceHub
        $('#import_type_select').val('CommerceHub').trigger('change');
      } else if ($(this).val().indexOf('search114611002') != -1) { // Bodo CommerceHub
        $('#import_type_select').val('CommerceHub').trigger('change');
      } else if ($(this).val().indexOf('order-exports-sheet') != -1) { // Wayfair
        $('#import_type_select').val('Wayfair').trigger('change');
      } else if ($(this).val().indexOf('DOWNLOAD') != -1) { // Amazon DC
        $('#import_type_select').val('Amazon DC').trigger('change');
      } else if ($(this).val().indexOf('amazon-orders') != -1) { // Amazon DC
        $('#import_type_select').val('Amazon DF').trigger('change');
      } else if ($(this).val().indexOf('51') != -1) { // Amazon FBA
        $('#import_type_select').val('Amazon FBA').trigger('change');
      }
    });

  }

//----------------------------------------------------------------------------//
//---------- ORDERS INDEX RELATED --------------------------------------------//
//----------------------------------------------------------------------------//

  if ($('.orders_index_div').length > 0) {
    
    $('.loading-overlay').show();

    var MyDate = new Date();
    var date;
    MyDate.setDate(MyDate.getDate());
    date = MyDate.getFullYear() + '-' + ('0' + (MyDate.getMonth() + 1)).slice(-2) + '-' + ('0' + MyDate.getDate()).slice(-2);
    var filename = 'Orders, ' + date + ' Export';
    var table = $('#ordersDataTable').DataTable({
      'retrieve': true,
      'processing': true,
      'serverSide': true,
      'initComplete': function( settings, json ) {
        $('.loading-overlay').hide();
      },
      'ajax': {
        'url': $('#ordersDataTable').data('source')
      },
      'columns': [ // global search is disabled due to bugged columns below
  /*0 */{data: 'id', searchable: false, visible: false},
  /*1 */{data: 'batch_id', searchable: false, visible: false},
  /*2 */{data: 'order_date'},
  /*3 */{data: 'order_type'},
  /*4 */{data: 'supplier'},
  /*5 */{data: 'retailer'},
  /*6 */{data: 'customer'},
  /*7 */{data: 'po_number'},
  /*8 */{data: 'carrier'},
  /*9 */{data: 'line_items', searchable: false, orderable: false}, // bugged global search
  /*10*/{data: 'cost_total', searchable: false},
  /*11*/{data: 'remittance_total', searchable: false},
  /*12*/{data: 'tracking_number'}, // bugged global search
  /*13*/{data: 'order_status'}, // bugged global search
  /*14*/{data: 'ship_date'},
  /*15*/{data: 'ship_cost'},
  /*16*/{data: 'invoiced', searchable: false},
  /*17*/{data: 'returned', searchable: false, visible: false},
  // Broken for now, Rails complains that a partial is missing? /*17*/{data: 'notes', orderable: false, visible: false},
  /*18*/{data: 'created_by', visible: false},
  /*19*/{data: 'actions', orderable: false},
      ], // Order Date, Supplier, Retailer, Order Status, Order ID
      'order': [[2, 'desc'], [4, 'asc'], [5, 'asc'], [12, 'desc'], [0, 'asc']],
      'oLanguage': { 'sZeroRecords': 'No records to display for this filter' },
      'dom': '<"row"<"col-sm-12 dataTableToolbar"><"col-sm-2"B>>t<"row dataTableBottomRow"<"col-md-2"i><"col-md-10"p>>',
      'buttons': [
        {
          extend: 'excelHtml5',
          title: filename,
          // Only exports columns that are currently visible.  Adjusted by the 'Visibility' dropdown and Filtered text.
          exportOptions: {columns: ':visible'},
          className: 'dtButtonHide'
        },
        {
          extend: 'csv',
          title: filename,
          exportOptions: {columns: ':visible'},
          className: 'dtButtonHide'
        },
        {
          extend: 'pdfHtml5',
          title: filename,
          orientation: 'landscape',
          exportOptions: {columns: ':visible'},
          className: 'dtButtonHide'
        },
        {
          extend: 'copyHtml5',
          title: filename,
          exportOptions: {columns: ':visible'},
          className: 'dtButtonHide'
        }, 
        {
          extend: 'print',
          autoPrint: false,
          title: filename,
          exportOptions: {columns: ':visible'},
          customize: function (win) {
              $(win.document.body).find('table').addClass('display').css('font-size', '12px');
              $(win.document.body).find('tr:nth-child(odd) td').each(function(index){
                  $(this).css('background-color','#D0D0D0');
              });
              $(win.document.body).find('h1').css('text-align','center');
          },
          className: 'dtButtonHide'
        }
      ]
    });
  
    function dataTableToolbar(model) {
      return '' +
      '<ul class="nav" style="margin-bottom:-10px">' +
      '  <li>' +
      '    <span class="options-label">Options:</span>' +
      '  </li>' +
      '  <li>' +
      '    <div class="dataTables_length" id="' + model + 'DataTable_length">' +
      '      <select name="' + model + 'DataTable_length" aria-controls="' + model + 'DataTable" id="' + model + 'TableLength" class="form-control form-control-sm tableLength" title="Number of records to show">' +
      '        <option value="5">5</option>' +
      '        <option value="10">10</option>' +
      '        <option selected="selected" value="25">25</option>' +
      '        <option value="50">50</option>' +
      '        <option value="100">100</option>' +
      '        <option value="250">250</option>' +
      '        <option value="500">500</option>' +
      '        <option value="750">750</option>' +
      '        <option value="1000">1000</option>' +
      '      </select>' +
      '    </div>' +
      '  </li>' +
      '  <li>' +
      '    <div class="tableClickerDiv dropdown">' +
      '      <button class="btn btn-outline-primary btn-sm dropdown-toggle" title="Column visibility options" type="button" id="tableClickerColvis" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="fas fa-columns"></i> Columns</button>' +
      '      <div class="dropdown-menu no-select card-shadow" aria-labelledby="tableClickerColvis">' +
      '        <a class="dropdown-item button-highlight" id="colvisClicker-0">Record ID</a>' +
      '        <a class="dropdown-item button-highlight" id="colvisClicker-1">Batch ID</a>' +
      '        <a class="dropdown-item" id="colvisClicker-2">Order Date</a>' +
      '        <a class="dropdown-item" id="colvisClicker-3">Order Type</a>' +
      '        <a class="dropdown-item" id="colvisClicker-4">Supplier</a>' +
      '        <a class="dropdown-item" id="colvisClicker-5">Retailer</a>' +
      '        <a class="dropdown-item" id="colvisClicker-6">Customer</a>' +
      '        <a class="dropdown-item" id="colvisClicker-7">PO Number</a>' +
      '        <a class="dropdown-item" id="colvisClicker-8">Carrier</a>' +
      '        <a class="dropdown-item" id="colvisClicker-9">Line Items</a>' +
      '        <a class="dropdown-item" id="colvisClicker-10">Purchase Revenue</a>' +
      '        <a class="dropdown-item" id="colvisClicker-11">Remittance Total</a>' +
      '        <a class="dropdown-item" id="colvisClicker-12">Tracking Number</a>' +
      '        <a class="dropdown-item" id="colvisClicker-13">Order Status</a>' +
      '        <a class="dropdown-item" id="colvisClicker-14">Ship Date</a>' +
      '        <a class="dropdown-item" id="colvisClicker-15">Ship Cost</a>' +
      '        <a class="dropdown-item" id="colvisClicker-16">Invoiced?</a>' +
      '        <a class="dropdown-item button-highlight" id="colvisClicker-17">Returned Items?</a>' +
      // '        <a class="dropdown-item button-highlight" id="colvisClicker-17">Notes</a>' +
      '        <a class="dropdown-item button-highlight" id="colvisClicker-18">Created By</a>' +
      '        <a class="dropdown-item" id="colvisClicker-19">Actions</a>' +
      '      </div>' +
      '    </div>' +
      '  </li>' +
      '  <li>' +
      '    <div class="tableClickerDiv dropdown">' +
      '      <button class="btn btn-outline-primary btn-sm dropdown-toggle" title="Export Options" type="button" id="tableClickerExports" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="fas fa-file-export"></i> Export</button>' +
      '      <div class="dropdown-menu" aria-labelledby="tableClickerExports">' +
      '        <div class="text-primary" style="font-size:11px;padding:0.25rem 1.5rem;">' +
      '          <i class="fas fa-exclamation-triangle text-danger"></i> Only the currently visible columns & rows will be exported' +
      '        </div>' +
      '        <a class="dropdown-item tableClickerExcel"><i class="fas fa-file-excel file-icon-color"></i> Excel <i class="fas fa-download text-primary"></i></a>' +
      '        <a class="dropdown-item tableClickerCsv"><i class="fas fa-file-csv file-icon-color"></i> CSV <i class="fas fa-download text-primary"></i></a>' +
      '        <a class="dropdown-item tableClickerPdf"><i class="fas fa-file-pdf file-icon-color"></i> PDF <i class="fas fa-download text-primary"></i></a>' +
      '        <a class="dropdown-item tableClickerCopy"><i class="fas fa-copy file-icon-color"></i> Copy</a>' +
      '        <a class="dropdown-item tableClickerPrint"><i class="fas fa-print file-icon-color"></i> Print/Preview</a>' +
      '      </div>' +
      '    </div>' +
      '  </li>' +
      '  <li class="filter-section mt-2" style="width:100%">' +
      '    <span class="filter-label">Filters:</span>' +
      // '    <div id="' + model + 'DataTable_filter" class="dataTables_filter mr-2">' +
      // '      <input id="' + model + 'TableSearch" class="form-control form-control-sm tableSearch" title="right-click to clear" oncontextmenu="return false" aria-controls="' + model + 'DataTable" placeholder="Search Entire Table...">' +
      // '    </div>' +
      '    <span id="order-type-dropdown" class="filter-span-select colvisSearch-3"></span>' +
      '    <span id="order-status-dropdown" class="filter-span-select colvisSearch-11"></span>' +
      '    <span id="supplier-select-dropdown" class="filter-span-select colvisSearch-4"></span>' +
      '    <span id="retailer-select-dropdown" class="filter-span-select colvisSearch-5"></span>' +
      '    <span id="carrier-select-dropdown" class="filter-span-select colvisSearch-8"></span>' +
      // '    <span id="ship-cost-select-input" class="filter-span-input colvisSearch-13"></span>' +
      '  </li>' +
      '  <li class="filter-section" style="width:100%;margin-top:.5rem;">' +
      '    <span class="filter-label">Filters:</span>' +
      '    <span id="customer-select-input" class="filter-span-input colvisSearch-6"></span>' +
      '    <span id="po-select-input" class="filter-span-input colvisSearch-7"></span>' +
      '    <span id="tracking-select-input" class="filter-span-input colvisSearch-10"></span>' +
      '    <span id="invoiced-dropdown" class="filter-span-select colvisSearch-14" style="display:none;"></span>' +
      '    <form action="/orders" accept-charset="UTF-8" method="get">' +
      '      <span class="filter-span-range colvisSearch-2" title="Order Date Range Search (leaving start or end date blank will assume infinity)">' +
      '        <div>' +
      '          <div class="yadcf-filter-wrapper">' +
      '            <div class="yadcf-filter-wrapper-inner">' +
      '              <input type="date" name="startdate" id="startdate" placeholder="From" class="yadcf-filter-range-date yadcf-filter-range yadcf-filter-range-start hasDatepicker" oncontextmenu="return false" style="width:150px;">' +
      '              <input type="date" name="enddate" id="enddate" placeholder="To" class="yadcf-filter-range-date yadcf-filter-range yadcf-filter-range-end hasDatepicker" oncontextmenu="return false" style="width:150px;margin-left:-3px;">' +
      '            </div>' +
      '            <button type="submit" class="yadcf-filter-reset-button" title="Note that Date Range search will clear all other filters">Go</button>' +
      '          </div>' +
      '        </div>' +
      '      </span>' +
      '    </form>' +
      '  </li>' +
      '</ul>' +
      '';
    }

    table.page.len(25).draw();

    $('div.dataTableToolbar').html(dataTableToolbar('orders'));

    var search = $.fn.dataTable.util.throttle(
      function (val) {
        table.search(val).draw();
      }, 500);
     
    $('.tableSearch').on('keyup', function () {
      search( this.value );
    });
  
    $('.tableLength').on('change', function () {
      table.page.len($(this).find('option:selected').attr('value')).draw();
    });
  
    function colVisButtons(index) {
      var button = $('#colvisClicker-'+index);
      button.on('click', function(e) {
        e.stopPropagation();
        if (table.column(index).visible() === true) {
          table.column(index).visible(false);
          button.addClass('button-highlight');
          $('.colvisSearch-'+index).hide();
        }
        else {
          table.column(index).visible(true);
          button.removeClass('button-highlight');
          $('.colvisSearch-'+index).show();
        }
      });
    }
    let colVisIndex = -1;
    do { colVisIndex += 1;
         colVisButtons(colVisIndex);
    } while (colVisIndex < 19);

    // $(document).on('click', '.notesModalButton', function() {
    //   $(this).closest('td').find('.notes-modal').modal();
    // });

    yadcf.init(table,
      [
  
        { column_number : 3, // Order Type
          filter_container_id : 'order-type-dropdown',
          style_class : 'form-control form-control-sm',
          filter_default_label : 'Order Type...',
          select_type: 'select',
          data: [
            {value: '1', label : 'E-Commerce Order'},
            {value: '2', label : 'Warranty Order'},
            {value: '3', label : 'Store POP'},
            {value: '5', label : 'Third Party Replenishment'},
            {value: '6', label : 'Third Party Sale'},
            {value: '4', label : 'Other'},
          ]
        },
  
        { column_number : 13, // Order Status
          filter_container_id : 'order-status-dropdown',
          style_class : 'form-control form-control-sm',
          filter_default_label : 'Order Status...',
          select_type: 'select',
          data: [
            {value: '1', label : 'Open'},
            {value: '2', label : 'Shipped'},
            {value: '3', label : 'On Hold'},
            {value: '4', label : 'Backordered'},
            {value: '5', label : 'Cancelled (Out of Stock)'},
            {value: '6', label : 'Cancelled (Merchant Request)'},
            {value: '7', label : 'Partial Return'},
            {value: '8', label : 'Full Return'},
            {value: '9', label : 'Other (See Notes)'},
          ]
        },
  
        { column_number : 4, // Supplier
          filter_container_id : "supplier-select-dropdown",
          filter_default_label : "Suppliers... ↓↓↓",
          filter_match_mode : "exact",
          filter_type: 'multi_select',
          select_type: 'select2',
          data: $('#params_span').data('suppliers_dropdown')
        },

        { column_number : 6, // Customer
          filter_type: 'text',
          filter_container_id : 'customer-select-input',
          style_class : 'form-control form-control-sm',
          filter_default_label : 'Customer...'
        },
  
        { column_number : 7, // PO #
          filter_type: 'text',
          filter_container_id : 'po-select-input',
          style_class : 'form-control form-control-sm',
          filter_default_label : 'PO #...'
        },
  
        { column_number : 5, // Retailer
          filter_container_id : "retailer-select-dropdown",
          filter_default_label : "Retailers... ↓↓↓",
          filter_match_mode : "exact",
          filter_type: 'multi_select',
          select_type: 'select2',
          data: $('#params_span').data('retailers_dropdown')
        },

        { column_number : 8, // Carrier
          filter_container_id : "carrier-select-dropdown",
          filter_default_label : "Carriers... ↓↓↓",
          filter_match_mode : "exact",
          filter_type: 'multi_select',
          select_type: 'select2',
          data: $('#params_span').data('carriers_dropdown')
        },

        { column_number : 12, // Tracking Number
          filter_type: 'text',
          filter_container_id : 'tracking-select-input',
          style_class : 'form-control form-control-sm',
          filter_default_label : 'Tracking #...'
        },
  
        { column_number : 16, // Invoiced
          filter_container_id : 'invoiced-dropdown',
          style_class : 'form-control form-control-sm',
          filter_default_label : 'Invoiced?...',
          data: [
            {value: '1', label : 'Yes'},
            {value: '2', label : 'No'},
          ]
        },

      ],
      { filters_position : 'footer' },
      { autocomplete : false },
      { externally_triggered : true }
    );

    // This block executes any query string searches that may be present in the URL
    function filterTable(columnIndex, filterValue){
      yadcf.exFilterColumn(table, [
        [columnIndex, filterValue],
      ]);
    }
    const queryStringParams = window.location.search.substring(1).split('&');

    queryStringParams.forEach(param => {

      if (param.indexOf('retailer') !== -1) {
        filterTable(5, $('#params_span').data('retailer'));
      } else if (param.indexOf('supplier') !== -1) {
        filterTable(4, $('#params_span').data('supplier'));
      } else if (param.indexOf('carrier') !== -1) {
        filterTable(8, $('#params_span').data('carrier'));
      } else if (param.indexOf('order_type') !== -1) {
        filterTable(3, [$('#params_span').data('order_type')]);
      } else if (param.indexOf('order_status') !== -1) {
        filterTable(13, [$('#params_span').data('order_status')]);
      }
    });

    // Text column searches need to be executed a second time to trigger the actual search
    yadcf.exFilterColumn(table, [
      [5, yadcf.exGetColumnFilterVal(table,5)],
      [4, yadcf.exGetColumnFilterVal(table,4)]
    ], true);

    $('#startdate').val($('#startdate_h').attr('value'));
    $('#enddate').val($('#enddate_h').attr('value'));
  
    $('body').tooltip({selector: '[data-toggle="tooltip"]'});

  }

//----------------------------------------------------------------------------//
//---------- ORDERS PACKING SLIP RELATED -------------------------------------//
//----------------------------------------------------------------------------//

  if ($('.packing_slip_div').length > 0) {

    $('body').tooltip({
      selector: '[data-toggle="tooltip"]'
    });

  }

//----------------------------------------------------------------------------//
//---------- ORDERS SHOW RELATED ---------------------------------------------//
//----------------------------------------------------------------------------//

  if ($('.order_show_div').length > 0) {

    $(document).on('click', '.ots-clicker', function() {
      $(this).closest('span').find('.on_the_spot_editing').click();
    });
  
    $(document).on('click', '.order-ship-date', function() {
      $(this).hide();
      $(this).parent().find('.ots-ship-date').show();
      $(this).parent().find('.on_the_spot_editing').click();
    });
  
    $(document).on('focus', '.ship-date', function() {
      var raw_date = $(this).closest('.ship-date-container').find('.ship-date-trunc').data('rawdate');
      $(this).attr('type', 'date');
      $(this).val(raw_date);
    });
  
    $(document).on('click', 'button:contains("Ok")', function() {
      $('.ots-ship-date').hide();
      $('.order-ship-date').show();
      $('#ship_date_async_refresh').click();
    });

  }

});