/*global $*/
/*global yadcf*/

import 'stylesheets/line_items-global.scss';

$(document).on("turbolinks:load", function() {

//----------------------------------------------------------------------------//
//---------- LINE ITEMS CUSTOM ITEMS INDEX RELATED ---------------------------//
//----------------------------------------------------------------------------//

  if ($('.custom_items_index_div').length > 0) {
    
    var MyDate = new Date();
    var date;
    MyDate.setDate(MyDate.getDate());
    date = MyDate.getFullYear() + "-" + ("0" + (MyDate.getMonth() + 1)).slice(-2) + "-" + ("0" + MyDate.getDate()).slice(-2);
    var filename = "SRS Orders, " + date + " Export";
    var table = $("#customItemsDataTable").DataTable({
      "retrieve": true,
      "processing": true,
      "serverSide": true,
      // "stateSave": true,
      // "stateDuration": "-1",
      "ajax": {
        "url": $("#customItemsDataTable").data("source"),
        // // "error": function (reason) {
        // //   if (reason.status == 401) { // Not Authorized
        // //     window.location = '#/logout';
        // //   }
        // }
      },
      "columns": [ // global search is disabled due to bugged columns below
  /*0 */{data: "id", searchable: false, visible: false},
  /*1 */{data: "order_date"},
  /*2 */{data: "order_type"},
  /*3 */{data: "supplier"},
  /*4 */{data: "retailer"},
  /*5 */{data: "customer"},
  /*6 */{data: "po_number"},
  /*7 */{data: "carrier"},
  /*8 */{data: "custom_item"},
  /*9 */{data: "description"},
  /*10*/{data: "quantity"},
  /*11*/{data: "custom_cost"},
  /*12*/{data: "actions", orderable: false},
      ], // Order Date, Supplier, Retailer, Order Status, Order ID
      "order": [[1,"desc"], [3, "asc"], [4, "asc"], [0, "asc"]],
  
      "oLanguage": { "sZeroRecords": "No records to display for this filter" },
      "dom": "<'row'<'col-sm-10 dataTableToolbar'><'col-sm-2'B>>t<'row dataTableBottomRow'<'col-md-2'i><'col-md-10'p>>",
      "buttons": [
        {
          extend: "excelHtml5",
          title: filename,
          // Only exports columns that are currently visible.  Adjusted by the "Visibility" dropdown and Filtered text.
          exportOptions: {columns: ":visible"},
          className: "dtButtonHide",
        },
        {
          extend: "csv",
          title: filename,
          exportOptions: {columns: ":visible"},
          className: "dtButtonHide"
        },
        {
          extend: "pdfHtml5",
          title: filename,
          orientation: "landscape",
          exportOptions: {columns: ":visible"},
          className: "dtButtonHide"
        },
        {
          extend: "copyHtml5",
          title: filename,
          exportOptions: {columns: ":visible"},
          className: "dtButtonHide"
        }, 
        {
          extend: "print",
          autoPrint: false,
          title: filename,
          exportOptions: {columns: ":visible"},
          customize: function (win) {
              $(win.document.body).find('table').addClass('display').css('font-size', '12px');
              $(win.document.body).find('tr:nth-child(odd) td').each(function(index){
                  $(this).css('background-color','#D0D0D0');
              });
              $(win.document.body).find('h1').css('text-align','center');
          },
          className: "dtButtonHide"
        }
      ]
    });
  
    function dataTableToolbar() {
      return "" +
      "<ul class='nav' style='margin-bottom:10px'>" +
      "  <li>" +
      "    <span class='options-label'>Options:</span>" +
      "  </li>" +
      "  <li>" +
      "    <div class='dataTables_length' id='customItemsDataTable_length'>" +
      "      <select name='customItemsDataTable_length' aria-controls='customItemsDataTable' class='form-control form-control-sm tableLength' title='Number of records to show'>" +
      "        <option value='5'>5</option>" +
      "        <option value='10'>10</option>" +
      "        <option selected='selected' value='25'>25</option>" +
      "        <option value='50'>50</option>" +
      "        <option value='100'>100</option>" +
      "        <option value='250'>250</option>" +
      "        <option value='1000'>1000</option>" +
      "      </select>" +
      "    </div>" +
      "  </li>" +
      "  <li>" +
      "    <div class='tableClickerDiv dropdown'>" +
      "      <button class='btn btn-outline-primary btn-sm dropdown-toggle' title='Column visibility options' type='button' id='tableClickerColvis' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'><i class='fas fa-columns'></i> Columns</button>" +
      "      <div class='dropdown-menu card-shadow' aria-labelledby='tableClickerColvis'>" +
      "        <a class='dropdown-item button-highlight' id='colvisClicker-0'>Record ID</a>" +
      "        <a class='dropdown-item' id='colvisClicker-1'>Order Date</a>" +
      "        <a class='dropdown-item' id='colvisClicker-2'>Order Type</a>" +
      "        <a class='dropdown-item' id='colvisClicker-3'>Supplier</a>" +
      "        <a class='dropdown-item' id='colvisClicker-4'>Retailer</a>" +
      "        <a class='dropdown-item' id='colvisClicker-5'>Customer</a>" +
      "        <a class='dropdown-item' id='colvisClicker-6'>PO Number</a>" +
      "        <a class='dropdown-item' id='colvisClicker-7'>Carrier</a>" +
      "        <a class='dropdown-item' id='colvisClicker-8'>Custom Item</a>" +
      "        <a class='dropdown-item' id='colvisClicker-9'>Description</a>" +
      "        <a class='dropdown-item' id='colvisClicker-10'>Quantity</a>" +
      "        <a class='dropdown-item' id='colvisClicker-11'>Custom Cost</a>" +
      "        <a class='dropdown-item' id='colvisClicker-12'>Actions</a>" +
      "      </div>" +
      "    </div>" +
      "  </li>" +
      "  <li>" +
      "    <div class='tableClickerDiv dropdown'>" +
      "      <button class='btn btn-outline-primary btn-sm dropdown-toggle' title='Export Options' type='button' id='tableClickerExports' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'><i class='fas fa-file-export'></i> Export</button>" +
      "      <div class='dropdown-menu' aria-labelledby='tableClickerExports'>" +
      "        <div class='text-primary' style='font-size:11px;padding:0.25rem 1.5rem;'>" +
      "          <i class='fas fa-exclamation-triangle text-danger'></i> Only the currently visible columns & rows will be exported" +
      "        </div>" +
      "        <a class='dropdown-item tableClickerExcel'><i class='fas fa-file-excel file-icon-color'></i> Excel <i class='fas fa-download text-primary'></i></a>" +
      "        <a class='dropdown-item tableClickerCsv'><i class='fas fa-file-csv file-icon-color'></i> CSV <i class='fas fa-download text-primary'></i></a>" +
      "        <a class='dropdown-item tableClickerPdf'><i class='fas fa-file-pdf file-icon-color'></i> PDF <i class='fas fa-download text-primary'></i></a>" +
      "        <a class='dropdown-item tableClickerCopy'><i class='fas fa-copy file-icon-color'></i> Copy</a>" +
      "        <a class='dropdown-item tableClickerPrint'><i class='fas fa-print file-icon-color'></i> Print/Preview</a>" +
      "      </div>" +
      "    </div>" +
      "  </li>" +
      "  <li class='filter-section' style='width:100%'>" +
      "    <span class='filter-label'>Filters:</span>" +
      "    <div id='customItemsDataTable_filter' class='dataTables_filter mr-2'>" +
      "      <input id='lineItemsTableSearch' class='form-control form-control-sm tableSearch' title='right-click to clear' oncontextmenu='return false' aria-controls='customItemsDataTable' placeholder='Search Entire Table...'>" +
      "    </div>" +
      "    <span id='order-type-dropdown' class='filter-span-select colvisSearch-2'></span>" +
      "    <span id='supplier-select-input' class='filter-span-input colvisSearch-3'></span>" +
      "    <span id='retailer-select-input' class='filter-span-input colvisSearch-4'></span>" +
      "    <span id='customer-select-input' class='filter-span-input colvisSearch-5'></span>" +
      "    <span id='po-select-input' class='filter-span-input colvisSearch-6'></span>" +
      "  </li>" +
      "  <li class='filter-section' style='width:100%;margin-top:.5rem;'>" +
      "    <span class='filter-label'>Filters:</span>" +
      "    <span id='carrier-select-input' class='filter-span-input colvisSearch-7'></span>" +
      "    <span id='custom-item-select-input' class='filter-span-input colvisSearch-8'></span>" +
      "    <span id='description-select-input' class='filter-span-input colvisSearch-9'></span>" +
      "    <form action='/line_items' accept-charset='UTF-8' method='get'>" +
      "      <span class='filter-span-range colvisSearch-2' title='Order Date Range Search (leaving start or end date blank will assume infinity)'>" +
      "        <div>" +
      "          <div class='yadcf-filter-wrapper'>" +
      "            <div class='yadcf-filter-wrapper-inner'>" +
      "              <input type='date' name='startdate' id='startdate' placeholder='From' class='yadcf-filter-range-date yadcf-filter-range yadcf-filter-range-start hasDatepicker' oncontextmenu='return false' style='width:150px;'>" +
      "              <input type='date' name='enddate' id='enddate' placeholder='To' class='yadcf-filter-range-date yadcf-filter-range yadcf-filter-range-end hasDatepicker' oncontextmenu='return false' style='width:150px;margin-left:-3px;'>" +
      "            </div>" +
      "            <button type='submit' name='commit' class='yadcf-filter-reset-button' title='Note that Date Range search will clear all other filters'>Go</button>" +
      "          </div>" +
      "        </div>" +
      "      </span>" +
      "    </form>" +
      "  </li>" +
      "</ul>" +
      "";
    }
  
    table.page.len(25).draw();
  
    $("div.dataTableToolbar").html(dataTableToolbar());

    var search = $.fn.dataTable.util.throttle(
      function (val) {
        table.search(val).draw();
      }, 500);
     
    $('.tableSearch').on('keyup', function () {
      search( this.value );
    });
  
    $(".tableLength").on("change", function () {
      table.page.len($(this).find("option:selected").attr("value")).draw();
    });
  
    function colVisButtons(index) {
      var button = $("#colvisClicker-"+index);
      button.on("click", function(e) {
        e.stopPropagation();
        if (table.column(index).visible() === true) {
          table.column(index).visible(false);
          button.addClass("button-highlight");
          $(".colvisSearch-"+index).hide();
        }
        else {
          table.column(index).visible(true);
          button.removeClass("button-highlight");
          $(".colvisSearch-"+index).show();
        }
      });
    }
    let colVisIndex = -1;
    do { colVisIndex += 1;
         colVisButtons(colVisIndex);
    } while (colVisIndex < 12);
  
    yadcf.init(table,
      [
  
        { column_number : 2, // Order Type
          filter_container_id : "order-type-dropdown",
          style_class : "form-control form-control-sm",
          filter_default_label : "Order Type...",
          data: [
            {value: "1", label : "E-Commerce Order"},
            {value: "2", label : "Warranty order"},
            {value: "3", label : "Store POP"},
            {value: "5", label : "Third Party Replenishment"},
            {value: "6", label : "Third Party Sale"},
            {value: "4", label : "Other"},
          ]
        },
  
        { column_number : 3, // Supplier
          filter_type: "text",
          filter_container_id : "supplier-select-input",
          style_class : "form-control form-control-sm",
          filter_default_label : "Supplier..."
        },
  
        { column_number : 5, // Customer
          filter_type: "text",
          filter_container_id : "customer-select-input",
          style_class : "form-control form-control-sm",
          filter_default_label : "Customer..."
        },
  
        { column_number : 6, // PO #
          filter_type: "text",
          filter_container_id : "po-select-input",
          style_class : "form-control form-control-sm",
          filter_default_label : "PO #..."
        },
  
        { column_number : 4, // Retailer
          filter_type: "text",
          filter_container_id : "retailer-select-input",
          style_class : "form-control form-control-sm",
          filter_default_label : "Retailer..."
        },
  
        { column_number : 7, // Carrier
          filter_type: "text",
          filter_container_id : "carrier-select-input",
          style_class : "form-control form-control-sm",
          filter_default_label : "Carrier..."
        },
  
        { column_number : 8, // Custom Item
          filter_type: "text",
          filter_container_id : "custom-item-select-input",
          style_class : "form-control form-control-sm",
          filter_default_label : "Custom Item..."
        },
  
        { column_number : 9, // Description
          filter_type: "text",
          filter_container_id : "description-select-input",
          style_class : "form-control form-control-sm",
          filter_default_label : "Description..."
        },
  
      ],
      { filters_position : "footer" },
      { externally_triggered : true }
    );
  
    $("#startdate").val($("#startdate_h").attr("value"));
    $("#enddate").val($("#enddate_h").attr("value"));
  
    $(document).on("mousedown", "input.yadcf-filter-range-date", function() {
      if (event.which === 3) {
        $(this).val("");
      }
    });
  
  }

//----------------------------------------------------------------------------//
//---------- LINE ITEMS INDEX RELATED ----------------------------------------//
//----------------------------------------------------------------------------//

  if ($('.line_items_index_div').length > 0) {
    
    $(".loading-overlay").show();

    var MyDate = new Date();
    var date;
    MyDate.setDate(MyDate.getDate());
    date = MyDate.getFullYear() + "-" + ("0" + (MyDate.getMonth() + 1)).slice(-2) + "-" + ("0" + MyDate.getDate()).slice(-2);
    var filename = "SRS Orders, " + date + " Export";
    var table = $("#lineItemsDataTable").DataTable({
      // "retrieve": true,
      'destroy': true,
      "processing": true,
      "serverSide": true,
      "initComplete": function( settings, json ) {
        $('.loading-overlay').hide();
      },
      // "stateSave": true,
      // "stateDuration": "-1",
      "ajax": {
        "url": $("#lineItemsDataTable").data("source"),
        // // "error": function (reason) {
        // //   if (reason.status == 401) { // Not Authorized
        // //     window.location = '#/logout';
        // //   }
        // }
  
  
      },
      "columns": [ // global search is disabled due to bugged columns below
  /*0 */{data: "id", searchable: false, visible: false},
  /*1 */{data: "order_date"},
  /*2 */{data: "order_type"},
  /*3 */{data: "supplier"},
  /*4 */{data: "retailer"},
  /*5 */{data: "customer"},
  /*6 */{data: "po_number"},
  /*7 */{data: "carrier"},
  /*8 */{data: "line_item"},
  /*9 */{data: "quantity"},
  /*10*/{data: "cost_total"},
  /*11*/{data: "tracking_number"},
  /*12*/{data: "order_status"}, // bugged global search
  /*13*/{data: "invoiced"},
  /*14*/{data: "ship_date"},
  /*15*/{data: "ship_cost"},
  /*16*/{data: "returned", visible: false},
  /*17*/{data: "return_status", visible: false},
  /*18*/{data: "rts_qty", searchable: false, visible: false},
  /*19*/{data: "defect_qty", searchable: false, visible: false},
  /*20*/{data: "actions", orderable: false},
      ], // Order Date, Supplier, Retailer, Order Status, Order ID
      "order": [[1,"desc"], [3, "asc"], [4, "asc"], [11, "desc"], [0, "asc"]],
  
      "oLanguage": { "sZeroRecords": "No records to display for this filter" },
      "dom": "<'row'<'col-sm-10 dataTableToolbar'><'col-sm-2'B>>t<'row dataTableBottomRow'<'col-md-2'i><'col-md-10'p>>",
      "buttons": [
        {
          extend: "excelHtml5",
          title: filename,
          // Only exports columns that are currently visible.  Adjusted by the "Visibility" dropdown and Filtered text.
          exportOptions: {columns: ":visible"},
          className: "dtButtonHide",
        },
        {
          extend: "csv",
          title: filename,
          exportOptions: {columns: ":visible"},
          className: "dtButtonHide"
        },
        {
          extend: "pdfHtml5",
          title: filename,
          orientation: "landscape",
          exportOptions: {columns: ":visible"},
          className: "dtButtonHide"
        },
        {
          extend: "copyHtml5",
          title: filename,
          exportOptions: {columns: ":visible"},
          className: "dtButtonHide"
        }, 
        {
          extend: "print",
          autoPrint: false,
          title: filename,
          exportOptions: {columns: ":visible"},
          customize: function (win) {
              $(win.document.body).find('table').addClass('display').css('font-size', '12px');
              $(win.document.body).find('tr:nth-child(odd) td').each(function(index){
                  $(this).css('background-color','#D0D0D0');
              });
              $(win.document.body).find('h1').css('text-align','center');
          },
          className: "dtButtonHide"
        }
      ]
    });
  
    function dataTableToolbar() {
      return "" +
      '<ul class="nav" style="margin-bottom:10px">' +
      '  <li>' +
      '    <span class="options-label">Options:</span>' +
      '  </li>' +
      '  <li>' +
      '    <div class="dataTables_length" id="lineItemsDataTable_length">' +
      '      <select name="lineItemsDataTable_length" aria-controls="lineItemsDataTable" class="form-control form-control-sm tableLength" title="Number of records to show">' +
      '        <option value="5">5</option>' +
      '        <option value="10">10</option>' +
      '        <option value="25">25</option>' +
      '        <option value="50">50</option>' +
      '        <option selected="selected" value="100">100</option>' +
      '        <option value="250">250</option>' +
      '        <option value="1000">1000</option>' +
      '        <option value="1500">1500</option>' +
      '        <option value="2000">2000</option>' +
      '        <option value="2500">2500</option>' +
      '        <option value="3000">3000</option>' +
      '        <option value="3500">3500</option>' +
      '        <option value="4000">4000</option>' +
      '        <option value="4500">4500</option>' +
      '        <option value="5000">5000</option>' +
      '      </select>' +
      '    </div>' +
      '  </li>' +
      '  <li>' +
      '    <div class="tableClickerDiv dropdown">' +
      '      <button class="btn btn-outline-primary btn-sm dropdown-toggle" title="Column visibility options" type="button" id="tableClickerColvis" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="fas fa-columns"></i> Columns</button>' +
      '      <div class="dropdown-menu card-shadow" aria-labelledby="tableClickerColvis">' +
      '        <a class="dropdown-item button-highlight" id="colvisClicker-0">Record ID</a>' +
      '        <a class="dropdown-item" id="colvisClicker-1">Order Date</a>' +
      '        <a class="dropdown-item" id="colvisClicker-2">Order Type</a>' +
      '        <a class="dropdown-item" id="colvisClicker-3">Supplier</a>' +
      '        <a class="dropdown-item" id="colvisClicker-4">Retailer</a>' +
      '        <a class="dropdown-item" id="colvisClicker-5">Customer</a>' +
      '        <a class="dropdown-item" id="colvisClicker-6">PO Number</a>' +
      '        <a class="dropdown-item" id="colvisClicker-7">Carrier</a>' +
      '        <a class="dropdown-item" id="colvisClicker-8">Line Item</a>' +
      '        <a class="dropdown-item" id="colvisClicker-9">Quantity</a>' +
      '        <a class="dropdown-item" id="colvisClicker-10">Cost Total</a>' +
      '        <a class="dropdown-item" id="colvisClicker-11">Master Tracking</a>' +
      '        <a class="dropdown-item" id="colvisClicker-12">Order Status</a>' +
      '        <a class="dropdown-item" id="colvisClicker-13">Invoiced?</a>' +
      '        <a class="dropdown-item" id="colvisClicker-14">Ship Date</a>' +
      '        <a class="dropdown-item" id="colvisClicker-15">Shipping Cost</a>' +
      '        <a class="dropdown-item button-highlight" id="colvisClicker-16">Returned?</a>' +
      '        <a class="dropdown-item button-highlight" id="colvisClicker-17">Return Status</a>' +
      '        <a class="dropdown-item button-highlight" id="colvisClicker-18">RTS Quantity</a>' +
      '        <a class="dropdown-item button-highlight" id="colvisClicker-19">Defective Quantity</a>' +
      '        <a class="dropdown-item" id="colvisClicker-20">Actions</a>' +
      '      </div>' +
      '    </div>' +
      '  </li>' +
      '  <li>' +
      '    <div class="tableClickerDiv dropdown">' +
      '      <button class="btn btn-outline-primary btn-sm dropdown-toggle" title="Export Options" type="button" id="tableClickerExports" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="fas fa-file-export"></i> Export</button>' +
      '      <div class="dropdown-menu" aria-labelledby="tableClickerExports">' +
      '        <div class="text-primary" style="font-size:11px;padding:0.25rem 1.5rem;">' +
      '          <i class="fas fa-exclamation-triangle text-danger"></i> Only the currently visible columns & rows will be exported' +
      '        </div>' +
      '        <a class="dropdown-item tableClickerExcel"><i class="fas fa-file-excel file-icon-color"></i> Excel <i class="fas fa-download text-primary"></i></a>' +
      '        <a class="dropdown-item tableClickerCsv"><i class="fas fa-file-csv file-icon-color"></i> CSV <i class="fas fa-download text-primary"></i></a>' +
      '        <a class="dropdown-item tableClickerPdf"><i class="fas fa-file-pdf file-icon-color"></i> PDF <i class="fas fa-download text-primary"></i></a>' +
      '        <a class="dropdown-item tableClickerCopy"><i class="fas fa-copy file-icon-color"></i> Copy</a>' +
      '        <a class="dropdown-item tableClickerPrint"><i class="fas fa-print file-icon-color"></i> Print/Preview</a>' +
      '      </div>' +
      '    </div>' +
      '  </li>' +
      '  <li class="filter-section" style="width:100%;margin-top:.5rem;">' +
      '    <span class="filter-label">Filters:</span>' +
      '    <div id="lineItemsDataTable_filter" class="dataTables_filter mr-2">' +
      '      <input id="lineItemsTableSearch" class="form-control form-control-sm tableSearch" title="right-click to clear" oncontextmenu="return false" aria-controls="lineItemsDataTable" placeholder="Search Entire Table...">' +
      '    </div>' +
      '    <span id="order-type-dropdown" class="filter-span-select colvisSearch-2"></span>' +
      '    <span id="order-status-dropdown" class="filter-span-select colvisSearch-11"></span>' +
      '    <span id="supplier-select-dropdown" class="filter-span-select colvisSearch-3"></span>' +
      '    <span id="retailer-select-dropdown" class="filter-span-select colvisSearch-4"></span>' +
      '    <span id="carrier-select-dropdown" class="filter-span-select colvisSearch-7"></span>' +
      '  </li>' +
      '  <li class="filter-section" style="width:100%">' +
      '    <span class="filter-label">Filters:</span>' +
      '    <span id="customer-select-input" class="filter-span-input colvisSearch-5"></span>' +
      '    <span id="po-select-input" class="filter-span-input colvisSearch-6"></span>' +
      '    <span id="sku-select-dropdown" class="filter-span-select colvisSearch-8"></span>' +
      '    <span id="tracking-select-input" class="filter-span-input colvisSearch-10"></span>' +
      '    <form action="/line_items" accept-charset="UTF-8" method="get">' +
      '      <span class="filter-span-range colvisSearch-2" title="Order Date Range Search (leaving start or end date blank will assume infinity)">' +
      '        <div>' +
      '          <div class="yadcf-filter-wrapper">' +
      '            <div class="yadcf-filter-wrapper-inner">' +
      '              <input type="date" name="startdate" id="startdate" placeholder="From" class="yadcf-filter-range-date yadcf-filter-range yadcf-filter-range-start hasDatepicker" oncontextmenu="return false" style="width:150px;">' +
      '              <input type="date" name="enddate" id="enddate" placeholder="To" class="yadcf-filter-range-date yadcf-filter-range yadcf-filter-range-end hasDatepicker" oncontextmenu="return false" style="width:150px;margin-left:-3px;">' +
      '            </div>' +
      '            <button type="submit" name="commit" class="yadcf-filter-reset-button" title="Note that Date Range search will clear all other filters">Go</button>' +
      '          </div>' +
      '        </div>' +
      '      </span>' +
      '    </form>' +
      '  </li>' +
      '</ul>' +
      '';
    }

    table.page.len(100).draw();

    $("div.dataTableToolbar").html(dataTableToolbar());

    var search = $.fn.dataTable.util.throttle(
      function (val) {
        table.search(val).draw();
      }, 500);
     
    $('.tableSearch').on('keyup', function () {
      search( this.value );
    });
  
    $(".tableLength").on("change", function () {
      table.page.len($(this).find("option:selected").attr("value")).draw();
    });
  
    function colVisButtons(index) {
      var button = $("#colvisClicker-"+index);
      button.on("click", function(e) {
        e.stopPropagation();
        if (table.column(index).visible() === true) {
          table.column(index).visible(false);
          button.addClass("button-highlight");
          $(".colvisSearch-"+index).hide();
        }
        else {
          table.column(index).visible(true);
          button.removeClass("button-highlight");
          $(".colvisSearch-"+index).show();
        }
      });
    }
    let colVisIndex = -1;
    do { colVisIndex += 1;
         colVisButtons(colVisIndex);
    } while (colVisIndex < 20);
  
    yadcf.init(table,
      [
  
        { column_number : 2, // Order Type
          filter_container_id : "order-type-dropdown",
          style_class : "form-control form-control-sm",
          filter_default_label : "Order Type...",
          data: [
            {value: "1", label : "E-Commerce Order"},
            {value: "2", label : "Warranty order"},
            {value: "3", label : "Store POP"},
            {value: "5", label : "Third Party Replenishment"},
            {value: "6", label : "Third Party Sale"},
            {value: "4", label : "Other"},
          ]
        },
  
        { column_number : 12, // Order Status
          filter_container_id : "order-status-dropdown",
          style_class : "form-control form-control-sm",
          filter_default_label : "Order Status...",
          data: [
            {value: "1", label : "Open"},
            {value: "2", label : "Shipped"},
            {value: "3", label : "On Hold"},
            {value: "4", label : "Backordered"},
            {value: "5", label : "Cancelled (Out of Stock)"},
            {value: "6", label : "Cancelled (Merchant Request)"},
            {value: "7", label : "Partial Return"},
            {value: "8", label : "Full Return"},
            {value: "9", label : "Other (See Notes)"},
          ]
        },
  
        { column_number : 3, // Supplier
          filter_container_id : "supplier-select-dropdown",
          filter_default_label : "Suppliers... ↓↓↓",
          filter_match_mode : "exact",
          filter_type: 'multi_select',
          select_type: 'select2',
          data: $('#params_span').data('suppliers_dropdown')
        },
  
        { column_number : 5, // Customer
          filter_type: "text",
          filter_container_id : "customer-select-input",
          style_class : "form-control form-control-sm",
          filter_default_label : "Customer..."
        },
  
        { column_number : 6, // PO #
          filter_type: "text",
          filter_container_id : "po-select-input",
          style_class : "form-control form-control-sm",
          filter_default_label : "PO #..."
        },
  
        { column_number : 4, // Retailer
          filter_container_id : "retailer-select-dropdown",
          filter_default_label : "Retailers... ↓↓↓",
          filter_match_mode : "exact",
          filter_type: 'multi_select',
          select_type: 'select2',
          data: $('#params_span').data('retailers_dropdown')
        },
  
        { column_number : 7, // Carrier
          filter_container_id : "carrier-select-dropdown",
          filter_default_label : "Carriers... ↓↓↓",
          filter_match_mode : "exact",
          filter_type: 'multi_select',
          select_type: 'select2',
          data: $('#params_span').data('carriers_dropdown')
        },
  
        { column_number : 8, // Line Item
          filter_container_id : "sku-select-dropdown",
          filter_default_label : "Line Item SKUs... ↓↓↓",
          filter_match_mode : "exact",
          filter_type: 'multi_select',
          select_type: 'select2',
          data: $('#params_span').data('skus_dropdown')
        },
  
        { column_number : 11, // Tracking Number
          filter_type: "text",
          filter_container_id : "tracking-select-input",
          style_class : "form-control form-control-sm",
          filter_default_label : "Tracking #..."
        },
      ],
      { filters_position : "footer" },
      { autocomplete: false },
      { externally_triggered : true }
    );
  
    // This block executes any query string searches that may be present in the URL
    function filterTable(columnIndex, filterValue){
      yadcf.exFilterColumn(table, [
        [columnIndex, filterValue],
      ]);
    }
    const queryStringParams = window.location.search.substring(1).split('&');

    queryStringParams.forEach(param => {

      if (param.indexOf('retailer') !== -1) {
        filterTable(4, $('#params_span').data('retailer'));
      } else if (param.indexOf('sku') !== -1) {
        filterTable(8, $('#params_span').data('sku'));
      } else if (param.indexOf('supplier') !== -1) {
        filterTable(3, $('#params_span').data('supplier'));
      } else if (param.indexOf('carrier') !== -1) {
        filterTable(7, $('#params_span').data('carrier'));
      } else if (param.indexOf('order_type') !== -1) {
        filterTable(2, [$('#params_span').data('order_type')]);
      } else if (param.indexOf('order_status') !== -1) {
        filterTable(12, [$('#params_span').data('order_status')]);
      }
    });

    // Text column searches need to be executed a second time to trigger the actual search
    // yadcf.exFilterColumn(table, [
    //   [5, yadcf.exGetColumnFilterVal(table,5)],
    //   [4, yadcf.exGetColumnFilterVal(table,4)]
    // ], true);

    $("#startdate").val($("#startdate_h").attr("value"));
    $("#enddate").val($("#enddate_h").attr("value"));
  
    $(document).on("mousedown", "input.yadcf-filter-range-date", function() {
      if (event.which === 3) {
        $(this).val("");
      }
    });
  
  }

//----------------------------------------------------------------------------//
//---------- LINE ITEMS QUICKBOOKS EXPORT RELATED ----------------------------//
//----------------------------------------------------------------------------//

  if ($('.quickbooks_export_div').length > 0) {
    
    $(".loading-overlay").show();

    var MyDate = new Date();
    var date;
    MyDate.setDate(MyDate.getDate());
    date = MyDate.getFullYear() + "-" + ("0" + (MyDate.getMonth() + 1)).slice(-2) + "-" + ("0" + MyDate.getDate()).slice(-2);
    var filename = "Quickbooks Line Items Export, " + date;
    var table = $("#quickbooksExportDataTable").DataTable({
      "retrieve": true,
      "processing": true,
      "serverSide": true,
      "initComplete": function( settings, json ) {
        $('.loading-overlay').hide();
      },
      "ajax": {
        "url": $("#quickbooksExportDataTable").data("source")
      },
      "columns": [ // global search is disabled due to bugged columns below
  /*0 */{data: "invoice_no"},
  /*1 */{data: "customer"},
  /*2 */{data: "invoice_date", searchable: false},
  /*3 */{data: "due_date", searchable: false},
  /*4 */{data: "terms", searchable: false, orderable: false},
  /*5 */{data: "location"},
  /*6 */{data: "memo", searchable: false, orderable: false},
  /*7 */{data: "item"},
  /*8 */{data: "item_description"},
  /*9 */{data: "item_quantity", searchable: false},
  /*10*/{data: "item_rate", searchable: false, orderable: false},
  /*11*/{data: "item_amount", searchable: false, orderable: false},
  /*12*/{data: "service_date", searchable: false},
  /*13*/{data: "discounts", searchable: false, orderable: false}
      ], 
      
      // Service Date, Invoice Number, Item
      "order": [[12,"desc"], [0, "asc"], [7, "asc"]],

      "oLanguage": { "sZeroRecords": "No records to display for this filter" },
      "dom": "<'row'<'col-sm-10 dataTableToolbar'><'col-sm-2'B>>t<'row dataTableBottomRow'<'col-md-2'i><'col-md-10'p>>",
      "buttons": [
        {
          extend: "excelHtml5",
          title: null,
          // Only exports columns that are currently visible.  Adjusted by the "Visibility" dropdown and Filtered text.
          exportOptions: {columns: ":visible"},
          className: "dtButtonHide",
        },
        {
          extend: "csv",
          title: null,
          exportOptions: {columns: ":visible"},
          className: "dtButtonHide"
        },
        {
          extend: "pdfHtml5",
          title: null,
          orientation: "landscape",
          exportOptions: {columns: ":visible"},
          className: "dtButtonHide"
        },
        {
          extend: "copyHtml5",
          title: null,
          exportOptions: {columns: ":visible"},
          className: "dtButtonHide"
        }, 
        {
          extend: "print",
          autoPrint: false,
          title: null,
          exportOptions: {columns: ":visible"},
          customize: function (win) {
              $(win.document.body).find('table').addClass('display').css('font-size', '12px');
              $(win.document.body).find('tr:nth-child(odd) td').each(function(index){
                  $(this).css('background-color','#D0D0D0');
              });
              $(win.document.body).find('h1').css('text-align','center');
          },
          className: "dtButtonHide"
        },
        // {
        //   extend: "excelHtml5",
        //   title: null,
        //   exportOptions: {
        //     columns: [0,13],
        //     rows: ':not(.exclude)',

        //     rows: function ( idx, data, node ) {
        //         return data[2] === 'Product' ?
        //                 true : false;
        //     }
        //   },
        //   className: "quick-export-discounts dtButtonHide"
        // }
      ],

      // "rowCallback": function (row, data) {
      //   let name = data.invoice_no;
      //   if (names.indexOf(name) > -1) {
      //     duplicateName.push(name);
      //   }
      //   $(row).attr('data-val', name).addClass('exclude');
      //   names.push(name);
      // },
      // "drawCallback": function () {
      //   $.each(duplicateName, function(index, name) {
      //     $("[data-val='" + name + "']:last").removeClass('exclude');
      //   });
      // },

    });

    function dataTableToolbar() {
      return "" +
      "<ul class='nav' style='margin-bottom:10px'>" +
      "  <li class='filter-section mt-2' style='width:100%'>" +
      "    <span class='filter-label'>Options:</span>" +
      "    <div class='dataTables_length' id='quickbooksExportDataTable_length'>" +
      "      <select name='quickbooksExportDataTable_length' aria-controls='quickbooksExportDataTable' class='form-control form-control-sm tableLength' title='Number of records to show'>" +
      "        <option value='5'>5</option>" +
      "        <option value='10'>10</option>" +
      "        <option value='25'>25</option>" +
      "        <option value='50'>50</option>" +
      "        <option value='100'>100</option>" +
      "        <option selected='selected' value='150'>150</option>" +
      "        <option value='250'>250</option>" +
      "        <option value='500'>500</option>" +
      "        <option value='1000'>1000</option>" +
      "      </select>" +
      "    </div>" +
      "    <div class='tableClickerDiv dropdown mr-2'>" +
      "      <button class='btn btn-outline-primary btn-sm dropdown-toggle' title='Export Options' type='button' id='tableClickerExports' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'><i class='fas fa-file-export'></i> Export</button>" +
      "      <div class='dropdown-menu' aria-labelledby='tableClickerExports'>" +
      "        <div class='text-primary' style='font-size:11px;padding:0.25rem 1.5rem;'>" +
      "          <i class='fas fa-exclamation-triangle text-danger'></i> Only the currently visible rows will be exported" +
      "        </div>" +
      "        <a class='dropdown-item tableClickerExcel'><i class='fas fa-file-excel file-icon-color'></i> Excel <i class='fas fa-download text-primary'></i></a>" +
      "        <a class='dropdown-item tableClickerCsv'><i class='fas fa-file-csv file-icon-color'></i> CSV <i class='fas fa-download text-primary'></i></a>" +
      "        <a class='dropdown-item tableClickerPdf'><i class='fas fa-file-pdf file-icon-color'></i> PDF <i class='fas fa-download text-primary'></i></a>" +
      "        <a class='dropdown-item tableClickerCopy'><i class='fas fa-copy file-icon-color'></i> Copy</a>" +
      "        <a class='dropdown-item tableClickerPrint'><i class='fas fa-print file-icon-color'></i> Print/Preview</a>" +
      // "        <a class='dropdown-item' id='tableClickerQuickExportDiscounts'><i class='fas fa-print file-icon-color'></i> Export Discounts</a>" +
      "      </div>" +
      "    </div>" +
      "    <div id='quickbooksExportDataTable_filter' class='dataTables_filter mr-2'>" +
      "      <input id='quickbooksExportTableSearch' class='form-control form-control-sm tableSearch' title='Searchable Columns: InvoiceNo, Customer, Class, Item, ItemDescription' oncontextmenu='return false' aria-controls='quickbooksExportDataTable' placeholder='Search...'>" +
      "    </div>" +
      "    <span id='retailer-select-dropdown' class='filter-span-select'></span>" +
      "    <form action='/line_items/quickbooks_export' accept-charset='UTF-8' method='get'>" +
      "      <span class='filter-span-range colvisSearch-2' title='Line Items Date Range Search (leaving start or end date blank will assume infinity)'>" +
      "        <div>" +
      "          <div class='yadcf-filter-wrapper'>" +
      "            <div class='yadcf-filter-wrapper-inner'>" +
      "              <input type='date' name='startdate' id='startdate' placeholder='From' class='yadcf-filter-range-date yadcf-filter-range yadcf-filter-range-start hasDatepicker' oncontextmenu='return false' style='width:150px;'>" +
      "              <input type='date' name='enddate' id='enddate' placeholder='To' class='yadcf-filter-range-date yadcf-filter-range yadcf-filter-range-end hasDatepicker' oncontextmenu='return false' style='width:150px;margin-left:-3px;'>" +
      "            </div>" +
      "            <button type='submit' name='commit' class='yadcf-filter-reset-button submit-date-range' title='Note that Date Range search will clear all other filters'>Go</button>" +
      "          </div>" +
      "        </div>" +
      "      </span>" +
      "    </form>" +
      "    <div class='tableClickerDiv dropdown mr-2'>" +
      "      <button class='btn btn-outline-primary btn-sm dropdown-toggle' title='Month Quick Select' type='button' id='tableClickerMonthSelect' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'><i class='fas fa-file-export'></i> Month</button>" +
      "      <div class='dropdown-menu' aria-labelledby='tableClickerMonthSelect'>" +
      "        <a class='dropdown-item' id='month0122' title='01'>January 2022</a>" +
      "        <a class='dropdown-item' id='month0222' title='02'>February 2022</a>" +
      "        <a class='dropdown-item' id='month0322' title='03'>March 2022</a>" +
      "        <a class='dropdown-item' id='month0422' title='04'>April 2022</a>" +
      "        <a class='dropdown-item' id='month0522' title='05'>May 2022</a>" +
      "        <a class='dropdown-item' id='month0622' title='06'>June 2022</a>" +
      "        <a class='dropdown-item' id='month0722' title='07'>July 2022</a>" +
      "        <a class='dropdown-item' id='month0822' title='08'>August 2022</a>" +
      "        <a class='dropdown-item' id='month0922' title='09'>September 2022</a>" +
      "        <a class='dropdown-item' id='month1022' title='10'>October 2022</a>" +
      "        <a class='dropdown-item' id='month1122' title='11'>November 2022</a>" +
      "        <a class='dropdown-item' id='month1222' title='12'>December 2022</a>" +
      "      </div>" +
      "    </div>" +
      "  </li>" +
      "</ul>" +
      "";
    }

    table.page.len(150).draw();

    $("div.dataTableToolbar").html(dataTableToolbar());

    // clears the global Table Search column on right-click
    $(document).on('mousedown', '.tableSearch', function() {
      if (event.which === 3) {
        $(this).val('');
        table.search("").draw();
      }
    });

    var search = $.fn.dataTable.util.throttle(
      function (val) {
        table.search(val).draw();
      }, 500);
     
    $('.tableSearch').on('keyup', function () {
      search( this.value );
    });
  
    $(".tableLength").on("change", function () {
      table.page.len($(this).find("option:selected").attr("value")).draw();
    });
  
    function daterange_shortcut(e, start, end) {
      $(document).on('click', e, function() {
        $(this).closest('.filter-section').find('#startdate').val(start);
        $(this).closest('.filter-section').find('#enddate').val(end);
        $(".submit-date-range").click();
      });
    }
    daterange_shortcut('#month0122', '2022-01-01', '2022-01-31');
    daterange_shortcut('#month0222', '2022-02-01', '2022-02-28');
    daterange_shortcut('#month0322', '2022-03-01', '2022-03-31');
    daterange_shortcut('#month0422', '2022-04-01', '2022-04-30');
    daterange_shortcut('#month0522', '2022-05-01', '2022-05-31');
    daterange_shortcut('#month0622', '2022-06-01', '2022-06-30');
    daterange_shortcut('#month0722', '2022-07-01', '2022-07-31');
    daterange_shortcut('#month0822', '2022-08-01', '2022-08-31');
    daterange_shortcut('#month0922', '2022-09-01', '2022-09-30');
    daterange_shortcut('#month1022', '2022-10-01', '2022-10-31');
    daterange_shortcut('#month1122', '2022-11-01', '2022-11-30');
    daterange_shortcut('#month1222', '2022-12-01', '2022-12-31');

    // $("#tableClickerQuickExportDiscounts").on("click", function() {
    //   $(".loading-overlay").show();
    //   table.page.len(500).draw();
    //   setTimeout(function() {$(".quick-export-discounts").click()}, 5000);
    //   setTimeout(function() {$(".loading-overlay").hide()}, 5000);
    // });

    $("#startdate").val($("#startdate_h").attr("value"));
    $("#enddate").val($("#enddate_h").attr("value"));
  
    yadcf.init(table,
      [
  
        { column_number : 5, // Class (populated with Retailer)
          filter_container_id : "retailer-select-dropdown",
          style_class : "form-control form-control-sm",
          filter_default_label : "Retailer...",
          data: [
            {value: "Home Depot", label : "Home Depot"},
            {value: "Wayfair", label : "Wayfair"}
          ]
        },
 
      ],
      { filters_position : "footer" },
      { autocomplete : false },
      { externally_triggered : true }
    );

    $(document).on("mousedown", "input.yadcf-filter-range-date", function() {
      if (event.which === 3) {
        $(this).val("");
      }
    });
  
  }

//----------------------------------------------------------------------------//
//---------- LINE ITEMS TRACKING INDEX RELATED -------------------------------//
//----------------------------------------------------------------------------//

  if ($('.tracking_index_div').length > 0) {
  
    $(".loading-overlay").show();

    var MyDate = new Date();
    var date;
    MyDate.setDate(MyDate.getDate());
    date = MyDate.getFullYear() + "-" + ("0" + (MyDate.getMonth() + 1)).slice(-2) + "-" + ("0" + MyDate.getDate()).slice(-2);
    var filename = "SRS Orders, " + date + " Export";
    var table = $("#trackingNumbersDataTable").DataTable({
      "retrieve": true,
      "processing": true,
      "serverSide": true,
      "initComplete": function( settings, json ) {
        $('.loading-overlay').hide();
      },
      // "stateSave": true,
      // "stateDuration": "-1",
      "ajax": {
        "url": $("#trackingNumbersDataTable").data("source"),
        // // "error": function (reason) {
        // //   if (reason.status == 401) { // Not Authorized
        // //     window.location = '#/logout';
        // //   }
        // }
  
  
      },
      "columns": [ // global search is disabled due to bugged columns below
  /*0 */{data: "id", searchable: false, visible: false},
  /*1 */{data: "order_date"},
  /*2 */{data: "order_type"},
  /*3 */{data: "order_status"}, // bugged global search
  /*4 */{data: "ship_date"},
  /*5 */{data: "supplier"},
  /*6 */{data: "retailer"},
  /*7 */{data: "customer"},
  /*8 */{data: "po_number"},
  /*9 */{data: "line_item"},
  /*10*/{data: "quantity"},
  /*11*/{data: "carrier"},
  /*12*/{data: "tracking_number"},
  /*13*/{data: "ship_cost"},
  /*14*/{data: 'invoiced'},
  /*15*/{data: "surcharges"},
  /*16*/{data: "actions", orderable: false},
      ], // Order Date, Supplier, Retailer, Order Status, Order ID
      "order": [[4,"desc"], [6, "asc"], [7, "asc"], [13, "desc"], [0, "asc"]],
  
      "oLanguage": { "sZeroRecords": "No records to display for this filter" },
      "dom": "<'row'<'col-sm-10 dataTableToolbar'><'col-sm-2'B>>t<'row dataTableBottomRow'<'col-md-2'i><'col-md-10'p>>",
      "buttons": [
        {
          extend: "excelHtml5",
          title: filename,
          // Only exports columns that are currently visible.  Adjusted by the "Visibility" dropdown and Filtered text.
          exportOptions: {columns: ":visible"},
          className: "dtButtonHide",
        },
        {
          extend: "csv",
          title: filename,
          exportOptions: {columns: ":visible"},
          className: "dtButtonHide"
        },
        {
          extend: "pdfHtml5",
          title: filename,
          orientation: "landscape",
          exportOptions: {columns: ":visible"},
          className: "dtButtonHide"
        },
        {
          extend: "copyHtml5",
          title: filename,
          exportOptions: {columns: ":visible"},
          className: "dtButtonHide"
        }, 
        {
          extend: "print",
          autoPrint: false,
          title: filename,
          exportOptions: {columns: ":visible"},
          customize: function (win) {
              $(win.document.body).find('table').addClass('display').css('font-size', '12px');
              $(win.document.body).find('tr:nth-child(odd) td').each(function(index){
                  $(this).css('background-color','#D0D0D0');
              });
              $(win.document.body).find('h1').css('text-align','center');
          },
          className: "dtButtonHide"
        }
      ]
    });
  
    function dataTableToolbar() {
      return "" +
      "<ul class='nav' style='margin-bottom:10px'>" +
      "  <li>" +
      "    <span class='options-label'>Options:</span>" +
      "  </li>" +
      "  <li>" +
      "    <div class='dataTables_length' id='trackingNumbersDataTable_length'>" +
      "      <select name='trackingNumbersDataTable_length' aria-controls='trackingNumbersDataTable' class='form-control form-control-sm tableLength' title='Number of records to show'>" +
      "        <option value='5'>5</option>" +
      "        <option value='10'>10</option>" +
      "        <option value='25'>25</option>" +
      "        <option selected='selected' value='50'>50</option>" +
      "        <option value='100'>100</option>" +
      "        <option value='250'>250</option>" +
      "        <option value='1000'>1000</option>" +
      "      </select>" +
      "    </div>" +
      "  </li>" +
      "  <li>" +
      "    <div class='tableClickerDiv dropdown'>" +
      "      <button class='btn btn-outline-primary btn-sm dropdown-toggle' title='Column visibility options' type='button' id='tableClickerColvis' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'><i class='fas fa-columns'></i> Columns</button>" +
      "      <div class='dropdown-menu card-shadow' aria-labelledby='tableClickerColvis'>" +
      "        <a class='dropdown-item button-highlight' id='colvisClicker-0'>Record ID</a>" +
      "        <a class='dropdown-item' id='colvisClicker-1'>Order Date</a>" +
      "        <a class='dropdown-item' id='colvisClicker-2'>Order Type</a>" +
      "        <a class='dropdown-item' id='colvisClicker-3'>Order Status</a>" +
      "        <a class='dropdown-item' id='colvisClicker-4'>Ship Date</a>" +
      "        <a class='dropdown-item' id='colvisClicker-5'>Supplier</a>" +
      "        <a class='dropdown-item' id='colvisClicker-6'>Retailer</a>" +
      "        <a class='dropdown-item' id='colvisClicker-7'>Customer</a>" +
      "        <a class='dropdown-item' id='colvisClicker-8'>PO Number</a>" +
      "        <a class='dropdown-item' id='colvisClicker-9'>SKU</a>" +
      "        <a class='dropdown-item' id='colvisClicker-10'>Quantity</a>" +
      "        <a class='dropdown-item' id='colvisClicker-11'>Carrier</a>" +
      "        <a class='dropdown-item' id='colvisClicker-12'>Tracking Number</a>" +
      "        <a class='dropdown-item' id='colvisClicker-13'>Shipping Cost</a>" +
      "        <a class='dropdown-item' id='colvisClicker-14'>Invoiced?</a>" +
      "        <a class='dropdown-item' id='colvisClicker-15'>Surcharges Added?</a>" +
      "        <a class='dropdown-item' id='colvisClicker-16'>Actions</a>" +
      "      </div>" +
      "    </div>" +
      "  </li>" +
      "  <li>" +
      "    <div class='tableClickerDiv dropdown'>" +
      "      <button class='btn btn-outline-primary btn-sm dropdown-toggle' title='Export Options' type='button' id='tableClickerExports' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'><i class='fas fa-file-export'></i> Export</button>" +
      "      <div class='dropdown-menu' aria-labelledby='tableClickerExports'>" +
      "        <div class='text-primary' style='font-size:11px;padding:0.25rem 1.5rem;'>" +
      "          <i class='fas fa-exclamation-triangle text-danger'></i> Only the currently visible columns & rows will be exported" +
      "        </div>" +
      "        <a class='dropdown-item tableClickerExcel'><i class='fas fa-file-excel file-icon-color'></i> Excel <i class='fas fa-download text-primary'></i></a>" +
      "        <a class='dropdown-item tableClickerCsv'><i class='fas fa-file-csv file-icon-color'></i> CSV <i class='fas fa-download text-primary'></i></a>" +
      "        <a class='dropdown-item tableClickerPdf'><i class='fas fa-file-pdf file-icon-color'></i> PDF <i class='fas fa-download text-primary'></i></a>" +
      "        <a class='dropdown-item tableClickerCopy'><i class='fas fa-copy file-icon-color'></i> Copy</a>" +
      "        <a class='dropdown-item tableClickerPrint'><i class='fas fa-print file-icon-color'></i> Print/Preview</a>" +
      "      </div>" +
      "    </div>" +
      "  </li>" +
      "  <li class='filter-section' style='width:100%'>" +
      "    <span class='filter-label'>Filters:</span>" +
      "    <div id='trackingNumbersDataTable_filter' class='dataTables_filter mr-2'>" +
      "      <input id='trackingNumbersTableSearch' class='form-control form-control-sm tableSearch' title='right-click to clear' oncontextmenu='return false' aria-controls='trackingNumbersDataTable' placeholder='Search Entire Table...'>" +
      "    </div>" +
      "    <form action='/line_items/tracking_index' accept-charset='UTF-8' method='get'>" +
      "      <span class='filter-span-range colvisSearch-2' title='Order Date Range Search (leaving start or end date blank will assume infinity)'>" +
      "        <div>" +
      "          <div class='yadcf-filter-wrapper'>" +
      "            <div class='yadcf-filter-wrapper-inner'>" +
      "              <input type='date' name='startdate' id='startdate' placeholder='From' class='yadcf-filter-range-date yadcf-filter-range yadcf-filter-range-start hasDatepicker' oncontextmenu='return false' style='width:150px;'>" +
      "              <input type='date' name='enddate' id='enddate' placeholder='To' class='yadcf-filter-range-date yadcf-filter-range yadcf-filter-range-end hasDatepicker' oncontextmenu='return false' style='width:150px;margin-left:-3px;'>" +
      "            </div>" +
      "            <button type='submit' name='commit' class='yadcf-filter-reset-button' title='Note that Date Range search will clear all other filters'>Go</button>" +
      "          </div>" +
      "        </div>" +
      "      </span>" +
      "    </form>" +
      "    <span id='order-type-dropdown' class='filter-span-select colvisSearch-2'></span>" +
      "    <span id='status-dropdown' class='filter-span-select colvisSearch-3'></span>" +
      "    <span id='supplier-select-dropdown' class='filter-span-select colvisSearch-5'></span>" +
      "    <span id='retailer-select-dropdown' class='filter-span-select colvisSearch-6'></span>" +
      "  </li>" +
      "  <li class='filter-section' style='width:100%;margin-top:.5rem;'>" +
      "    <span class='filter-label'>Filters:</span>" +
      "    <span id='customer-select-input' class='filter-span-input colvisSearch-7'></span>" +
      "    <span id='po-select-input' class='filter-span-input colvisSearch-8'></span>" +
      "    <span id='line-item-select-input' class='filter-span-input colvisSearch-9'></span>" +
      "    <span id='carrier-select-dropdown' class='filter-span-select colvisSearch-11'></span>" +
      "    <span id='tracking-select-input' class='filter-span-input colvisSearch-12'></span>" +
      "    <span id='invoiced-dropdown' class='filter-span-select colvisSearch-14'></span>" +
      "    <span id='surcharges-dropdown' class='filter-span-select colvisSearch-15'></span>" +
      "    <span id='ship-cost-range' class='filter-span-select colvisSearch-13'></span>" +
      "  </li>" +
      "</ul>" +
      "";
    }
  
  
    table.page.len(50).draw();
  
  
    $("div.dataTableToolbar").html(dataTableToolbar());

    var search = $.fn.dataTable.util.throttle(
      function (val) {
        table.search(val).draw();
      }, 500);
     
    $('.tableSearch').on('keyup', function () {
      search( this.value );
    });
  
    $(".tableLength").on("change", function () {
      table.page.len($(this).find("option:selected").attr("value")).draw();
    });
  
    function colVisButtons(index) {
      var button = $("#colvisClicker-"+index);
      button.on("click", function(e) {
        e.stopPropagation();
        if (table.column(index).visible() === true) {
          table.column(index).visible(false);
          button.addClass("button-highlight");
          $(".colvisSearch-"+index).hide();
        }
        else {
          table.column(index).visible(true);
          button.removeClass("button-highlight");
          $(".colvisSearch-"+index).show();
        }
      });
    }
    let colVisIndex = -1;
    do { colVisIndex += 1;
         colVisButtons(colVisIndex);
    } while (colVisIndex < 16);
  
    yadcf.init(table,
      [
  
        { column_number : 2, // Order Type
          filter_container_id : "order-type-dropdown",
          style_class : "form-control form-control-sm",
          filter_default_label : "Order Type...",
          data: [
            {value: "1", label : "E-Commerce Order"},
            {value: "2", label : "Warranty order"},
            {value: "3", label : "Store POP"},
            {value: "5", label : "Third Party Replenishment"},
            {value: "6", label : "Third Party Sale"},
            {value: "4", label : "Other"},
          ]
        },
  
        { column_number : 3, // Status
          filter_container_id : "status-dropdown",
          style_class : "form-control form-control-sm",
          filter_default_label : "Order Status...",
          data: [
            {value: "1", label : "Open"},
            {value: "2", label : "Shipped"},
            {value: "3", label : "On Hold"},
            {value: "4", label : "Backordered"},
            {value: "5", label : "Cancelled (Out of Stock)"},
            {value: "6", label : "Cancelled (Merchant Request)"},
            {value: "7", label : "Partial Return"},
            {value: "8", label : "Full Return"},
            {value: "9", label : "Other (See Notes)"},
          ]
        },
  
        { column_number : 5, // Supplier
          filter_container_id : "supplier-select-dropdown",
          filter_default_label : "Suppliers...",
          filter_match_mode : "exact",
          filter_type: 'multi_select',
          select_type: 'select2',
          data: $('#params_span').data('suppliers_dropdown')
        },

        { column_number : 6, // Retailer
          filter_container_id : "retailer-select-dropdown",
          filter_default_label : "Retailers...",
          filter_match_mode : "exact",
          filter_type: 'multi_select',
          select_type: 'select2',
          data: $('#params_span').data('retailers_dropdown')
        },

        { column_number : 7, // Customer
          filter_type: "text",
          filter_container_id : "customer-select-input",
          style_class : "form-control form-control-sm",
          filter_default_label : "Customer..."
        },
  
        { column_number : 8, // PO #
          filter_type: "text",
          filter_container_id : "po-select-input",
          style_class : "form-control form-control-sm",
          filter_default_label : "PO #..."
        },
  
        { column_number : 9, // Line Item
          filter_type: "text",
          filter_container_id : "line-item-select-input",
          style_class : "form-control form-control-sm",
          filter_default_label : "Line Item SKU..."
        },
  
        { column_number : 11, // Carrier
          filter_container_id : "carrier-select-dropdown",
          filter_default_label : "Carriers...",
          filter_match_mode : "exact",
          filter_type: 'multi_select',
          select_type: 'select2',
          data: $('#params_span').data('carriers_dropdown')
        },

        { column_number : 12, // Tracking Number
          filter_type: "text",
          filter_container_id : "tracking-select-input",
          style_class : "form-control form-control-sm",
          filter_default_label : "Tracking #..."
        },
  
        // { column_number : 13, // Shipping Cost
        //   filter_type: "range_number",
        //   // exclude: true,
        //   filter_container_id : "ship-cost-range",
        //   ignore_char: '\\.|\\$',
        //   // style_class : "form-control form-control-sm",
        //   filter_default_label : "Ship Cost..."
        // },

        { column_number : 14, // Invoiced?
          filter_container_id : "invoiced-dropdown",
          style_class : "form-control form-control-sm",
          filter_default_label : "Invoiced?...",
          data: [
            {value: "1", label : "N/A"},
            {value: "2", label : "No"},
            {value: "3", label : "Yes"},
          ]
        },
        
        { column_number : 15, // Surcharges Added?
          filter_container_id : "surcharges-dropdown",
          style_class : "form-control form-control-sm",
          filter_default_label : "Surcharges...",
          data: [
            {value: "true", label : "Yes"},
            {value: "false", label : "No"},
          ]
        }
  
      ],
      { filters_position : "footer" },
      { externally_triggered : true }
    );
  
    // This block executes any query string searches that may be present in the URL
    function filterTable(columnIndex, filterValue){
      yadcf.exFilterColumn(table, [
        [columnIndex, filterValue],
      ]);
    }
    const queryStringParams = window.location.search.substring(1).split('&');

    queryStringParams.forEach(param => {

      if (param.indexOf('retailer') !== -1) {
        filterTable(6, $('#params_span').data('retailer'));
      } else if (param.indexOf('supplier') !== -1) {
        filterTable(5, $('#params_span').data('supplier'));
      } else if (param.indexOf('carrier') !== -1) {
        filterTable(11, $('#params_span').data('carrier'));
      // } else if (param.indexOf('order_type') !== -1) {
      //   filterTable(3, $('#params_span').data('order_type'));
      // } else if (param.indexOf('order_status') !== -1) {
      //   filterTable(12, $('#params_span').data('order_status'));
      }

    });

    $("#startdate").val($("#startdate_h").attr("value"));
    $("#enddate").val($("#enddate_h").attr("value"));
  
    $(document).on("mousedown", "input.yadcf-filter-range-date", function() {
      if (event.which === 3) {
        $(this).val("");
      }
    });
  
  }

});