/*global $*/
/*global tippy*/
/*global Turbolinks*/
/*global globalObjects*/
/*global paginationDataTable*/
/*global clipboardData*/
/*global itemShowObjects*/

// DO NOT PLACE IMPORTS IN THIS FILE

$(document).on("turbolinks:load", function () {

//----------------------------------------------------------------------------//
//----------- ACTUALLY GLOBAL FUNCTIONS --------------------------------------//
//----------------------------------------------------------------------------//

  $('.dt-buttons').hide(); // hides DataTables stock buttons that are not used

  $('[data-toggle="tooltip"]').tooltip({
    trigger: "hover"
  }); // Forces availability of Bootstrap tooltips across all pages

  tippy('[data-toggle="tippy"]', {
    animation: 'perspective',
  });

  $(document).on('ajaxComplete', function() {
    tippy('[data-toggle="tippy"]', {
      animation: 'perspective',
    });
  });

  $('[rel="tooltip"]').on('click', function () {
      $(this).tooltip('hide');
  });
  
  $(document).on('click', '.ots-clicker', function() {
    $(this).closest('span').find('.on_the_spot_editing').click();
  });
  
  $('[data-toggle="popover"]').popover(); // Forces availability of popovers across all pages
  // $('input[type="number"]').forceNumeric(); // Use to accept only numbers in number_fields
  $("#alertReadNotifications").on("click", function() {
    $("#notificationDropdown").click();
  });


  $(".order-upload-archive").on("ajax:success", function() {
    var parent = $(this).closest(".order_uploads_div");
    $(".loading-overlay").show();
    parent.slideUp( "slow", function() { parent.remove() } );
  });

  $(".user-roles").selectize();

  // Click icon to copy adjacent text, such as Tracking Number
  $(document).on('click', '.copy-cut', function() {
    var tracking = $(this).closest('.copy-body').find('.copy-text').text();
    copyToClipboard(tracking);
    $(this).addClass('highlight-selected');
  });

  // in all SHOW views, this adds a URL paramater for the currently
  // active tab, then that tab will automatically be open on reload
  // BUG: If other params are active and a new tab is selected, those other
  //      params will be removed
  $(document).on('click', '.nav-link.secondary', function() {
    var arr = window.location.href.split('?active_tab=');
    if (arr.length > 1 && arr[1] !== '') {
      window.history.pushState({}, '', window.location.pathname + '?active_tab=' + $(this).attr('aria-controls'));
    } else {
      window.history.pushState({}, '', window.location.href + '?active_tab=' + $(this).attr('aria-controls'));
    }
  });
  $(document).on('click', '.nav-link.initial', function() {
    window.history.pushState({}, '', window.location.pathname);
  });

  function copyToClipboard(text) {
    if (window.clipboardData && window.clipboardData.setData) {
      // IE specific code path to prevent textarea being shown while dialog is visible.
      return clipboardData.setData('Text', text); 
    } else if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
      var textarea = document.createElement('textarea');
      textarea.textContent = text;
      textarea.style.position = 'fixed';  // Prevent scrolling to bottom of page in MS Edge.
      document.body.appendChild(textarea);
      textarea.select();
      try {
        return document.execCommand('copy');  // Security exception may be thrown by some browsers.
      } catch (ex) {
        console.warn('Copy to clipboard failed.', ex);
        return false;
      } finally {
        document.body.removeChild(textarea);
      }
    }
  }

  $.fn.forceNumeric = function () {
    return this.each(function () {
      $(this).keydown(function (e) {
        var key = e.which || e.keyCode;
        if (
          !e.shiftKey && !e.altKey && !e.ctrlKey && key >= 48 && key <= 57 || // numbers
          key >= 96 && key <= 105 || // Numeric keypad
          key == 190 || key == 188 || key == 189 || key == 109 || key == 110 || // comma, period and minus, . on keypad
          key == 8 || key == 9 || key == 13 || // Backspace and Tab and Enter
          key == 35 || key == 36 || // Home and End
          key == 37 || key == 39 || // left and right arrows
          key == 38 || key == 40 || // up and down arrows
          key == 46 || key == 45 // Del and Ins
        )
        return true;
        return false;
      });
    });
  };
  
  (function($) {
    $.fn.currencyInput = function() {
      this.each(function() {
        var wrapper = $("<div class='currency-input' />");
        $(this).wrap(wrapper);
        $(this).before("<span class='currency-symbol'>$</span>");
        $(this).change(function() {
          var min = parseFloat($(this).attr("min"));
          var max = parseFloat($(this).attr("max"));
          var value = this.valueAsNumber;
          if(value < min)
            value = min;
          else if(value > max)
            value = max;
          $(this).val(value.toFixed(2)); 
        });
      });
    };
  });

//----------------------------------------------------------------------------//
//----------- SRS_DOT_COM RELATED --------------------------------------------//
//----------------------------------------------------------------------------//

  // $(document).scrollspy({ target: '#srs_dot_com_nav' })

  $(document).on('click', '.show_item_registration_modal', function() {
    $('#item_registration_modal').modal();
  });

  // Network Call Avoidance Workaround (for Turbolinks) attempting to short-circuit network calls
  $(document).on('turbolinks:click', function (e) {
    if (e.target.getAttribute('href').charAt(0) === '#') {return event.preventDefault()}
  });

//----------------------------------------------------------------------------//
//----------- MAIN DASHBOARD FUNCTIONS ---------------------------------------//
//----------------------------------------------------------------------------//

  paginationDataTable.table('dashboardUnshippedLTLOrders', 6);
  paginationDataTable.table('dashboardInboundShipments', 10);
  paginationDataTable.table('dashboardRecentOrderBatches', 6);
  paginationDataTable.table('dashboardUninvoicedOrders', 10);

  $(document).on('keydown', '#revenue_period', function() {
    $('.update_revenue_period').show();
  });

  $(document).on('mouseover', '.highlightable, .select2-container', function() {
    var line = $(this).closest('.highlightable-container');
    line.find('.highlightable').css('background-color', '#d7e6ff');
    line.find('.highlightable[readonly]').css('background-color', '#dae7fd');
    line.find('.select2-selection').css('background-color', '#d7e6ff');
    line.find('.selectize-input').css('background-color', '#d7e6ff');
  });

  $(document).on('mouseleave', '.highlightable, .select2-container', function() {
    var line = $(this).closest('.highlightable-container');
    line.find('.highlightable').css('background-color', '');
    line.find('.select2-selection').css('background-color', '');
    line.find('.selectize-input').css('background-color', '');
  });

  window.addEventListener('trix-file-accept', function (e) {
    // Only image uploads are allowed within the Trix editor
    const acceptedTypes = ['image/jpeg', 'image/png', 'image/bmp', 'image/gif'];
    if (!acceptedTypes.includes(e.file.type)) {
      e.preventDefault();
      alert('Only images attachments are supported within the text editor.');
    }
  });
  
  
  // monkeypatch to fix issue of search field not being selected on open.  https://github.com/select2/select2/issues/2311#issuecomment-822473913
  $(document).on('select2:open', e => {
    const id = e.target.id;
    const target = document.querySelector(`[aria-controls=select2-${id}-results]`);
    target.focus();
  });
  
  // disables pressing of 'Enter' key only on this form
  $(document).on('keydown', 'form input:not([type="submit"])', e => {
    if (e.keyCode === 13) {
      e.preventDefault();
      return false;
    }
    return true;
  });

});

//----------------------------------------------------------------------------//
//----------- DATATABLES GLOBAL STUFF ----------------------------------------//
//----------------------------------------------------------------------------//

globalObjects = {

  // Generic DataTable used in all Show views
  genericDataTable: function (class_name) {
    var Filename = class_name + ' Export';
    var table = $('#' + class_name + 'Table').DataTable({
      'retrieve': true,
      'processing': true,
      'order': [],
      'oLanguage': { 'sZeroRecords': 'No records to display for this filter' },
      'dom': '<"row m-0"<"col-sm-10 dataTableToolbar ' + class_name + '"><"col-sm-2"B>>t<"row m-0 dataTableBottomRow"<"col-md-2 ml-3"i><"col-md-9"p>>',
      'buttons': [
        {
          extend: 'excelHtml5',
          title: Filename,
          // Only exports columns that are currently visible.  Adjusted by the 'Visibility' dropdown and Filtered text.
          exportOptions: {columns: ':visible'},
          className: 'dtButtonHide'
        },
        {
          extend: 'csv',
          title: Filename,
          exportOptions: {columns: ':visible'},
          className: 'dtButtonHide'
        },
        {
          extend: 'pdfHtml5',
          title: Filename,
          orientation: 'landscape',
          exportOptions: {columns: ':visible'},
          className: 'dtButtonHide'
        },
        {
          extend: 'copyHtml5',
          title: Filename,
          exportOptions: {columns: ':visible'},
          className: 'dtButtonHide'
        }, 
        {
          extend: 'print',
          autoPrint: false,
          title: Filename,
          exportOptions: {columns: ':visible'},
          customize: function (win) {
              $(win.document.body).find('table').addClass('display').css('font-size', '12px');
              $(win.document.body).find('tr:nth-child(odd) td').each(function(index){
                  $(this).css('background-color','#D0D0D0');
              });
              $(win.document.body).find('h1').css('text-align','center');
          },
          className: 'dtButtonHide'
        }
      ]
    });
  
    function DataTableToolbar () {
      return '' +
      '<ul class="nav ml-2 mb-3">' +
      '  <li>' +
      '    <div class="dataTables_length" id="' + class_name + 'Table_length">' +
      '      <select name="' + class_name + 'Table_length" aria-controls="' + class_name + 'Table" class="form-control form-control-sm tableLength ' + class_name + '" title="Number of records to show">' +
      '        <option value="5">5</option>' +
      '        <option value="10">10</option>' +
      '        <option value="25">25</option>' +
      '        <option selected="selected" value="50">50</option>' +
      '        <option value="100">100</option>' +
      '        <option value="250">250</option>' +
      '        <option value="-1">All</option>' +
      '      </select>' +
      '    </div>' +
      '  </li>' +
      '  <li>' +
      '    <div class="tableClickerDiv dropdown">' +
      '      <button class="btn btn-outline-primary btn-sm dropdown-toggle" title="Export Options" type="button" id="tableClickerExports" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="fas fa-file-export"></i> Export</button>' +
      '      <div class="dropdown-menu" aria-labelledby="tableClickerExports">' +
      '        <div class="text-primary" style="font-size:11px;padding:0.25rem 1.5rem;">' +
      '          <i class="fas fa-exclamation-triangle text-danger"></i> Only the currently visible columns & rows will be exported' +
      '        </div>' +
      '        <a class="dropdown-item tableClickerExcel"><i class="fas fa-file-excel file-icon-color"></i> Excel <i class="fas fa-download text-primary"></i></a>' +
      '        <a class="dropdown-item tableClickerCsv"><i class="fas fa-file-csv file-icon-color"></i> CSV <i class="fas fa-download text-primary"></i></a>' +
      '        <a class="dropdown-item tableClickerPdf"><i class="fas fa-file-pdf file-icon-color"></i> PDF <i class="fas fa-download text-primary"></i></a>' +
      '        <a class="dropdown-item tableClickerCopy"><i class="fas fa-copy file-icon-color"></i> Copy</a>' +
      '        <a class="dropdown-item tableClickerPrint"><i class="fas fa-print file-icon-color"></i> Print/Preview</a>' +
      '      </div>' +
      '    </div>' +
      '  </li>' +
      '  <li class="filter-section ml-3">' +
      '    <div id="' + class_name + 'Table_filter" class="dataTables_filter mr-2">' +
      '      <input id="' + class_name + 'Table_tableSearch" class="form-control form-control-sm tableSearch ' + class_name + '" title="right-click to clear" oncontextmenu="return false" aria-controls="' + class_name + 'Table" placeholder="Search Entire Table...">' +
      '    </div>' +
      '  </li>' +
      '</ul>' +
      '';
    }
  
    table.page.len(50).draw();
  
    $('div.dataTableToolbar.' + class_name).html(DataTableToolbar());
  
    $('.tableLength.' + class_name).on('change', function () {
      table.page.len($(this).find('option:selected').attr('value')).draw();
    });
  
    $('.tableSearch.' + class_name).on('keyup', function () {
        table.search($(this).val()).draw();
    });
  
    // clears the global Table Search column on right-click
    $(document).on('mousedown', '.tableSearch', function() {
      if (event.which === 3) {
        $(this).val('');
        table.search('').draw();
      }
    });

  }

};

paginationDataTable = {

  // Generic DataTable used in views that require inline pagination
  table: function (class_name, page_length) {
    var table = $('#' + class_name + 'Table').DataTable({
      'retrieve': true,
      'processing': true,
      'order': [],
      'oLanguage': { 'sZeroRecords': 'No records to display for this filter' },
      'dom': '<"row m-0"<"col-sm-10"><"col-sm-2">>t<"row m-0 dataTableBottomRow"<"col-md-2 ml-3"i><"col-md-9"p>>',
    });
  
    table.page.len(page_length).draw();

  }

};

itemShowObjects = {

  // Generic DataTable used in all Show views
  dataTable: function (class_name, table_length) {
    var Filename = class_name + ' Export';
    var table = $('.table_container.' + class_name).find('table').DataTable({
      'retrieve': true,
      'processing': true,
      'order': [],
      'oLanguage': { 'sZeroRecords': 'No records to display for this filter' },
      'dom': '<"row m-0"<"col-sm-10 dataTableToolbar ' + class_name + '"><"col-sm-2"B>>t<"row m-0 dataTableBottomRow"<"col-md-2 ml-3"i><"col-md-9"p>>',
      'buttons': [
        {
          extend: 'excelHtml5',
          title: Filename,
          // Only exports columns that are currently visible.  Adjusted by the 'Visibility' dropdown and Filtered text.
          exportOptions: {columns: ':visible'},
          className: 'dtButtonHide'
        },
        {
          extend: 'csv',
          title: Filename,
          exportOptions: {columns: ':visible'},
          className: 'dtButtonHide'
        },
        {
          extend: 'pdfHtml5',
          title: Filename,
          orientation: 'landscape',
          exportOptions: {columns: ':visible'},
          className: 'dtButtonHide'
        },
        {
          extend: 'copyHtml5',
          title: Filename,
          exportOptions: {columns: ':visible'},
          className: 'dtButtonHide'
        }, 
        {
          extend: 'print',
          autoPrint: false,
          title: Filename,
          exportOptions: {columns: ':visible'},
          customize: function (win) {
              $(win.document.body).find('table').addClass('display').css('font-size', '12px');
              $(win.document.body).find('tr:nth-child(odd) td').each(function(index){
                  $(this).css('background-color','#D0D0D0');
              });
              $(win.document.body).find('h1').css('text-align','center');
          },
          className: 'dtButtonHide'
        }
      ]
    });
  
    function DataTableToolbar () {
      return '' +
      '<ul class="nav ml-4 mb-3">' +
      '  <li>' +
      '    <div class="dataTables_length" id="' + class_name + 'Table_length">' +
      '      <select name="' + class_name + 'Table_length" aria-controls="' + class_name + 'Table" class="form-control form-control-sm tableLength ' + class_name + '" title="Number of records to show">' +
               // Default page length is 5 if the option isn't specified in the global table call function
      '        <option ' + (table_length == 5 ? 'selected="selected" ' : '') + 'value="5">5</option>' +
      '        <option ' + (table_length == 10 ? 'selected="selected" ' : '') + 'value="10">10</option>' +
      '        <option ' + (table_length == 25 ? 'selected="selected" ' : '') + 'value="25">25</option>' +
      '        <option ' + (table_length == 50 ? 'selected="selected" ' : '') + 'value="50">50</option>' +
      '        <option ' + (table_length == 100 ? 'selected="selected" ' : '') + 'value="100">100</option>' +
      '        <option ' + (table_length == 250 ? 'selected="selected" ' : '') + 'value="250">250</option>' +
      '        <option ' + (table_length == -1 ? 'selected="selected" ' : '') + 'value="-1">All</option>' +
      '      </select>' +
      '    </div>' +
      '  </li>' +
      '  <li>' +
      '    <div class="tableClickerDiv dropdown">' +
      '      <button class="btn btn-outline-primary btn-sm dropdown-toggle" title="Export Options" type="button" id="tableClickerExports" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="fas fa-file-export"></i> Export</button>' +
      '      <div class="dropdown-menu" aria-labelledby="tableClickerExports">' +
      '        <div class="text-primary" style="font-size:11px;padding:0.25rem 1.5rem;">' +
      '          <i class="fas fa-exclamation-triangle text-danger"></i> Only the currently visible columns & rows will be exported' +
      '        </div>' +
      '        <a class="dropdown-item tableClickerExcel"><i class="fas fa-file-excel file-icon-color"></i> Excel <i class="fas fa-download text-primary"></i></a>' +
      '        <a class="dropdown-item tableClickerCsv"><i class="fas fa-file-csv file-icon-color"></i> CSV <i class="fas fa-download text-primary"></i></a>' +
      '        <a class="dropdown-item tableClickerPdf"><i class="fas fa-file-pdf file-icon-color"></i> PDF <i class="fas fa-download text-primary"></i></a>' +
      '        <a class="dropdown-item tableClickerCopy"><i class="fas fa-copy file-icon-color"></i> Copy</a>' +
      '        <a class="dropdown-item tableClickerPrint"><i class="fas fa-print file-icon-color"></i> Print/Preview</a>' +
      '      </div>' +
      '    </div>' +
      '  </li>' +
      '  <li class="filter-section ml-3">' +
      '    <div id="' + class_name + 'Table_filter" class="dataTables_filter mr-2">' +
      '      <input id="' + class_name + 'Table_tableSearch" class="form-control form-control-sm tableSearch ' + class_name + '" title="right-click to clear" oncontextmenu="return false" aria-controls="' + class_name + 'Table" placeholder="Search Entire Table...">' +
      '    </div>' +
      '  </li>' +
      '</ul>' +
      '';
    }
  
    table.page.len(table_length || 5).draw();
  
    $('div.dataTableToolbar.' + class_name).html(DataTableToolbar());
  
    $('.tableLength.' + class_name).on('change', function () {
      table.page.len($(this).find('option:selected').attr('value')).draw();
    });
  
    $('.tableSearch.' + class_name).on('keyup', function () {
        table.search($(this).val()).draw();
    });

    // clears the global Table Search column on right-click
    $(document).on('mousedown', '.tableSearch', function() {
      if (event.which === 3) {
        $(this).val('');
        table.search('').draw();
      }
    });

  }

};

$(document).on('click', '.tableClickerExcel', function() {
  $(this).closest('.row').find('.buttons-excel').click();
});
$(document).on('click', '.tableClickerCsv', function() {
  $(this).closest('.row').find('.buttons-csv').click();
});
$(document).on('click', '.tableClickerPdf', function() {
  $(this).closest('.row').find('.buttons-pdf').click();
});
$(document).on('click', '.tableClickerCopy', function() {
  $(this).closest('.row').find('.buttons-copy').click();
});
$(document).on('click', '.tableClickerPrint', function() {
  $(this).closest('.row').find('.buttons-print').click();
});

//----------------------------------------------------------------------------//
//----------- DIRECT UPLOADS RELATED -----------------------------------------//
//----------------------------------------------------------------------------//

addEventListener("direct-upload:initialize", event => {
  const { target, detail } = event;
  const { id, file } = detail;
  target.insertAdjacentHTML("beforebegin", `
    <div id="direct-upload-${id}" class="direct-upload direct-upload--pending">
      <div id="direct-upload-progress-${id}" class="direct-upload__progress" style="width: 0%"></div>
      <span class="direct-upload__filename">${file.name}</span>
    </div>
  `);
});

addEventListener("direct-upload:start", event => {
  const { id } = event.detail;
  const element = document.getElementById(`direct-upload-${id}`);
  element.classList.remove("direct-upload--pending");
});

addEventListener("direct-upload:progress", event => {
  const { id, progress } = event.detail;
  const progressElement = document.getElementById(`direct-upload-progress-${id}`);
  progressElement.style.width = `${progress}%`;
});

addEventListener("direct-upload:error", event => {
  event.preventDefault();
  const { id, error } = event.detail;
  const element = document.getElementById(`direct-upload-${id}`);
  element.classList.add("direct-upload--error");
  element.setAttribute("title", error);
});

addEventListener("direct-upload:end", event => {
  const { id } = event.detail;
  const element = document.getElementById(`direct-upload-${id}`);
  element.classList.add("direct-upload--complete");
});
