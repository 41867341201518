/*global $*/
/*global bootbox*/
/*global yadcf*/
/*global toastr*/

import 'stylesheets/ia_batches-global.scss';

  
$(document).on('turbolinks:load', function() {

//----------------------------------------------------------------------------//
//---------- IA BATCHES FORM RELATED -----------------------------------------//
//----------------------------------------------------------------------------//

  if ($('.ia_batch-validate').length > 0) {

    $('label.required').append('<strong> *</strong>'); // adds a 'required' star next to each Inputs label
    $('label.required-s span').append('<strong> *</strong>'); // adds a 'required' star next to each Selects label
    $('#adjust-type-default-select option:first-child').attr('disabled', true);
  
    $(document).on('click', '.ots-clicker', function() {
      $(this).closest('span').find('.on_the_spot_editing').click();
    });
  
    $('.add_notes_button').on('click', function() {
      $(this).slideUp('fast');
      $('.batch-notes-div').slideDown('slow');
    });
  
    $('.add_adjustment_button').on('click', function() {
      $('.add_child_ia').click();
      if ( $('.fields_container').find('.offscreen_check:last').is(':off-screen') ) {
        toastr.options = {
          'closeButton': false,
          'debug': false,
          'newestOnTop': false,
          'progressBar': true,
          'positionClass': 'toast-bottom-center',
          'preventDuplicates': false,
          'onclick': null,
          'showDuration': '5',
          'hideDuration': '5',
          'timeOut': '5',
          'extendedTimeOut': '5',
          'showEasing': 'linear',
          'hideEasing': 'swing',
          'showMethod': 'fadeIn',
          'hideMethod': 'slideUp'
        };
        toastr.info('Adjustment Added Below');
      }
    });
    $('.add_10_adjustments_button').on('click', function() {
      for (var i = 0; i < 10; i++){
        $('.add_child_ia').click();
        if ( $('.fields_container').find('.offscreen_check:last').is(':off-screen') ) {
          toastr.options = {
            'closeButton': false,
            'debug': false,
            'newestOnTop': false,
            'progressBar': true,
            'positionClass': 'toast-bottom-center',
            'preventDuplicates': true,
            'onclick': null,
            'showDuration': '5',
            'hideDuration': '5',
            'timeOut': '5',
            'extendedTimeOut': '5',
            'showEasing': 'linear',
            'hideEasing': 'swing',
            'showMethod': 'fadeIn',
            'hideMethod': 'slideUp'
          };
          toastr.info('Adjustments Added Below');
        }
      }
    });
  
    $(document).on('change', '.productSearch', function() {
      $(this).next().removeClass('myErrorClass');
      var tr = $(this).closest('tr');
      tr.find('.new-quantity').attr('readonly', false); // Enables the New Quantity fields for this row
      tr.find('.adjust-quantity').attr('readonly', false).val(-1); // Enables the Adjust Quantity fields for this row
      tr.find('.current-quantity').val($(this).find('option:selected').data('quantity')); // imports the quantity from the 'data-quantity' attribute of the selected option
      var current_quantity = tr.find('.current-quantity').val();
      var new_quantity = tr.find('.new-quantity').val();
      tr.find('.new-quantity').val(new_quantity = current_quantity - 1); // Math function when adjusting quantity fields
      var arr = [];
      $('.productSearch').each(function(){
        $(this).next().removeClass('myErrorClass');
        var value = $(this).find('option:selected').val();
        if (arr.indexOf(value) == -1) {
          $(this).next().removeClass('myErrorClass');
          arr.push(value);
        } else {
          if ($(this).next().text() == 'Select Item...') {
            $(this).next().removeClass('myErrorClass');
          } else {
            $(this).next().addClass('myErrorClass');
          }
        }
      });
  
    });
  
  
    // Child Fields Template Loader inherited from inventory_adjustment_batches_helper.rb for Line Items
    $(document).on('click', 'a.add_child_ia', function() {
      var association, new_id, regexp, template, fields;
      association = $(this).attr('data-association');
      template = $('#' + association + '_fields_template').html();
      regexp = new RegExp('new_' + association, 'g');
      new_id = (new Date).getTime();
      $(this).parent().before(template.replace(regexp, new_id));
      // The 'jstemplates' hidden field needs the '.ignore' class to ensure it
      // doesn't get picked up by Jquery Validator.  This line removes it.
      fields = $(this).parent().prev('.fields');
      fields.find('select').removeClass('ignore');
      fields.find('.productSearch').select2({
        selectOnClose: true,
        allowClear: true,
        placeholder: 'Select Item...',
        ajax: {
          url: '/json_dropdown_products_inv.json',
          dataType: 'json',
          type: 'GET',
          processResults: function (data, params) {
            params.page = data.page || 1;
            return {
              results: $.map(data.items, function (item) {
                return {
                  text: item.sku + ' | ' + item.desc,
                  id: item.id,
                  quantity_on_hand: item.qty
                };
              }),
              pagination: {
                // more: data.current_page
              }
            };
          },
          cache: true
        },
        templateSelection: function(container) { // adds the current quantity on hand to the items 'data-quantity' attribute
            $(container.element).attr('data-quantity', container.quantity_on_hand);
            return container.text;
        }
      });
      $('.scroll').bind('wheel', function() {});
  
      return false;
    });
  

    $(document).on('click', 'a.remove_child_ia', function() { // Triggers the removal of the relevant 'Item Select' table row when clicked
      var tr = $(this).closest('tr');
      var hidden_field = $(this).prev('input[type=hidden]')[0];
      tr.find('.productSearch').val([]).trigger('change');
      if(hidden_field) {
        hidden_field.value = '1';
      }
      $(this).parents('.fields').remove();
      return false;
    });
  
    $('.ia_batch-validate').validate({
      ignore: '.ignore',
  		rules: {
  		// 	'supplier[brand_name]': {required: true, maxlength: 80},
  		},
  		messages: {
  		// 	'supplier[brand_name]': 'a Brand Name is required',
  		},
      onfocusout: function(element) {$(element).valid()},
      focusCleanup: true,
      errorClass: 'myErrorClass',
      errorPlacement: function (error, element) {
        error.insertAfter(element.parent());
      },
      highlight: function (element, errorClass, validClass) {
        var elem = $(element);
        if (elem.hasClass('select2-hidden-accessible')) {
          elem.parent().find('.select2-selection').addClass(errorClass);
        } 
        else {
          elem.addClass(errorClass);
        }
      },    
      unhighlight: function (element, errorClass, validClass) {
        var elem = $(element);
        if (elem.hasClass('select2-hidden-accessible')) {
          elem.parent().find('.select2-selection').removeClass(errorClass);
          elem.parent().next('label').remove();
        }
        else {
          elem.removeClass(errorClass);
          elem.parent().next('label').remove();
        }
      },
  		invalidHandler: function(e, validator) {
  			var errors = validator.numberOfInvalids();
  			if (errors) {
  				var message = errors == 1
  					? 'You missed 1 required field. Check below tabs for highlighted fields'
  					: 'You missed ' + errors + ' required fields.  Check below tabs for highlighted fields';
  				$('div.validatorErrorList span').html(message);
  				$('div.validatorErrorList').show();
  			}
  			else {
  				$('div.validatorErrorList').hide();
  			}
  		}
    });
    $(document).on('change', '#adjust-type-default-select', function() { // Changes the 'Adjust Type' default for all rows
      $('.adjust-type-select').val($(this).find('option:selected').val());
    });
  
    $('#iaBatchFirstSave').on('click', function(e) {
      e.preventDefault();
      if ($('.ia_batch-validate').valid()) {
        window.onbeforeunload = null;
        if ($('span').hasClass('myErrorClass')) {
          alert('Duplicate entries are present.');
        } else {
          $('#jstemplates').html('');
          $('.productSearch option:selected[value=""]').closest('tr').remove();
          $('.loading-overlay').show();
          $('#iaBatchSave').click();
        }
      }
      else {
        return false;
      }
    });

    $('#iaBatchFirstSubmit').on('click', function(e) {
      e.preventDefault();
      if ($('.ia_batch-validate').valid()) {
        window.onbeforeunload = null;
        if ($('span').hasClass('myErrorClass')) {
          alert('Duplicate entries are present.');
        } else {
          $('#jstemplates').html('');
          $('.productSearch option:selected[value=""]').closest('tr').remove();
          $('.loading-overlay').show();
          $('#iaBatchSubmit').click();
        }
      }
      else {
        return false;
      }
    });

    // on first focus (bubbles up to document), open the menu
    $(document).on('focus', '.select2-selection.select2-selection--single', function (e) {
      $(this).closest('.select2-container').siblings('select:enabled').select2('open');
    });
    
    // steal focus during close - only capture once and stop propogation
    $('select.select2').on('select2:closing', function (e) {
      $(e.target).data('select2').$selection.one('focus focusin', function (e) {
        e.stopPropagation();
      });
    });
  
    // monkeypatch to fix issue of search field not being selected on open.  https://github.com/select2/select2/issues/2311#issuecomment-822473913
    $(document).on('select2:open', e => {
      const id = e.target.id;
      const target = document.querySelector(`[aria-controls=select2-${id}-results]`);
      target.focus();
    });
  
    $(document).on('mouseover', '.ia-highlightable, .select2-container', function() {
      var line = $(this).closest('.itemSelectTable');
      line.find('.ia-highlightable').css('background-color', '#d7e6ff');
      line.find('.ia-highlightable[readonly]').css('background-color', '#dae7fd');
      line.find('.select2-selection').css('background-color', '#d7e6ff');
      
    });
  
    $(document).on('mouseleave', '.ia-highlightable, .select2-container', function() {
      var line = $(this).closest('.itemSelectTable');
      line.find('.ia-highlightable').css('background-color', '');
      line.find('.select2-selection').css('background-color', '');
    });


  //---- Edit Related ----------------------------------------------------------//

    $('.productEditSearch').select2({
      selectOnClose: true,
      allowClear: true,
      placeholder: 'Select Item...',
      ajax: {
        url: '/json_dropdown_products_inv.json',
        dataType: 'json',
        type: 'GET',
        processResults: function (data, params) {
          params.page = data.page || 1;
          return {
            results: $.map(data.items, function (item) {
              return {
                  text: item.sku + ' | ' + item.desc,
                  id: item.id,
                  quantity_on_hand: item.qty,
              };
            }),
            pagination: {
              // more: data.current_page
            }
          };
        },
        cache: true
      },
      templateSelection: function(container) { // adds the current quantity on hand to the items 'data-quantity' attribute
          $(container.element).attr('data-quantity', container.quantity_on_hand).attr('data-child_count', container.child_count);
          return container.text;
      }
    });
  
    // This populates the 'Product Search' dropdown on the 'Edit' view
    var productSelect = $('.productEditSearch');
    productSelect.each(function() {
      var t = $(this);
      $.ajax({
        url: '/products/' + t.data('selected') + '.json',
        dataType: 'json',
        type: 'GET',
      }).then(function (data) {
        var option = $('<option />')
                      .val(data.productSelect.id)
                      .text(data.productSelect.sku + ' | ' + data.productSelect.desc)
                      .prop('selected', true)
                      .attr('data-quantity', data.productSelect.qty);
        t.append(option).trigger('change');
        t.trigger({
          type: 'select2:select',
          params: {
            data: data
          }
        });
      });
    });
  
    $(document).on('click', '.inventory_adjustment_edit_remove_button', function() {
      var fields = $(this).closest('.fields');
      bootbox.confirm('Are you sure you want to delete this adjustment?', function(result){
        if (result === true) {
          fields.find('.remove_child_edit_inventory_adjustment').attr('value', 'true');
          fields.slideUp('slow');
        }
      });
    });
  
    // This facilitates deleting an association via AJAX
    $('.inventory-adjustment-delete').on('ajax:success', function() {
      var parent = $(this).closest('tr');
      parent.next('input').remove();
      parent.slideUp( 'fast', function() { parent.remove() } );
    });
  
    for (var i = 0; i < 25; i++){
      $('.add_child_ia').click();
    }
  
    // monkeypatch to re-enable scrollwheel on number fields
    $('.scroll').bind('wheel', function() {});
    
  
    $(document).on('change keyup', '.adjust-quantity', function() {
      var tr = $(this).closest('tr');
      var current_quantity = tr.find('.current-quantity').val();
      var adjusted_quantity = $(this).val();
      var new_quantity = parseInt(current_quantity, 10) + parseInt(adjusted_quantity, 10);
      tr.find('.new-quantity').val(new_quantity);
    });

    $(document).on('change keyup', '.new-quantity', function() {
      var tr = $(this).closest('tr');
      var current_quantity = tr.find('.current-quantity').val();
      var negative_quantity = tr.find('.adjust-quantity');
      var new_quantity = parseInt($(this).val(), 10) - parseInt(current_quantity, 10);
      negative_quantity.val(new_quantity);
    });

    window.onbeforeunload = function() {
    	return 'Are you sure you want to leave?';
    };

  }

//----------------------------------------------------------------------------//
//---------- INVENTORY ADJUSTMENTS INDEX RELATED -----------------------------//
//----------------------------------------------------------------------------//

  if ($('.ia_index_div').length > 0) {
  
    $('.loading-overlay').show();

    var MyDate = new Date();
    var date;
    MyDate.setDate(MyDate.getDate());
    date = MyDate.getFullYear() + '-' + ('0' + (MyDate.getMonth() + 1)).slice(-2) + '-' + ('0' + MyDate.getDate()).slice(-2);
    var filename = 'Adjustment History Export, ' + date;
    var table = $('#inventoryAdjustmentsDataTable').DataTable({
      'retrieve': true,
      'processing': true,
      'serverSide': true,
      'initComplete': function( settings, json ) {
        $('.loading-overlay').hide();
      },
      'ajax': {
        'url': $('#inventoryAdjustmentsDataTable').data('source')
      },
      'columns': [
  /*0 */{data: 'id'},
  /*1 */{data: 'batch_id', orderable: false},
  /*2 */{data: 'sku'},
  /*3 */{data: 'difference'},
  /*4 */{data: 'new_qty'},
  /*5 */{data: 'description'},
  /*6 */{data: 'supplier'},
  /*7 */{data: 'adjustment_type'},
  /*8 */{data: 'adjustment_date'},
  /*9 */{data: 'created_by'},
  /*10*/{data: 'actions', orderable: false}
      ],
      'order': [[ 0, 'desc' ]],
      'oLanguage': { 'sZeroRecords': 'No records to display for this filter' },
      'dom': '<"row"<"col-sm-10 dataTableToolbar"><"col-sm-2"B>>t<"row dataTableBottomRow"<"col-md-2"i><"col-md-10"p>>',
      'buttons': [
        {
          extend: 'excelHtml5',
          title: filename,
          // Only exports columns that are currently visible.  Adjusted by the 'Visibility' dropdown and Filtered text.
          exportOptions: {columns: ':visible'},
          className: 'dtButtonHide'
        },
        {
          extend: 'csv',
          title: filename,
          exportOptions: {columns: ':visible'},
          className: 'dtButtonHide'
        },
        {
          extend: 'pdfHtml5',
          title: filename,
          orientation: 'landscape',
          exportOptions: {columns: ':visible'},
          className: 'dtButtonHide'
        },
        {
          extend: 'copyHtml5',
          title: filename,
          exportOptions: {columns: ':visible'},
          className: 'dtButtonHide'
        }, 
        {
          extend: 'print',
          autoPrint: false,
          title: filename,
          exportOptions: {columns: ':visible'},
          customize: function (win) {
              $(win.document.body).find('table').addClass('display').css('font-size', '12px');
              $(win.document.body).find('tr:nth-child(odd) td').each(function(index){
                  $(this).css('background-color','#D0D0D0');
              });
              $(win.document.body).find('h1').css('text-align','center');
          },
          className: 'dtButtonHide'
        }
      ]
    });
  
    function dataTableToolbar() {
      return '' +
      '<ul class="nav" style="margin-bottom:10px">' +
      '  <li>' +
      '    <span class="options-label">Options:</span>' +
      '  </li>' +
      '  <li>' +
      '    <div class="dataTables_length" id="inventoryAdjustmentsDataTable_length">' +
      '      <select name="inventoryAdjustmentsDataTable_length" aria-controls="inventoryAdjustmentsDataTable" class="form-control form-control-sm tableLength" title="Number of records to show">' +
      '        <option value="5">5</option>' +
      '        <option value="10">10</option>' +
      '        <option selected="selected" value="25">25</option>' +
      '        <option value="50">50</option>' +
      '        <option value="100">100</option>' +
      '        <option value="250">250</option>' +
      '        <option value="1000">1000</option>' +
      '      </select>' +
      '    </div>' +
      '  </li>' +
      '  <li>' +
      '    <div class="tableClickerDiv dropdown">' +
      '      <button class="btn btn-outline-primary btn-sm dropdown-toggle" title="Column visibility options" type="button" id="tableClickerColvis" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="fas fa-columns"></i> Columns</button>' +
      '      <div class="dropdown-menu card-shadow" aria-labelledby="tableClickerColvis">' +
      '        <a class="dropdown-item" id="colvisClicker-0">Adjustment ID</a>' +
      '        <a class="dropdown-item" id="colvisClicker-1">Batch ID</a>' +
      '        <a class="dropdown-item" id="colvisClicker-2">SKU #</a>' +
      '        <a class="dropdown-item" id="colvisClicker-3">Difference</a>' +
      '        <a class="dropdown-item" id="colvisClicker-4">New Quantity</a>' +
      '        <a class="dropdown-item" id="colvisClicker-5">Description</a>' +
      '        <a class="dropdown-item" id="colvisClicker-6">Supplier</a>' +
      '        <a class="dropdown-item" id="colvisClicker-7">Adjustment Type</a>' +
      '        <a class="dropdown-item" id="colvisClicker-8">Adjustment Date</a>' +
      '        <a class="dropdown-item" id="colvisClicker-9">Created By</a>' +
      '        <a class="dropdown-item" id="colvisClicker-10">Actions</a>' +
      '      </div>' +
      '    </div>' +
      '  </li>' +
      '  <li>' +
      '    <div class="tableClickerDiv dropdown">' +
      '      <button class="btn btn-outline-primary btn-sm dropdown-toggle" title="Export Options" type="button" id="tableClickerExports" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="fas fa-file-export"></i> Export</button>' +
      '      <div class="dropdown-menu" aria-labelledby="tableClickerExports">' +
      '        <div class="text-primary" style="font-size:11px;padding:0.25rem 1.5rem;">' +
      '          <i class="fas fa-exclamation-triangle text-danger"></i> Only the currently visible columns & rows will be exported' +
      '        </div>' +
      '        <a class="dropdown-item tableClickerExcel"><i class="fas fa-file-excel file-icon-color"></i> Excel <i class="fas fa-download text-primary"></i></a>' +
      '        <a class="dropdown-item tableClickerCsv"><i class="fas fa-file-csv file-icon-color"></i> CSV <i class="fas fa-download text-primary"></i></a>' +
      '        <a class="dropdown-item tableClickerPdf"><i class="fas fa-file-pdf file-icon-color"></i> PDF <i class="fas fa-download text-primary"></i></a>' +
      '        <a class="dropdown-item tableClickerCopy"><i class="fas fa-copy file-icon-color"></i> Copy</a>' +
      '        <a class="dropdown-item tableClickerPrint"><i class="fas fa-print file-icon-color"></i> Print/Preview</a>' +
      '      </div>' +
      '    </div>' +
      '  </li>' +
      '  <li class="filter-section mt-2" style="width:100%">' +
      '    <span class="filter-label">Filters:</span>' +
      '    <div id="inventoryAdjustmentsDataTable_filter" class="dataTables_filter mr-2">' +
      '      <input id="inventoryAdjustmentsTableSearch" class="form-control form-control-sm tableSearch" title="right-click to clear" oncontextmenu="return false" aria-controls="inventoryAdjustmentsDataTable" placeholder="Search Entire Table...">' +
      '    </div>' +
      '    <span id="sku-select-input" class="filter-span-input colvisSearch-2"></span>' +
      '    <span id="description-select-input" class="filter-span-input colvisSearch-5"></span>' +
      '    <span id="supplier-select-dropdown" class="filter-span-select colvisSearch-6"></span>' +
      '    <span id="adjustment-type-dropdown" class="filter-span-select colvisSearch-7"></span>' +
      '  </li>' +
      '</ul>' +
      '';
    }
  
    table.page.len(25).draw();

    $('div.dataTableToolbar').html(dataTableToolbar());
  
    // clears the global Table Search column on right-click
    $(document).on('mousedown', '.tableSearch', function() {
      if (event.which === 3) {
        $(this).val('');
        table.search('').draw();
      }
    });

    var search = $.fn.dataTable.util.throttle(
      function (val) {
        table.search(val).draw();
      }, 500);
     
    $('.tableSearch').on('keyup', function () {
      search( this.value );
    });
  
    $('.tableLength').on('change', function () {
      table.page.len($(this).find('option:selected').attr('value')).draw();
    });
  
    function colVisButtons(index) {
      var button = $('#colvisClicker-'+index);
      button.on('click', function(e) {
        e.stopPropagation();
        if (table.column(index).visible() === true) {
          table.column(index).visible(false);
          button.addClass('button-highlight');
          $('.colvisSearch-'+index).hide();
        }
        else {
          table.column(index).visible(true);
          button.removeClass('button-highlight');
          $('.colvisSearch-'+index).show();
        }
      });
    }
    let colVisIndex = -1;
    do { colVisIndex += 1;
         colVisButtons(colVisIndex);
    } while (colVisIndex < 10);
  
    yadcf.init(table,
      [
  
        { column_number : 2, // SKU #
          filter_type: 'text',
          filter_container_id : 'sku-select-input',
          style_class : 'form-control form-control-sm',
          filter_default_label : 'Search SKU #...'
        },
  
        { column_number : 5, // Description
          filter_type: 'text',
          filter_container_id : 'description-select-input',
          style_class : 'form-control form-control-sm',
          filter_default_label : 'Search Description...'
        },
  
        { column_number : 6, // Supplier
          filter_container_id : "supplier-select-dropdown",
          filter_default_label : "Suppliers... ↓↓↓",
          filter_match_mode : "exact",
          filter_type: 'multi_select',
          select_type: 'select2',
          data: $('#params_span').data('suppliers_dropdown')
        },
  
        { column_number : 7, // Adjustment Type
          filter_container_id : 'adjustment-type-dropdown',
          style_class : 'form-control form-control-sm',
          filter_default_label : 'Adjustment Type...',
          filter_type: 'select',
          data: [
            {value: '0', label : 'None Defined'},
            {value: '1', label : 'Ecomm Order'},
            {value: '2', label : 'Warranty Order'},
            {value: '3', label : 'Physical Inventory'},
            {value: '4', label : 'Item Return'},
            {value: '5', label : 'Item Defect'},
          ]
        }

      ],
      { filters_position : 'footer' },
      { externally_triggered : true }
    );
  
    // This block executes any query string searches that may be present in the URL
    function filterTable(columnIndex, filterValue){
      yadcf.exFilterColumn(table, [
        [columnIndex, filterValue],
      ], true);
    }
    const queryStringParams = window.location.search.substring(1).split('&');

    queryStringParams.forEach(param => {
        if (param.indexOf('sku') !== -1) {
          filterTable(2, $('#params_span').data('sku'));
        } else if (param.indexOf('supplier') !== -1) {
          filterTable(6, $('#params_span').data('supplier'));
        } else if (param.indexOf('adjustment_type') !== -1) {
          filterTable(7, [$('#params_span').data('adjustment_type')]);
        }
    });

    // Text column searches need to be executed a second time to trigger the actual search
    yadcf.exFilterColumn(table, [
      [2, yadcf.exGetColumnFilterVal(table,2)],
      [7, yadcf.exGetColumnFilterVal(table,7)]
    ], true);

    // This facilitates deleting a file via AJAX
    $('.inventory-adjustment-delete').on('ajax:success', function() {
      var parent = $(this).closest('tr');
      parent.addClass('hidden');
    });
  
    $('body').tooltip({selector: '[data-toggle="tooltip"]'});

  }

});
