import Turbolinks from 'turbolinks';
Turbolinks.start();
import RailsUJS from '@rails/ujs';
RailsUJS.start();

import 'channels';

//----------------------------------------------------------------------------//
// ---- JAVASCRIPT ONLY, NO STYLESHEETS --------------------------------------//
//----------------------------------------------------------------------------//

import * as ActiveStorage from "@rails/activestorage";
ActiveStorage.start();
import '@rails/actiontext';
import 'trix';


window.$ = $;
window.jQuery = $;
import 'jquery-ui';
import 'jquery-jeditable'; // For inline editing of attributes
import '@nathanvda/on_the_spot'; // For inline editing of attributes
import 'easy-autocomplete';
import 'chartkick';
import 'chart.js';
import "@fortawesome/fontawesome-free/css/all";
import '@claviska/jquery-offscreen/jquery.offscreen.js'; // shows notifications when an element is created offscreen
import 'jquery-autosize';
import 'jquery-mousewheel'; // For scrolling number boxes
import toastr from 'toastr'; // For all alert notifications
window.toastr = toastr;

import 'search';

import Rails from '@rails/ujs';
window.Rails = Rails;
import 'jquery-validation/dist/jquery.validate.js';

// Uppy is broken & temporarily disabled
// import Uppy from '@uppy/core';
// window.Uppy = Uppy;
// import Dashboard from '@uppy/dashboard';
// window.Dashboard = Dashboard;
// import ActiveStorageUpload from '@excid3/uppy-activestorage-upload';
// window.ActiveStorageUpload = ActiveStorageUpload;

import selectize from 'selectize';
window.selectize = selectize;
import Inputmask from "inputmask/dist/jquery.inputmask.js";
window.Inputmask = Inputmask;
import bootbox from 'bootbox';
window.bootbox = bootbox;
import modal from 'bootstrap/js/dist/modal';
window.modal = modal;
import scrollspy from 'bootstrap/js/dist/scrollspy';
window.scrollspy = scrollspy;

// DataTables related JS
import 'datatables.net-bs4';
import 'datatables.net-buttons-bs4';
import 'datatables.net-buttons/js/buttons.colVis.js';
import 'datatables.net-buttons/js/buttons.html5.js';
import 'datatables.net-buttons/js/buttons.print.js';
import 'datatables.net-fixedheader-bs4';
import 'datatables.net-responsive-bs4';
import 'datatables.net-scroller-bs4';
import 'jszip';
import 'pdfmake';
import jsZip from 'jszip';
window.JSZip = jsZip;
import yadcf from 'yadcf';

import 'popper.js'; // tooltips require popper.js
// Importing the entire Bootstrap library breaks jquery on the site and forces
// everything to load twice in a row (such as `click` events).  Tooltip &
// Popover are all that's required for global_functions.js
// import "bootstrap/js/dist/popover";
// import "bootstrap/js/dist/dropdown";
// import "bootstrap/js/dist/tooltip";
// import "bootstrap/js/dist/tab";
import "bootstrap/js/src/popover";
import "bootstrap/js/src/dropdown";
import "bootstrap/js/src/tooltip";
import "bootstrap/js/src/tab";
import tippy from 'tippy.js';
window.tippy = tippy;

import select2 from 'select2';

// Begin all secondary JS files
import 'packs/ad_campaigns-global.js'
import 'packs/addresses-global.js'
import 'packs/carriers-global.js';
import 'packs/categories-global.js';
import 'packs/employees-global.js';
import 'packs/ia_batches-global.js';
import 'packs/inbound_shipments-global.js';
import 'packs/inventory_adjustments-global.js';
import 'packs/invoices-global.js';
import 'packs/item_availabilities-global.js';
import 'packs/item_costs-global.js';
import 'packs/item_revenue_reports-global.js';
import 'packs/line_items-global.js';
import 'packs/order_uploads-global.js';
import 'packs/orders-global.js';
import 'packs/products-global.js';
import 'packs/retailers-global.js';
import 'packs/return_batches-global.js';
import 'packs/suppliers-global.js';
import 'packs/time_entry_batches-global.js';
import 'packs/transactions-global.js';
import 'packs/users-profiles-global.js';
import 'packs/warehouses-global.js';

import 'global_functions';

//----------------------------------------------------------------------------//
//---- STYLESHEETS ONLY ------------------------------------------------------//
//----------------------------------------------------------------------------//

import 'trix/dist/trix.css';

import '../stylesheets/global/actiontext.scss';
import '../stylesheets/global/yora_ui/horizontal-layout-light/style.scss';
import '../stylesheets/global/application';
import '../stylesheets/global/easy-autocomplete.scss';
import '../stylesheets/global/toastr.scss';
import '../stylesheets/global/notifications.scss';

// import '@uppy/core/dist/style.css';
// import '@uppy/dashboard/dist/style.css';
import 'select2/src/scss/core.scss';
import 'jquery-ui-dist/jquery-ui.min.css';
import 'selectize/dist/css/selectize.css';
import 'selectize/dist/css/selectize.bootstrap3.css';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/perspective.css';

// from Stimulus
import "controllers";
