/*global $*/

import 'stylesheets/users-global.scss';

$(document).on('turbolinks:load', function() {

//----------------------------------------------------------------------------//
//---------- USERS EDIT PROFILE RELATED --------------------------------------//
//----------------------------------------------------------------------------//

  if ($('.profile-validate').length > 0) {

    $('.profileState').select2({
      placeholder: 'State',
      allowClear: true
    }).on('change', function() {
      $(this).valid();
    });
    
    $('.profileCountry').select2({
      placeholder: 'Country',
      allowClear: true
    }).on('change', function() {
      $(this).valid();
    });
    
    $('.select2-basic').select2({
      allowClear: true
    }).on('change', function() {
      $(this).valid();
    });
    
    $('.select2-basic-nosearch').select2({
      minimumResultsForSearch: Infinity, // No search box visible
      // allowClear: true
    }).on('change', function() {
      $(this).valid();
    });
    
    if ($('.profileCountry').find('option:selected').val() == 'US') {
      $('.phone_mask').inputmask({ mask: '(999) 999-9999[-999999]', greedy: false });
      $('.zip_mask').inputmask({ mask: '99999[-9999]', greedy: false });
    }
    else {
      $('.phone_mask').inputmask('remove');
      $('.zip_mask').inputmask('remove');
    }
    $('.add_child').hide(); // hides main button for adding additional upload slots since it's handled by a static 'add_file_button' button on top of the div
    $('label.required').append('<strong> *</strong>'); // adds a 'required' star next to each Inputs label
    $('label.required-s span').append('<strong> *</strong>'); // adds a 'required' star next to each Selects label
  
    // Child Fields Template Loader inherited from application_helper.rb for Associated Files
    $(document).on('click', 'a.add_child', function() {
      var association, new_id, regexp, template, fields;
      association = $(this).attr('data-association');
      template = $('#' + association + '_fields_template').html();
      regexp = new RegExp('new_' + association, 'g');
      new_id = (new Date).getTime();
      $(this).parent().before(template.replace(regexp, new_id));
      fields = $(this).parent().prev('.fields');
      // The 'jstemplates' hidden field needs the '.ignore' class to ensure it
      // doesn't get picked up by Jquery Validator.  This line removes it.
      fields.find('input').removeClass('ignore');
      fields.find('input[name$="[description]"]').each(function() {
        // rules for nested file fields need to be generated after the object is created
        $(this).rules('add', {
          messages: {
            required: `A title is required. If you don't intend to create this post, please click the "Remove" button below`
          }
        });
      });
      // ActionText Trix ID's need to be manually created, otherwise it will duplicate
      // itself as 'trix-toolbar-1' on all instances of a Trix Editor
      fields.find('trix-toolbar').attr('id', 'trix-toolbar-' + new_id);
      fields.find('trix-editor').attr('trix-id', new_id).attr('toolbar', 'trix-toolbar-' + new_id);
      fields.hide();
      fields.slideDown('fast');
      return false;
    });
  
    $(document).on('click', 'a.remove_child', function() {
      var hidden_field = $(this).prev('input[type=hidden]')[0];
      var fields = $(this).parents('.fields');
      if(hidden_field) {
        hidden_field.value = '1';
      }
      $(this).parents('.fields').slideUp('slow', function() {
        fields.remove();
      });
      return false;
    });
  
    $('.add_user_attachment_button').on('click', function() {
      $('.add_child').click();
    });
  
    $('.profile-validate').validate({
      ignore: '.ignore',
  		rules: {
  			'profile[company]': {required: true, maxlength: 80},
  		},
  		// messages: {
  		// 	'profile[company]': 'Please enter your company name',
  		// },
      onkeyup: function(element) {$(element).valid()}, // onkeyup: false,
      errorClass: 'myErrorClass',
      errorPlacement: function (error, element) {
        error.insertAfter(element.parent());
      },
      highlight: function (element, errorClass, validClass) {
        var elem = $(element);
        if (elem.hasClass('select2-hidden-accessible')) {
          elem.parent().find('.select2-selection').addClass(errorClass);
        } 
        else {
          elem.addClass(errorClass);
        }
      },    
      unhighlight: function (element, errorClass, validClass) {
        var elem = $(element);
        if (elem.hasClass('select2-hidden-accessible')) {
          elem.parent().find('.select2-selection').removeClass(errorClass);
          elem.parent().next('label').remove();
        }
        else {
          elem.removeClass(errorClass);
          elem.parent().next('label').remove();
        }
      },
  		invalidHandler: function(e, validator) {
  			var errors = validator.numberOfInvalids();
  			if (errors) {
  				var message = errors == 1
  					? 'You missed 1 required field. It has been highlighted above'
  					: 'You missed ' + errors + ' required fields.  They have been highlighted above';
  				$('div.validatorErrorList span').html(message);
  				$('div.validatorErrorList').show();
  			}
  			else {
  				$('div.validatorErrorList').hide();
  			}
  		}
    });
  
    $('#profileFirstSubmit').on('click', function(e) {
      e.preventDefault();
      if ($('.profile-validate').valid()) {
        $('#jstemplates').html('');
        $('#profileSubmit').click();
      }
      else {
        return false;
      }
    });
  
    $('.dropdown-hover').mouseenter(function() {
      // dropdown for file uploads when hovering over the file
      $(this).click();
    });
    $('.dropdown-hover-menu').mouseleave(function() {
      $(this).prev('.dropdown-hover').click();
    });

    $(document).on('change keyup', function() {
      // This populates the hidden 'State' field since a select_tag is used 
      // (not sure if neccessary to have a select_tag? It was required at one point.  Double check.  -Jan 25 2020)
      var state = $(this).find('.profileState').find('option:selected').val();
      $(this).find('.profileStateHidden').val(state);
    
      if ($('.profileCountry').find('option:selected').val() == 'US') {
        $('.phone_mask').inputmask({ mask: '(999) 999-9999[-999999]', greedy: false, showMaskOnHover: true });
        $('.zip_mask').inputmask({ mask: '99999[-9999]', greedy: false, showMaskOnHover: true });
      }
      else {
        $('.phone_mask').inputmask('remove');
        $('.zip_mask').inputmask('remove');
      }
    
      // This facilitates deleting the attached image via AJAX
      $('.avatar-image-attachment-delete').on('ajax:success', function() {
        var parent = $(this).closest('.image-body').find('img');
        parent.slideUp( 'slow', function() { parent.remove() } );
        $(this).slideUp( 'slow', function() { $(this).remove() } );
      });
    
    });

  }

//----------------------------------------------------------------------------//
//---------- USERS INDEX RELATED ---------------------------------------------//
//----------------------------------------------------------------------------//

  if ($('.users_index_div').length > 0) {
    
    var MyDate = new Date();
    var date;
    MyDate.setDate(MyDate.getDate());
    date = MyDate.getFullYear() + "-" + ("0" + (MyDate.getMonth() + 1)).slice(-2) + "-" + ("0" + MyDate.getDate()).slice(-2);
    var filename = "User List, " + date + " Export";
    var table = $("#userListDataTable").DataTable({
      "retrieve": true,
      "oLanguage": { "sZeroRecords": "No users to display for this filter" },
      "dom": "<'row'<'col-sm-10 dataTableToolbar'><'col-sm-2'B>>t<'row dataTableBottomRow'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-6'p>>",
      "buttons": [
        {
          extend: "excelHtml5",
          title: filename,
          // Only exports columns that are currently visible.  Adjusted by the "Visibility" dropdown and Filtered text.
          exportOptions: {columns: ":visible"},
          className: "dtButtonHide"
        },
        {
          extend: "csv",
          title: filename,
          exportOptions: {columns: ":visible"},
          className: "dtButtonHide"
        },
        {
          extend: "pdfHtml5",
          title: filename,
          orientation: "landscape",
          exportOptions: {columns: ":visible"},
          className: "dtButtonHide"
        },
        {
          extend: "copyHtml5",
          title: filename,
          exportOptions: {columns: ":visible"},
          className: "dtButtonHide"
        }, 
        {
          extend: "print",
          autoPrint: false,
          title: filename,
          exportOptions: {columns: ":visible"},
          customize: function (win) {
              $(win.document.body).find('table').addClass('display').css('font-size', '12px');
              $(win.document.body).find('tr:nth-child(odd) td').each(function(index){
                  $(this).css('background-color','#D0D0D0');
              });
              $(win.document.body).find('h1').css('text-align','center');
          },
          className: "dtButtonHide"
        }
      ]
    });

    function dataTableToolbar() {
      return "" +
      "<ul class='nav' style='margin-bottom:10px'>" +
      "  <li>" +
      "    <div id='userListDataTable_filter' class='dataTables_filter mr-2'>" +
      "      <input id='userListTableSearch' class='form-control form-control-sm tableSearch' title='right-click to clear' oncontextmenu='return false' aria-controls='userListDataTable' placeholder='Search Table...'>" +
      "    </div>" +
      "  <li>" +
      "    <div class='dataTables_length' id='userListDataTable_length'>" +
      "      <select name='userListDataTable_length' aria-controls='userListDataTable' class='form-control form-control-sm tableLength' title='Number of records to show'>" +
      "        <option value='5'>5</option>" +
      "        <option value='10'>10</option>" +
      "        <option value='25'>25</option>" +
      "        <option value='50'>50</option>" +
      "        <option value='100'>100</option>" +
      "        <option selected='selected' value='-1'>All</option>" +
      "      </select>" +
      "    </div>" +
      "  </li>" +
      "  <li>" +
      "    <div class='tableClickerDiv dropdown'>" +
      "      <button class='btn btn-primary btn-sm dropdown-toggle' type='button' id='tableClickerExports' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'><i class='fas fa-file-export'></i> Export</button>" +
      "      <div class='dropdown-menu' aria-labelledby='tableClickerExports'>" +
      "        <div class='text-primary' style='font-size:11px;padding:0.25rem 1.5rem;'>" +
      "          <i class='fas fa-exclamation-triangle text-danger'></i> Only the currently visible columns & rows will be exported" +
      "        </div>" +
      "        <a class='dropdown-item tableClickerExcel'><i class='fas fa-file-excel file-icon-color'></i> Excel <i class='fas fa-download text-primary'></i></a>" +
      "        <a class='dropdown-item tableClickerCsv'><i class='fas fa-file-csv file-icon-color'></i> CSV <i class='fas fa-download text-primary'></i></a>" +
      "        <a class='dropdown-item tableClickerPdf'><i class='fas fa-file-pdf file-icon-color'></i> PDF <i class='fas fa-download text-primary'></i></a>" +
      "        <a class='dropdown-item tableClickerCopy'><i class='fas fa-copy file-icon-color'></i> Copy</a>" +
      "        <a class='dropdown-item tableClickerPrint'><i class='fas fa-print file-icon-color'></i> Print/Preview</a>" +
      "      </div>" +
      "    </div>" +
      "  </li>" +
      "  <li>" +
      "    <div class='tableClickerDiv dropdown'>" +
      "      <button class='btn btn-primary btn-sm dropdown-toggle' type='button' id='tableClickerColvis' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'><i class='fas fa-columns'></i> Columns</button>" +
      "      <div class='dropdown-menu' aria-labelledby='tableClickerColvis'>" +
      "        <a class='dropdown-item' id='colvisClicker-0'>UID</a>" +
      "        <a class='dropdown-item' id='colvisClicker-1'>Friendly ID</a>" +
      "        <a class='dropdown-item' id='colvisClicker-2'>Email</a>" +
      "        <a class='dropdown-item' id='colvisClicker-3'>Name</a>" +
      "        <a class='dropdown-item' id='colvisClicker-4'>Custom Datasets</a>" +
      "        <a class='dropdown-item' id='colvisClicker-5'>User Created</a>" +
      "        <a class='dropdown-item' id='colvisClicker-6'>Last Modified</a>" +
      "        <a class='dropdown-item' id='colvisClicker-7'>Current Sign in Date</a>" +
      "        <a class='dropdown-item' id='colvisClicker-8'>Last Sign in Date</a>" +
      "        <a class='dropdown-item' id='colvisClicker-9'>Current Sign in IP</a>" +
      "        <a class='dropdown-item' id='colvisClicker-10'>Last Sign in IP</a>" +
      "        <a class='dropdown-item' id='colvisClicker-11'>Sign in Count</a>" +
      "        <a class='dropdown-item' id='colvisClicker-12'>Admin?</a>" +
      "        <a class='dropdown-item' id='colvisClicker-13'>Roles</a>" +
      "      </div>" +
      "    </div>" +
      "  </li>" +
      "</ul>" +
      "";
    }

    table.page.len("-1").draw();

    $("div.dataTableToolbar").html(dataTableToolbar());

    $(".tableSearch").keyup(function () {
      table.search($(this).val()).draw();
    });
  
    $(".tableLength").on("change", function () {
      table.page.len($(this).find("option:selected").attr("value")).draw();
    });
  
    function colVisButtons(index) {
      var button = $("#colvisClicker-"+index);
      button.on("click", function(e) {
        e.stopPropagation();
        if (table.column(index).visible() === true) {
          table.column(index).visible(false);
          button.addClass("button-highlight");
        }
        else {
          table.column(index).visible(true);
          button.removeClass("button-highlight");
        }
      });
    }
    let colVisIndex = -1;
    do { colVisIndex += 1;
         colVisButtons(colVisIndex);
    } while (colVisIndex < 13);
  
  }

//----------------------------------------------------------------------------//
//---------- USERS SHOW PROFILE RELATED --------------------------------------//
//----------------------------------------------------------------------------//

  if ($('.profile_show_div').length > 0) {

    $(document).on('click', '.ots-clicker', function() {
      $(this).closest('span').find('.on_the_spot_editing').click();
    });
  
    // This facilitates deleting a file via AJAX
    $('.profile-attachment-delete').on('ajax:success', function() {
      var parent = $(this).closest('li');
      parent.slideUp( 'slow', function() { parent.remove() } );
    });

  }

});
