/*global $*/
/*global bootbox*/

import 'stylesheets/time_entry_batches-global.scss';

$(document).on('turbolinks:load', function() {

//----------------------------------------------------------------------------//
//---------- TIME ENTRY BATCHES FORM RELATED ---------------------------------//
//----------------------------------------------------------------------------//

  if ($('.time_entry_batch-validate').length > 0) {

    $('label.required').append('<strong> *</strong>'); // adds a 'required' star next to each Inputs label
    $('label.required-s span').append('<strong> *</strong>'); // adds a 'required' star next to each Selects label
  
    $(".add_notes_button").on("click", function() {
      $(this).slideUp("fast");
      $(".batch-notes-div").slideDown("slow");
    });
  
    function employeeNameWorker() {
      var current_pay_rate = $('.employee-select').find('option:selected').attr('data-pay_rate');
      if ($('.employee-select option:selected').text() == 'Employee...') {
        $('.employee_name_header').text('');
        $('.time_entry_employee_input').val('');
        $('.employee_name_container').hide();
        $('#timeEntryBatchFirstSubmit, #timeEntryBatchFirstSave').hide();
      } else {
        $('.employee_name_container').show();
        $('.employee_name_header').text($('.employee-select option:selected').text());
        $('.time_entry_employee_input').val($('.employee-select option:selected').text());
        $('.time_entry_pay_rate_input').val(current_pay_rate);
        $('#timeEntryBatchFirstSubmit, #timeEntryBatchFirstSave').show();
      }
    }

    $(document).on('change', '.employee-select', function() {
      employeeNameWorker();
    });
  
    $('.time_entry_batch-validate').validate({
      ignore: '.ignore',
  		rules: {
  		// 	'product[model_number]': {required: true, maxlength: 80},
  		// 	'product[supplier_part_number]': {required: true, maxlength: 80},
  		},
  		messages: {
  		// 	'product[supplier_part_number]': 'If the Manufacturer Part Number & the Model Number are the same, enter the same SKU in both fields.',
  		// 	'product[model_number]': 'If the Model Number & the Manufacturer Part Number are the same, enter the same SKU in both fields.'
  		},
      onfocusout: function(element) {$(element).valid()},
      focusCleanup: true,
      errorClass: 'myErrorClass',
      errorPlacement: function (error, element) {
        error.insertAfter(element.parent());
      },
      highlight: function (element, errorClass, validClass) {
        var elem = $(element);
        var tabId = elem.closest('.tab-pane').attr('aria-labelledby');
        if (elem.hasClass('select2-hidden-accessible')) {
          elem.parent().find('.select2-selection').addClass(errorClass);
          elem.closest('.card-body').find('.nav-link[id="'+tabId+'"]').addClass(errorClass);
        } 
        else {
          elem.addClass(errorClass);
          elem.closest('.card-body').find('.nav-link[id="'+tabId+'"]').addClass(errorClass);
        }
      },    
      unhighlight: function (element, errorClass, validClass) {
        var elem = $(element);
        var tabId = elem.closest('.tab-pane').attr('aria-labelledby');
        if (elem.hasClass('select2-hidden-accessible')) {
          elem.parent().find('.select2-selection').removeClass(errorClass);
          elem.parent().next('label').remove();
          elem.closest('.card-body').find('.nav-link[id="'+tabId+'"]').removeClass(errorClass);
        }
        else {
          elem.removeClass(errorClass);
          elem.parent().next('label').remove();
          elem.closest('.card-body').find('.nav-link[id="'+tabId+'"]').removeClass(errorClass);
        }
      },
  		invalidHandler: function(e, validator) {
  			var errors = validator.numberOfInvalids();
  			if (errors) {
  				var message = errors == 1
  					? 'You missed 1 required field. Check below tabs for highlighted fields'
  					: 'You missed ' + errors + ' required fields.  Check below tabs for highlighted fields';
  				$('div.validatorErrorList span').html(message);
  				$('div.validatorErrorList').slideDown('fast');
  			}
  			else {
  				$('div.validatorErrorList').slideUp('slow');
  			}
  		}
    });
  
    $('#timeEntryBatchFirstSave').on('click', function(e) {
      e.preventDefault();
      if ($('.time_entry_batch-validate').valid()) {
        $('#jstemplates_time_entry').html('');
        $('.loading-overlay').show();
        $('#timeEntryBatchSave').click();
      }
      else {
        return false;
      }
    });
  
    $('#timeEntryBatchFirstSubmit').on('click', function(e) {
      e.preventDefault();
      if ($('.time_entry_batch-validate').valid()) {
        $('#jstemplates_time_entry').html('');
        $('.loading-overlay').show();
        $('#timeEntryBatchSubmit').click();
      }
      else {
        return false;
      }
    });

    // on first focus (bubbles up to document), open the menu
    $(document).on('focus', '.select2-selection.select2-selection--single', function (e) {
      $(this).closest('.select2-container').siblings('select:enabled').select2('open');
    });
  
    // steal focus during close - only capture once and stop propogation
    $('select.select2').on('select2:closing', function (e) {
      $(e.target).data('select2').$selection.one('focus focusin', function (e) {
        e.stopPropagation();
      });
    });
    
    $(document).on('change keyup', '.field-highlightable', function() {
      if ($(this).val()) {
        $(this).removeClass('field-highlight');
      }
      else {
        $(this).addClass('field-highlight');
      }
    });
    
    $('.field-highlightable').each(function() {
      if ($(this).val()) {
        $(this).removeClass('field-highlight');
      }
    });

//---- New Time Entry Related -----------------------------------------------//
  
    // Child Fields Template Loader inherited from transaction_batches_helper.rb for Associated Attachments
    $(document).on('click', 'a.add_child_time_entry', function() {
      var association, new_id, regexp, template, fields;
      association = $(this).attr('data-association');
      template = $('#' + association + '_fields_template').html();
      regexp = new RegExp('new_' + association, 'g');
      new_id = (new Date).getTime();
      $(this).parent().before(template.replace(regexp, new_id));
      fields = $(this).parent().prev('.fields_time_entry');
      // The 'jstemplates' hidden field needs the '.ignore' class to ensure it
      // doesn't get picked up by Jquery Validator.  This line removes it.
      fields.find('input').removeClass('ignore');
  
      fields.find('.timeEntryStatusSelect').select2({
        selectOnClose: true,
        placeholder: 'Status...',
      });

      employeeNameWorker();

      var newDate = new Date(fields.find('.order-date-select').val().replace(/-/g, '\/').replace(/T.+/, ''));
      fields.find('.time_entry_day_input').val(
        newDate.toLocaleString('en-us', {weekday:'short', timeZone:'CST'})
      );

      // monkeypatch to fix issue of search field not being selected on open.  https://github.com/select2/select2/issues/2311#issuecomment-822473913
      $(document).on('select2:open', e => {
        const id = e.target.id;
        const target = document.querySelector(`[aria-controls=select2-${id}-results]`);
        target.focus();
      });
  
      // on first focus (bubbles up to document), open the menu
      $(document).on('focus', '.select2-selection.select2-selection--single', function (e) {
        $(this).closest('.select2-container').siblings('select:enabled').select2('open');
      });
    
      // steal focus during close - only capture once and stop propogation
      $('select.select2').on('select2:closing', function (e) {
        $(e.target).data('select2').$selection.one('focus focusin', function (e) {
          e.stopPropagation();
        });
      });
  
      fields.find('[data-toggle="tooltip"]').tooltip({trigger: 'hover'});

      fields.hide().slideDown('fast');
  
      return false;
    });
  
    $(document).on('click', 'a.remove_child_time_entry', function() {
      var tr = $('.fields_time_entry').length;
      if (tr == 2) {
        $('#timeEntryBatchFirstSubmit').hide();
        $('#timeEntryBatchFirstSave').hide();
      }
      var hidden_field = $(this).prev('input[type=hidden]')[0];
      var fields = $(this).parents('.fields_time_entry');
      if(hidden_field) {
        hidden_field.value = '1';
      }
      $(this).parents('.fields_time_entry').slideUp('slow', function() {
        fields.remove();
      });
      return false;
    });
  
    $(document).on('change', '.order-date-select', function() {
      var fields = $(this).closest('.fields_time_entry');
      var newDate = new Date(fields.find('.order-date-select').val().replace(/-/g, '\/').replace(/T.+/, ''));
      fields.find('.time_entry_day_input').val(
        newDate.toLocaleString('en-us', {weekday:'short'})
      );
    });

    $(document).on('change', '.time-select', function() {
      var entry = $(this).closest('.timeEntrySelectTable');
      let start_time = entry.find('.start-time').val();
      let end_time = entry.find('.end-time').val();
      let pay_rate = $('.employee-select').find('option:selected').attr('data-pay_rate');

      function diff(start, end) {
        start = start.split(":");
        end = end.split(":");
        var startDate = new Date(0, 0, 0, start[0], start[1], 0);
        var endDate = new Date(0, 0, 0, end[0], end[1], 0);
        var diff = endDate.getTime() - startDate.getTime();
        var hours = Math.floor(diff / 1000 / 60 / 60);

        if ($('.employee-select option:selected').text() == 'Employee...') {
          entry.find('.time_entry_total_pay_input').val('');
        } else {
          entry.find('.time_entry_total_pay_input').val('$' + (diff*pay_rate.replace("$", "") / 1000 / 60 / 60).toFixed(2) || 0);
        }

        diff -= hours * 1000 * 60 * 60;
        var minutes = Math.floor(diff / 1000 / 60);
    
        // If using time pickers with 24 hours format, add the below line get exact hours
        if (hours < 0)
           hours = hours + 24;
    
        return (hours <= 9 ? "0" : "") + hours + ":" + (minutes <= 9 ? "0" : "") + minutes;
      }

      if (start_time == '' || end_time == '') {
        entry.find('.statusSelect').val('incomplete');
      } else {
        entry.find('.statusSelect').val('complete');
      }

      entry.find('.time_entry_hours_input').val(diff(start_time, end_time) || 0);

    });

    $(document).on('change mousedown', '.time-select', function() {
      function padLeadingZeros(num, size) {
        var s = num+"";
        while (s.length < size) s = "0" + s;
        return s;
      }
      var today = new Date();
      var current_time = today.getHours() + ":" + padLeadingZeros(today.getMinutes(), 2);
      
      if (event.which === 2) {
        $(this).val(current_time);
        $(this).removeClass('field-highlight');
        $(this).click();
      } else if (event.which === 3) {
        $(this).val('');
        $(this).addClass('field-highlight');
        $(this).closest('.timeEntrySelectTable').find('.statusSelect').val('incomplete');
        $(this).click();
      }
    });
  
    // Monkeypatch so all select2 fields actually clear to 'nil' when the 'X' is clicked
    $(document).on('select2:clear', '.select2-hidden-accessible', function (e) {
      $(this).append('<option value="" selected="selected"></option>');
    });
  
    $('.add_time_entry_button').on('click', function() {
      $('.add_child_time_entry').click();
      $('#timeEntryBatchFirstSave').show();
      $('#timeEntryBatchFirstSubmit').show();
      $('#timeEntryBatchEditFirstSave').show();
      $('#timeEntryBatchEditFirstSubmit').show();
    });

  //---- Edit View Related -------------------------------------------------------//
  
    $('#timeEntryBatchEditFirstSave').on('click', function(e) {
      e.preventDefault();
      if ($('.time_entry_batch-validate').valid()) {
        $('#jstemplates_time_entry').html('');
        $('.loading-overlay').show();
        $('#timeEntryBatchEditSave').click();
      }
      else {
        return false;
      }
    });
  
    $('#timeEntryBatchEditFirstSubmit').on('click', function(e) {
      e.preventDefault();
      if ($('.time_entry_batch-validate').valid()) {
        $('#jstemplates_time_entry').html('');
        $('.loading-overlay').show();
        $('#timeEntryBatchEditSubmit').click();
      }
      else {
        return false;
      }
    });
  
    $(document).on('click', '.time_entry_edit_remove_button', function() {
      var fields = $(this).closest('.fields_time_entry');
      bootbox.confirm('Are you sure you want to remove this entry?', function(result){
        if (result === true) {
          fields.find('.remove_child_edit_time_entry').attr('value', 'true');
          fields.slideUp('slow');
        }
      });
    });
  
    $('.scroll').bind('mousewheel', function() {});

  }

});
