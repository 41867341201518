/*global $*/
/*global yadcf*/
/*global globalObjects*/
/*global itemShowObjects*/


import 'stylesheets/suppliers-global.scss';

$(document).on("turbolinks:load", function() {

//----------------------------------------------------------------------------//
//---------- SUPPLIERS FORM RELATED ------------------------------------------//
//----------------------------------------------------------------------------//

  if ($('.supplier-validate').length > 0) {

    $(".add_child").hide(); // hides main button for adding additional address slots since it's handled by a static 'add_file_button' button on top of the div
    $('label.required').append('<strong> *</strong>'); // adds a "required" star next to each Inputs label
    $('label.required-s span').append('<strong> *</strong>'); // adds a "required" star next to each Selects label
  
    $(".supplier-users").selectize();
  
    $(document).on("click", ".add-notes-button", function() {
      $(this).closest(".fields").find(".address-notes-div").slideDown("fast");
      $(this).hide();
    });
  
    $(".supplier-validate").validate({
      ignore: ".ignore",
  		rules: {
  		// 	"supplier[brand_name]": {required: true, maxlength: 80},
  		},
  		messages: {
  			"supplier[brand_name]": "Brand name is required",
  		},
      onfocusout: function(element) {$(element).valid()},
      focusCleanup: true,
      errorClass: "myErrorClass",
      errorPlacement: function (error, element) {
        error.insertAfter(element.parent());
      },
      highlight: function (element, errorClass, validClass) {
        var elem = $(element);
        if (elem.hasClass("select2-hidden-accessible")) {
          elem.parent().find(".select2-selection").addClass(errorClass);
        } 
        else {
          elem.addClass(errorClass);
        }
      },    
      unhighlight: function (element, errorClass, validClass) {
        var elem = $(element);
        if (elem.hasClass("select2-hidden-accessible")) {
          elem.parent().find(".select2-selection").removeClass(errorClass);
          elem.parent().next("label").remove();
        }
        else {
          elem.removeClass(errorClass);
          elem.parent().next("label").remove();
        }
      },
  		invalidHandler: function(e, validator) {
  			var errors = validator.numberOfInvalids();
  			if (errors) {
  				var message = errors == 1
  					? 'You missed 1 required field. Check below tabs for highlighted fields'
  					: 'You missed ' + errors + ' required fields.  Check below tabs for highlighted fields';
  				$("div.validatorErrorList span").html(message);
  				$("div.validatorErrorList").show();
  			}
  			else {
  				$("div.validatorErrorList").hide();
  			}
  		}
    });
  
    // Monkeypatch so all select2 fields actually clear to 'nil' when the 'X' is clicked
    $(document).on('select2:clear', '.select2-hidden-accessible', function (e) {
      $(this).append('<option value="" selected="selected"></option>');
    });
  
    $("#supplierFirstSubmit").on("click", function(e) {
      e.preventDefault();
      if ($(".supplier-validate").valid()) {
        $("#jstemplates").html("");
        $(".loading-overlay").show();
        $("#supplierSubmit").click();
      }
      else {
        return false;
      }
    });
  
  //---- Attachments Related -----------------------------------------------------//
  
    // Child Fields Template Loader inherited from suppliers_helper.rb for Associated Attachments
    $(document).on('click', 'a.add_child_supplier_attachments', function() {
      var association, new_id, regexp, template, fields;
      association = $(this).attr('data-association');
      template = $('#' + association + '_fields_template').html();
      regexp = new RegExp('new_' + association, 'g');
      new_id = (new Date).getTime();
      $(this).parent().before(template.replace(regexp, new_id));
      fields = $(this).parent().prev(".fields_supplier_attachments");
      // The "jstemplates" hidden field needs the ".ignore" class to ensure it
      // doesn't get picked up by Jquery Validator.  This line removes it.
      fields.find("input").removeClass("ignore");
      fields.find("input[name$='[description]']").each(function() {
        // rules for nested file fields need to be generated after the object is created
        $(this).rules("add", {
          messages: {
            required: `A title is required. If you don't intend to create this attachment, please click the "Remove" button below`
          }
        });
      });
      // ActionText Trix ID's need to be manually created, otherwise it will duplicate
      // itself as "trix-toolbar-1" on all instances of a Trix Editor
      fields.find("trix-toolbar").attr("id", "trix-toolbar-" + new_id);
      fields.find("trix-editor").attr("trix-id", new_id).attr("toolbar", "trix-toolbar-" + new_id);
      fields.hide();
      fields.slideDown("fast");
      return false;
    });
  
    $(document).on("click", 'a.remove_child_supplier_attachments', function() {
      var hidden_field = $(this).prev('input[type=hidden]')[0];
      var fields = $(this).parents('.fields_supplier_attachments');
      if(hidden_field) {
        hidden_field.value = '1';
      }
      $(this).parents('.fields_supplier_attachments').slideUp("slow", function() {
        fields.remove();
      });
      return false;
    });
  
    $(document).on("change keyup", ".attachment-description", function() {
      var new_description = $(this).val();
      $(this).closest(".edit-attachment-fields").find(".attachment-title").text(new_description);
    });
  
    $(".add_supplier_attachment_button").on("click", function() {
      $(".add_child_supplier_attachments").click();
    });
    
    // This facilitates deleting a file via AJAX
    $(".supplier-attachment-delete").on("ajax:success", function() {
      var parent = $(this).closest("li");
      parent.slideUp( "slow", function() { parent.remove() } );
    });
  
  //---- Addresses Related -----------------------------------------------------//
  
    $(".existingAddress").find(".addressState").select2({
      placeholder: "State",
      allowClear: true,
    }).on("change", function() {
      $(this).valid();
    });
    
    $(".existingAddress").find(".addressCountry").select2({
      placeholder: "Country",
      allowClear: true
    }).on("change", function() {
      $(this).valid();
    });
    
    $(".existingAddress").find(".select2-basic-nosearch").select2({
      minimumResultsForSearch: Infinity, // No search box visible
      // allowClear: true
    }).on("change", function() {
      $(this).valid();
    });
    
    $(".addressCountry").each(function() {
      var fields = $(this).closest(".existingAddress");
      if ($(this).find("option:selected").val() == "US") {
        fields.find(".phone_mask").inputmask({ mask: "(999) 999-9999[-999999]", greedy: false });
        fields.find(".zip_mask").inputmask({ mask: "99999[-9999]", greedy: false });
      }
      else {
        fields.find(".phone_mask").inputmask("remove");
        fields.find(".zip_mask").inputmask("remove");
      }
    });
  
    $(".add_address_button").on("click", function() {
      $(".add_child").click();
    });
    // Child Fields Template Loader inherited from application_helper.rb for Associated Addresses
    $(document).on('click', 'a.add_child', function() {
      var association, new_id, regexp, template, fields;
      association = $(this).attr('data-association');
      template = $('#' + association + '_fields_template').html();
      regexp = new RegExp('new_' + association, 'g');
      new_id = (new Date).getTime();
      $(this).parent().before(template.replace(regexp, new_id));
      // The "jstemplates" hidden field needs the ".ignore" class to ensure it
      // doesn't get picked up by Jquery Validator.  This line removes it.
      fields = $(this).parent().prev(".fields");
      fields.find("input").removeClass("ignore");
      fields.find(".addressState").select2({
        placeholder: "State",
        allowClear: true,
      }).on("change", function() {
        $(this).valid();
      });
      
      fields.find(".addressCountry").select2({
        placeholder: "Country",
        allowClear: true
      }).on("change", function() {
        $(this).valid();
      });
      
      fields.find(".select2-basic").select2({
        allowClear: true
      }).on("change", function() {
        $(this).valid();
      });
      
      fields.find(".select2-basic-nosearch").select2({
        minimumResultsForSearch: Infinity, // No search box visible
        // allowClear: true
      }).on("change", function() {
        $(this).valid();
      });
  
      fields.find(".phone_mask").inputmask({ mask: "(999) 999-9999[-999999]", greedy: false });
      fields.find(".zip_mask").inputmask({ mask: "99999[-9999]", greedy: false });
      fields.find(".address-notes-div").hide();
      // ActionText Trix ID's need to be manually created, otherwise it will duplicate
      // itself as "trix-toolbar-1" on all instances of a Trix Editor
      fields.find("trix-toolbar").attr("id", "trix-toolbar-" + new_id);
      fields.find("trix-editor").attr("trix-id", new_id).attr("toolbar", "trix-toolbar-" + new_id);
      fields.hide();
      fields.slideDown("fast");
      return false;
    });
  
    $(document).on("click", 'a.remove_child', function() {
      var hidden_field = $(this).prev('input[type=hidden]')[0];
      var fields = $(this).parents('.fields');
      if(hidden_field) {
        hidden_field.value = '1';
      }
      $(this).parents('.fields').slideUp("slow", function() {
        fields.remove();
      });
      return false;
    });
  
    $(document).on("change keyup", ".addressState", function() {
      // This populates the hidden "State" field since a select_tag is used 
      var state = $(this).find("option:selected").val();
      $(this).closest(".fields").find(".addressStateHidden").val(state);
    });
    
    $(document).on("change keyup", ".addressCountry", function() {
      var fields = $(this).closest(".fields");
      if ($(this).find("option:selected").val() == "US") {
        fields.find(".phone_mask").inputmask({ mask: "(999) 999-9999[-999999]", greedy: false, showMaskOnHover: true });
        fields.find(".zip_mask").inputmask({ mask: "99999[-9999]", greedy: false, showMaskOnHover: true });
      }
      else {
        fields.find(".phone_mask").inputmask("remove");
        fields.find(".zip_mask").inputmask("remove");
      }
    });
  
    // This facilitates deleting the attached image via AJAX
    $(".supplier-image-attachment-delete").on("ajax:success", function() {
      var parent = $(this).closest(".image-body").find("img");
      parent.slideUp( "slow", function() { parent.remove() } );
      $(this).slideUp( "slow", function() { $(this).remove() } );
    });
  
  }

//----------------------------------------------------------------------------//
//---------- SUPPLIERS INDEX RELATED ------------------------------------------//
//----------------------------------------------------------------------------//

  if ($('.suppliers_index_div').length > 0) {
  
    $(".loading-overlay").show();

    var MyDate = new Date();
    var date;
    MyDate.setDate(MyDate.getDate());
    date = MyDate.getFullYear() + "-" + ("0" + (MyDate.getMonth() + 1)).slice(-2) + "-" + ("0" + MyDate.getDate()).slice(-2);
    var filename = "Suppliers, " + date + " Export";
    var table = $("#suppliersDataTable").DataTable({
      "retrieve": true,
      "processing": true,
      "serverSide": true,
      "initComplete": function( settings, json ) {
        $('.loading-overlay').hide();
      },
      "ajax": {
        "url": $("#suppliersDatatable").data("source")
      },
      "columns": [
  /*0 */{data: "brand_name"},
  /*1 */{data: "items", orderable: false},
  /*2 */{data: "addresses"},
  /*3 */{data: "users"},
  /*4 */{data: "actions", orderable: false}
      ],
      "order": [[ 1, "asc" ], [ 0, "asc" ]],
      "oLanguage": { "sZeroRecords": "No suppliers to display for this filter" },
      "dom": "<'row'<'col-sm-10 dataTableToolbar'><'col-sm-2'B>>t<'row dataTableBottomRow'<'col-md-2'i><'col-md-10'p>>",
      "buttons": [
        {
          extend: "excelHtml5",
          title: filename,
          // Only exports columns that are currently visible.  Adjusted by the "Visibility" dropdown and Filtered text.
          exportOptions: {columns: ":visible"},
          className: "dtButtonHide"
        },
        {
          extend: "csv",
          title: filename,
          exportOptions: {columns: ":visible"},
          className: "dtButtonHide"
        },
        {
          extend: "pdfHtml5",
          title: filename,
          orientation: "landscape",
          exportOptions: {columns: ":visible"},
          className: "dtButtonHide"
        },
        {
          extend: "copyHtml5",
          title: filename,
          exportOptions: {columns: ":visible"},
          className: "dtButtonHide"
        }, 
        {
          extend: "print",
          autoPrint: false,
          title: filename,
          exportOptions: {columns: ":visible"},
          customize: function (win) {
              $(win.document.body).find('table').addClass('display').css('font-size', '12px');
              $(win.document.body).find('tr:nth-child(odd) td').each(function(index){
                  $(this).css('background-color','#D0D0D0');
              });
              $(win.document.body).find('h1').css('text-align','center');
          },
          className: "dtButtonHide"
        }
      ],
    });
  
    function dataTableToolbar() {
      return "" +
      "<ul class='nav' style='margin-bottom:10px'>" +
      "  <li>" +
      "    <span class='options-label'>Options:</span>" +
      "  </li>" +
      "  <li>" +
      "    <div class='dataTables_length' id='suppliersDataTable_length'>" +
      "      <select name='suppliersDataTable_length' aria-controls='suppliersDataTable' class='form-control form-control-sm tableLength' title='Number of records to show'>" +
      "        <option value='5'>5</option>" +
      "        <option value='10'>10</option>" +
      "        <option value='25'>25</option>" +
      "        <option value='50'>50</option>" +
      "        <option value='100'>100</option>" +
      "        <option selected='selected' value='-1'>All</option>" +
      "      </select>" +
      "    </div>" +
      "  </li>" +
      "  <li>" +
      "    <div class='tableClickerDiv dropdown'>" +
      "      <button class='btn btn-outline-primary btn-sm dropdown-toggle' type='button' id='tableClickerColvis' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'><i class='fas fa-columns'></i> Columns</button>" +
      "      <div class='dropdown-menu card-shadow' aria-labelledby='tableClickerColvis'>" +
      "        <a class='dropdown-item' id='colvisClicker-0'>Brand Name</a>" +
      "        <a class='dropdown-item' id='colvisClicker-1'>Items</a>" +
      // "        <a class='dropdown-item' id='colvisClicker-XX'>Last 30 Days Shipped</a>" +
      // "        <a class='dropdown-item' id='colvisClicker-XX'>Warehouse Allotment</a>" +
      "        <a class='dropdown-item' id='colvisClicker-2'>Associated Addresses</a>" +
      "        <a class='dropdown-item' id='colvisClicker-3'>Associated Users</a>" +
      "        <a class='dropdown-item' id='colvisClicker-4'>Actions</a>" +
      "      </div>" +
      "    </div>" +
      "  </li>" +
      "  <li>" +
      "    <div class='tableClickerDiv dropdown'>" +
      "      <button class='btn btn-outline-primary btn-sm dropdown-toggle' title='Export Options' type='button' id='tableClickerExports' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'><i class='fas fa-file-export'></i> Export</button>" +
      "      <div class='dropdown-menu' aria-labelledby='tableClickerExports'>" +
      "        <div class='text-primary' style='font-size:11px;padding:0.25rem 1.5rem;'>" +
      "          <i class='fas fa-exclamation-triangle text-danger'></i> Only the currently visible columns & rows will be exported" +
      "        </div>" +
      "        <a class='dropdown-item tableClickerExcel'><i class='fas fa-file-excel file-icon-color'></i> Excel <i class='fas fa-download text-primary'></i></a>" +
      "        <a class='dropdown-item tableClickerCsv'><i class='fas fa-file-csv file-icon-color'></i> CSV <i class='fas fa-download text-primary'></i></a>" +
      "        <a class='dropdown-item tableClickerPdf'><i class='fas fa-file-pdf file-icon-color'></i> PDF <i class='fas fa-download text-primary'></i></a>" +
      "        <a class='dropdown-item tableClickerCopy'><i class='fas fa-copy file-icon-color'></i> Copy</a>" +
      "        <a class='dropdown-item tableClickerPrint'><i class='fas fa-print file-icon-color'></i> Print/Preview</a>" +
      "      </div>" +
      "    </div>" +
      "  </li>" +
      "  <li class='filter-section pt-2' style='width:100%;'>" +
      "    <span class='filter-label'>Filters:</span>" +
      "    <div id='suppliersDataTable_filter' class='dataTables_filter mr-2'>" +
      "      <input id='suppliersTableSearch' class='form-control form-control-sm tableSearch' title='right-click to clear' oncontextmenu='return false' aria-controls='suppliersDataTable' placeholder='Search Entire Table...'>" +
      "    </div>" +
      "    <span id='brand-name-select-input' class='filter-span-input colvisSearch-0'></span>" +
      "    <span id='user-select-input' class='filter-span-input colvisSearch-3'></span>" +
      "  </li>" +
      "</ul>" +
      "";
    }

    table.page.len("-1").draw();

    $("div.dataTableToolbar").html(dataTableToolbar());
  
    $(".tableSearch").keyup(function () {
      table.search($(this).val()).draw();
    });
  
    $(".tableLength").on("change", function () {
      table.page.len($(this).find("option:selected").attr("value")).draw();
    });
  
    function colVisButtons(index) {
      var button = $("#colvisClicker-"+index);
      button.on("click", function(e) {
        e.stopPropagation();
        if (table.column(index).visible() === true) {
          table.column(index).visible(false);
          button.addClass('button-highlight');
          $(".colvisSearch-"+index).hide();
        }
        else {
          table.column(index).visible(true);
          button.removeClass('button-highlight');
          $(".colvisSearch-"+index).show();
        }
      });
    }
    let colVisIndex = -1;
    do { colVisIndex += 1;
         colVisButtons(colVisIndex);
    } while (colVisIndex < 4);
  
    yadcf.init(table,
      [
  
        { column_number : 1, // Brand Name
          filter_type: "text",
          filter_container_id : "brand-name-select-input",
          style_class : "form-control form-control-sm",
          filter_default_label : "Search Brand Name..."
        },
  
        { column_number : 4, // Users
          filter_type: "text",
          filter_container_id : "user-select-input",
          style_class : "form-control form-control-sm",
          filter_default_label : "Search Users..."
        },
  
      ],
      { filters_position : "footer" },
      { externally_triggered : true }
    );
  
  }

//----------------------------------------------------------------------------//
//---------- SUPPLIERS OVERVIEW RELATED --------------------------------------//
//----------------------------------------------------------------------------//

//----------------------------------------------------------------------------//
//---------- SUPPLIERS SHOW RELATED ------------------------------------------//
//----------------------------------------------------------------------------//

  if ($('.supplier_show_div').length > 0) {

    // This facilitates deleting a file via AJAX
    $(".supplier-attachment-delete").on("ajax:success", function() {
      var parent = $(this).closest("li");
      parent.slideUp( "slow", function() { parent.remove() } );
    });
  
    $(document).on("click", ".expandNotesButton", function() {
      var div = $(this).closest(".inbound_shipments_div");
      var truncated = div.find(".truncatedNotes");
      var untruncated = div.find(".untruncatedNotes");
      untruncated.slideDown("slow");
      truncated.slideUp("fast");
      $(this).hide();
      div.find(".collapseNotesButton").show();
    });
  
    $(document).on("click", ".collapseNotesButton", function() {
      var div = $(this).closest(".inbound_shipments_div");
      var truncated = div.find(".truncatedNotes");
      var untruncated = div.find(".untruncatedNotes");
      truncated.slideDown("fast");
      untruncated.slideUp("slow");
      $(this).hide();
      div.find(".expandNotesButton").show();
    });
  
    // Adjustments History Chart functions to show or hide the day/week charts
    $(document).on("click", ".ILSortByWeekButton", function() {
      var div = $(this).closest("#movement_charts");
      var ilw = div.find(".ilw");
      var ild = div.find(".ild");
      ilw.slideDown("slow");
      ild.slideUp("slow");
      $(this).hide();
      div.find(".ILSortByDayButton").show();
    });
  
    $(document).on("click", ".ILSortByDayButton", function() {
      var div = $(this).closest("#movement_charts");
      var ilw = div.find(".ilw");
      var ild = div.find(".ild");
      ild.slideDown("slow");
      ilw.slideUp("slow");
      $(this).hide();
      div.find(".ILSortByWeekButton").show();
    });
  
    $(document).on("click", ".IASortByWeekButton", function() {
      var div = $(this).closest("#movement_charts");
      var iaw = div.find(".iaw");
      var iad = div.find(".iad");
      iaw.slideDown("slow");
      iad.slideUp("slow");
      $(this).hide();
      div.find(".IASortByDayButton").show();
    });
  
    $(document).on("click", ".IASortByDayButton", function() {
      var div = $(this).closest("#movement_charts");
      var iaw = div.find(".iaw");
      var iad = div.find(".iad");
      iad.slideDown("slow");
      iaw.slideUp("slow");
      $(this).hide();
      div.find(".IASortByWeekButton").show();
    });

    globalObjects.genericDataTable('customerOrders');
    globalObjects.genericDataTable('transferOrders');
    globalObjects.genericDataTable('otherOrders');
    globalObjects.genericDataTable('items');
    globalObjects.genericDataTable('inventoryAdjustments');
    globalObjects.genericDataTable('revenueHistory');
    globalObjects.genericDataTable('currentItemCosts');
    globalObjects.genericDataTable('historicalItemCosts');
    globalObjects.genericDataTable('users');

  }

//----------------------------------------------------------------------------//
//---------- SUPPLIERS NEW SHOW RELATED --------------------------------------//
//----------------------------------------------------------------------------//

  if ($('.supplier_new_show_div').length > 0) {

    $(document).on('click', '.tab_collapse.up', function() {
      var div = $(this).closest('.card-body');
      div.find('.header-not-collapsed').hide();
      div.find('.header-collapsed').show();
      div.find('.tab_collapse_body').slideUp('fast');
      $(this).hide();
      $(this).next('.tab_collapse.down').show();
      $('.expand_all_tabs').show();
      $('.hide_all_tabs').hide();
    });

    $(document).on('click', '.tab_collapse.down', function() {
      $(this).closest('.card-body').find('.tab_collapse_body').slideDown('fast');
      $(this).hide();
      $(this).prev('.tab_collapse.up').show();
      $('.expand_all_tabs').hide();
      $('.hide_all_tabs').show();
    });

    $(document).on('click', '.header-collapsed', function() {
      var div = $(this).closest('.card-body');
      div.find('.tab_collapse.down').click();
      div.find('.header-not-collapsed').show();
      $(this).hide();
    });

    $(document).on('click', '.hide_all_tabs', function() {
      $('.tab_collapse.up').click();
      $(this).hide();
      $('.expand_all_tabs').show();
    });

    $(document).on('click', '.expand_all_tabs', function() {
      $('.tab_collapse.down').click();
      $(this).hide();
      $('.hide_all_tabs').show();
    });

    $(document).on('order_tables_loaded', function() {
      itemShowObjects.dataTable('customer_orders');
      itemShowObjects.dataTable('transfer_orders');
      itemShowObjects.dataTable('other_orders');
      itemShowObjects.dataTable('future_orders');
      
      var customer_orders_count = $('.customer_orders_count').data('order_count');
      var transfer_orders_count = $('.transfer_orders_count').data('order_count');
      var other_orders_count = $('.other_orders_count').data('order_count');
      var future_orders_count = $('.future_orders_count').data('order_count');
      
      $('.order_pill_count.customer_orders').text(customer_orders_count || '0');
      $('.order_pill_count.transfer_orders').text(transfer_orders_count || '0');
      $('.order_pill_count.other_orders').text(other_orders_count || '0');
      $('.order_pill_count.future_orders').text(future_orders_count || '0');
      
    });

    $(document).on('inventory_adjustments_loaded', function() {
      itemShowObjects.dataTable('inventory_adjustments');
      itemShowObjects.dataTable('inbound_shipments');
    });

    $('#retailer_select').selectize({
      placeholder: 'Retailers...'
    });

    // Collects the values from Retailer select and converts them to a simple array for query parameter filtering
    $(document).on('change', '#retailer_select', function() {
      $('#retailers').val($(this).find('option:selected').toArray().map(item => item.value).join());
    });

  }

});
