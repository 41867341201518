/*global $*/

import 'stylesheets/order_uploads-global.scss';

$(document).on("turbolinks:load", function() {

   if ($('.order_upload_batch-validate.edit').length > 0) {

     $('.orderUploadSupplierSearch').select2({
        selectOnClose: true,
        allowClear: true,
        placeholder: 'Select Supplier...',
        ajax: {
          url: '/json_dropdown_suppliers.json',
          dataType: 'json',
          type: 'GET',
          processResults: function (data, params) {
            params.page = data.page || 1;
            return {
              results: $.map(data.items, function (item) {
                return {
                  text: item.brand_name,
                  id: item.id
                };
              }),
              pagination: {
                more: data.current_page
              }
            };
          },
          cache: true
        }
      });
  
    // This populates the 'Supplier Search' dropdown on the 'Edit' view
    var supplierSelect = $('.orderUploadSupplierSearch');
    $.ajax({
        url: '/suppliers/' + supplierSelect.data('selected') + '.json',
        dataType: 'json',
        type: 'GET',
    }).then(function (data) {
        var option = new Option(data.supplierSelect.brand_name, data.supplierSelect.id, true, true);
        supplierSelect.append(option).trigger('change');
        supplierSelect.trigger({
          type: 'select2:select',
          params: {
            data: data
          }
        });
    });
  
      $('.orderUploadRetailerSearch').select2({
        selectOnClose: true,
        allowClear: true,
        placeholder: 'Select Retailer...',
        ajax: {
          url: '/json_dropdown_retailers.json',
          dataType: 'json',
          type: 'GET',
          processResults: function (data, params) {
            params.page = data.page || 1;
            return {
              results: $.map(data.items, function (item) {
                return {
                  text: item.name,
                  id: item.id
                };
              }),
              pagination: {
                more: data.current_page
              }
            };
          },
          cache: true
        }
      });
  
    // This populates the 'Retailer Search' dropdown on the 'Edit' view
    var retailerSelect = $('.orderUploadRetailerSearch');
    $.ajax({
        url: '/retailers/' + retailerSelect.data('selected') + '.json',
        dataType: 'json',
        type: 'GET',
    }).then(function (data) {
        var option = new Option(data.retailerSelect.name, data.retailerSelect.id, true, true);
        retailerSelect.append(option).trigger('change');
        retailerSelect.trigger({
          type: 'select2:select',
          params: {
            data: data
          }
        });
    });

    $(document).on('change keyup', '.orderUploadSupplierSearch', function() {
      var supplier = $(this).next().find('.select2-selection__rendered').text().substring(1);
      var retailer = $(this).closest('.edit_attachment_fields').find('.orderUploadRetailerSearch').next().find('.select2-selection__rendered').text().substring(1);
      $(this).closest('.edit_attachment_fields').find('.order_upload_title').val(supplier + ' orders for ' + retailer);
    });
  
    $(document).on('change keyup', '.orderUploadRetailer', function() {
      var retailer = $(this).next().find('.select2-selection__rendered').text().substring(1);
      var supplier = $(this).closest('.edit_attachment_fields').find('.orderUploadSupplierSearch').next().find('.select2-selection__rendered').text().substring(1);
      $(this).closest('.edit_attachment_fields').find('.order_upload_title').val(supplier + ' orders for ' + retailer);
    });
  
    $(document).on('change keyup', '.order_upload_title', function() {
      var new_description = $(this).val();
      $(this).closest('.edit-attachment-fields').find('.attachment-title').text(new_description);
    });

   }

//----------------------------------------------------------------------------//
//---------- ORDER UPLOAD BATCHES FORM RELATED -------------------------------//
//----------------------------------------------------------------------------//

  if ($('.order_upload_batch-validate').length > 0) {

    $(".add_child").hide();
    $('label.required').append('<strong> *</strong>'); // adds a "required" star next to each Inputs label
    $('label.required-s span').append('<strong> *</strong>'); // adds a "required" star next to each Selects label
  
  
    $(document).on("click", ".expandNotesButton", function() {
      var div = $(this).closest(".archived_order_upload");
      var truncated = div.find(".truncatedNotes");
      var untruncated = div.find(".untruncatedNotes");
      untruncated.slideDown("slow");
      truncated.slideUp("fast");
      $(this).hide();
      div.find(".collapseNotesButton").show();
    });
  
    $(document).on("click", ".collapseNotesButton", function() {
      var div = $(this).closest(".archived_order_upload");
      var truncated = div.find(".truncatedNotes");
      var untruncated = div.find(".untruncatedNotes");
      truncated.slideDown("fast");
      untruncated.slideUp("slow");
      $(this).hide();
      div.find(".expandNotesButton").show();
    });
  
  
  
  
    $(".order_upload_batch-validate").validate({
      ignore: ".ignore",
  		rules: {
  		// 	"product[model_number]": {required: true, maxlength: 80},
  		// 	"product[supplier_part_number]": {required: true, maxlength: 80},
  		},
  		messages: {
  		// 	"product[supplier_part_number]": "If the Manufacturer Part Number & the Model Number are the same, enter the same SKU in both fields.",
  		// 	"product[model_number]": "If the Model Number & the Manufacturer Part Number are the same, enter the same SKU in both fields."
  		},
      onfocusout: function(element) {$(element).valid()},
      focusCleanup: true,
      errorClass: "myErrorClass",
      errorPlacement: function (error, element) {
        error.insertAfter(element.parent());
      },
      highlight: function (element, errorClass, validClass) {
        var elem = $(element);
        var tabId = elem.closest(".tab-pane").attr("aria-labelledby");
        if (elem.hasClass("select2-hidden-accessible")) {
          elem.parent().find(".select2-selection").addClass(errorClass);
          elem.closest(".card-body").find(".nav-link[id='"+tabId+"']").addClass(errorClass);
        } 
        else {
          elem.addClass(errorClass);
          elem.closest(".card-body").find(".nav-link[id='"+tabId+"']").addClass(errorClass);
        }
      },    
      unhighlight: function (element, errorClass, validClass) {
        var elem = $(element);
        var tabId = elem.closest(".tab-pane").attr("aria-labelledby");
        if (elem.hasClass("select2-hidden-accessible")) {
          elem.parent().find(".select2-selection").removeClass(errorClass);
          elem.parent().next("label").remove();
          elem.closest(".card-body").find(".nav-link[id='"+tabId+"']").removeClass(errorClass);
        }
        else {
          elem.removeClass(errorClass);
          elem.parent().next("label").remove();
          elem.closest(".card-body").find(".nav-link[id='"+tabId+"']").removeClass(errorClass);
        }
      },
  		invalidHandler: function(e, validator) {
  			var errors = validator.numberOfInvalids();
  			if (errors) {
  				var message = errors == 1
  					? 'You missed 1 required field. Check below tabs for highlighted fields'
  					: 'You missed ' + errors + ' required fields.  Check below tabs for highlighted fields';
  				$("div.validatorErrorList span").html(message);
  				$("div.validatorErrorList").slideDown("slow");
  			}
  			else {
  				$("div.validatorErrorList").slideUp("slow");
  			}
  		}
    });
  
    $("#orderUploadBatchFirstSubmit").on("click", function(e) {
      e.preventDefault();
      if ($(".order_upload_batch-validate").valid()) {
        $("#jstemplates_order_uploads").html("");
        $(".loading-overlay").show();
        $("#orderUploadBatchSubmit").click();
      }
      else {
        return false;
      }
    });
  
    // on first focus (bubbles up to document), open the menu
    $(document).on('focus', '.select2-selection.select2-selection--single', function (e) {
      $(this).closest(".select2-container").siblings('select:enabled').select2('open');
    });
  
    // steal focus during close - only capture once and stop propogation
    $('select.select2').on('select2:closing', function (e) {
      $(e.target).data("select2").$selection.one('focus focusin', function (e) {
        e.stopPropagation();
      });
    });
  
  
  
    $(document).on("click", ".order_uploads_help_button", function() {
      $(this).hide();
      $(".order_uploads_help_button_shown").show();
      $(".order_uploads_help_alert").slideDown("slow");
    });
  
    $(document).on("click", ".order_uploads_help_button_shown", function() {
      $(this).hide();
      $(".order_uploads_help_button").show();
      $(".order_uploads_help_alert").slideUp("slow");
    });
  
  
  //---- Attachments Related -----------------------------------------------------//
  
    // Child Fields Template Loader inherited from products_helper.rb for Associated Attachments
    $(document).on('click', 'a.add_child_order_uploads', function() {
      var association, new_id, regexp, template, fields;
      association = $(this).attr('data-association');
      template = $('#' + association + '_fields_template').html();
      regexp = new RegExp('new_' + association, 'g');
      new_id = (new Date).getTime();
      $(this).parent().before(template.replace(regexp, new_id));
      fields = $(this).parent().prev(".fields_order_uploads");
      // The "jstemplates" hidden field needs the ".ignore" class to ensure it
      // doesn't get picked up by Jquery Validator.  This line removes it.
      fields.find("input").removeClass("ignore");
      fields.find(".orderUploadSupplierSearch").removeClass("ignore");
      fields.find(".orderUploadRetailerSearch").removeClass("ignore");
      fields.find("input[name$='[title]']").each(function() {
        // rules for nested file fields need to be generated after the object is created
        $(this).rules("add", {
          messages: {
            required: `A description is required.`
          }
        });
      });
  
      // Fixes for Supplier Dropdown --->>>>
      fields.find('.orderUploadSupplierSearch').select2({
        selectOnClose: true,
        allowClear: true,
        placeholder: "Select Supplier...",
        ajax: {
          url: "/json_dropdown_suppliers.json",
          dataType: "json",
          type: "GET",
          processResults: function (data, params) {
            params.page = data.page || 1;
            return {
              results: $.map(data.items, function (item) {
                return {
                  text: item.brand_name,
                  id: item.id
                };
              }),
              pagination: {
                more: data.current_page
              }
            };
          },
          cache: true
        }
      });
  
      // Fixes for Retailer Dropdown --->>>>
      fields.find('.orderUploadRetailerSearch').select2({
        selectOnClose: true,
        allowClear: true,
        placeholder: "Select Retailer...",
        ajax: {
          url: "/json_dropdown_retailers.json",
          dataType: "json",
          type: "GET",
          processResults: function (data, params) {
            params.page = data.page || 1;
            return {
              results: $.map(data.items, function (item) {
                return {
                  text: item.name,
                  id: item.id
                };
              }),
              pagination: {
                more: data.current_page
              }
            };
          },
          cache: true
        }
      });
  
      $(".add_upload_notes_button").on("click", function() {
        $(this).closest(".fields_order_uploads").find(".upload-notes-div").slideDown("slow");
        $(this).slideUp("fast");
      });
  
      // monkeypatch to fix issue of search field not being selected on open.  https://github.com/select2/select2/issues/2311#issuecomment-822473913
      $(document).on('select2:open', e => {
        const id = e.target.id;
        const target = document.querySelector(`[aria-controls=select2-${id}-results]`);
        target.focus();
      });
  
      // on first focus (bubbles up to document), open the menu
      $(document).on('focus', '.select2-selection.select2-selection--single', function (e) {
        $(this).closest(".select2-container").siblings('select:enabled').select2('open');
      });
    
      // steal focus during close - only capture once and stop propogation
      $('select.select2').on('select2:closing', function (e) {
        $(e.target).data("select2").$selection.one('focus focusin', function (e) {
          e.stopPropagation();
        });
      });
  
  
      // ActionText Trix ID's need to be manually created, otherwise it will duplicate
      // itself as "trix-toolbar-1" on all instances of a Trix Editor
      fields.find("trix-toolbar").attr("id", "trix-toolbar-" + new_id);
      fields.find("trix-editor").attr("trix-id", new_id).attr("toolbar", "trix-toolbar-" + new_id);
      fields.hide();
      fields.slideDown("fast");
      return false;
    });
  
    $(document).on("click", 'a.remove_child_order_uploads', function() {
      var tr = $(".fields_order_uploads").length;
      if (tr == 2) {
        $("#orderUploadBatchFirstSubmit").hide();
      }
      var hidden_field = $(this).prev('input[type=hidden]')[0];
      var fields = $(this).parents('.fields_order_uploads');
      if(hidden_field) {
        hidden_field.value = '1';
      }
      $(this).parents('.fields_order_uploads').slideUp("slow", function() {
        fields.remove();
      });
      return false;
    });
  
    $(document).on("change keyup", ".orderUploadSupplierSearch", function() {
      var supplier = $(this).next().find(".select2-selection__rendered").text().substring(1);
      var retailer = $(this).closest(".fields_order_uploads").find(".orderUploadRetailerSearch").next().find(".select2-selection__rendered").text().substring(1);
      $(this).closest(".fields_order_uploads").find(".order_upload_title").val(supplier + " orders for " + retailer).attr("readonly", false);
    });
  
    $(document).on("change keyup", ".orderUploadRetailerSearch", function() {
      var retailer = $(this).next().find(".select2-selection__rendered").text().substring(1);
      var supplier = $(this).closest(".fields_order_uploads").find(".orderUploadSupplierSearch").next().find(".select2-selection__rendered").text().substring(1);
      $(this).closest(".fields_order_uploads").find(".order_upload_title").val(supplier + " orders for " + retailer).attr("readonly", false);
    });
  
  
    $(".add_order_upload_button").on("click", function() {
      $(".add_child_order_uploads").click();
      $("#orderUploadBatchFirstSubmit").show();
    });
    
    // This facilitates deleting a file via AJAX
    $(".order-upload-attachment-delete").on("ajax:success", function() {
      var parent = $(this).closest("li");
      parent.slideUp( "slow", function() { parent.remove() } );
    });
  
    $(".order-upload-archive").on("ajax:success", function() {
      var parent = $(this).closest(".new_order_upload");
      parent.slideUp( "slow", function() { parent.remove() } );
    });
  
    $(".order-upload-unarchive").on("ajax:success", function() {
      var parent = $(this).closest(".archived_order_upload");
      parent.slideUp( "slow", function() { parent.remove() } );
    });
  
    $("#loadOrderUploadModal").on("click", function() {
      $(".order-upload-modal").modal();
      $(".add_order_upload_button").click();
    });
  
    // Monkeypatch so all select2 fields actually clear to 'nil' when the 'X' is clicked
    $(document).on('select2:clear', '.select2-hidden-accessible', function (e) {
      $(this).append('<option value="" selected="selected"></option>');
    });
  
    // monkeypatch to fix issue of search field not being selected on open.  https://github.com/select2/select2/issues/2311#issuecomment-822473913
    $(document).on('select2:open', e => {
      const id = e.target.id;
      const target = document.querySelector(`[aria-controls=select2-${id}-results]`);
      target.focus();
    });

  }

//----------------------------------------------------------------------------//
//---------- ORDER UPLOAD BATCHES SHOW RELATED -------------------------------//
//----------------------------------------------------------------------------//

  if ($('.order_upload_show_div').length > 0) {

    $(document).on("click", ".expandNotesButton", function() {
      var div = $(this).closest(".order_uploads_div");
      var truncated = div.find(".truncatedNotes");
      var untruncated = div.find(".untruncatedNotes");
      untruncated.slideDown("slow");
      truncated.slideUp("fast");
      $(this).hide();
      div.find(".collapseNotesButton").show();
    });
  
    $(document).on("click", ".collapseNotesButton", function() {
      var div = $(this).closest(".order_uploads_div");
      var truncated = div.find(".truncatedNotes");
      var untruncated = div.find(".untruncatedNotes");
      truncated.slideDown("fast");
      untruncated.slideUp("slow");
      $(this).hide();
      div.find(".expandNotesButton").show();
    });
  
    // This facilitates deleting a file via AJAX
    $(".order-attachment-delete").on("ajax:success", function() {
      var parent = $(this).closest("li");
      parent.slideUp( "slow", function() { parent.remove() } );
    });

  }

//----------------------------------------------------------------------------//
//---------- ORDER UPLOADS INDEX RELATED -------------------------------------//
//----------------------------------------------------------------------------//

  if ($('.order_uploads_index_div').length > 0) {
    
    $(".loading-overlay").show();

    var MyDate = new Date();
    var date;
    MyDate.setDate(MyDate.getDate());
    date = MyDate.getFullYear() + "-" + ("0" + (MyDate.getMonth() + 1)).slice(-2) + "-" + ("0" + MyDate.getDate()).slice(-2);
    var filename = "Order Uploads, " + date + " Export";
    var table = $("#orderUploadsDataTable").DataTable({
      "retrieve": true,
      "processing": true,
      "serverSide": true,
      "initComplete": function( settings, json ) {
        $('.loading-overlay').hide();
      },
      "ajax": {
        "url": $("#orderUploadsDataTable").data("source")
      },
      "columns": [
  /*0 */{data: "batch_id"},
  /*1 */{data: "id"},
  /*2 */{data: "upload_date"},
  /*3 */{data: "uploaded_by"},
  /*4 */{data: "supplier"},
  /*5 */{data: "retailer"},
  /*6 */{data: "title"},
  /*7 */{data: "files", orderable: false},
  // /*8 */{data: "notes", orderable: false},
  /*9 */{data: "updated_by"},
  /*10*/{data: "archived"},
  /*11*/{data: "actions", orderable: false}
      ],
      "order": [[ 0, "desc" ]],
      "oLanguage": { "sZeroRecords": "No uploads to display for this filter" },
      "dom": "<'row'<'col-sm-10 dataTableToolbar'><'col-sm-2'B>>t<'row dataTableBottomRow'<'col-md-2'i><'col-md-10'p>>",
      "buttons": [
        {
          extend: "excelHtml5",
          title: filename,
          // Only exports columns that are currently visible.  Adjusted by the "Visibility" dropdown and Filtered text.
          exportOptions: {columns: ":visible"},
          className: "dtButtonHide"
        },
        {
          extend: "csv",
          title: filename,
          exportOptions: {columns: ":visible"},
          className: "dtButtonHide"
        },
        {
          extend: "pdfHtml5",
          title: filename,
          orientation: "landscape",
          exportOptions: {columns: ":visible"},
          className: "dtButtonHide"
        },
        {
          extend: "copyHtml5",
          title: filename,
          exportOptions: {columns: ":visible"},
          className: "dtButtonHide"
        }, 
        {
          extend: "print",
          autoPrint: false,
          title: filename,
          exportOptions: {columns: ":visible"},
          customize: function (win) {
              $(win.document.body).find('table').addClass('display').css('font-size', '12px');
              $(win.document.body).find('tr:nth-child(odd) td').each(function(index){
                  $(this).css('background-color','#D0D0D0');
              });
              $(win.document.body).find('h1').css('text-align','center');
          },
          className: "dtButtonHide"
        }
      ]
    });
  
    function dataTableToolbar() {
      return "" +
      "<ul class='nav' style='margin-bottom:10px'>" +
      "  <li>" +
      "    <span class='options-label'>Options:</span>" +
      "  </li>" +
      "  <li>" +
      "    <div class='dataTables_length' id='orderUploadsDataTable_length'>" +
      "      <select name='orderUploadsDataTable_length' aria-controls='orderUploadsDataTable' id='orderUploadsTableLength' class='form-control form-control-sm tableLength' title='Number of records to show'>" +
      "        <option value='5'>5</option>" +
      "        <option value='10'>10</option>" +
      "        <option selected='selected' value='25'>25</option>" +
      "        <option value='50'>50</option>" +
      "        <option value='100'>100</option>" +
      "        <option value='250'>250</option>" +
      "      </select>" +
      "    </div>" +
      "  </li>" +
      // "  <li>" +
      // "    <div class='tableClickerDiv dropdown'>" +
      // "      <button class='btn btn-outline-primary btn-sm dropdown-toggle' title='Column visibility options' type='button' id='tableClickerColvis' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'><i class='fas fa-columns'></i> Columns</button>" +
      // "      <div class='dropdown-menu card-shadow' aria-labelledby='tableClickerColvis'>" +
      // "        <a class='dropdown-item' id='colvisClicker-0'>Upload ID</a>" +
      // "        <a class='dropdown-item' id='colvisClicker-1'>Batch ID</a>" +
      // "        <a class='dropdown-item' id='colvisClicker-2'>Title</a>" +
      // "        <a class='dropdown-item' id='colvisClicker-3'>Supplier</a>" +
      // "        <a class='dropdown-item' id='colvisClicker-4'>Retailer</a>" +
      // "        <a class='dropdown-item' id='colvisClicker-5'>Files</a>" +
      // "        <a class='dropdown-item' id='colvisClicker-6'>Uploaded By</a>" +
      // "        <a class='dropdown-item' id='colvisClicker-7'>Notes</a>" +
      // "        <a class='dropdown-item' id='colvisClicker-8'>Updated By</a>" +
      // "        <a class='dropdown-item' id='colvisClicker-9'>Archived</a>" +
      // "        <a class='dropdown-item' id='colvisClicker-10'>Actions</a>" +
      // "      </div>" +
      // "    </div>" +
      // "  </li>" +
      "  <li>" +
      "    <div class='tableClickerDiv dropdown'>" +
      "      <button class='btn btn-outline-primary btn-sm dropdown-toggle' title='Export Options' type='button' id='tableClickerExports' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'><i class='fas fa-file-export'></i> Export</button>" +
      "      <div class='dropdown-menu' aria-labelledby='tableClickerExports'>" +
      "        <div class='text-primary' style='font-size:11px;padding:0.25rem 1.5rem;'>" +
      "          <i class='fas fa-exclamation-triangle text-danger'></i> Only the currently visible columns & rows will be exported" +
      "        </div>" +
      "        <a class='dropdown-item' id='tableClickerExcel'><i class='fas fa-file-excel file-icon-color'></i> Excel <i class='fas fa-download text-primary'></i></a>" +
      "        <a class='dropdown-item' id='tableClickerCsv'><i class='fas fa-file-csv file-icon-color'></i> CSV <i class='fas fa-download text-primary'></i></a>" +
      "        <a class='dropdown-item' id='tableClickerPdf'><i class='fas fa-file-pdf file-icon-color'></i> PDF <i class='fas fa-download text-primary'></i></a>" +
      "        <a class='dropdown-item' id='tableClickerCopy'><i class='fas fa-copy file-icon-color'></i> Copy</a>" +
      "        <a class='dropdown-item' id='tableClickerPrint'><i class='fas fa-print file-icon-color'></i> Print/Preview</a>" +
      "      </div>" +
      "    </div>" +
      "  </li>" +
      "  <li class='filter-section' style='width:100%;'>" +
      "    <span class='filter-label'>Filters:</span>" +
      "    <div id='orderUploadsDataTable_filter' class='dataTables_filter'>" +
      "      <input id='orderUploadsTableSearch' class='form-control form-control-sm tableSearch' title='right-click to clear' oncontextmenu='return false' aria-controls='orderUploadsDataTable' placeholder='Search Entire Table...'>" +
      "    </div>" +
      // "    <span id='sku-select-input' class='filter-span-input'></span>" +
      // "    <span id='description-select-input' class='filter-span-input'></span>" +
      // "    <span id='brand-select-input' class='filter-span-input'></span>" +
      // "    <span id='adjustment-type-dropdown' class='filter-span-select'></span>" +
      // "    <span id='adjustment-date-range' class='filter-span-range'></span>" +
      "  </li>" +
      "</ul>" +
      "";
    }

    table.page.len(25).draw();

    $("div.dataTableToolbar").html(dataTableToolbar());
  
    // clears the global Table Search column on right-click
    $(document).on('mousedown', '.tableSearch', function() {
      if (event.which === 3) {
        $(this).val('');
        table.search("").draw();
      }
    });

    $(".tableSearch").keyup(function () {
      table.search($(this).val()).draw();
    });
  
    $(".tableLength").on("change", function () {
      table.page.len($(this).find("option:selected").attr("value")).draw();
    });
  
    $(".dtButtonHide").hide(); // Hides default Colvis & Export buttons
    $("#tableClickerExcel").on("click", function() {
      $(".buttons-excel").click();
    });
    $("#tableClickerCsv").on("click", function() {
      $(".buttons-csv").click();
    });
    $("#tableClickerPdf").on("click", function() {
      $(".buttons-pdf").click();
    });
    $("#tableClickerCopy").on("click", function() {
      $(".buttons-copy").click();
    });
    $("#tableClickerPrint").on("click", function() {
      $(".buttons-print").click();
    });
  
    $(document).on("click", ".notesModalButton", function() {
      $(this).closest("td").find(".notes-modal").modal();
    });
  
  
    // This facilitates deleting a file via AJAX
    $(".order-upload-delete").on("ajax:success", function() {
      var parent = $(this).closest("tr");
      parent.addClass("hidden");
    });
  
  }

});