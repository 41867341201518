/*global $*/
/*global globalObjects*/

$(document).on("turbolinks:load", function() {

//----------------------------------------------------------------------------//
//---------- CARRIERS FORM RELATED -------------------------------------------//
//----------------------------------------------------------------------------//

  if ($('.carrier-validate').length > 0) {

    $('label.required').append('<strong> *</strong>'); // adds a "required" star next to each Inputs label
  
    $(".carrier-validate").validate({
      ignore: ".ignore",
      onkeyup: function(element) {$(element).valid()},
      errorClass: "myErrorClass",
      errorPlacement: function (error, element) {
        error.insertAfter(element.parent());
      },
      highlight: function (element, errorClass, validClass) {
        $(element).addClass(errorClass);
      },    
      unhighlight: function (element, errorClass, validClass) {
        $(element).removeClass(errorClass);
        $(element).parent().next("label").remove();
      },
  		invalidHandler: function(e, validator) {
  			var errors = validator.numberOfInvalids();
  			if (errors) {
  				var message = errors == 1
  					? 'You missed 1 required field. It has been highlighted above'
  					: 'You missed ' + errors + ' required fields.  They have been highlighted above';
  				$("div.validatorErrorList span").html(message);
  				$("div.validatorErrorList").show();
  			}
  			else {
  				$("div.validatorErrorList").hide();
  			}
  		}
    });
  
    $(".carrier-type-select").select2({
      tags: true,
      placeholder: "Carrier Type..."
    }).on("change", function() {
        $(this).valid();
      });
  
    // Monkeypatch so all select2 fields actually clear to 'nil' when the 'X' is clicked
    $(document).on('select2:clear', '.select2-hidden-accessible', function (e) {
      $(this).append('<option value="" selected="selected"></option>');
    });
  
    $("#carrierFirstSubmit").on("click", function(e) {
      e.preventDefault();
      if ($(".carrier-validate").valid()) {
        $("#carrierSubmit").click();
      }
      else {
        return false;
      }
    });
  
    // This facilitates deleting the attached image via AJAX
    $(".carrier-image-attachment-delete").on("ajax:success", function() {
      var parent = $(this).closest(".image-body").find("img");
      parent.slideUp( "slow", function() { parent.remove() } );
      $(this).slideUp( "slow", function() { $(this).remove() } );
    });

  }

//----------------------------------------------------------------------------//
//---------- CARRIERS INDEX RELATED ------------------------------------------//
//----------------------------------------------------------------------------//

//----------------------------------------------------------------------------//
//---------- CARRIERS SHOW RELATED -------------------------------------------//
//----------------------------------------------------------------------------//

  if ($('.carrier_show_div').length > 0) {

    globalObjects.genericDataTable('customerOrders');
    globalObjects.genericDataTable('transferOrders');
    globalObjects.genericDataTable('otherOrders');

  }

});
