/*global $*/
/*global yadcf*/

import 'stylesheets/item_availabilities-global.scss';

$(document).on("turbolinks:load", function() {

//----------------------------------------------------------------------------//
//---------- ITEM AVAILABILITIES FORM RELATED --------------------------------//
//----------------------------------------------------------------------------//

  if ($('.item_availability-validate').length > 0) {

    $('label.required').append('<strong> *</strong>'); // adds a "required" star next to each Inputs label
    $('label.required-s span').append('<strong> *</strong>'); // adds a "required" star next to each Selects label
  
    $('.itemAvailabilityRetailerSearch').select2({
      selectOnClose: true,
      allowClear: true,
      placeholder: "Select Retailer...",
      ajax: {
        url: "/json_dropdown_retailers.json",
        dataType: "json",
        type: "GET",
        processResults: function (data, params) {
          params.page = data.page || 1;
          return {
            results: $.map(data.items, function (item) {
              return {
                text: item.name,
                id: item.id
              };
            }),
            pagination: {
              more: data.current_page
            }
          };
        },
        cache: true
      }
    });
  
    $('.itemAvailabilityProductSearch').select2({
      selectOnClose: true,
      allowClear: true,
      placeholder: "Select Item...",
      ajax: {
        url: "/json_dropdown_products_all.json",
        dataType: "json",
        type: "GET",
        processResults: function (data, params) {
          params.page = data.page || 1;
          return {
            results: $.map(data.items, function (item) {
              return {
                text: item.sku + " | " + item.desc,
                id: item.id,
              };
            }),
            pagination: {
              more: data.current_page
            }
          };
        },
        cache: true
      },
    });
  
    $(".availability-status").select2({
      selectOnClose: true,
      allowClear: true,
      placeholder: "Availability Status..."
    });
    
    $(".item_availability-validate").validate({
      ignore: ".ignore",
  		rules: {
  		// 	"product[model_number]": {required: true, maxlength: 80},
  		// 	"product[supplier_part_number]": {required: true, maxlength: 80},
  		},
  		messages: {
  		// 	"inbound_shipment[po_number]": "If no PO Number is available, enter 'N/A' into the field.",
  		// 	"product[model_number]": "If the Model Number & the Manufacturer Part Number are the same, enter the same SKU in both fields."
  		},
      onfocusout: function(element) {$(element).valid()},
      focusCleanup: true,
      errorClass: "myErrorClass",
      errorPlacement: function (error, element) {
        error.insertAfter(element.parent());
      },
      highlight: function (element, errorClass, validClass) {
        var elem = $(element);
        var tabId = elem.closest(".tab-pane").attr("aria-labelledby");
        if (elem.hasClass("select2-hidden-accessible")) {
          elem.parent().find(".select2-selection").addClass(errorClass);
          elem.closest(".card-body").find(".nav-link[id='"+tabId+"']").addClass(errorClass);
        } 
        else {
          elem.addClass(errorClass);
          elem.closest(".card-body").find(".nav-link[id='"+tabId+"']").addClass(errorClass);
        }
      },    
      unhighlight: function (element, errorClass, validClass) {
        var elem = $(element);
        var tabId = elem.closest(".tab-pane").attr("aria-labelledby");
        if (elem.hasClass("select2-hidden-accessible")) {
          elem.parent().find(".select2-selection").removeClass(errorClass);
          elem.parent().next("label").remove();
          elem.closest(".card-body").find(".nav-link[id='"+tabId+"']").removeClass(errorClass);
        }
        else {
          elem.removeClass(errorClass);
          elem.parent().next("label").remove();
          elem.closest(".card-body").find(".nav-link[id='"+tabId+"']").removeClass(errorClass);
        }
      },
  		invalidHandler: function(e, validator) {
  			var errors = validator.numberOfInvalids();
  			if (errors) {
  				var message = errors == 1
  					? 'You missed 1 required field. Check below tabs for highlighted fields'
  					: 'You missed ' + errors + ' required fields.  Check below tabs for highlighted fields';
  				$("div.validatorErrorList span").html(message);
  				$("div.validatorErrorList").slideDown("slow");
  			}
  			else {
  				$("div.validatorErrorList").slideUp("slow");
  			}
  		}
    });
  
    $("#itemAvailabilityFirstSubmit").on("click", function(e) {
      e.preventDefault();
      if ($(".item_availability-validate").valid()) {
        $("#itemAvailabilitySubmit").click();
      }
      else {
        return false;
      }
    });
  
    // on first focus (bubbles up to document), open the menu
    $(document).on('focus', '.select2-selection.select2-selection--single', function (e) {
      $(this).closest(".select2-container").siblings('select:enabled').select2('open');
    });
  
    function pickSelect2OptionOnTab() {
      let $select;
      let optionSelected;
      let select2Closing = false;
  
      $('select').on('select2:closing', function(event) {
        select2Closing = true;
        $select = $(event.target);
        optionSelected = $('.select2-results__option--highlighted').text();
        setTimeout(function() {
          select2Closing = false;
        }, 1);
      });
  
      $(document).bind('keydown', function(event) {
        if (event.key === 'Tab' && select2Closing) {
          const val = $select.find('option').filter(function() {
            return $(this).text() === optionSelected;
          }).first().prop('value');
          $select.val(val);
          $select.trigger('change');
        }
      });
    }
  
    // monkeypatch to fix issue of search field not being selected on open.  https://github.com/select2/select2/issues/2311#issuecomment-822473913
    $(document).on('select2:open', e => {
      const id = e.target.id;
      const target = document.querySelector(`[aria-controls=select2-${id}-results]`);
      target.focus();
    });
  
  }

//----------------------------------------------------------------------------//
//---------- ITEM AVAILABILITIES INDEX RELATED -------------------------------//
//----------------------------------------------------------------------------//

  if ($('.item_availabilities_index_div').length > 0) {
  
    var MyDate = new Date();
    var date;
    MyDate.setDate(MyDate.getDate());
    date = MyDate.getFullYear() + "-" + ("0" + (MyDate.getMonth() + 1)).slice(-2) + "-" + ("0" + MyDate.getDate()).slice(-2);
    var filename = "Item Availabilities, " + date + " Export";
    var table = $("#itemAvailabilitiesDataTable").DataTable({
      "retrieve": true,
      "processing": true,
      "serverSide": true,
      // "stateSave": true,
      // "stateDuration": "-1",
      "ajax": { "url": $("#itemAvailabilitiesDataTable").data("source") },
      "columns": [ // global search is disabled due to bugged columns below
  /*0 */{data: "record_id", searchable: false, visible: false},
  /*1 */{data: "retailer"},
  /*2 */{data: "srs_sku"},
  /*3 */{data: "srs_inventory"},
  /*4 */{data: "retailer_sku"},
  /*5 */{data: "current_cost"},
  /*6 */{data: "suggested_retail"},
  /*7 */{data: "site_link", orderable: false},
  /*8 */{data: "active"},
  /*9 */{data: "status"},
  /*10*/{data: "inv_qty", searchable: false},
  /*11*/{data: "inv_last_updated"},
  /*12*/{data: "next_avail_qty", searchable: false},
  /*13*/{data: "next_avail_date"},
  /*14*/{data: "actions", orderable: false},
      ], // Order Date, Supplier, Retailer, Order Status, Order ID
      "order": [[1,"desc"]],
  
      "oLanguage": { "sZeroRecords": "No records to display for this filter" },
      "dom": "<'row'<'col-sm-10 dataTableToolbar'><'col-sm-2'B>>t<'row dataTableBottomRow'<'col-md-2'i><'col-md-10'p>>",
      "buttons": [
        {
          extend: "excelHtml5",
          title: filename,
          // Only exports columns that are currently visible.  Adjusted by the "Visibility" dropdown and Filtered text.
          exportOptions: {columns: ":visible"},
          className: "dtButtonHide",
        },
        {
          extend: "csv",
          title: filename,
          exportOptions: {columns: ":visible"},
          className: "dtButtonHide"
        },
        {
          extend: "pdfHtml5",
          title: filename,
          orientation: "landscape",
          exportOptions: {columns: ":visible"},
          className: "dtButtonHide"
        },
        {
          extend: "copyHtml5",
          title: filename,
          exportOptions: {columns: ":visible"},
          className: "dtButtonHide"
        }, 
        {
          extend: "print",
          autoPrint: false,
          title: filename,
          exportOptions: {columns: ":visible"},
          customize: function (win) {
              $(win.document.body).find('table').addClass('display').css('font-size', '12px');
              $(win.document.body).find('tr:nth-child(odd) td').each(function(index){
                  $(this).css('background-color','#D0D0D0');
              });
              $(win.document.body).find('h1').css('text-align','center');
          },
          className: "dtButtonHide"
        }
      ]
    });
  
    function dataTableToolbar(model) {
      return '' +
      '<ul class="nav" style="margin-bottom:10px">' +
      '  <li>' +
      '    <span class="options-label">Options:</span>' +
      '  </li>' +
      '  <li>' +
      '    <div class="dataTables_length" id="' + model + 'DataTable_length">' +
      '      <select name="' + model + 'DataTable_length" aria-controls="' + model + 'DataTable" id="' + model + 'TableLength" class="form-control form-control-sm tableLength" title="Number of records to show">' +
      '        <option value="5">5</option>' +
      '        <option value="10">10</option>' +
      '        <option value="25">25</option>' +
      '        <option selected="selected" value="50">50</option>' +
      '        <option value="100">100</option>' +
      '        <option value="250">250</option>' +
      '        <option value="1000">1000</option>' +
      '      </select>' +
      '    </div>' +
      '  </li>' +
      '  <li>' +
      '    <div class="tableClickerDiv dropdown">' +
      '      <button class="btn btn-outline-primary btn-sm dropdown-toggle" title="Column visibility options" type="button" id="tableClickerColvis" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="fas fa-columns"></i> Columns</button>' +
      '      <div class="dropdown-menu card-shadow" aria-labelledby="tableClickerColvis">' +
      '        <a class="dropdown-item button-highlight" id="colvisClicker-0">Record ID</a>' +
      '        <a class="dropdown-item" id="colvisClicker-1">Retailer</a>' +
      '        <a class="dropdown-item" id="colvisClicker-2">SRS SKU</a>' +
      '        <a class="dropdown-item" id="colvisClicker-3">SRS Inventory</a>' +
      '        <a class="dropdown-item" id="colvisClicker-4">Retailer SKU</a>' +
      '        <a class="dropdown-item" id="colvisClicker-5">Current Cost</a>' +
      '        <a class="dropdown-item" id="colvisClicker-6">Suggested Retail</a>' +
      '        <a class="dropdown-item" id="colvisClicker-7">Site Link</a>' +
      '        <a class="dropdown-item" id="colvisClicker-8">Active</a>' +
      '        <a class="dropdown-item" id="colvisClicker-9">Status</a>' +
      '        <a class="dropdown-item" id="colvisClicker-10">Inventory Qty</a>' +
      '        <a class="dropdown-item" id="colvisClicker-11">Inventory Last Updated</a>' +
      '        <a class="dropdown-item" id="colvisClicker-12">Next Available Qty</a>' +
      '        <a class="dropdown-item" id="colvisClicker-13">Next Available Date</a>' +
      '        <a class="dropdown-item" id="colvisClicker-14">Actions</a>' +
      '      </div>' +
      '    </div>' +
      '  </li>' +
      '  <li>' +
      '    <div class="tableClickerDiv dropdown">' +
      '      <button class="btn btn-outline-primary btn-sm dropdown-toggle" title="Export Options" type="button" id="tableClickerExports" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="fas fa-file-export"></i> Export</button>' +
      '      <div class="dropdown-menu" aria-labelledby="tableClickerExports">' +
      '        <div class="text-primary" style="font-size:11px;padding:0.25rem 1.5rem;">' +
      '          <i class="fas fa-exclamation-triangle text-danger"></i> Only the currently visible columns & rows will be exported' +
      '        </div>' +
      '        <a class="dropdown-item tableClickerExcel"><i class="fas fa-file-excel file-icon-color"></i> Excel <i class="fas fa-download text-primary"></i></a>' +
      '        <a class="dropdown-item tableClickerCsv"><i class="fas fa-file-csv file-icon-color"></i> CSV <i class="fas fa-download text-primary"></i></a>' +
      '        <a class="dropdown-item tableClickerPdf"><i class="fas fa-file-pdf file-icon-color"></i> PDF <i class="fas fa-download text-primary"></i></a>' +
      '        <a class="dropdown-item tableClickerCopy"><i class="fas fa-copy file-icon-color"></i> Copy</a>' +
      '        <a class="dropdown-item tableClickerPrint"><i class="fas fa-print file-icon-color"></i> Print/Preview</a>' +
      '      </div>' +
      '    </div>' +
      '  </li>' +
      '  <li class="filter-section mt-2" style="width:100%">' +
      '    <span class="filter-label">Filters:</span>' +
      '    <div id="' + model + 'DataTable_filter" class="dataTables_filter mr-2">' +
      '      <input id="' + model + 'TableSearch" class="form-control form-control-sm tableSearch" title="right-click to clear" oncontextmenu="return false" aria-controls="' + model + 'DataTable" placeholder="Search Entire Table...">' +
      '    </div>' +
      '    <span id="retailer-select-dropdown" class="filter-span-select colvisSearch-1"></span>' +
      '    <span id="srs-sku-input" class="filter-span-input colvisSearch-2"></span>' +
      '    <span id="retailer-sku-input" class="filter-span-input colvisSearch-4"></span>' +
      '    <span id="active-select-dropdown" class="filter-span-select colvisSearch-6"></span>' +
      '    <span id="status-select-dropdown" class="filter-span-select colvisSearch-7"></span>' +
      '  </li>' +
      '</ul>' +
      '';
    }

    table.page.len(50).draw();

    $("div.dataTableToolbar").html(dataTableToolbar('itemAvailabilities'));

    var search = $.fn.dataTable.util.throttle(
      function (val) {
        table.search(val).draw();
      }, 500);
     
    $('.tableSearch').on('keyup', function () {
      search( this.value );
    });
  
    $(".tableLength").on("change", function () {
      table.page.len($(this).find("option:selected").attr("value")).draw();
    });
  
    function colVisButtons(index) {
      var button = $("#colvisClicker-"+index);
      button.on("click", function(e) {
        e.stopPropagation();
        if (table.column(index).visible() === true) {
          table.column(index).visible(false);
          button.addClass("button-highlight");
          $(".colvisSearch-"+index).hide();
        }
        else {
          table.column(index).visible(true);
          button.removeClass("button-highlight");
          $(".colvisSearch-"+index).show();
        }
      });
    }
    let colVisIndex = -1;
    do { colVisIndex += 1;
         colVisButtons(colVisIndex);
    } while (colVisIndex < 14);
  
    yadcf.init(table,
      [
  
        { column_number : 2, // SRS SKU
          filter_type: "text",
          filter_container_id : "srs-sku-input",
          style_class : "form-control form-control-sm",
          filter_default_label : "SRS SKU..."
        },
  
        { column_number : 4, // Retailer SKU
          filter_type: "text",
          filter_container_id : "retailer-sku-input",
          style_class : "form-control form-control-sm",
          filter_default_label : "Retailer SKU..."
        },
  
        { column_number : 1, // Retailer
          filter_container_id : "retailer-select-dropdown",
          filter_default_label : "Retailers...",
          filter_match_mode : "exact",
          filter_type: 'multi_select',
          select_type: 'select2',
          select_type_options: {
              width: 'auto',
              'min-width': '200px',
              placeholder: 'Retailers...',
          },
          data: ['Home Depot', 'Wayfair', 'Amazon DC', 'Amazon DF', 'Amazon FBA', 'Amazon FBM', 'Lowes', 'Busy Beaver', 'Menards']
        },
  
        { column_number : 8, // Active
          filter_container_id : "active-select-dropdown",
          style_class : "form-control form-control-sm",
          filter_default_label : "Active?...",
          data: [
            {value: 'true', label : 'Yes'},
            {value: 'false', label : 'No'},
          ]
        },
  
        { column_number : 9, // Status
          filter_container_id : "status-select-dropdown",
          style_class : "form-control form-control-sm",
          filter_default_label : "Availability Status...",
          data: [
            {value: "1", label : "Available"},
            {value: "2", label : "Out of Stock"},
            {value: "3", label : "In Dispute"},
            {value: "4", label : "Discontinued"},
            {value: "5", label : "Other"},
          ]
        },
  
      ],
      { filters_position : "footer" },
      { externally_triggered : true }
    );

    // yadcf.exFilterColumn(table, [
    //   [1, ['Home Depot', 'Wayfair', 'Amazon DC', 'Amazon DF', 'Amazon FBA', 'Amazon FBM', 'Lowes', 'Busy Beaver', 'Menards']]
    // ]);


  
  }
  
//----------------------------------------------------------------------------//
//---------- ITEM AVAILABILITIES OVERVIEW RELATED ----------------------------//
//----------------------------------------------------------------------------//

  if ($('.item_availabilities_overview_div').length > 0) {
  
    var MyDate = new Date();
    var date;
    MyDate.setDate(MyDate.getDate());
    date = MyDate.getFullYear() + "-" + ("0" + (MyDate.getMonth() + 1)).slice(-2) + "-" + ("0" + MyDate.getDate()).slice(-2);
    var filename = "Item Availabilities, " + date + " Export";
    var table = $("#itemAvailabilityDataTable").DataTable({
      "retrieve": true,
      "processing": true,
      "serverSide": true,
      // "stateSave": true,
      // "stateDuration": "-1",
      "ajax": { "url": $("#itemAvailabilityDataTable").data("source") },
      "columns": [ // global search is disabled due to bugged columns below
  /*0 */{data: "record_id", searchable: false, visible: false},
  /*1 */{data: "model_number"},
  /*2 */{data: "srs_inventory", searchable: false},
  /*3 */{data: "home_depot", searchable: false, orderable: false},
  /*4 */{data: "wayfair", searchable: false, orderable: false},
  /*5 */{data: "amazon_dc", searchable: false, orderable: false},
  /*6 */{data: "amazon_df", searchable: false, orderable: false},
  /*7 */{data: "amazon_fba", searchable: false, orderable: false},
  /*8 */{data: "amazon_fbm", searchable: false, orderable: false},
  /*9 */{data: "lowes", searchable: false, orderable: false},
  /*10*/{data: "busy_beaver", searchable: false, orderable: false},
  /*11*/{data: "menards", searchable: false, orderable: false},
  /*12*/{data: "interior_innovations", searchable: false, orderable: false},
  /*13*/{data: "actions", orderable: false},
      ], // Sort by Model Number
      "order": [[1,"desc"]],
  
      "oLanguage": { "sZeroRecords": "No records to display for this filter" },
      "dom": "<'row'<'col-sm-10 dataTableToolbar'><'col-sm-2'B>>t<'row dataTableBottomRow'<'col-md-2'i><'col-md-10'p>>",
      "buttons": [
        {
          extend: "excelHtml5",
          title: filename,
          // Only exports columns that are currently visible.  Adjusted by the "Visibility" dropdown and Filtered text.
          exportOptions: {columns: ":visible"},
          className: "dtButtonHide",
        },
        {
          extend: "csv",
          title: filename,
          exportOptions: {columns: ":visible"},
          className: "dtButtonHide"
        },
        {
          extend: "pdfHtml5",
          title: filename,
          orientation: "landscape",
          exportOptions: {columns: ":visible"},
          className: "dtButtonHide"
        },
        {
          extend: "copyHtml5",
          title: filename,
          exportOptions: {columns: ":visible"},
          className: "dtButtonHide"
        }, 
        {
          extend: "print",
          autoPrint: false,
          title: filename,
          exportOptions: {columns: ":visible"},
          customize: function (win) {
              $(win.document.body).find('table').addClass('display').css('font-size', '12px');
              $(win.document.body).find('tr:nth-child(odd) td').each(function(index){
                  $(this).css('background-color','#D0D0D0');
              });
              $(win.document.body).find('h1').css('text-align','center');
          },
          className: "dtButtonHide"
        }
      ]
    });
  
    function dataTableToolbar() {
      return "" +
      "<ul class='nav' style='margin-bottom:10px'>" +
      "  <li>" +
      "    <span class='options-label'>Options:</span>" +
      "  </li>" +
      "  <li>" +
      "    <div class='dataTables_length' id='itemAvailabilityDataTable_length'>" +
      "      <select name='itemAvailabilityDataTable_length' aria-controls='itemAvailabilityDataTable' id='itemAvailabilityTableLength' class='form-control form-control-sm tableLength' title='Number of records to show'>" +
      "        <option value='5'>5</option>" +
      "        <option value='10'>10</option>" +
      "        <option value='25'>25</option>" +
      "        <option selected='selected' value='50'>50</option>" +
      "        <option value='100'>100</option>" +
      "        <option value='250'>250</option>" +
      "      </select>" +
      "    </div>" +
      "  </li>" +
      "  <li>" +
      "    <div class='tableClickerDiv dropdown'>" +
      "      <button class='btn btn-outline-primary btn-sm dropdown-toggle' title='Column visibility options' type='button' id='tableClickerColvis' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'><i class='fas fa-columns'></i> Columns</button>" +
      "      <div class='dropdown-menu card-shadow' aria-labelledby='tableClickerColvis'>" +
      "        <a class='dropdown-item button-highlight' id='colvisClicker-0'>Record ID</a>" +
      "        <a class='dropdown-item' id='colvisClicker-1'>SRS SKU</a>" +
      "        <a class='dropdown-item' id='colvisClicker-2'>SRS Inventory</a>" +
      "        <a class='dropdown-item' id='colvisClicker-3'>Home Depot</a>" +
      "        <a class='dropdown-item' id='colvisClicker-4'>Wayfair</a>" +
      "        <a class='dropdown-item' id='colvisClicker-5'>Amazon DC</a>" +
      "        <a class='dropdown-item' id='colvisClicker-6'>Amazon DF</a>" +
      "        <a class='dropdown-item' id='colvisClicker-7'>Amazon FBA</a>" +
      "        <a class='dropdown-item' id='colvisClicker-8'>Amazon FBM</a>" +
      "        <a class='dropdown-item' id='colvisClicker-9'>Lowes</a>" +
      "        <a class='dropdown-item' id='colvisClicker-10'>Busy Beaver</a>" +
      "        <a class='dropdown-item' id='colvisClicker-11'>Menards</a>" +
      "        <a class='dropdown-item' id='colvisClicker-12'>interiorinnovation.com</a>" +
      "        <a class='dropdown-item' id='colvisClicker-13'>Actions</a>" +
      "      </div>" +
      "    </div>" +
      "  </li>" +
      "  <li>" +
      "    <div class='tableClickerDiv dropdown'>" +
      "      <button class='btn btn-outline-primary btn-sm dropdown-toggle' title='Export Options' type='button' id='tableClickerExports' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'><i class='fas fa-file-export'></i> Export</button>" +
      "      <div class='dropdown-menu' aria-labelledby='tableClickerExports'>" +
      "        <div class='text-primary' style='font-size:11px;padding:0.25rem 1.5rem;'>" +
      "          <i class='fas fa-exclamation-triangle text-danger'></i> Only the currently visible columns & rows will be exported" +
      "        </div>" +
      "        <a class='dropdown-item' id='tableClickerExcel'><i class='fas fa-file-excel file-icon-color'></i> Excel <i class='fas fa-download text-primary'></i></a>" +
      "        <a class='dropdown-item' id='tableClickerCsv'><i class='fas fa-file-csv file-icon-color'></i> CSV <i class='fas fa-download text-primary'></i></a>" +
      "        <a class='dropdown-item' id='tableClickerPdf'><i class='fas fa-file-pdf file-icon-color'></i> PDF <i class='fas fa-download text-primary'></i></a>" +
      "        <a class='dropdown-item' id='tableClickerCopy'><i class='fas fa-copy file-icon-color'></i> Copy</a>" +
      "        <a class='dropdown-item' id='tableClickerPrint'><i class='fas fa-print file-icon-color'></i> Print/Preview</a>" +
      "      </div>" +
      "    </div>" +
      "  </li>" +
      "  <li>" +
      "    <div class='tableclickerDiv highlightButton form-check' title='Toggles cell highlighting'>" +
      "      <label class='form-check-label' for='highlightCheck'>" +
      "        <input class='form-check-input' type='checkbox' value='1' id='highlightCheck'/>" +
      "        Field Highlighting" +
      "        <i class='input-helper'></i>" +
      "      </label>" +
      "    </div>" +
      "  </li>" +
      "  <li class='filter-section' style='width:100%'>" +
      "    <span class='filter-label'>Filters:</span>" +
      "    <div id='ordersDataTable_filter' class='dataTables_filter mr-2'>" +
      "      <input id='ordersTableSearch' class='form-control form-control-sm tableSearch' title='right-click to clear' oncontextmenu='return false' aria-controls='ordersDataTable' placeholder='Search SRS SKU...'>" +
      "    </div>" +
      "  </li>" +
      "</ul>" +
      "";
    }

    table.page.len(50).draw();

    $("div.dataTableToolbar").html(dataTableToolbar());

    var search = $.fn.dataTable.util.throttle(
      function (val) {
        table.search(val).draw();
      }, 500);
     
    $('.tableSearch').on('keyup', function () {
      search( this.value );
    });
  
    $(".tableLength").on("change", function () {
      table.page.len($(this).find("option:selected").attr("value")).draw();
    });
  
    function colVisButtons(index) {
      var button = $("#colvisClicker-"+index);
      button.on("click", function(e) {
        e.stopPropagation();
        if (table.column(index).visible() === true) {
          table.column(index).visible(false);
          button.addClass("button-highlight");
          $(".colvisSearch-"+index).hide();
        }
        else {
          table.column(index).visible(true);
          button.removeClass("button-highlight");
          $(".colvisSearch-"+index).show();
        }
      });
    }
    let colVisIndex = -1;
    do { colVisIndex += 1;
         colVisButtons(colVisIndex);
    } while (colVisIndex < 13);
  

  }

});