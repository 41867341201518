/*global $*/
/*global bootbox*/
/*global Option*/
/*global yadcf*/

import 'stylesheets/ad_campaigns-global.scss';

  
$(document).on("turbolinks:load", function() {

//----------------------------------------------------------------------------//
//---------- AD CAMPAIGN BATCHES FORM RELATED --------------------------------//
//----------------------------------------------------------------------------//

  if ($('.ad_campaign_batch-validate').length > 0) {

    $('label.required').append('<strong> *</strong>'); // adds a "required" star next to each Inputs label
    $('label.required-s span').append('<strong> *</strong>'); // adds a "required" star next to each Selects label

    $(".add_notes_button").on("click", function() {
      $(this).slideUp("fast");
      $(".batch-notes-div").slideDown("slow");
    });
  
    $(".add_ad_campaign_button").on("click", function() {
      $(".add_child_ad_campaign").click();
    });
    $(".add_10_ad_campaigns_button").on("click", function() {
      for (var i = 0; i < 10; i++){
        $(".add_child_ad_campaign").click();
      }
    });
  
    $(document).on('click', 'a.add_child_ad_campaign', function() {
      var association, new_id, regexp, template, fields;
      association = $(this).attr('data-association');
      template = $('#' + association + '_fields_template').html();
      regexp = new RegExp('new_' + association, 'g');
      new_id = (new Date).getTime();
      $(this).parent().before(template.replace(regexp, new_id));
      // The "jstemplates" hidden field needs the ".ignore" class to ensure it
      // doesn't get picked up by Jquery Validator.  This line removes it.
      fields = $(this).parent().prev(".fields");
      fields.find("select").removeClass("ignore");
      fields.find('.adCampaignRetailerSearch').select2({
        selectOnClose: true,
        allowClear: true,
        placeholder: 'Retailer...',
      }).val($('#retailer-default-select').find('option:selected').val()).trigger('change');
      fields.find('.productSearch').select2({
        selectOnClose: true,
        allowClear: true,
        placeholder: "Select Item...",
        ajax: {
          url: "/json_dropdown_products_all.json",
          dataType: "json",
          type: "GET",
          processResults: function (data, params) {
            params.page = data.page || 1;
            return {
              results: $.map(data.items, function (item) {
                return {
                  text: item.sku + " | " + item.desc,
                  id: item.id,
                  quantity_on_hand: item.qty
                };
              }),
              pagination: {
                more: data.current_page
              }
            };
          },
          cache: true
        },
        templateSelection: function(container) { // adds the current quantity on hand to the items 'data-quantity' attribute
            $(container.element).attr("data-quantity", container.quantity_on_hand);
            return container.text;
        }
      });


      fields.find(".ad_campaign_start_date").val($('#start-date-default-select').val());
      fields.find(".ad_campaign_end_date").val($('#end-date-default-select').val());

      $(".scroll").bind('wheel', function() {});
  
      return false;
    });
  

    $(document).on("click", "a.remove_child_ad_campaign", function() { // Triggers the removal of the relevant "Ad Campaign Select" table row when clicked
      var tr = $(this).closest("tr");
      var hidden_field = $(this).prev("input[type=hidden]")[0];
      tr.find(".productSearch").val([]).trigger("change");
      if(hidden_field) {
        hidden_field.value = "1";
      }
      $(this).parents(".fields").remove();
      return false;
    });
  
    $(".ad_campaign_batch-validate").validate({
      ignore: ".ignore",
  		rules: {
  		// 	"supplier[brand_name]": {required: true, maxlength: 80},
  		},
  		messages: {
  		// 	"supplier[brand_name]": "a Brand Name is required",
  		},
      onfocusout: function(element) {$(element).valid()},
      focusCleanup: true,
      errorClass: "myErrorClass",
      errorPlacement: function (error, element) {
        error.insertAfter(element.parent());
      },
      highlight: function (element, errorClass, validClass) {
        var elem = $(element);
        if (elem.hasClass("select2-hidden-accessible")) {
          elem.parent().find(".select2-selection").addClass(errorClass);
        } 
        else {
          elem.addClass(errorClass);
        }
      },    
      unhighlight: function (element, errorClass, validClass) {
        var elem = $(element);
        if (elem.hasClass("select2-hidden-accessible")) {
          elem.parent().find(".select2-selection").removeClass(errorClass);
          elem.parent().next("label").remove();
        }
        else {
          elem.removeClass(errorClass);
          elem.parent().next("label").remove();
        }
      },
  		invalidHandler: function(e, validator) {
  			var errors = validator.numberOfInvalids();
  			if (errors) {
  				var message = errors == 1
  					? 'You missed 1 required field. Check below tabs for highlighted fields'
  					: 'You missed ' + errors + ' required fields.  Check below tabs for highlighted fields';
  				$("div.validatorErrorList span").html(message);
  				$("div.validatorErrorList").show();
  			}
  			else {
  				$("div.validatorErrorList").hide();
  			}
  		}
    });

    $("#adCampaignBatchFirstSave").on("click", function(e) {
      e.preventDefault();
      if ($(".ad_campaign_batch-validate").valid()) {
        window.onbeforeunload = null;
        $("#jstemplates").html("");
        $('.productSearch option:selected[value=""]').closest('tr').remove();
        $(".loading-overlay").show();
        $("#adCampaignBatchSave").click();
      }
      else {
        return false;
      }
    });

    $("#adCampaignBatchFirstSubmit").on("click", function(e) {
      e.preventDefault();
      if ($(".ad_campaign_batch-validate").valid()) {
        window.onbeforeunload = null;
        $("#jstemplates").html("");
        $('.productSearch option:selected[value=""]').closest('tr').remove();
        $(".loading-overlay").show();
        $("#adCampaignBatchSubmit").click();
      }
      else {
        return false;
      }
    });

    $(document).on('click', '.spend_amount', function() {
      if ($(this).val() == '0.00') {
        $(this).select();
      }
    });
  
    // on first focus (bubbles up to document), open the menu
    $(document).on('focus', '.select2-selection.select2-selection--single', function (e) {
      $(this).closest(".select2-container").siblings('select:enabled').select2('open');
    });
    
    // steal focus during close - only capture once and stop propogation
    $('select.select2').on('select2:closing', function (e) {
      $(e.target).data("select2").$selection.one('focus focusin', function (e) {
        e.stopPropagation();
      });
    });
  
    // monkeypatch to fix issue of search field not being selected on open.  https://github.com/select2/select2/issues/2311#issuecomment-822473913
    $(document).on('select2:open', e => {
      const id = e.target.id;
      const target = document.querySelector(`[aria-controls=select2-${id}-results]`);
      target.focus();
    });
  
  //---- Edit Related ----------------------------------------------------------//

    $('.productEditSearch').select2({
      selectOnClose: true,
      allowClear: true,
      placeholder: 'Select Item...',
      ajax: {
        url: '/json_dropdown_products_all.json',
        dataType: 'json',
        type: 'GET',
        processResults: function (data, params) {
          params.page = data.page || 1;
          return {
            results: $.map(data.items, function (item) {
              return {
                  text: item.sku + ' | ' + item.desc,
                  id: item.id,
                  quantity_on_hand: item.qty,
              };
            }),
            pagination: {
              more: data.current_page
            }
          };
        },
        cache: true
      },
      templateSelection: function(container) { // adds the current quantity on hand to the items 'data-quantity' attribute
          $(container.element).attr('data-quantity', container.quantity_on_hand).attr('data-child_count', container.child_count);
          return container.text;
      }
    });
  
    // This populates the 'Product Search' dropdown on the 'Edit' view
    var productSelect = $('.productEditSearch');
    productSelect.each(function() {
      var t = $(this);
      $.ajax({
        url: '/products/' + t.data('selected') + '.json',
        dataType: 'json',
        type: 'GET',
      }).then(function (data) {
        var option = $('<option />')
                      .val(data.productSelect.id)
                      .text(data.productSelect.sku + ' | ' + data.productSelect.desc)
                      .prop('selected', true)
                      .attr('data-quantity', data.productSelect.qty);
        t.append(option).trigger('change');
        t.trigger({
          type: 'select2:select',
          params: {
            data: data
          }
        });
      });
    });

    // This populates the 'Retailer' dropdown on the 'Edit' view
    var retailerSelect = $('.adCampaignEditRetailerSearch');
    retailerSelect.each(function() {
      var t = $(this);
      $.ajax({
        url: '/retailers/' + t.data('selected') + '.json',
        dataType: 'json',
        type: 'GET',
      }).then(function (data) {
        var option = new Option(data.retailerSelect.name, data.retailerSelect.id, true, true);
        t.append(option).trigger('change');
        t.trigger({
          type: 'select2:select',
          params: {
            data: data
          }
        });
      });
    });
  
    $('.adCampaignEditRetailerSearch').select2({
      selectOnClose: true,
      allowClear: true,
      placeholder: 'Retailer...',
    });

    $(".fields").each(function() {
      $(this).next('input').attr('data-item', $(this).data('item')).addClass('item_sort');
    });
  
    // grabs the 'Model Number' field from each Item, then sorts alphabetically.
    $(document).on('click', '.sort_by_item_button', function() {
      var mylist = $('.ad_campaign_fields');
      var listitems = mylist.children('.item_sort').get();
      listitems.sort(function(a, b) {
         return $(a).data('item').toUpperCase().localeCompare($(b).data('item').toUpperCase());
      });
      $.each(listitems, function(idx, itm) { mylist.append(itm); });
    });

    $(document).on('click', '.ad_campaign_edit_remove_button', function() {
      var fields = $(this).closest('.fields');
      bootbox.confirm('Are you sure you want to delete this Ad Campaign?', function(result){
        if (result === true) {
          fields.find('.remove_child_edit_ad_campaign').attr('value', 'true');
          fields.slideUp('slow');
        }
      });
    });

    // This facilitates deleting an association via AJAX
    $(".ad-campaign-delete").on("ajax:success", function() {
      var parent = $(this).closest("tr");
      parent.next("input").remove();
      parent.slideUp( "fast", function() { parent.remove() } );
    });

    // monkeypatch to re-enable scrollwheel on number fields
    $(".scroll").bind('wheel', function() {});

    window.onbeforeunload = function() {
    	return 'Are you sure you want to leave?';
    };

  }

//----------------------------------------------------------------------------//
//---------- AD CAMPAIGN BATCHES SHOW RELATED --------------------------------//
//----------------------------------------------------------------------------//

  if ($('.ad_campaign_batch_show_div').length > 0) {

    $(document).on('click', '.ots-clicker', function() {
      $(this).closest('span').find('.on_the_spot_editing').click();
    });
  
    // This facilitates deleting an association via AJAX
    $(".ad-campaign-delete").on("ajax:success", function() {
      var parent = $(this).closest("tr");
      parent.next("input").remove();
      parent.slideUp( "fast", function() { parent.remove() } );
    });

    var MyDate = new Date();
    var date;
    MyDate.setDate(MyDate.getDate());
    date = MyDate.getFullYear() + "-" + ("0" + (MyDate.getMonth() + 1)).slice(-2) + "-" + ("0" + MyDate.getDate()).slice(-2);
    var filename = "Ad Campaigns Export, " + date;
    var table = $(".ad_campaigns_table").DataTable({
      "retrieve": true,
      "processing": true,
      // "order": [[ 1, "asc" ], [ 10, "desc" ], [ 9, "desc" ], [ 8, "desc" ], [ 7, "desc" ]],
      "oLanguage": { "sZeroRecords": "No records to display for this filter" },
      "dom": "<'row'<'col-sm-10 dataTableToolbar'><'col-sm-2'B>>t<'row dataTableBottomRow'<'col-md-2 ml-3'i><'col-md-9'p>>",
      "buttons": [
        {
          extend: "excelHtml5",
          title: filename,
          // Only exports columns that are currently visible.  Adjusted by the "Visibility" dropdown and Filtered text.
          exportOptions: {columns: ":visible"},
          className: "dtButtonHide"
        },
        {
          extend: "csv",
          title: filename,
          exportOptions: {columns: ":visible"},
          className: "dtButtonHide"
        },
        {
          extend: "pdfHtml5",
          title: filename,
          orientation: "landscape",
          exportOptions: {columns: ":visible"},
          className: "dtButtonHide"
        },
        {
          extend: "copyHtml5",
          title: filename,
          exportOptions: {columns: ":visible"},
          className: "dtButtonHide"
        }, 
        {
          extend: "print",
          autoPrint: false,
          title: filename,
          exportOptions: {columns: ":visible"},
          customize: function (win) {
              $(win.document.body).find('table').addClass('display').css('font-size', '12px');
              $(win.document.body).find('tr:nth-child(odd) td').each(function(index){
                  $(this).css('background-color','#D0D0D0');
              });
              $(win.document.body).find('h1').css('text-align','center');
          },
          className: "dtButtonHide"
        }
      ]
    });
  
    function dataTableToolbar() {
      return "" +
      "<ul class='nav ml-2 mb-3'>" +
      "  <li>" +
      "    <div class='dataTables_length' id='ad_campaigns_table_length'>" +
      "      <select name='ad_campaigns_table_length' aria-controls='ad_campaigns_table' class='form-control form-control-sm tableLength' title='Number of records to show'>" +
      "        <option value='5'>5</option>" +
      "        <option value='10'>10</option>" +
      "        <option selected='selected' value='25'>25</option>" +
      "        <option value='50'>50</option>" +
      "        <option value='100'>100</option>" +
      "        <option value='250'>250</option>" +
      "        <option value='-1'>All</option>" +
      "      </select>" +
      "    </div>" +
      "  </li>" +
      "  <li>" +
      "    <div class='tableClickerDiv dropdown'>" +
      "      <button class='btn btn-outline-primary btn-sm dropdown-toggle' title='Export Options' type='button' id='tableClickerExports' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'><i class='fas fa-file-export'></i> Export</button>" +
      "      <div class='dropdown-menu' aria-labelledby='tableClickerExports'>" +
      "        <div class='text-primary' style='font-size:11px;padding:0.25rem 1.5rem;'>" +
      "          <i class='fas fa-exclamation-triangle text-danger'></i> Only the currently visible columns & rows will be exported" +
      "        </div>" +
      "        <a class='dropdown-item tableClickerExcel'><i class='fas fa-file-excel file-icon-color'></i> Excel <i class='fas fa-download text-primary'></i></a>" +
      "        <a class='dropdown-item tableClickerCsv'><i class='fas fa-file-csv file-icon-color'></i> CSV <i class='fas fa-download text-primary'></i></a>" +
      "        <a class='dropdown-item tableClickerPdf'><i class='fas fa-file-pdf file-icon-color'></i> PDF <i class='fas fa-download text-primary'></i></a>" +
      "        <a class='dropdown-item tableClickerCopy'><i class='fas fa-copy file-icon-color'></i> Copy</a>" +
      "        <a class='dropdown-item tableClickerPrint'><i class='fas fa-print file-icon-color'></i> Print/Preview</a>" +
      "      </div>" +
      "    </div>" +
      "  </li>" +
      "  <li class='filter-section ml-3'>" +
      "    <div id='ad_campaigns_table_filter' class='dataTables_filter mr-2'>" +
      "      <input id='ad_campaigns_table_tableSearch' class='form-control form-control-sm tableSearch' title='right-click to clear' oncontextmenu='return false' aria-controls='ad_campaigns_table' placeholder='Search Entire Table...'>" +
      "    </div>" +
      "  </li>" +
      "</ul>" +
      "";
    }
  
    table.page.len(25).draw();
  
    $("div.dataTableToolbar").html(dataTableToolbar());
  
    $(".tableLength").on("change", function () {
      table.page.len($(this).find("option:selected").attr("value")).draw();
    });
  
    var search = $.fn.dataTable.util.throttle(
      function (val) {
        table.search(val).draw();
      }, 500);
     
    $('.tableSearch').on('keyup', function () {
      search( this.value );
    });

  }

  //--------------------------------------------------------------------------//
 //---------- AD CAMPAIGN BATCHES INDEX RELATED -----------------------------//
//--------------------------------------------------------------------------//

  if ($('.ad_campaign_batches_index_div').length > 0) {
  
    var MyDate = new Date();
    var date;
    MyDate.setDate(MyDate.getDate());
    date = MyDate.getFullYear() + '-' + ('0' + (MyDate.getMonth() + 1)).slice(-2) + '-' + ('0' + MyDate.getDate()).slice(-2);
    var filename = 'Ad Campaign Batches Export, ' + date;
    var table = $('#ad_campaign_batches_table').DataTable({
      'retrieve': true,
      'processing': true,
      'serverSide': true,
      'ajax': {
        'url': $('#ad_campaign_batches_table').data('source')
      },
      'columns': [
        {data: 'id'},
        {data: 'batch_identifier'},
        {data: 'primary_period'},
        {data: 'primary_retailer'},
        {data: 'ad_campaigns'},
        {data: 'total_spend'},
        {data: 'total_pcogs'},
        {data: 'attributed_units'},
        {data: 'attributed_cogs'},
        {data: 'total_clicks'},
        {data: 'total_impressions'},
        {data: 'actions', orderable: false}
      ],
      'order': [[ 0, 'desc' ]],
      'oLanguage': { 'sZeroRecords': 'No records to display for this filter' },
      'dom': '<"row"<"col-sm-10 dataTableToolbar"><"col-sm-2"B>>t<"row dataTableBottomRow"<"col-md-2"i><"col-md-10"p>>',
      'buttons': [
        {
          extend: 'excelHtml5',
          title: filename,
          // Only exports columns that are currently visible.  Adjusted by the 'Visibility' dropdown and Filtered text.
          exportOptions: {columns: ':visible'},
          className: 'dtButtonHide'
        },
        {
          extend: 'csv',
          title: filename,
          exportOptions: {columns: ':visible'},
          className: 'dtButtonHide'
        },
        {
          extend: 'pdfHtml5',
          title: filename,
          orientation: 'landscape',
          exportOptions: {columns: ':visible'},
          className: 'dtButtonHide'
        },
        {
          extend: 'copyHtml5',
          title: filename,
          exportOptions: {columns: ':visible'},
          className: 'dtButtonHide'
        }, 
        {
          extend: 'print',
          autoPrint: false,
          title: filename,
          exportOptions: {columns: ':visible'},
          customize: function (win) {
              $(win.document.body).find('table').addClass('display').css('font-size', '12px');
              $(win.document.body).find('tr:nth-child(odd) td').each(function(index){
                  $(this).css('background-color','#D0D0D0');
              });
              $(win.document.body).find('h1').css('text-align','center');
          },
          className: 'dtButtonHide'
        }
      ]
    });
  
    function dataTableToolbar() {
      return '' +
      '<ul class="nav" style="margin-bottom:10px">' +
      '  <li>' +
      '    <span class="options-label">Options:</span>' +
      '  </li>' +
      '  <li>' +
      '    <div class="dataTables_length" id="ad_campaign_batches_table_length">' +
      '      <select name="ad_campaign_batches_table_length" aria-controls="ad_campaign_batches_table" class="form-control form-control-sm tableLength" title="Number of records to show">' +
      '        <option value="5">5</option>' +
      '        <option value="10">10</option>' +
      '        <option selected="selected" value="25">25</option>' +
      '        <option value="50">50</option>' +
      '        <option value="100">100</option>' +
      '        <option value="250">250</option>' +
      '        <option value="1000">1000</option>' +
      '      </select>' +
      '    </div>' +
      '  </li>' +
      '  <li>' +
      '    <div class="tableClickerDiv dropdown">' +
      '      <button class="btn btn-outline-primary btn-sm dropdown-toggle" title="Column visibility options" type="button" id="tableClickerColvis" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="fas fa-columns"></i> Columns</button>' +
      '      <div class="dropdown-menu card-shadow" aria-labelledby="tableClickerColvis">' +
      '        <a class="dropdown-item" id="colvisClicker-0">Batch ID</a>' +
      '        <a class="dropdown-item" id="colvisClicker-1">Batch Identifier</a>' +
      '        <a class="dropdown-item" id="colvisClicker-2">Primary Period</a>' +
      '        <a class="dropdown-item" id="colvisClicker-3">Primary Retailer</a>' +
      '        <a class="dropdown-item" id="colvisClicker-4">Campaign Count</a>' +
      '        <a class="dropdown-item" id="colvisClicker-5">Total Spend</a>' +
      '        <a class="dropdown-item" id="colvisClicker-6">Total PCOGs</a>' +
      '        <a class="dropdown-item" id="colvisClicker-7">Attributed Units</a>' +
      "        <a class='dropdown-item' id='colvisClicker-8'>Attributed COG's</a>" +
      '        <a class="dropdown-item" id="colvisClicker-9">Total Clicks</a>' +
      '        <a class="dropdown-item" id="colvisClicker-10">Total Impressions</a>' +
      '        <a class="dropdown-item" id="colvisClicker-11">Actions</a>' +
      '      </div>' +
      '    </div>' +
      '  </li>' +
      '  <li>' +
      '    <div class="tableClickerDiv dropdown">' +
      '      <button class="btn btn-outline-primary btn-sm dropdown-toggle" title="Export Options" type="button" id="tableClickerExports" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="fas fa-file-export"></i> Export</button>' +
      '      <div class="dropdown-menu" aria-labelledby="tableClickerExports">' +
      '        <div class="text-primary" style="font-size:11px;padding:0.25rem 1.5rem;">' +
      '          <i class="fas fa-exclamation-triangle text-danger"></i> Only the currently visible columns & rows will be exported' +
      '        </div>' +
      '        <a class="dropdown-item tableClickerExcel"><i class="fas fa-file-excel file-icon-color"></i> Excel <i class="fas fa-download text-primary"></i></a>' +
      '        <a class="dropdown-item tableClickerCsv"><i class="fas fa-file-csv file-icon-color"></i> CSV <i class="fas fa-download text-primary"></i></a>' +
      '        <a class="dropdown-item tableClickerPdf"><i class="fas fa-file-pdf file-icon-color"></i> PDF <i class="fas fa-download text-primary"></i></a>' +
      '        <a class="dropdown-item tableClickerCopy"><i class="fas fa-copy file-icon-color"></i> Copy</a>' +
      '        <a class="dropdown-item tableClickerPrint"><i class="fas fa-print file-icon-color"></i> Print/Preview</a>' +
      '      </div>' +
      '    </div>' +
      '  </li>' +
      '  <li class="filter-section mt-2" style="width:100%">' +
      '    <span class="filter-label">Filters:</span>' +
      '    <div id="ad_campaign_batches_table_filter" class="dataTables_filter mr-2">' +
      '      <input id="AdCampaignsTableSearch" class="form-control form-control-sm tableSearch" title="right-click to clear" oncontextmenu="return false" aria-controls="ad_campaign_batches_table" placeholder="Search Entire Table...">' +
      '    </div>' +
      '    <span id="retailer-select-dropdown" class="filter-span-select colvisSearch-3"></span>' +
      '    <form action="/ad_campaign_batches" accept-charset="UTF-8" method="get">' +
      '      <span class="filter-span-range" data-toggle="tippy" data-tippy-delay="[500,10]" data-tippy-content="Ad Campaign Batch Date Range Search (leaving start or end date blank will assume infinity)">' +
      '        <div>' +
      '          <div class="yadcf-filter-wrapper">' +
      '            <div class="yadcf-filter-wrapper-inner">' +
      '              <input type="date" name="start_date" id="start_date" placeholder="From" class="yadcf-filter-range-date yadcf-filter-range yadcf-filter-range-start hasDatepicker" oncontextmenu="return false" style="width:150px;">' +
      '              <input type="date" name="end_date" id="end_date" placeholder="To" class="yadcf-filter-range-date yadcf-filter-range yadcf-filter-range-end hasDatepicker" oncontextmenu="return false" style="width:150px;margin-left:-3px;">' +
      '            </div>' +
      '            <button type="submit" class="yadcf-filter-reset-button submit-date-range" title="Note that Date Range search will clear all other filters">Go</button>' +
      '          </div>' +
      '        </div>' +
      '      </span>' +
      '    </form>' +
      '  </li>' +
      '</ul>' +
      '';
    }
  
    table.page.len(25).draw();

    $('div.dataTableToolbar').html(dataTableToolbar());
  
    var search = $.fn.dataTable.util.throttle(
      function (val) {
        table.search(val).draw();
      }, 500);
     
    $('.tableSearch').on('keyup', function () {
      search( this.value );
    });
  
    $('.tableLength').on('change', function () {
      table.page.len($(this).find('option:selected').attr('value')).draw();
    });
  
    function colVisButtons(index) {
      var button = $('#colvisClicker-'+index);
      button.on('click', function(e) {
        e.stopPropagation();
        if (table.column(index).visible() === true) {
          table.column(index).visible(false);
          button.addClass('button-highlight');
          $('.colvisSearch-'+index).hide();
        }
        else {
          table.column(index).visible(true);
          button.removeClass('button-highlight');
          $('.colvisSearch-'+index).show();
        }
      });
    }
    let colVisIndex = -1;
    do { colVisIndex += 1;
         colVisButtons(colVisIndex);
    } while (colVisIndex < 11);
  
    yadcf.init(table,
      [
        { column_number : 3, // Retailer
          filter_container_id : "retailer-select-dropdown",
          filter_default_label : "Retailers... ↓↓↓",
          filter_match_mode : "exact",
          filter_type: 'multi_select',
          select_type: 'select2',
          data: $('#params_span').data('retailers_dropdown')
        }
      ],
      { filters_position : 'footer' },
      { externally_triggered : true }
    );
  
    // This block executes any query string searches that may be present in the URL
    function filterTable(columnIndex, filterValue){
      yadcf.exFilterColumn(table, [
        [columnIndex, filterValue],
      ]);
    }
    const queryStringParams = window.location.search.substring(1).split('&');

    queryStringParams.forEach(param => {

      if (param.indexOf('sku') !== -1) {
        filterTable(3, $('#params_span').data('sku'));
      }
    });

    $("#start_date").val($("#start_date_hidden").attr("value"));
    $("#end_date").val($("#end_date_hidden").attr("value"));
  
    $('.ad-campaign-batch-delete').on('ajax:success', function() {
      var parent = $(this).closest('tr');
      parent.addClass('hidden');
    });
  
    $('body').tooltip({selector: '[data-toggle="tooltip"]'});

  }

  //--------------------------------------------------------------------------//
 //---------- AD CAMPAIGNS INDEX RELATED ------------------------------------//
//--------------------------------------------------------------------------//

  if ($('.ad_campaigns_index_div').length > 0) {
  
    var MyDate = new Date();
    var date;
    MyDate.setDate(MyDate.getDate());
    date = MyDate.getFullYear() + '-' + ('0' + (MyDate.getMonth() + 1)).slice(-2) + '-' + ('0' + MyDate.getDate()).slice(-2);
    var filename = 'Ad Campaigns Export, ' + date;
    var table = $('#ad_campaigns_table').DataTable({
      'retrieve': true,
      'processing': true,
      'serverSide': true,
      'ajax': {
        'url': $('#ad_campaigns_table').data('source')
      },
      'columns': [
        {data: 'id'},
        {data: 'batch_id'},
        {data: 'start_date'},
        {data: 'end_date'},
        {data: 'sku'},
        {data: 'retailer'},
        {data: 'campaign_names'},
        {data: 'spend'},
        {data: 'percentage_cogs'},
        {data: 'attributed_units'},
        {data: 'attributed_cogs'},
        {data: 'all_units'},
        {data: 'all_cogs'},
        {data: 'clicks'},
        {data: 'impressions'},
        {data: 'actions', orderable: false}
      ],
      'order': [[ 0, 'desc' ]],
      'oLanguage': { 'sZeroRecords': 'No records to display for this filter' },
      'dom': '<"row"<"col-sm-10 dataTableToolbar"><"col-sm-2"B>>t<"row dataTableBottomRow"<"col-md-2"i><"col-md-10"p>>',
      'buttons': [
        {
          extend: 'excelHtml5',
          title: filename,
          // Only exports columns that are currently visible.  Adjusted by the 'Visibility' dropdown and Filtered text.
          exportOptions: {columns: ':visible'},
          className: 'dtButtonHide'
        },
        {
          extend: 'csv',
          title: filename,
          exportOptions: {columns: ':visible'},
          className: 'dtButtonHide'
        },
        {
          extend: 'pdfHtml5',
          title: filename,
          orientation: 'landscape',
          exportOptions: {columns: ':visible'},
          className: 'dtButtonHide'
        },
        {
          extend: 'copyHtml5',
          title: filename,
          exportOptions: {columns: ':visible'},
          className: 'dtButtonHide'
        }, 
        {
          extend: 'print',
          autoPrint: false,
          title: filename,
          exportOptions: {columns: ':visible'},
          customize: function (win) {
              $(win.document.body).find('table').addClass('display').css('font-size', '12px');
              $(win.document.body).find('tr:nth-child(odd) td').each(function(index){
                  $(this).css('background-color','#D0D0D0');
              });
              $(win.document.body).find('h1').css('text-align','center');
          },
          className: 'dtButtonHide'
        }
      ]
    });
  
    function dataTableToolbar() {
      return '' +
      '<ul class="nav" style="margin-bottom:10px">' +
      '  <li>' +
      '    <span class="options-label">Options:</span>' +
      '  </li>' +
      '  <li>' +
      '    <div class="dataTables_length" id="ad_campaigns_table_length">' +
      '      <select name="ad_campaigns_table_length" aria-controls="ad_campaigns_table" class="form-control form-control-sm tableLength" title="Number of records to show">' +
      '        <option value="5">5</option>' +
      '        <option value="10">10</option>' +
      '        <option selected="selected" value="25">25</option>' +
      '        <option value="50">50</option>' +
      '        <option value="100">100</option>' +
      '        <option value="250">250</option>' +
      '        <option value="1000">1000</option>' +
      '      </select>' +
      '    </div>' +
      '  </li>' +
      '  <li>' +
      '    <div class="tableClickerDiv dropdown">' +
      '      <button class="btn btn-outline-primary btn-sm dropdown-toggle" title="Column visibility options" type="button" id="tableClickerColvis" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="fas fa-columns"></i> Columns</button>' +
      '      <div class="dropdown-menu card-shadow" aria-labelledby="tableClickerColvis">' +
      '        <a class="dropdown-item" id="colvisClicker-0">ID</a>' +
      '        <a class="dropdown-item" id="colvisClicker-1">Batch ID</a>' +
      '        <a class="dropdown-item" id="colvisClicker-2">Start Date</a>' +
      '        <a class="dropdown-item" id="colvisClicker-3">End Date</a>' +
      '        <a class="dropdown-item" id="colvisClicker-4">SKU</a>' +
      '        <a class="dropdown-item" id="colvisClicker-5">Retailer</a>' +
      '        <a class="dropdown-item" id="colvisClicker-6">Campaign Names</a>' +
      '        <a class="dropdown-item" id="colvisClicker-7">Spend</a>' +
      `        <a class="dropdown-item" id="colvisClicker-8">Percentage COG's</a>` +
      `        <a class="dropdown-item" id="colvisClicker-9">Attributed Units</a>` +
      `        <a class="dropdown-item" id="colvisClicker-10">Attributed COG's</a>` +
      '        <a class="dropdown-item" id="colvisClicker-11">Storewide Units</a>' +
      `        <a class="dropdown-item" id="colvisClicker-12">Storewide COG's</a>` +
      '        <a class="dropdown-item" id="colvisClicker-13">Clicks</a>' +
      '        <a class="dropdown-item" id="colvisClicker-14">Impressions</a>' +
      '        <a class="dropdown-item" id="colvisClicker-15">Actions</a>' +
      '      </div>' +
      '    </div>' +
      '  </li>' +
      '  <li>' +
      '    <div class="tableClickerDiv dropdown">' +
      '      <button class="btn btn-outline-primary btn-sm dropdown-toggle" title="Export Options" type="button" id="tableClickerExports" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="fas fa-file-export"></i> Export</button>' +
      '      <div class="dropdown-menu" aria-labelledby="tableClickerExports">' +
      '        <div class="text-primary" style="font-size:11px;padding:0.25rem 1.5rem;">' +
      '          <i class="fas fa-exclamation-triangle text-danger"></i> Only the currently visible columns & rows will be exported' +
      '        </div>' +
      '        <a class="dropdown-item tableClickerExcel"><i class="fas fa-file-excel file-icon-color"></i> Excel <i class="fas fa-download text-primary"></i></a>' +
      '        <a class="dropdown-item tableClickerCsv"><i class="fas fa-file-csv file-icon-color"></i> CSV <i class="fas fa-download text-primary"></i></a>' +
      '        <a class="dropdown-item tableClickerPdf"><i class="fas fa-file-pdf file-icon-color"></i> PDF <i class="fas fa-download text-primary"></i></a>' +
      '        <a class="dropdown-item tableClickerCopy"><i class="fas fa-copy file-icon-color"></i> Copy</a>' +
      '        <a class="dropdown-item tableClickerPrint"><i class="fas fa-print file-icon-color"></i> Print/Preview</a>' +
      '      </div>' +
      '    </div>' +
      '  </li>' +
      '  <li class="filter-section mt-2" style="width:100%">' +
      '    <span class="filter-label">Filters:</span>' +
      '    <div id="ad_campaigns_table_filter" class="dataTables_filter mr-2">' +
      '      <input id="AdCampaignsTableSearch" class="form-control form-control-sm tableSearch" title="right-click to clear" oncontextmenu="return false" aria-controls="ad_campaigns_table" placeholder="Search Entire Table...">' +
      '    </div>' +
      '    <span id="sku-select-dropdown" class="filter-span-select colvisSearch-4"></span>' +
      '    <span id="retailer-select-dropdown" class="filter-span-select colvisSearch-5"></span>' +
      "    <form action='/ad_campaigns' accept-charset='UTF-8' method='get'>" +
      "      <span class='filter-span-range colvisSearch-2' data-toggle='tippy' data-tippy-delay='[500,10]' data-tippy-content='Ad Campaign Date Range Search (leaving start or end date blank will assume infinity)'>" +
      "        <div>" +
      "          <div class='yadcf-filter-wrapper'>" +
      "            <div class='yadcf-filter-wrapper-inner'>" +
      "              <input type='date' name='start_date' id='start_date' placeholder='From' class='yadcf-filter-range-date yadcf-filter-range yadcf-filter-range-start hasDatepicker' oncontextmenu='return false' style='width:150px;'>" +
      "              <input type='date' name='end_date' id='end_date' placeholder='To' class='yadcf-filter-range-date yadcf-filter-range yadcf-filter-range-end hasDatepicker' oncontextmenu='return false' style='width:150px;margin-left:-3px;'>" +
      "            </div>" +
      "            <button type='submit' class='yadcf-filter-reset-button submit-date-range' title='Note that Date Range search will clear all other filters'>Go</button>" +
      "          </div>" +
      "        </div>" +
      "      </span>" +
      "    </form>" +
      '  </li>' +
      '</ul>' +
      '';
    }
  
    table.page.len(25).draw();

    $('div.dataTableToolbar').html(dataTableToolbar());
  
    var search = $.fn.dataTable.util.throttle(
      function (val) {
        table.search(val).draw();
      }, 500);
     
    $('.tableSearch').on('keyup', function () {
      search( this.value );
    });
  
    $('.tableLength').on('change', function () {
      table.page.len($(this).find('option:selected').attr('value')).draw();
    });
  
    function colVisButtons(index) {
      var button = $('#colvisClicker-'+index);
      button.on('click', function(e) {
        e.stopPropagation();
        if (table.column(index).visible() === true) {
          table.column(index).visible(false);
          button.addClass('button-highlight');
          $('.colvisSearch-'+index).hide();
        }
        else {
          table.column(index).visible(true);
          button.removeClass('button-highlight');
          $('.colvisSearch-'+index).show();
        }
      });
    }
    let colVisIndex = -1;
    do { colVisIndex += 1;
         colVisButtons(colVisIndex);
    } while (colVisIndex < 15);
  
    yadcf.init(table,
      [
  
        { column_number : 4, // SKU
          filter_container_id : "sku-select-dropdown",
          filter_default_label : "SKUs... ↓↓↓",
          filter_match_mode : "exact",
          filter_type: 'multi_select',
          select_type: 'select2',
          data: $('#params_span').data('skus_dropdown')
        },
  
        { column_number : 5, // Retailer
          filter_container_id : "retailer-select-dropdown",
          filter_default_label : "Retailers... ↓↓↓",
          filter_match_mode : "exact",
          filter_type: 'multi_select',
          select_type: 'select2',
          data: $('#params_span').data('retailers_dropdown')
        },
  
      ],
      { filters_position : 'footer' },
      { autocomplete: false },
      { externally_triggered : true }
    );
  
    // This block executes any query string searches that may be present in the URL
    function filterTable(columnIndex, filterValue){
      yadcf.exFilterColumn(table, [
        [columnIndex, filterValue],
      ]);
    }
    const queryStringParams = window.location.search.substring(1).split('&');

    queryStringParams.forEach(param => {

      if (param.indexOf('sku') !== -1) {
        filterTable(4, $('#params_span').data('sku'));
      } else if (param.indexOf('retailer') !== -1) {
        filterTable(5, $('#params_span').data('retailer'));
      }
    });

    $("#start_date").val($("#start_date_hidden").attr("value"));
    $("#end_date").val($("#end_date_hidden").attr("value"));
  
    $('.ad-campaign-delete').on('ajax:success', function() {
      var parent = $(this).closest('tr');
      parent.addClass('hidden');
    });
  
    $('body').tooltip({selector: '[data-toggle="tooltip"]'});

  }

});
