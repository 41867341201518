/*global $*/

import 'stylesheets/warehouses-global.scss';

$(document).on('turbolinks:load', function() {

//----------------------------------------------------------------------------//
//---------- WAREHOUSES FORM RELATED -----------------------------------------//
//----------------------------------------------------------------------------//

  if ($('.warehouse-validate').length > 0) {

    $('.add_child').hide();
    $('label.required').append('<strong> *</strong>'); // adds a 'required' star next to each Inputs label
    $('label.required-s span').append('<strong> *</strong>'); // adds a 'required' star next to each Selects label
  
    $('.warehouse-validate').validate({
      ignore: '.ignore',
  		rules: {
  		// 	'product[model_number]': {required: true, maxlength: 80},
  		// 	'product[supplier_part_number]': {required: true, maxlength: 80},
  		},
  		messages: {
  		// 	'product[supplier_part_number]': 'If the Manufacturer Part Number & the Model Number are the same, enter the same SKU in both fields.',
  		// 	'product[model_number]': 'If the Model Number & the Manufacturer Part Number are the same, enter the same SKU in both fields.'
  		},
      onfocusout: function(element) {$(element).valid()},
      focusCleanup: true,
      errorClass: 'myErrorClass',
      errorPlacement: function (error, element) {
        error.insertAfter(element.parent());
      },
      highlight: function (element, errorClass, validClass) {
        var elem = $(element);
        var tabId = elem.closest('.tab-pane').attr('aria-labelledby');
        if (elem.hasClass('select2-hidden-accessible')) {
          elem.parent().find('.select2-selection').addClass(errorClass);
          elem.closest('.card-body').find('.nav-link[id="'+tabId+'"]').addClass(errorClass);
        } 
        else {
          elem.addClass(errorClass);
          elem.closest('.card-body').find('.nav-link[id="'+tabId+'"]').addClass(errorClass);
        }
      },    
      unhighlight: function (element, errorClass, validClass) {
        var elem = $(element);
        var tabId = elem.closest('.tab-pane').attr('aria-labelledby');
        if (elem.hasClass('select2-hidden-accessible')) {
          elem.parent().find('.select2-selection').removeClass(errorClass);
          elem.parent().next('label').remove();
          elem.closest('.card-body').find('.nav-link[id="'+tabId+'"]').removeClass(errorClass);
        }
        else {
          elem.removeClass(errorClass);
          elem.parent().next('label').remove();
          elem.closest('.card-body').find('.nav-link[id="'+tabId+'"]').removeClass(errorClass);
        }
      },
  		invalidHandler: function(e, validator) {
  			var errors = validator.numberOfInvalids();
  			if (errors) {
  				var message = errors == 1
  					? 'You missed 1 required field. Check below tabs for highlighted fields'
  					: 'You missed ' + errors + ' required fields.  Check below tabs for highlighted fields';
  				$('div.validatorErrorList span').html(message);
  				$('div.validatorErrorList').slideDown('slow');
  			}
  			else {
  				$('div.validatorErrorList').slideUp('slow');
  			}
  		}
    });
  
    $('#warehouseFirstSubmit').on('click', function(e) {
      e.preventDefault();
      if ($('.warehouse-validate').valid()) {
        $('#jstemplates_warehouse_nodes').html('');
        $(".loading-overlay").show();
        $('#warehouseSubmit').click();
      }
      else {
        return false;
      }
    });
  
    // on first focus (bubbles up to document), open the menu
    $(document).on('focus', '.select2-selection.select2-selection--single', function (e) {
      $(this).closest('.select2-container').siblings('select:enabled').select2('open');
    });
  
    // steal focus during close - only capture once and stop propogation
    $('select.select2').on('select2:closing', function (e) {
      $(e.target).data('select2').$selection.one('focus focusin', function (e) {
        e.stopPropagation();
      });
    });
  
    $(document).on('change keyup', '.cube-calc', function() {
      var e,l,w,h,c;
      e = $(this).closest('.cube-calc-body');
      l = e.find('.depth-value').val();
      w = e.find('.width-value').val();
      h = e.find('.height-value').val();
      c = (l*w)*h;
      e.find('.cube-value').val(c.toFixed(2));
    });
  
  //---- Warehouse Nodes Related ------------------------------------------------//
  
    $('.ancestriesParent').select2({
    placeholder: {
      id: '-1', // the value of the option
      text: 'Select an option'
    },
      minimumResultsForSearch: 10
    });
  
  
    // Child Fields Template Loader inherited from products_helper.rb for Product Associations
    $(document).on('click', 'a.add_child_warehouse_nodes', function() {
      var association, new_id, regexp, template, fields;
      association = $(this).attr('data-association');
      template = $('#' + association + '_fields_template').html();
      regexp = new RegExp('new_' + association, 'g');
      new_id = (new Date).getTime();
      $(this).parent().prev().append(template.replace(regexp, new_id));
      fields = $(this).parent().prev().find('.fields_warehouse_nodes').last();
      // The 'jstemplates' hidden field needs the '.ignore' class to ensure it
      // doesn't get picked up by Jquery Validator.  This line removes it.
      fields.find('input').removeClass('ignore');
      fields.find('select').removeClass('ignore');
  
      fields.find('.node_type_select').select2({
        selectOnClose: true,
        placeholder: 'Node Type...'
      });


      $('.scroll').bind('mousewheel', function() {});
  
      fields.hide();
      fields.slideDown('fast');
      return false;
    });
  
  
    $(document).on('click', 'a.remove_child_warehouse_nodes', function() {
      var hidden_field = $(this).prev('input[type=hidden]')[0];
      var fields = $(this).parents('.fields_warehouse_nodes');
      if(hidden_field) {
        hidden_field.value = '1';
      }
      $(this).parents('.fields_warehouse_nodes').slideUp('slow', function() {
        fields.remove();
      });
      return false;
    });
  
    $('.add_warehouse_node_button').on('click', function() {
      $('.add_child_warehouse_nodes').click();
    });
  

  //---- Attachments Related -----------------------------------------------------//
  
    // Child Fields Template Loader inherited from products_helper.rb for Associated Attachments
    $(document).on('click', 'a.add_child_warehouse_attachments', function() {
      var association, new_id, regexp, template, fields;
      association = $(this).attr('data-association');
      template = $('#' + association + '_fields_template').html();
      regexp = new RegExp('new_' + association, 'g');
      new_id = (new Date).getTime();
      $(this).parent().before(template.replace(regexp, new_id));
      fields = $(this).parent().prev('.fields_warehouse_attachments');
      // The 'jstemplates' hidden field needs the '.ignore' class to ensure it
      // doesn't get picked up by Jquery Validator.  This line removes it.
      fields.find('input').removeClass('ignore');
      fields.find('input[name$="[description]"]').each(function() {
        // rules for nested file fields need to be generated after the object is created
        $(this).rules('add', {
          messages: {
            required: `A title is required. If you don't intend to create this attachment, please click the "Remove" button below`
          }
        });
      });
      // ActionText Trix ID's need to be manually created, otherwise it will duplicate
      // itself as 'trix-toolbar-1' on all instances of a Trix Editor
      fields.find('trix-toolbar').attr('id', 'trix-toolbar-' + new_id);
      fields.find('trix-editor').attr('trix-id', new_id).attr('toolbar', 'trix-toolbar-' + new_id);
      fields.hide();
      fields.slideDown('fast');
      return false;
    });
  
    $(document).on('click', 'a.remove_child_warehouse_attachments', function() {
      var hidden_field = $(this).prev('input[type=hidden]')[0];
      var fields = $(this).parents('.fields_warehouse_attachments');
      if(hidden_field) {
        hidden_field.value = '1';
      }
      $(this).parents('.fields_warehouse_attachments').slideUp('slow', function() {
        fields.remove();
      });
      return false;
    });
  
    $(document).on('change keyup', '.attachment-description', function() {
      var new_description = $(this).val();
      $(this).closest('.edit-attachment-fields').find('.attachment-title').text(new_description);
    });
  
    $('.add_warehouse_attachment_button').on('click', function() {
      $('.add_child_warehouse_attachments').click();
    });
    
    // This facilitates deleting a file via AJAX
    $('.warehouse-attachment-delete').on('ajax:success', function() {
      var parent = $(this).closest('li');
      parent.slideUp( 'slow', function() { parent.remove() } );
    });

  }

//----------------------------------------------------------------------------//
//---------- WAREHOUSES INDEX RELATED ----------------------------------------//
//----------------------------------------------------------------------------//

//----------------------------------------------------------------------------//
//---------- WAREHOUSES SHOW RELATED -----------------------------------------//
//----------------------------------------------------------------------------//

});