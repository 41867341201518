/*global $*/
/*global bootbox*/
/*global globalObjects*/

import 'stylesheets/retailers-global.scss';

$(document).on('turbolinks:load', function() {

//----------------------------------------------------------------------------//
//---------- RETAILERS FORM RELATED ------------------------------------------//
//----------------------------------------------------------------------------//

  if ($('.retailer-validate').length > 0) {

    $('label.required').append('<strong> *</strong>'); // adds a 'required' star next to each Inputs label
  
    $('.retailer-users').selectize();
  
    $('.carrierRouteEditCarrierSelect').select2({
      selectOnClose: true,
      placeholder: 'Carrier...'
    }).on('change', function() {
        $(this).valid();
      });
  
    $('.carrierRouteEditStateSelect').select2({
      selectOnClose: true,
      placeholder: 'Carrier...'
    }).on('change', function() {
        $(this).valid();
      });
  
    $('.retailer-validate').validate({
      ignore: '.ignore',
      onkeyup: function(element) {$(element).valid()},
      errorClass: 'myErrorClass',
      errorPlacement: function (error, element) {
        error.insertAfter(element.parent());
      },
      highlight: function (element, errorClass, validClass) {
        $(element).addClass(errorClass);
      },    
      unhighlight: function (element, errorClass, validClass) {
        $(element).removeClass(errorClass);
        $(element).parent().next('label').remove();
      },
  		invalidHandler: function(e, validator) {
  			var errors = validator.numberOfInvalids();
  			if (errors) {
  				var message = errors == 1
  					? 'You missed 1 required field. It has been highlighted above'
  					: 'You missed ' + errors + ' required fields.  They have been highlighted above';
  				$('div.validatorErrorList span').html(message);
  				$('div.validatorErrorList').show();
  			}
  			else {
  				$('div.validatorErrorList').hide();
  			}
  		}
    });
  
    // Monkeypatch so all select2 fields actually clear to 'nil' when the 'X' is clicked
    $(document).on('select2:clear', '.select2-hidden-accessible', function (e) {
      $(this).append('<option value="" selected="selected"></option>');
    });
  
    $('#retailerFirstSubmit').on('click', function(e) {
      e.preventDefault();
      if ($('.retailer-validate').valid()) {
        $('#jstemplates_carrier_route').html('');
        $(".loading-overlay").show();
        $('#retailerSubmit').click();
      }
      else {
        return false;
      }
    });
  
    // Child Fields Template Loader inherited from products_helper.rb for Product Associations
    $(document).on('click', 'a.add_child_carrier_route', function() {
      var association, new_id, regexp, template, fields;
      association = $(this).attr('data-association');
      template = $('#' + association + '_fields_template').html();
      regexp = new RegExp('new_' + association, 'g');
      new_id = (new Date).getTime();
      $(this).parent().prev().append(template.replace(regexp, new_id));
      fields = $(this).parent().prev().find('.fields_carrier_route').last();
      // The 'jstemplates' hidden field needs the '.ignore' class to ensure it
      // doesn't get picked up by Jquery Validator.  This line removes it.
      fields.find('input').removeClass('ignore');
      fields.find('select').removeClass('ignore');
  
      // iterates through the Select to ensure each Retailer can only be selected once  
      let selects = $('.carrierRouteStateSelect');
      selects.change(function() {
        let selected = selects.map((i, s) => s.value).get();
        selects.find('option').each(function(i, o) {
          $(o).prop('disabled', o.value.length && selected.includes(o.value) && $(o).parent().val() !== o.value);
        });
        selects.find('option:disabled').each(function() {
          $(this).css('background-color', '#d3d3d3');
        });
        selects.find('option:not([disabled])').each(function() {
          $(this).css('background-color', '#fff');
        });
      });
      fields.find('.carrierRouteCarrierSelect').select2({
        selectOnClose: true,
        allowClear: true,
        placeholder: 'Select Carrier...'
      });
      fields.find('.carrierRouteStateSelect').select2({
        selectOnClose: true,
        allowClear: true,
        placeholder: 'Select State...'
      });
  
      // monkeypatch to fix issue of search field not being selected on open.  https://github.com/select2/select2/issues/2311#issuecomment-822473913
      $(document).on('select2:open', e => {
        const id = e.target.id;
        const target = document.querySelector(`[aria-controls=select2-${id}-results]`);
        target.focus();
      });
  
      
      fields.hide();
      fields.slideDown('fast');
      return false;
    });
  
    $(document).on('click', 'a.remove_child_carrier_route', function() {
      var hidden_field = $(this).prev('input[type=hidden]')[0];
      var fields = $(this).parents('.fields_carrier_route');
      if(hidden_field) {
        hidden_field.value = '1';
      }
      $(this).parents('.fields_carrier_route').slideUp('slow', function() {
        fields.remove();
      });
      return false;
    });
  
    $('.add_carrier_route_button').on('click', function() {
      $('.add_child_carrier_route').click();
    });
  
    // This facilitates deleting the record via AJAX
    $('.carrier_route_edit_remove_button').on('ajax:success', function() {
      var parent = $(this).closest('tr');
      parent.next('input').remove();
      parent.slideUp( 'fast', function() { parent.remove() } );
    });
  
    $(document).on('click', '.carrier_route_edit_remove_button', function() {
      var fields = $(this).closest('tr');
      bootbox.confirm('Are you sure you want to remove this entry?', function(result){
        if (result === true) {
          fields.find('.remove_child_edit_carrier_route').attr('value', 'true');
          fields.find('td').html('');
          fields.slideUp('slow');
          disable_options(); // re-check the existing fields to ensure removed state isn't disabled
        }
      });
    });
  
    // iterates through the Select to ensure each State can only be selected once  
    function disable_options() {
      let selects = $('.carrierRouteStateSelect, .carrierRouteEditStateSelect');
      selects.each(function() {
        let selected = selects.map((i, s) => s.value).get();
        selects.find('option').each(function(i, o) {
          $(o).prop('disabled', o.value.length && selected.includes(o.value) && $(o).parent().val() !== o.value);
        });
        selects.find('option:disabled').each(function() {
          $(this).css('background-color', '#d3d3d3');
        });
        selects.find('option:not([disabled])').each(function() {
          $(this).css('background-color', '#fff');
        });
      });
      selects.change(function() {
        let selected = selects.map((i, s) => s.value).get();
        selects.find('option').each(function(i, o) {
          $(o).prop('disabled', o.value.length && selected.includes(o.value) && $(o).parent().val() !== o.value);
        });
        selects.find('option:disabled').each(function() {
          $(this).css('background-color', '#d3d3d3');
        });
        selects.find('option:not([disabled])').each(function() {
          $(this).css('background-color', '#fff');
        });
      });
    }
  
    disable_options();
  
    // This facilitates deleting the attached image via AJAX
    $('.retailer-image-attachment-delete').on('ajax:success', function() {
      var parent = $(this).closest('.image-body').find('img');
      parent.slideUp( 'slow', function() { parent.remove() } );
      $(this).slideUp( 'slow', function() { $(this).remove() } );
    });
  
    // monkeypatch to fix issue of search field not being selected on open.  https://github.com/select2/select2/issues/2311#issuecomment-822473913
    $(document).on('select2:open', e => {
      const id = e.target.id;
      const target = document.querySelector(`[aria-controls=select2-${id}-results]`);
      target.focus();
    });
  
  }

//----------------------------------------------------------------------------//
//---------- RETAILERS INDEX RELATED -----------------------------------------//
//----------------------------------------------------------------------------//

//----------------------------------------------------------------------------//
//---------- RETAILERS OVERVIEW RELATED --------------------------------------//
//----------------------------------------------------------------------------//

//----------------------------------------------------------------------------//
//---------- RETAILERS SHOW RELATED ------------------------------------------//
//----------------------------------------------------------------------------//

  if ($('.retailer_show_div').length > 0) {

    $(document).on('click', '.OHSortByMonthButton', function() {
      var div = $(this).closest('#movement-charts');
      var iam = div.find('.ohm');
      var iaw = div.find('.ohw');
      iam.slideDown('slow');
      iaw.slideUp('slow');
      $(this).hide();
      div.find('.OHSortByDayButton').show();
    });
  
    $(document).on('click', '.OHSortByWeekButton', function() {
      var div = $(this).closest('#movement-charts');
      var iaw = div.find('.ohw');
      var iad = div.find('.ohd');
      iaw.slideDown('slow');
      iad.slideUp('slow');
      $(this).hide();
      div.find('.OHSortByMonthButton').show();
    });
  
    $(document).on('click', '.OHSortByDayButton', function() {
      var div = $(this).closest('#movement-charts');
      var iam = div.find('.ohm');
      var iad = div.find('.ohd');
      iad.slideDown('slow');
      iam.slideUp('slow');
      $(this).hide();
      div.find('.OHSortByWeekButton').show();
    });

    globalObjects.genericDataTable('customerOrders');
    globalObjects.genericDataTable('transferOrders');
    globalObjects.genericDataTable('otherOrders');
    globalObjects.genericDataTable('revenueHistory');
    globalObjects.genericDataTable('currentItemCosts');
    globalObjects.genericDataTable('historicalItemCosts');
    globalObjects.genericDataTable('users');
  
  }
  
});
