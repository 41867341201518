/*global $*/
/*global Rails*/
/*global bootbox*/
/*global yadcf*/

import 'stylesheets/inbound_shipments-global.scss';

$(document).on('turbolinks:load', function() {

//----------------------------------------------------------------------------//
//---------- INBOUND SHIPMENTS FORM RELATED ----------------------------------//
//----------------------------------------------------------------------------//

  if ($('.inbound_shipment-validate').length > 0) {
  
    $('.add_child_line_item').hide();
    $('label.required').append('<strong> *</strong>'); // adds a 'required' star next to each Inputs label
    $('label.required-s span').append('<strong> *</strong>'); // adds a 'required' star next to each Selects label
  
    $('.add_line_item_button').on('click', function() {
      $('.add_child_line_item').click();
    });
    $('.add_10_line_items_button').on('click', function() {
      for (var i = 0; i < 10; i++){
        $('.add_child_line_item').click();
      }
    });
  
    $('.add_custom_item_button').on('click', function() {
      $('.add_child_line_item').click();
      var fields = $(this).closest('div').find('.fields').last();
      fields.find('.productSearch').next().hide();
      fields.find('.order-quantity').attr('readonly', false);
      fields.find('.received-quantity').attr('readonly', false);
      fields.find('.current-quantity').hide();
      fields.find('.custom-item-field').show();
      fields.find('.custom-item-field').parent().addClass('custom-item-width');
      fields.find('.custom-description-field').show();
      fields.find('.custom-description-field').parent().addClass('custom-description-width');
    });
  
    $(document).on('click', '.line_item_edit_remove_button', function() {
      var fields = $(this).closest('.lineItemSelectTable');
      bootbox.confirm('Are you sure you want to remove this line item?', function(result){
        if (result === true) {
          fields.find('.remove_child_edit_line_item').attr('value', 'true');
          fields.hide();
        }
      });
    });
  
    $('.inboundShipmentSupplierSearch').select2({
      selectOnClose: true,
      allowClear: true,
      placeholder: 'Supplier...'
    }).on('change', function() {
        $(this).valid();
      });
  
    $('.inboundShipmentCarrierSearch').select2({
      selectOnClose: true,
      allowClear: true,
      placeholder: 'Carrier...'
    }).on('change', function() {
        $(this).valid();
      });
  
    $('.add_notes_button').on('click', function() {
      $(this).closest('.tab-content').find('.notes-div').slideDown('slow');
      $(this).addClass('hidden_preserve_position');
    });
  
    $('.add_iab_association_button').on('click', function() {
      $(this).closest('.tab-content').find('.iab-association-div').slideDown('slow');
      $(this).addClass('hidden_preserve_position');
    });
  
    $('.cancel_shipment_button').on('click', function() {
      $(this).closest('.tab-content').find('.cancellation-div').slideDown('slow');
      $(this).addClass('hidden_preserve_position');
    });
  
    $('.inbound_shipment-validate').validate({
      ignore: '.ignore',
  		rules: {
  		// 	'product[model_number]': {required: true, maxlength: 80},
  		// 	'product[supplier_part_number]': {required: true, maxlength: 80},
  		},
  		messages: {
  			'inbound_shipment[po_number]': 'If no PO Number is available, enter "N/A" into the field.',
  		// 	'product[model_number]': 'If the Model Number & the Manufacturer Part Number are the same, enter the same SKU in both fields.'
  		},
      onfocusout: function(element) {$(element).valid()},
      focusCleanup: true,
      errorClass: 'myErrorClass',
      errorPlacement: function (error, element) {
        error.insertAfter(element.parent());
      },
      highlight: function (element, errorClass, validClass) {
        var elem = $(element);
        var tabId = elem.closest('.tab-pane').attr('aria-labelledby');
        if (elem.hasClass('select2-hidden-accessible')) {
          elem.parent().find('.select2-selection').addClass(errorClass);
          elem.closest('.card-body').find('.nav-link[id="'+tabId+'"]').addClass(errorClass);
        } 
        else {
          elem.addClass(errorClass);
          elem.closest('.card-body').find('.nav-link[id="'+tabId+'"]').addClass(errorClass);
        }
      },    
      unhighlight: function (element, errorClass, validClass) {
        var elem = $(element);
        var tabId = elem.closest('.tab-pane').attr('aria-labelledby');
        if (elem.hasClass('select2-hidden-accessible')) {
          elem.parent().find('.select2-selection').removeClass(errorClass);
          elem.parent().next('label').remove();
          elem.closest('.card-body').find('.nav-link[id="'+tabId+'"]').removeClass(errorClass);
        }
        else {
          elem.removeClass(errorClass);
          elem.parent().next('label').remove();
          elem.closest('.card-body').find('.nav-link[id="'+tabId+'"]').removeClass(errorClass);
        }
      },
  		invalidHandler: function(e, validator) {
  			var errors = validator.numberOfInvalids();
  			if (errors) {
  				var message = errors == 1
  					? 'You missed 1 required field. Check below tabs for highlighted fields'
  					: 'You missed ' + errors + ' required fields.  Check below tabs for highlighted fields';
  				$('div.validatorErrorList span').html(message);
  				$('div.validatorErrorList').slideDown('slow');
  			}
  			else {
  				$('div.validatorErrorList').slideUp('slow');
  			}
  		}
    });
  
    // Monkeypatch so all select2 fields actually clear to 'nil' when the 'X' is clicked
    $(document).on('select2:clear', '.select2-hidden-accessible', function (e) {
      $(this).append('<option value="" selected="selected"></option>');
    });
  
    $('#inboundShipmentFirstSubmit').on('click', function(e) {
      e.preventDefault();
      if ($('.inbound_shipment-validate').valid()) {
        $('#jstemplates_line_items').html('');
        $('#jstemplates_inbound_shipment_attachments').html('');
        $(".loading-overlay").show();
        $('#inboundShipmentSubmit').click();
      }
      else {
        return false;
      }
    });
  
  //---- Category Modal Related --------------------------------------------------//
  
  
    var selectizeCallback = null;
  
    $('.category-modal').on('hide.bs.modal', function(e) {
      if (selectizeCallback != null) {
        selectizeCallback();
        selectizeCallback = null;
      }
  
      $('#new_category').trigger('reset');
      Rails.enableElement($('#new_category')[0]);
    });
  
    $('#new_category').on('submit', function(e) {
      e.preventDefault();
      $.ajax({
        method: 'POST',
        url: $(this).attr('action'),
        data: $(this).serialize(),
        success: function(response) {
          selectizeCallback({value: response.id, text: response.name});
          selectizeCallback = null;
  
          $('.category-modal').modal('toggle');
        }
      });
    });
  
    $('.inboundShipmentCategories').selectize({
      create: function(input, callback) {
        selectizeCallback = callback;
  
        $('.category-modal').modal();
        $('#category_name').val(input);
      },
    });
    
  //---- Line Items Related ------------------------------------------------------//
  
    // Child Fields Template Loader inherited from inbound_shipments_helper.rb for Line Items
    $(document).on('click', 'a.add_child_line_item', function() {
      var association, new_id, regexp, template, fields;
      association = $(this).attr('data-association');
      template = $('#' + association + '_fields_template').html();
      regexp = new RegExp('new_' + association, 'g');
      new_id = (new Date).getTime();
      $(this).parent().before(template.replace(regexp, new_id));
      // The 'jstemplates' hidden field needs the '.ignore' class to ensure it
      // doesn't get picked up by Jquery Validator.  This line removes it.
      fields = $(this).parent().prev('.fields');
      fields.find('select').removeClass('ignore');
      fields.find('.productSearch').select2({
        selectOnClose: true,
        allowClear: true,
        placeholder: 'Select Item...',
        ajax: {
          url: '/json_dropdown_products_all.json',
          dataType: 'json',
          type: 'GET',
          processResults: function (data, params) {
            params.page = data.page || 1;
            return {
              results: $.map(data.items, function (item) {
                return {
                  text: item.sku + ' | ' + item.desc,
                  id: item.id,
                  quantity_on_hand: item.qty
                };
              }),
              pagination: {
                more: data.current_page
              }
            };
          },
          cache: true
        },
        templateSelection: function(container) { // adds the current quantity on hand to the items 'data-quantity' attribute
            $(container.element).attr('data-quantity', container.quantity_on_hand);
            return container.text;
        }
      });
  
      // monkeypatch to re-enable scrollwheel on number fields
      $('.scroll').bind('mousewheel', function() {});
  
      return false;
    });
  
    $(document).on('click', 'a.remove_child_line_item', function() { // Triggers the removal of the relevant 'Item Select' table row when clicked
      var tr = $(this).closest('tr');
      var hidden_field = $(this).prev('input[type=hidden]')[0];
      tr.find('.productSearch').val([]).trigger('change');
      if(hidden_field) {
        hidden_field.value = '1';
      }
      $(this).parents('.fields').remove();
      return false;
    });
  
    $(document).on('change', '.productSearch', function() {
      $(this).next().removeClass('myErrorClass');
      var fields = $(this).closest('.fields');
      var tr = $(this).closest('tr');
      tr.find('.order-quantity').attr('readonly', false); // Enables the quantity fields for this given field
      tr.find('.received-quantity').attr('readonly', false); // Enables the quantity fields for this given field
      tr.find('.current-quantity').val($(this).find('option:selected').data('quantity')); // imports the quantity from the 'data-quantity' attribute of the selected option
      var arr = [];
      $('.productSearch').each(function(){
        $(this).next().removeClass('myErrorClass');
        var value = $(this).find('option:selected').val();
        if (arr.indexOf(value) == -1) {
          $(this).next().removeClass('myErrorClass');
          arr.push(value);
        } else {
          if ($(this).next().text() == 'Select Item...') {
            $(this).next().removeClass('myErrorClass');
          } else {
            $(this).next().addClass('myErrorClass');
          }
        }
      });
  
    });
  
  //---- Attachments Related -----------------------------------------------------//
  
    // Child Fields Template Loader inherited from inbound_shipments_helper.rb for Associated Attachments
    $(document).on('click', 'a.add_child_inbound_shipment_attachments', function() {
      var association, new_id, regexp, template, fields;
      association = $(this).attr('data-association');
      template = $('#' + association + '_fields_template').html();
      regexp = new RegExp('new_' + association, 'g');
      new_id = (new Date).getTime();
      $(this).parent().before(template.replace(regexp, new_id));
      fields = $(this).parent().prev('.fields_inbound_shipment_attachments');
      // The 'jstemplates' hidden field needs the '.ignore' class to ensure it
      // doesn't get picked up by Jquery Validator.  This line removes it.
      fields.find('input').removeClass('ignore');
      fields.find('input[name$="[description]"]').each(function() {
        // rules for nested file fields need to be generated after the object is created
        $(this).rules('add', {
          messages: {
            required: `A title is required. If you don't intend to save this attachment, please click the "Remove" button below`
          }
        });
      });
      // ActionText Trix ID's need to be manually created, otherwise it will duplicate
      // itself as 'trix-toolbar-1' on all instances of a Trix Editor
      fields.find('trix-toolbar').attr('id', 'trix-toolbar-' + new_id);
      fields.find('trix-editor').attr('trix-id', new_id).attr('toolbar', 'trix-toolbar-' + new_id);
      fields.hide();
      fields.slideDown('fast');
      return false;
    });
  
    $(document).on('click', 'a.remove_child_inbound_shipment_attachments', function() {
      var hidden_field = $(this).prev('input[type=hidden]')[0];
      var fields = $(this).parents('.fields_inbound_shipment_attachments');
      if(hidden_field) {
        hidden_field.value = '1';
      }
      $(this).parents('.fields_inbound_shipment_attachments').slideUp('slow', function() {
        fields.remove();
      });
      return false;
    });
  
    $('.add_inbound_shipment_attachment_button').on('click', function() {
      $('.add_child_inbound_shipment_attachments').click();
    });
    
    $(document).on('change keyup', '.attachment-description', function() {
      var new_description = $(this).val();
      $(this).closest('.edit-attachment-fields').find('.attachment-title').text(new_description);
    });
  
    // This facilitates deleting a file via AJAX
    $('.inbound_shipment-attachment-delete').on('ajax:success', function() {
      var parent = $(this).closest('li');
      parent.slideUp( 'slow', function() { parent.remove() } );
    });
  
    // monkeypatch to fix issue of search field not being selected on open.  https://github.com/select2/select2/issues/2311#issuecomment-822473913
    $(document).on('select2:open', e => {
      const id = e.target.id;
      const target = document.querySelector(`[aria-controls=select2-${id}-results]`);
      target.focus();
    });

  }

//----------------------------------------------------------------------------//
//---------- INBOUND SHIPMENTS INDEX RELATED ---------------------------------//
//----------------------------------------------------------------------------//

  if ($('.inbound_shipments_index_div').length > 0) {
    
    var MyDate = new Date();
    var date;
    MyDate.setDate(MyDate.getDate());
    date = MyDate.getFullYear() + "-" + ("0" + (MyDate.getMonth() + 1)).slice(-2) + "-" + ("0" + MyDate.getDate()).slice(-2);
    var filename = "Inbound Shipments, " + date + " Export";
    var table = $("#inboundShipmentsDataTable").DataTable({
      "retrieve": true,
      "processing": true,
      "serverSide": true,
      "ajax": {
        "url": $("#inboundShipmentsDataTable").data("source")
      },
      "columns": [
  /*0 */{data: "id", searchable: false, visible: false},
  /*1 */{data: "status"},
  /*2 */{data: "supplier"},
  /*3 */{data: "carrier", visible: false},
  /*4 */{data: "category"},
  /*5 */{data: "shipment_type"},
  /*6 */{data: "po_number"},
  /*7 */{data: "container_number"},
  /*8 */{data: "line_items", searchable: false, orderable: false},
  /*9 */{data: "order_date"},
  /*10*/{data: "ship_date"},
  /*11*/{data: "estimated_delivery_date"},
  /*12*/{data: "actual_delivery_date"},
  /*13*/{data: "added_to_stock", searchable: false, orderable: false},
  /*14*/{data: "notes", orderable: false},
  /*15*/{data: "created_by", visible: false},
  /*16*/{data: "actions", orderable: false}
      ],
      "order": [[ 1, "asc" ], [ 10, "desc" ], [ 9, "desc" ], [ 8, "desc" ], [ 7, "desc" ]],
      "oLanguage": { "sZeroRecords": "No records to display for this filter" },
      "dom": "<'row'<'col-sm-10 dataTableToolbar'><'col-sm-2'B>>t<'row dataTableBottomRow'<'col-md-2'i><'col-md-10'p>>",
      "buttons": [
        {
          extend: "excelHtml5",
          title: filename,
          // Only exports columns that are currently visible.  Adjusted by the "Visibility" dropdown and Filtered text.
          exportOptions: {columns: ":visible"},
          className: "dtButtonHide"
        },
        {
          extend: "csv",
          title: filename,
          exportOptions: {columns: ":visible"},
          className: "dtButtonHide"
        },
        {
          extend: "pdfHtml5",
          title: filename,
          orientation: "landscape",
          exportOptions: {columns: ":visible"},
          className: "dtButtonHide"
        },
        {
          extend: "copyHtml5",
          title: filename,
          exportOptions: {columns: ":visible"},
          className: "dtButtonHide"
        }, 
        {
          extend: "print",
          autoPrint: false,
          title: filename,
          exportOptions: {columns: ":visible"},
          customize: function (win) {
              $(win.document.body).find('table').addClass('display').css('font-size', '12px');
              $(win.document.body).find('tr:nth-child(odd) td').each(function(index){
                  $(this).css('background-color','#D0D0D0');
              });
              $(win.document.body).find('h1').css('text-align','center');
          },
          className: "dtButtonHide"
        }
      ]
    });
  
    function dataTableToolbar() {
      return "" +
      "<ul class='nav' style='margin-bottom:10px'>" +
      "  <li>" +
      "    <span class='options-label'>Options:</span>" +
      "  </li>" +
      "  <li>" +
      "    <div class='dataTables_length' id='inboundShipmentsDataTable_length'>" +
      "      <select name='inboundShipmentsDataTable_length' aria-controls='inboundShipmentsDataTable' class='form-control form-control-sm tableLength' title='Number of records to show'>" +
      "        <option value='5'>5</option>" +
      "        <option value='10'>10</option>" +
      "        <option selected='selected' value='25'>25</option>" +
      "        <option value='50'>50</option>" +
      "        <option value='100'>100</option>" +
      "        <option value='250'>250</option>" +
      "      </select>" +
      "    </div>" +
      "  </li>" +
      "  <li>" +
      "    <div class='tableClickerDiv dropdown'>" +
      "      <button class='btn btn-outline-primary btn-sm dropdown-toggle' title='Column visibility options' type='button' id='tableClickerColvis' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'><i class='fas fa-columns'></i> Columns</button>" +
      "      <div class='dropdown-menu card-shadow' aria-labelledby='tableClickerColvis'>" +
      "        <a class='dropdown-item button-highlight' id='colvisClicker-0'>Record ID</a>" +
      "        <a class='dropdown-item' id='colvisClicker-1'>Shipment Status</a>" +
      "        <a class='dropdown-item' id='colvisClicker-2'>Supplier</a>" +
      "        <a class='dropdown-item button-highlight' id='colvisClicker-3'>Carrier</a>" +
      "        <a class='dropdown-item' id='colvisClicker-4'>Category</a>" +
      "        <a class='dropdown-item' id='colvisClicker-5'>Shipment Type</a>" +
      "        <a class='dropdown-item' id='colvisClicker-6'>PO Number</a>" +
      "        <a class='dropdown-item' id='colvisClicker-7'>Container Number</a>" +
      "        <a class='dropdown-item' id='colvisClicker-8'>Line Items</a>" +
      "        <a class='dropdown-item' id='colvisClicker-9'>Order Date</a>" +
      "        <a class='dropdown-item' id='colvisClicker-10'>Ship Date</a>" +
      "        <a class='dropdown-item' id='colvisClicker-11'>Estimated Delivery Date</a>" +
      "        <a class='dropdown-item' id='colvisClicker-12'>Actual Delivery Date</a>" +
      "        <a class='dropdown-item' id='colvisClicker-13'>Adjustments</a>" +
      "        <a class='dropdown-item' id='colvisClicker-14'>Notes</a>" +
      "        <a class='dropdown-item button-highlight' id='colvisClicker-15'>Created By</a>" +
      "        <a class='dropdown-item' id='colvisClicker-16'>Actions</a>" +
      "      </div>" +
      "    </div>" +
      "  </li>" +
      "  <li>" +
      "    <div class='tableClickerDiv dropdown'>" +
      "      <button class='btn btn-outline-primary btn-sm dropdown-toggle' title='Export Options' type='button' id='tableClickerExports' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'><i class='fas fa-file-export'></i> Export</button>" +
      "      <div class='dropdown-menu' aria-labelledby='tableClickerExports'>" +
      "        <div class='text-primary' style='font-size:11px;padding:0.25rem 1.5rem;'>" +
      "          <i class='fas fa-exclamation-triangle text-danger'></i> Only the currently visible columns & rows will be exported" +
      "        </div>" +
      "        <a class='dropdown-item tableClickerExcel'><i class='fas fa-file-excel file-icon-color'></i> Excel <i class='fas fa-download text-primary'></i></a>" +
      "        <a class='dropdown-item tableClickerCsv'><i class='fas fa-file-csv file-icon-color'></i> CSV <i class='fas fa-download text-primary'></i></a>" +
      "        <a class='dropdown-item tableClickerPdf'><i class='fas fa-file-pdf file-icon-color'></i> PDF <i class='fas fa-download text-primary'></i></a>" +
      "        <a class='dropdown-item tableClickerCopy'><i class='fas fa-copy file-icon-color'></i> Copy</a>" +
      "        <a class='dropdown-item tableClickerPrint'><i class='fas fa-print file-icon-color'></i> Print/Preview</a>" +
      "      </div>" +
      "    </div>" +
      "  </li>" +
      "  <li class='filter-section mt-2' style='width:100%'>" +
      "    <span class='filter-label'>Filters:</span>" +
      // Investigate why global table search isn't working here
      // "    <div id='inboundShipmentsDataTable_filter' class='dataTables_filter mr-2'>" +
      // "      <input id='inboundShipmentsTableSearch' class='form-control form-control-sm tableSearch' title='right-click to clear' oncontextmenu='return false' aria-controls='inboundShipmentsDataTable' placeholder='Search Entire Table...'>" +
      // "    </div>" +
      "    <span id='supplier-select-dropdown' class='filter-span-select colvisSearch-2'></span>" +
      "    <span id='carrier-select-dropdown' class='filter-span-select colvisSearch-3'></span>" +
      "    <span id='shipment-status-dropdown' class='filter-span-select colvisSearch-1'></span>" +
      "    <span id='shipment-type-dropdown' class='filter-span-select colvisSearch-5'></span>" +
      "    <span id='po-number-select-input' class='filter-span-input colvisSearch-6'></span>" +
      "    <span id='container-number-select-input' class='filter-span-input colvisSearch-7'></span>" +
      "  </li>" +
      "</ul>" +
      "";
    }
  
    table.page.len(25).draw();

    $("div.dataTableToolbar").html(dataTableToolbar());
  
    var search = $.fn.dataTable.util.throttle(
      function (val) {
        table.search(val).draw();
      }, 500);
     
    $('.tableSearch').on('keyup', function () {
      search( this.value );
    });
  
    $(".tableLength").on("change", function () {
      table.page.len($(this).find("option:selected").attr("value")).draw();
    });
  
    function colVisButtons(index) {
      var button = $("#colvisClicker-"+index);
      button.on("click", function(e) {
        e.stopPropagation();
        if (table.column(index).visible() === true) {
          table.column(index).visible(false);
          button.addClass("button-highlight");
          $(".colvisSearch-"+index).hide();
        }
        else {
          table.column(index).visible(true);
          button.removeClass("button-highlight");
          $(".colvisSearch-"+index).show();
        }
      });
    }
    let colVisIndex = -1;
    do { colVisIndex += 1;
         colVisButtons(colVisIndex);
    } while (colVisIndex < 16);
  
    yadcf.init(table,
      [
  
        { column_number : 2, // Supplier
          filter_container_id : "supplier-select-dropdown",
          filter_default_label : "Suppliers... ↓↓↓",
          filter_match_mode : "exact",
          filter_type: 'multi_select',
          select_type: 'select2',
          data: $('#params_span').data('suppliers_dropdown')
        },
  
        { column_number : 3, // Carrier
          filter_container_id : "carrier-select-dropdown",
          filter_default_label : "Carriers... ↓↓↓",
          filter_match_mode : "exact",
          filter_type: 'multi_select',
          select_type: 'select2',
          data: $('#params_span').data('carriers_dropdown')
        },
  
        { column_number : 5, // Shipment Type
          filter_container_id : "shipment-type-dropdown",
          style_class : "form-control form-control-sm",
          filter_default_label : "Shipment Type...",
          filter_type: 'select',
          data: [
            {value: "1", label : "Container"},
            {value: "2", label : "Truck"},
            {value: "3", label : "Small Parcel"},
          ]
        },
  
        { column_number : 1, // Shipment Status
          filter_container_id : "shipment-status-dropdown",
          style_class : "form-control form-control-sm",
          filter_default_label : "Shipment Status...",
          filter_type: 'select',
          data: [
            {value: "1", label : "Pending"},
            {value: "2", label : "In Transit"},
            {value: "3", label : "Delivered"},
          ]
        },
  
        { column_number : 6, // PO Number
          filter_type: "text",
          filter_container_id : "po-number-select-input",
          style_class : "form-control form-control-sm",
          filter_default_label : "Search PO #..."
        },
  
        { column_number : 7, // Container Number
          filter_type: "text",
          filter_container_id : "container-number-select-input",
          style_class : "form-control form-control-sm",
          filter_default_label : "Search Container Number..."
        },
  
      ],
      { filters_position : "footer" },
      { externally_triggered : true }
    );
  
    // This block executes any query string searches that may be present in the URL
    function filterTable(columnIndex, filterValue){
      yadcf.exFilterColumn(table, [
        [columnIndex, filterValue],
      ], true);
    }
    const queryStringParams = window.location.search.substring(1).split('&');

    queryStringParams.forEach(param => {
        if (param.indexOf('supplier') !== -1) {
          filterTable(2, $('#params_span').data('supplier'));
        } else if (param.indexOf('carrier') !== -1) {
          filterTable(3, $('#params_span').data('carrier'));
        } else if (param.indexOf('po') !== -1) {
          filterTable(6, $('#params_span').data('po'));
        } else if (param.indexOf('container_number') !== -1) {
          filterTable(7, $('#params_span').data('container_number'));
        } else if (param.indexOf('shipment_status') !== -1) {
          filterTable(1, [$('#params_span').data('shipment_status')]);
        } else if (param.indexOf('shipment_type') !== -1) {
          filterTable(5, [$('#params_span').data('shipment_type')]);
        }
    });

    // Text column searches need to be executed a second time to trigger the actual search
    yadcf.exFilterColumn(table, [
      [6, yadcf.exGetColumnFilterVal(table,6)],
      [7, yadcf.exGetColumnFilterVal(table,7)]
    ], true);

    // $(document).on("click", ".notesModalButton", function() {
    //   $(this).closest("td").find(".notes-modal").modal();
    // });

    $('body').tooltip({selector: '[data-toggle="tooltip"]'});

  }

//----------------------------------------------------------------------------//
//---------- INBOUND SHIPMENTS SHOW RELATED ----------------------------------//
//----------------------------------------------------------------------------//

  if ($('.inbound_shipment_show_div').length > 0) {

    // This facilitates deleting an association via AJAX
    $(".inbound-shipment-delete").on("ajax:success", function() {
      var parent = $(this).closest("tr");
      parent.next("input").remove();
      parent.slideUp( "fast", function() { parent.remove() } );
    });
  
    // This facilitates deleting a file via AJAX
    $(".inbound-shipment-attachment-delete").on("ajax:success", function() {
      var parent = $(this).closest("li");
      parent.slideUp( "slow", function() { parent.remove() } );
    });

  }

});