/*global $*/
/*global globalObjects*/

import 'stylesheets/categories-global.scss';

$(document).on('turbolinks:load', function() {

//----------------------------------------------------------------------------//
//---------- CATEGORIES FORM RELATED -----------------------------------------//
//----------------------------------------------------------------------------//

  if ($('.category-validate').length > 0) {

    $('label.required').append('<strong> *</strong>'); // adds a 'required' star next to each Inputs label
  
    $('.category-validate').validate({
      ignore: '.ignore',
      onkeyup: function(element) {$(element).valid()},
      errorClass: 'myErrorClass',
      errorPlacement: function (error, element) {
        error.insertAfter(element.parent());
      },
      highlight: function (element, errorClass, validClass) {
        $(element).addClass(errorClass);
      },    
      unhighlight: function (element, errorClass, validClass) {
        $(element).removeClass(errorClass);
        $(element).parent().next('label').remove();
      },
  		invalidHandler: function(e, validator) {
  			var errors = validator.numberOfInvalids();
  			if (errors) {
  				var message = errors == 1
  					? 'You missed 1 required field. It has been highlighted above'
  					: 'You missed ' + errors + ' required fields.  They have been highlighted above';
  				$('div.validatorErrorList span').html(message);
  				$('div.validatorErrorList').show();
  			}
  			else {
  				$('div.validatorErrorList').hide();
  			}
  		}
    });
  
    $('.category_items').selectize();

    $('#categoryFirstSubmit').on('click', function(e) {
      e.preventDefault();
      if ($('.category-validate').valid()) {
        $('#categorySubmit').click();
      }
      else {
        return false;
      }
    });

  }

//----------------------------------------------------------------------------//
//---------- CATEGORIES INDEX RELATED ----------------------------------------//
//----------------------------------------------------------------------------//

  if ($('.categories_index_div').length > 0) {
    
    var MyDate = new Date();
    var date;
    MyDate.setDate(MyDate.getDate());
    date = MyDate.getFullYear() + "-" + ("0" + (MyDate.getMonth() + 1)).slice(-2) + "-" + ("0" + MyDate.getDate()).slice(-2);
    var filename = "Categories Export, " + date;
    var table = $("#categoriesDataTable").DataTable({
      "retrieve": true,
      "oLanguage": { "sZeroRecords": "No categories to display for this filter" },
      "dom": "<'row'<'col-sm-10 dataTableToolbar'><'col-sm-2'B>>t<'row dataTableBottomRow'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-6'p>>",
      "buttons": [
        {
          extend: "excelHtml5",
          title: filename,
          // Only exports columns that are currently visible.  Adjusted by the "Visibility" dropdown and Filtered text.
          exportOptions: {columns: ":visible"},
          className: "dtButtonHide"
        },
        {
          extend: "csv",
          title: filename,
          exportOptions: {columns: ":visible"},
          className: "dtButtonHide"
        },
        {
          extend: "pdfHtml5",
          title: filename,
          orientation: "landscape",
          exportOptions: {columns: ":visible"},
          className: "dtButtonHide"
        },
        {
          extend: "copyHtml5",
          title: filename,
          exportOptions: {columns: ":visible"},
          className: "dtButtonHide"
        }, 
        {
          extend: "print",
          autoPrint: false,
          title: filename,
          exportOptions: {columns: ":visible"},
          customize: function (win) {
              $(win.document.body).find('table').addClass('display').css('font-size', '12px');
              $(win.document.body).find('tr:nth-child(odd) td').each(function(index){
                  $(this).css('background-color','#D0D0D0');
              });
              $(win.document.body).find('h1').css('text-align','center');
          },
          className: "dtButtonHide"
        }
      ],
      "columnDefs": [
        {
        "targets": 0,
        "visible": false,
        }
      ],
      "order": [[1, "asc"]],
    });
    
  
    function dataTableToolbar() {
      return "" +
      "<ul class='nav' style='margin-bottom:10px'>" +
      "  <li>" +
      "    <div id='categoriesDataTable_filter' class='dataTables_filter mr-2'>" +
      "      <input id='categoriesTableSearch' class='form-control form-control-sm tableSearch' title='right-click to clear' oncontextmenu='return false' aria-controls='categoriesDataTable' placeholder='Search Table...'>" +
      "    </div>" +
      "  <li>" +
      "    <div class='dataTables_length' id='categoriesDataTable_length'>" +
      "      <select name='categoriesDataTable_length' aria-controls='categoriesDataTable' class='form-control form-control-sm tableLength' title='Number of records to show'>" +
      "        <option value='5'>5</option>" +
      "        <option value='10'>10</option>" +
      "        <option value='25'>25</option>" +
      "        <option value='50'>50</option>" +
      "        <option value='100'>100</option>" +
      "        <option selected='selected' value='-1'>All</option>" +
      "      </select>" +
      "    </div>" +
      "  </li>" +
      "  <li>" +
      "    <div class='tableClickerDiv dropdown'>" +
      "      <button class='btn btn-outline-primary btn-sm dropdown-toggle' type='button' id='tableClickerExports' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'><i class='fas fa-file-export'></i> Export</button>" +
      "      <div class='dropdown-menu' aria-labelledby='tableClickerExports'>" +
      "        <div class='text-primary' style='font-size:11px;padding:0.25rem 1.5rem;'>" +
      "          <i class='fas fa-exclamation-triangle text-danger'></i> Only the currently visible columns & rows will be exported" +
      "        </div>" +
      "        <a class='dropdown-item tableClickerExcel'><i class='fas fa-file-excel file-icon-color'></i> Excel <i class='fas fa-download text-primary'></i></a>" +
      "        <a class='dropdown-item tableClickerCsv'><i class='fas fa-file-csv file-icon-color'></i> CSV <i class='fas fa-download text-primary'></i></a>" +
      "        <a class='dropdown-item tableClickerPdf'><i class='fas fa-file-pdf file-icon-color'></i> PDF <i class='fas fa-download text-primary'></i></a>" +
      "        <a class='dropdown-item tableClickerCopy'><i class='fas fa-copy file-icon-color'></i> Copy</a>" +
      "        <a class='dropdown-item tableClickerPrint'><i class='fas fa-print file-icon-color'></i> Print/Preview</a>" +
      "      </div>" +
      "    </div>" +
      "  </li>" +
      "  <li>" +
      "    <div class='tableClickerDiv dropdown'>" +
      "      <button class='btn btn-outline-primary btn-sm dropdown-toggle' type='button' id='tableClickerColvis' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'><i class='fas fa-columns'></i> Columns</button>" +
      "      <div class='dropdown-menu' aria-labelledby='tableClickerColvis'>" +
      "        <a class='dropdown-item button-highlight' id='colvisClicker-0'>ID</a>" +
      "        <a class='dropdown-item' id='colvisClicker-1'>Name</a>" +
      "        <a class='dropdown-item' id='colvisClicker-2'>Description</a>" +
      "        <a class='dropdown-item' id='colvisClicker-3'>Associated Items</a>" +
      "        <a class='dropdown-item' id='colvisClicker-4'>7d Movement</a>" +
      "      </div>" +
      "    </div>" +
      "  </li>" +
      "</ul>" +
      "";
    }
  
    table.page.len(-1).draw();
  
    $("div.dataTableToolbar").html(dataTableToolbar());
  
    $(".tableSearch").keyup(function () {
      table.search($(this).val()).draw();
    });
  
    $(".tableLength").on("change", function () {
      table.page.len($(this).find("option:selected").attr("value")).draw();
    });
  
    function colVisButtons(index) {
      var button = $("#colvisClicker-"+index);
      button.on("click", function(e) {
        e.stopPropagation();
        if (table.column(index).visible() === true) {
          table.column(index).visible(false);
          button.addClass("button-highlight");
        }
        else {
          table.column(index).visible(true);
          button.removeClass("button-highlight");
        }
      });
    }
    let colVisIndex = -1;
    do { colVisIndex += 1;
         colVisButtons(colVisIndex);
    } while (colVisIndex < 4);
  
    $(".dt-buttons").hide(); // Hides default Colvis & Export buttons

    $('body').tooltip({selector: '[data-toggle="tooltip"]'});

  }

//----------------------------------------------------------------------------//
//---------- CATEGORIES SHOW RELATED -----------------------------------------//
//----------------------------------------------------------------------------//

  if ($('.category_show_div').length > 0) {

    // Adjustments History Chart functions to show or hide the day/week charts
    $(document).on("click", ".IASortByDayButton", function() {
      var div = $(this).closest("#history_charts");
      var iaw = div.find(".iaw");
      var iad = div.find(".iad");

      if (iad.is(':visible')) {
        return;
      } else {
        iad.slideDown('slow');
        iaw.slideUp('slow');
        $(this).removeClass('badge-secondary').addClass('badge-success');
        $('.IASortByWeekButton').removeClass('badge-success').addClass('badge-secondary');
      }

    });

    $(document).on("click", ".IASortByWeekButton", function() {
      var div = $(this).closest("#history_charts");
      var iad = div.find(".iad");
      var iaw = div.find(".iaw");

      if (iaw.is(':visible')) {
        return;
      } else {
        iad.slideUp('slow');
        iaw.slideDown('slow');
        $(this).removeClass('badge-secondary').addClass('badge-success');
        $('.IASortByDayButton').removeClass('badge-success').addClass('badge-secondary');
      }

    });
  
    $('.discontinued_button').on('click', function() {
      $('#discontinued_items-tab-vertical').click();
    });

    // Current Item Costing Table
    var MyDate = new Date();
    var date;
    MyDate.setDate(MyDate.getDate());
    date = MyDate.getFullYear() + "-" + ("0" + (MyDate.getMonth() + 1)).slice(-2) + "-" + ("0" + MyDate.getDate()).slice(-2);
    var filename = "Category Item Export, " + date;
    var table = $(".category_items_table").DataTable({
      "retrieve": true,
      "processing": true,
      // "order": [[ 1, "asc" ], [ 10, "desc" ], [ 9, "desc" ], [ 8, "desc" ], [ 7, "desc" ]],
      "oLanguage": { "sZeroRecords": "No records to display for this filter" },
      "dom": "<'row'<'col-sm-10 dataTableToolbar'><'col-sm-2'B>>t<'row dataTableBottomRow'<'col-md-2 ml-3'i><'col-md-9'p>>",
      "buttons": [
        {
          extend: "excelHtml5",
          title: filename,
          // Only exports columns that are currently visible.  Adjusted by the "Visibility" dropdown and Filtered text.
          exportOptions: {columns: ":visible"},
          className: "dtButtonHide"
        },
        {
          extend: "csv",
          title: filename,
          exportOptions: {columns: ":visible"},
          className: "dtButtonHide"
        },
        {
          extend: "pdfHtml5",
          title: filename,
          orientation: "landscape",
          exportOptions: {columns: ":visible"},
          className: "dtButtonHide"
        },
        {
          extend: "copyHtml5",
          title: filename,
          exportOptions: {columns: ":visible"},
          className: "dtButtonHide"
        }, 
        {
          extend: "print",
          autoPrint: false,
          title: filename,
          exportOptions: {columns: ":visible"},
          customize: function (win) {
              $(win.document.body).find('table').addClass('display').css('font-size', '12px');
              $(win.document.body).find('tr:nth-child(odd) td').each(function(index){
                  $(this).css('background-color','#D0D0D0');
              });
              $(win.document.body).find('h1').css('text-align','center');
          },
          className: "dtButtonHide"
        }
      ]
    });
  
    function dataTableToolbar(category) {
      return "" +
      "<ul class='nav ml-2 mb-3'>" +
      "  <li>" +
      "    <div class='dataTables_length' id='category_items_table_length'>" +
      "      <select name='category_items_table_length' aria-controls='category_items_table' class='form-control form-control-sm tableLength' title='Number of records to show'>" +
      "        <option value='5'>5</option>" +
      "        <option value='10'>10</option>" +
      "        <option value='25'>25</option>" +
      "        <option selected='selected' value='50'>50</option>" +
      "        <option value='100'>100</option>" +
      "        <option value='250'>250</option>" +
      "        <option value='-1'>All</option>" +
      "      </select>" +
      "    </div>" +
      "  </li>" +
      "  <li>" +
      "    <div class='tableClickerDiv dropdown'>" +
      "      <button class='btn btn-outline-primary btn-sm dropdown-toggle' title='Export Options' type='button' id='tableClickerExports' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'><i class='fas fa-file-export'></i> Export</button>" +
      "      <div class='dropdown-menu' aria-labelledby='tableClickerExports'>" +
      "        <div class='text-primary' style='font-size:11px;padding:0.25rem 1.5rem;'>" +
      "          <i class='fas fa-exclamation-triangle text-danger'></i> Only the currently visible columns & rows will be exported" +
      "        </div>" +
      "        <a class='dropdown-item tableClickerExcel'><i class='fas fa-file-excel file-icon-color'></i> Excel <i class='fas fa-download text-primary'></i></a>" +
      "        <a class='dropdown-item tableClickerCsv'><i class='fas fa-file-csv file-icon-color'></i> CSV <i class='fas fa-download text-primary'></i></a>" +
      "        <a class='dropdown-item tableClickerPdf'><i class='fas fa-file-pdf file-icon-color'></i> PDF <i class='fas fa-download text-primary'></i></a>" +
      "        <a class='dropdown-item tableClickerCopy'><i class='fas fa-copy file-icon-color'></i> Copy</a>" +
      "        <a class='dropdown-item tableClickerPrint'><i class='fas fa-print file-icon-color'></i> Print/Preview</a>" +
      "      </div>" +
      "    </div>" +
      "  </li>" +
      "  <li class='filter-section ml-3'>" +
      "    <div id='category_items_table_table_filter' class='dataTables_filter mr-2'>" +
      "      <input id='category_items_table_tableSearch' class='form-control form-control-sm tableSearch' title='right-click to clear' oncontextmenu='return false' aria-controls='category_items_table_table' placeholder='Search Entire Table...'>" +
      "    </div>" +
      "    <form action='/categories/" + category + "' accept-charset='UTF-8' method='get'>" +
      "      <span class='filter-span-input colvisSearch-10' title='Select the number of weeks worth of orders to show'>" +
      "        <div>" +
      "          <div class='yadcf-filter-wrapper'>" +
      "            <div class='yadcf-filter-wrapper-inner'>" +
      "              <input type='number' name='order_date_range' id='order_date_range' title='This updates the quantity sold over the last X weeks in the `Orders Last 8 Weeks` column' placeholder='Order Range (in weeks)' class='filter-span-input'>" +
      "            </div>" +
      "            <button type='submit' class='yadcf-filter-reset-button' title='Note that Date Range search will clear all other filters'>Go</button>" +
      "          </div>" +
      "        </div>" +
      "      </span>" +
      "    </form>" +
      "  </li>" +
      "</ul>" +
      "";
    }
  
    table.page.len(50).draw();
  
    var category_id = $('.category_items_table').data('category')
    $("div.dataTableToolbar").html(dataTableToolbar(category_id));
  
    $(".tableLength").on("change", function () {
      table.page.len($(this).find("option:selected").attr("value")).draw();
    });
  
    var search = $.fn.dataTable.util.throttle(
      function (val) {
        table.search(val).draw();
      }, 500);
     
    $('.tableSearch').on('keyup', function () {
      search( this.value );
    });
  
    globalObjects.genericDataTable('customerOrders');
    globalObjects.genericDataTable('transferOrders');
    globalObjects.genericDataTable('otherOrders');

  }

});
