/*global $*/
/* global GMaps */
/* global google */
import "packs/maps/gmaps";
import GMaps from "packs/maps/gmaps";
window.GMaps = GMaps;

import 'stylesheets/addresses-global.scss';

$(document).on("turbolinks:load", function() {

//----------------------------------------------------------------------------//
//---------- ADDRESSES FORM RELATED ------------------------------------------//
//----------------------------------------------------------------------------//

  if ($('.address-validate').length > 0) {

    $(".addressState").select2({
      placeholder: "State",
      allowClear: true
    }).on("change", function() {
        $(this).valid();
      });
    $(".addressCountry").select2({
      placeholder: "Country",
      allowClear: true
    }).on("change", function() {
        $(this).valid();
      });
    $(".select2-basic-nosearch").select2({
      minimumResultsForSearch: Infinity,
      placeholder: "Select one...",
      allowClear: true
    }).on("change", function() {
        $(this).valid();
      });
  
    $('.addressSupplierSearch').select2({
      minimumResultsForSearch: 10,
      allowClear: true,
      placeholder: "Select Supplier...",
      ajax: {
        url: "/addressable_dropdowns.json",
        dataType: "json",
        type: "GET",
        processResults: function (data, params) {
          params.page = data.page || 1;
          return {
            results: $.map(data.items, function (item) {
              return {
                text: item.brand_name,
                id: item.id
              };
            }),
            pagination: {
              more: data.current_page
            }
          };
        },
        cache: true
      }
    });
  
    $(".phone_mask").inputmask({"mask": "(999) 999-9999"});
    $(".zip_mask").inputmask({ mask: "99999[-9999]", greedy: false });
  
    $('label.required').append('<strong> *</strong>'); // adds a "required" star next to each Inputs label
    $('label.required-s span').append('<strong> *</strong>'); // adds a "required" star next to each Selects label
  
    $(".address-validate").validate({
      ignore: ".ignore",
      onkeyup: function(element) {$(element).valid()}, // onkeyup: false,
      errorClass: "myErrorClass",
      errorPlacement: function (error, element) {
        error.insertAfter(element.parent());
      },
      highlight: function (element, errorClass, validClass) {
        var elem = $(element);
        if (elem.hasClass("select2-hidden-accessible")) {
          elem.parent().find(".select2-selection").addClass(errorClass);
        } 
        else {
          elem.addClass(errorClass);
        }
      },    
      unhighlight: function (element, errorClass, validClass) {
        var elem = $(element);
        if (elem.hasClass("select2-hidden-accessible")) {
          elem.parent().find(".select2-selection").removeClass(errorClass);
          elem.parent().next("label").remove();
        }
        else {
          elem.removeClass(errorClass);
          elem.parent().next("label").remove();
        }
      },
  		invalidHandler: function(e, validator) {
  			var errors = validator.numberOfInvalids();
  			if (errors) {
  				var message = errors == 1
  					? 'You missed 1 required field. It has been highlighted above'
  					: 'You missed ' + errors + ' required fields.  They have been highlighted above';
  				$("div.validatorErrorList span").html(message);
  				$("div.validatorErrorList").show();
  			}
  			else {
  				$("div.validatorErrorList").hide();
  			}
  		}
    });
  
    $("#addressFirstSubmit").on("click", function(e) {
      e.preventDefault();
      if ($(".address-validate").valid()) {
        $("#addressSubmit").click();
      }
      else {
        return false;
      }
    });
  

    $(document).on("change keyup", function() {
      // This populates the hidden "State" field since a select_tag is used 
      var state = $(this).find(".addressState").find("option:selected").val();
      $(this).find(".addressStateHidden").val(state);
    
      if ($(".addressCountry").find("option:selected").val() == "US") {
        $(".phone_mask").inputmask({ mask: "(999) 999-9999[-999999]", greedy: false, showMaskOnHover: true });
        $(".zip_mask").inputmask({ mask: "99999[-9999]", greedy: false, showMaskOnHover: true });
      }
      else {
        $(".phone_mask").inputmask("remove");
        $(".zip_mask").inputmask("remove");
      }
    });

  }

//----------------------------------------------------------------------------//
//---------- ADDRESSES INDEX RELATED -----------------------------------------//
//----------------------------------------------------------------------------//

  if ($('.addresses_index_div').length > 0) {

    $(".loading-overlay").show();

    var MyDate = new Date();
    var date;
    MyDate.setDate(MyDate.getDate());
    date = MyDate.getFullYear() + "-" + ("0" + (MyDate.getMonth() + 1)).slice(-2) + "-" + ("0" + MyDate.getDate()).slice(-2);
    var filename = "Address List, " + date + " Export";
    var table = $("#addressesDataTable").DataTable({
      "retrieve": true,
      "processing": true,
      "serverSide": true,
      "initComplete": function( settings, json ) {
        $('.loading-overlay').hide();
      },
      "ajax": {
        "url": $("#addressesDatatable").data("source")
      },
      "columns": [
  /*0 */{data: "created_at"},
  /*1 */{data: "address_line_1"},
  /*2 */{data: "address_line_2"},
  /*3 */{data: "address_line_3"},
  /*4 */{data: "city"},
  /*5 */{data: "state"},
  /*6 */{data: "postal_code"},
  /*7 */{data: "country"},
  /*8 */{data: "phone_1"},
  /*9 */{data: "phone_2"},
  /*10*/{data: "title"},
  /*11*/{data: "actions"}
      ],
      'order': [0, 'desc'],
      "oLanguage": { "sZeroRecords": "No addresses to display for this filter" },
      "dom": "<'row'<'col-sm-10 dataTableToolbar'><'col-sm-2'B>>t<'row dataTableBottomRow'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-6'p>>",
      "buttons": [
        {
          extend: "excelHtml5",
          title: filename,
          // Only exports columns that are currently visible.  Adjusted by the "Visibility" dropdown and Filtered text.
          exportOptions: {columns: ":visible"},
          className: "dtButtonHide"
        },
        {
          extend: "csv",
          title: filename,
          exportOptions: {columns: ":visible"},
          className: "dtButtonHide"
        },
        {
          extend: "pdfHtml5",
          title: filename,
          orientation: "landscape",
          exportOptions: {columns: ":visible"},
          className: "dtButtonHide"
        },
        {
          extend: "copyHtml5",
          title: filename,
          exportOptions: {columns: ":visible"},
          className: "dtButtonHide"
        }, 
        {
          extend: "print",
          autoPrint: false,
          title: filename,
          exportOptions: {columns: ":visible"},
          customize: function (win) {
              $(win.document.body).find('table').addClass('display').css('font-size', '12px');
              $(win.document.body).find('tr:nth-child(odd) td').each(function(index){
                  $(this).css('background-color','#D0D0D0');
              });
              $(win.document.body).find('h1').css('text-align','center');
          },
          className: "dtButtonHide"
        }
      ],
      "columnDefs": [
        {
          "targets": 11, // "Actions" column
          "orderable": false
        }
      ]
    });
  
    function dataTableToolbar() {
      return '' +
      '<ul class="nav" style="margin-bottom:10px">' +
      '  <li>' +
      '    <div id="addressesDataTable_filter" class="dataTables_filter mr-2">' +
      '      <input id="addressesTableSearch" class="form-control form-control-sm tableSearch" title="right-click to clear" oncontextmenu="return false" aria-controls="addressesDataTable" placeholder="Search Table...">' +
      '    </div>' +
      '  </li>' +
      '  <li>' +
      '    <div class="dataTables_length" id="addressesDataTable_length">' +
      '      <select name="addressesDataTable_length" aria-controls="addressesDataTable" class="form-control form-control-sm tableLength" title="Number of records to show">' +
      '        <option value="5">5</option>' +
      '        <option value="10">10</option>' +
      '        <option selected="selected" value="25">25</option>' +
      '        <option value="50">50</option>' +
      '        <option value="100">100</option>' +
      '        <option value="250">250</option>' +
      '      </select>' +
      '    </div>' +
      '  </li>' +
      '  <li>' +
      '    <div class="tableClickerDiv dropdown">' +
      '      <button class="btn btn-outline-primary btn-sm dropdown-toggle" title="Column visibility options" type="button" id="tableClickerColvis" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="fas fa-columns"></i> Columns</button>' +
      '      <div class="dropdown-menu no-select card-shadow" aria-labelledby="tableClickerColvis">' +
      '        <a class="dropdown-item" id="colvisClicker-0">Created</a>' +
      '        <a class="dropdown-item" id="colvisClicker-1">Address Line 1</a>' +
      '        <a class="dropdown-item" id="colvisClicker-2">Address Line 2</a>' +
      '        <a class="dropdown-item" id="colvisClicker-3">Address Line 3</a>' +
      '        <a class="dropdown-item" id="colvisClicker-4">City</a>' +
      '        <a class="dropdown-item" id="colvisClicker-5">State</a>' +
      '        <a class="dropdown-item" id="colvisClicker-6">ZIP/Postal Code</a>' +
      '        <a class="dropdown-item" id="colvisClicker-7">Country</a>' +
      '        <a class="dropdown-item" id="colvisClicker-8">Phone 1</a>' +
      '        <a class="dropdown-item" id="colvisClicker-9">Phone 2</a>' +
      '        <a class="dropdown-item" id="colvisClicker-10">Association</a>' +
      '        <a class="dropdown-item" id="colvisClicker-11">Actions</a>' +
      '      </div>' +
      '    </div>' +
      '  </li>' +
      '  <li>' +
      '    <div class="tableClickerDiv dropdown">' +
      '      <button class="btn btn-outline-primary btn-sm dropdown-toggle" title="Export Options" type="button" id="tableClickerExports" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="fas fa-file-export"></i> Export</button>' +
      '      <div class="dropdown-menu" aria-labelledby="tableClickerExports">' +
      '        <div class="text-primary" style="font-size:11px;padding:0.25rem 1.5rem;">' +
      '          <i class="fas fa-exclamation-triangle text-danger"></i> Only the currently visible columns & rows will be exported' +
      '        </div>' +
      '        <a class="dropdown-item tableClickerExcel"><i class="fas fa-file-excel file-icon-color"></i> Excel <i class="fas fa-download text-primary"></i></a>' +
      '        <a class="dropdown-item tableClickerCsv"><i class="fas fa-file-csv file-icon-color"></i> CSV <i class="fas fa-download text-primary"></i></a>' +
      '        <a class="dropdown-item tableClickerPdf"><i class="fas fa-file-pdf file-icon-color"></i> PDF <i class="fas fa-download text-primary"></i></a>' +
      '        <a class="dropdown-item tableClickerCopy"><i class="fas fa-copy file-icon-color"></i> Copy</a>' +
      '        <a class="dropdown-item tableClickerPrint"><i class="fas fa-print file-icon-color"></i> Print/Preview</a>' +
      '      </div>' +
      '    </div>' +
      '  </li>' +
      '</ul>' +
      '';
    }
  
    table.page.len(25).draw();
  
    $("div.dataTableToolbar").html(dataTableToolbar());
  
    $(".tableSearch").keyup(function () {
      table.search($(this).val()).draw();
    });
  
    $(".tableLength").on("change", function () {
      table.page.len($(this).find("option:selected").attr("value")).draw();
    });
  
      function colVisButtons(index) {
        var button = $("#colvisClicker-"+index);
        button.on("click", function(e) {
          e.stopPropagation();
          if (table.column(index).visible() === true) {
            table.column(index).visible(false);
            button.addClass('button-highlight');
            $(".colvisSearch-"+index).hide();
          }
          else {
            table.column(index).visible(true);
            button.removeClass('button-highlight');
            $(".colvisSearch-"+index).show();
          }
        });
      }
      let colVisIndex = -1;
      do { colVisIndex += 1;
           colVisButtons(colVisIndex);
      } while (colVisIndex < 11);
    
    $('body').tooltip({
      selector: '[data-toggle="tooltip"]'
    });

  }

//----------------------------------------------------------------------------//
//---------- ADDRESSES MAP RELATED -------------------------------------------//
//----------------------------------------------------------------------------//

  if ($('.addresses_map_div').length > 0) {

    var element = document.querySelector("#single_map");
    var marker = JSON.parse(element.dataset.marker);
    var single_map = new GMaps({
      div: "#single_map",
      lat: marker.lat,
      lng: marker.long
    });
    single_map.addMarker({
      lat: marker.lat,
      lng: marker.long,
      title: marker.title,
      infoWindow: {
        content: `<div class='infowindow-style'>${marker.address_infowindow}</div>`
      },
      icon: {
        url: "//maps.google.com/mapfiles/kml/paddle/pink-blank.png",
        scaledSize: new google.maps.Size(45,35),
        labelOrigin: new google.maps.Point(23, 11)
      },
    });

  }

});
