/*global $*/
/*global bootbox*/
/*global yadcf*/
/*global Option*/
/*global itemShowObjects*/
/*global tippy*/

import 'stylesheets/item_revenue_reports-global.scss';

$(document).on('turbolinks:load', function() {

  //--------------------------------------------------------------------------//
 //---------- ITEM REVENUE REPORT BATCH FORM RELATED ------------------------//
//--------------------------------------------------------------------------//

  if ($('.item_revenue_report_batch-validate').length > 0) {

    // For the hidden 'line_item_ids' field on each associated Item Revenue Report,
    // this automatically selects the ID's of each line_item to properly persist the data
    $('select[name$="[line_item_ids][]"]').each(function() {
      $(this).find('option').attr('selected', 'selected');
    });

    $(".item_revenue_report_batch-validate").validate({
      ignore: ".ignore",
  		rules: {
  		// 	"supplier[brand_name]": {required: true, maxlength: 80},
  		},
  		messages: {
  		// 	"supplier[brand_name]": "a Brand Name is required",
  		},
      onfocusout: function(element) {$(element).valid()},
      focusCleanup: true,
      errorClass: "myErrorClass",
      errorPlacement: function (error, element) {
        error.insertAfter(element.parent());
      },
      highlight: function (element, errorClass, validClass) {
        var elem = $(element);
        if (elem.hasClass("select2-hidden-accessible")) {
          elem.parent().find(".select2-selection").addClass(errorClass);
        } 
        else {
          elem.addClass(errorClass);
        }
      },    
      unhighlight: function (element, errorClass, validClass) {
        var elem = $(element);
        if (elem.hasClass("select2-hidden-accessible")) {
          elem.parent().find(".select2-selection").removeClass(errorClass);
          elem.parent().next("label").remove();
        }
        else {
          elem.removeClass(errorClass);
          elem.parent().next("label").remove();
        }
      },
  		invalidHandler: function(e, validator) {
  			var errors = validator.numberOfInvalids();
  			if (errors) {
  				var message = errors == 1
  					? 'You missed 1 required field. Check below tabs for highlighted fields'
  					: 'You missed ' + errors + ' required fields.  Check below tabs for highlighted fields';
  				$("div.validatorErrorList span").html(message);
  				$("div.validatorErrorList").show();
  			}
  			else {
  				$("div.validatorErrorList").hide();
  			}
  		}
    });

    $("#itemRevenueReportBatchFirstSave").on("click", function(e) {
      e.preventDefault();
      if ($(".item_revenue_report_batch-validate").valid()) {
        window.onbeforeunload = null;
        $("#jstemplates").html("");
        $(".loading-overlay").show();
        $("#itemRevenueReportBatchSave").click();
      } else {
        return false;
      }
    });

    $("#itemRevenueReportBatchFirstSubmit").on("click", function(e) {
      e.preventDefault();
      if ($(".item_revenue_report_batch-validate").valid()) {
        window.onbeforeunload = null;
        $("#jstemplates").html("");
        $(".loading-overlay").show();
        $("#itemRevenueReportBatchSubmit").click();
      }
      else {
        return false;
      }
    });

    $(document).on('click', '#load_line_items', function() {
      var month_select = $('.month_default_select').val();
      var revenue_type_select = $('.revenue_type_default_select').val();
      window.history.replaceState(null, null, '?month_select='+month_select+'&revenue_type_select='+revenue_type_select);
      window.location.reload(false);
    });

    $(document).on('change mouseup click', '#month_select', function() {
      $('.month_default_select').val($(this).val());
    });

    $(document).on('click', 'a.remove_child_item_revenue_report', function() {
      var hidden_field = $(this).prev('input[type=hidden]')[0];
      var fields = $(this).parents('.fields_retailer_skus');
      if(hidden_field) {
        hidden_field.value = '1';
      }
      $(this).parents('.fields_retailer_skus').slideUp('slow', function() {
        fields.remove();
      });
      return false;
    });

  //--------------------------------------------------------------------------//
 //---------- ITEM REVENUE REPORT BATCH EDIT RELATED ------------------------//
//--------------------------------------------------------------------------//

    // the item_revenue_reports.build call in the IRRB Controller creates an extra unusable
    // record (with no data-item attribute), so it gets removed here
    $('.item_sort').filter(function() {
        return $(this).data('item') == '';
    }).remove();

    $(".add_item_revenue_report_button").on("click", function() {
      $(".add_child_item_revenue_report").click();
    });

    $(document).on('click', 'a.add_child_item_revenue_report', function() {
      var association, new_id, regexp, template, fields;
      association = $(this).attr('data-association');
      template = $('#' + association + '_fields_template').html();
      regexp = new RegExp('new_' + association, 'g');
      new_id = (new Date).getTime();
      $(this).parent().before(template.replace(regexp, new_id));
      // The "jstemplates" hidden field needs the ".ignore" class to ensure it
      // doesn't get picked up by Jquery Validator.  This line removes it.
      fields = $(this).parent().prev(".fields");
      fields.find("select").removeClass("ignore");
      fields.find('.itemRevenueReportRetailerSearch').select2({
        selectOnClose: true,
        allowClear: true,
        placeholder: 'Retailer...',
      });
      fields.find('.productSearch').select2({
        selectOnClose: true,
        allowClear: true,
        placeholder: "Select Item...",
        ajax: {
          url: "/json_dropdown_products_all.json",
          dataType: "json",
          type: "GET",
          processResults: function (data, params) {
            params.page = data.page || 1;
            return {
              results: $.map(data.items, function (item) {
                return {
                  text: item.sku + " | " + item.desc,
                  id: item.id,
                  quantity_on_hand: item.qty
                };
              }),
              pagination: {
                more: data.current_page
              }
            };
          },
          cache: true
        },
        templateSelection: function(container) { // adds the current quantity on hand to the items 'data-quantity' attribute
            $(container.element).attr("data-quantity", container.quantity_on_hand);
            return container.text;
        }
      });

      $(".scroll").bind('wheel', function() {});
  
      return false;
    });
  

    $(document).on("click", "a.remove_child_item_revenue_report", function() {
      var tr = $(this).closest("tr");
      var hidden_field = $(this).prev("input[type=hidden]")[0];
      tr.find(".productSearch").val([]).trigger("change");
      if(hidden_field) {
        hidden_field.value = "1";
      }
      $(this).parents(".fields").remove();
      return false;
    });
  
    $('.item_revenue_report_line_items').selectize();

    $('.productEditSearch').select2({
      selectOnClose: true,
      allowClear: true,
      placeholder: 'Select Item...',
      ajax: {
        url: '/json_dropdown_products_all.json',
        dataType: 'json',
        type: 'GET',
        processResults: function (data, params) {
          params.page = data.page || 1;
          return {
            results: $.map(data.items, function (item) {
              return {
                  text: item.sku + ' | ' + item.desc,
                  id: item.id,
                  quantity_on_hand: item.qty,
              };
            }),
            pagination: {
              more: data.current_page
            }
          };
        },
        cache: true
      },
      templateSelection: function(container) { // adds the current quantity on hand to the items 'data-quantity' attribute
          $(container.element).attr('data-quantity', container.quantity_on_hand).attr('data-child_count', container.child_count);
          return container.text;
      }
    });
  
    // This populates the 'Product Search' dropdown on the 'Edit' view
    var productSelect = $('.productEditSearch');
    productSelect.each(function() {
      var t = $(this);
      $.ajax({
        url: '/products/' + t.data('selected') + '.json',
        dataType: 'json',
        type: 'GET',
      }).then(function (data) {
        var option = $('<option />')
                      .val(data.productSelect.id)
                      .text(data.productSelect.sku + ' | ' + data.productSelect.desc)
                      .prop('selected', true)
                      .attr('data-quantity', data.productSelect.qty);
        t.append(option).trigger('change');
        t.trigger({
          type: 'select2:select',
          params: {
            data: data
          }
        });
      });
    });

    $('.itemRevenueReportBatchEditRetailerSearch').select2({
      selectOnClose: true,
      allowClear: true,
      placeholder: 'Retailer...',
    });

    // This populates the 'Retailer' dropdown on the 'Edit' view
    var retailerSelect = $('.itemRevenueReportBatchEditRetailerSearch');
    retailerSelect.each(function() {
      var t = $(this);
      $.ajax({
        url: '/retailers/' + t.data('selected') + '.json',
        dataType: 'json',
        type: 'GET',
      }).then(function (data) {
        var option = new Option(data.retailerSelect.name, data.retailerSelect.id, true, true);
        t.append(option).trigger('change');
        t.trigger({
          type: 'select2:select',
          params: {
            data: data
          }
        });
      });
    });
  
    // This populates the Supplier Chargeback 'Supplier' dropdown on the 'Edit' view
    var supplierSelect = $('.supplierChargebackEditSupplierSearch');
    supplierSelect.each(function() {
      var t = $(this);
      $.ajax({
        url: '/suppliers/' + t.data('selected') + '.json',
        dataType: 'json',
        type: 'GET',
      }).then(function (data) {
        var option = new Option(data.supplierSelect.brand_name, data.supplierSelect.id, true, true);
        t.append(option).trigger('change');
        t.trigger({
          type: 'select2:select',
          params: {
            data: data
          }
        });
      });
    });
  
    $('.supplierChargebackEditSupplierSearch').select2({
      selectOnClose: true,
      allowClear: true,
      placeholder: 'Supplier...',
    });

    $(".fields").each(function() {
      $(this).next('input').attr('data-item', $(this).data('item')).addClass('item_sort');
    });
  
    // grabs the 'Model Number' field from each Item, then sorts alphabetically.
    $(document).on('click', '.sort_by_item_button', function() {
      var mylist = $('.item_revenue_report_fields');
      var listitems = mylist.children('.item_sort').get();
      listitems.sort(function(a, b) {
         return $(a).data('item').toUpperCase().localeCompare($(b).data('item').toUpperCase());
      });
      $.each(listitems, function(idx, itm) { mylist.append(itm); });
    });

    $(document).on('click', '.item_revenue_report_edit_remove_button', function() {
      var fields = $(this).closest('.fields');
      bootbox.confirm('Are you sure you want to delete this Revenue Report?', function(result){
        if (result === true) {
          fields.find('.remove_child_edit_item_revenue_report').attr('value', 'true');
          fields.slideUp('slow');
        }
      });
    });

    $(document).on('click', '.remove_child_item_revenue_report_import', function() {
      var fields = $(this).closest('table');
      bootbox.confirm('Are you sure you want to delete this Revenue Report?', function(result){
        if (result === true) {
          fields.remove();
        }
      });
    });

    $(document).on('click', '.supplier_chargeback_edit_remove_button', function() {
      var fields = $(this).closest('.fields');
      bootbox.confirm('Are you sure you want to delete this Revenue Report?', function(result){
        if (result === true) {
          fields.find('.remove_child_edit_supplier_chargeback').attr('value', 'true');
          fields.slideUp('slow');
        }
      });
    });

  }

  //--------------------------------------------------------------------------//
 //---------- ITEM REVENUE REPORT BATCH SHOW --------------------------------//
//--------------------------------------------------------------------------//

  if ($('.item_revenue_report_batch_show_div').length > 0) {

    itemShowObjects.dataTable('item_revenue_report_exports', 50);
    
    $(document).on('click', '.report_skus.expand', function() {
      var sid = $(this).data('sid');
      $(this).hide();
      $(this).next('.report_skus.collapse').show();
      $(this).closest('.row').find('.sku_reports_table_'+sid).slideDown('fast');
    });
    $(document).on('click', '.report_skus.collapse', function() {
      var sid = $(this).data('sid');
      $(this).hide();
      $(this).prev('.report_skus.expand').show();
      $(this).closest('.row').find('.sku_reports_table_'+sid).slideUp('fast');
    });

    $(document).on('click', '.report_retailers.expand', function() {
      var pid = $(this).data('pid');
      $(this).hide();
      $(this).next('.report_retailers.collapse').show();
      $(this).closest('tbody').find('.report_retailer_row_'+pid).slideDown('fast');
    });
    $(document).on('click', '.report_retailers.collapse', function() {
      var pid = $(this).data('pid');
      $(this).hide();
      $(this).prev('.report_retailers.expand').show();
      $(this).closest('tbody').find('.report_retailer_row_'+pid).slideUp('fast');
    });

    $(document).on('click', '.tab_collapse.up', function() {
      var div = $(this).closest('.collapse_tab');
      $(this).addClass('down').removeClass(['text-info', 'up', 'header_shadow']);
      $(this).attr('data-tippy-content', 'Expand This Tab');
      tippy('[data-toggle="tippy"]', {animation: 'perspective'});
      div.find('.tab_collapse_body').slideUp('fast');
      $(this).hide();
      div.find('.tab_collapse.down').show();
      $('.expand_all_tabs').show();
      $('.hide_all_tabs').hide();
    });

    $(document).on('click', '.tab_collapse.down', function() {
      var div = $(this).closest('.collapse_tab');
      $(this).addClass(['up', 'text-info', 'header_shadow']).removeClass('down');
      $(this).attr('data-tippy-content', 'Collapse This Tab');
      tippy('[data-toggle="tippy"]', {animation: 'perspective'});
      div.find('.tab_collapse_body').slideDown('fast');
      $(this).hide();
      div.find('.tab_collapse.up').show();
      $('.expand_all_tabs').hide();
      $('.hide_all_tabs').show();
    });

    function middle_click_expand(e) {
      $(document).on('mousedown', e, function() {
        if (event.which === 2) {
          event.preventDefault();
          $(e).click();
        }
      });
    }
    middle_click_expand('.tab_collapse.up');
    middle_click_expand('.tab_collapse.down');
    middle_click_expand('.report_skus.expand');
    middle_click_expand('.report_skus.collapse');
    middle_click_expand('.report_retailers.expand');
    middle_click_expand('.report_retailers.collapse');
    middle_click_expand('.charges_expand.not_expanded');
    middle_click_expand('.charges_expand.expanded');

    $(document).on('change', '#revenue_reports_dropdown', function() {
       var url = "/revenue_reports/" + $(this).val();
        if (url) {
            window.location.replace(url);
        }
        return false;
    });

    $(document).on('batch_all_charges_loaded', function() {
      tippy('[data-toggle="tippy"]', {animation: 'perspective'});
    });

    $(document).on('suppliers_breakdown_loaded', function() {
      tippy('[data-toggle="tippy"]', {animation: 'perspective'});
    });

    $(document).on('export_by_supplier_table_loaded', function() {
      itemShowObjects.dataTable('export_by_supplier_table', -1);
    });

    $(document).on('export_by_retailer_table_loaded', function() {
      itemShowObjects.dataTable('export_by_retailer_table', -1);
    });

    $(document).on('export_ad_campaigns_table_loaded', function() {
      itemShowObjects.dataTable('export_ad_campaigns_table', -1);
    });

    
    $(document).on('click', '.charges_expand.not_expanded', function() {
      var container = $(this).closest('.charges_container');
      $(this).addClass('text-warning expanded mdi-equal').removeClass('text-success not_expanded mdi-menu-right');
      $(this).attr('data-tippy-content', 'Hide Charges Breakdown')
      container.addClass('col-md-7').removeClass('col-md-2');
      container.find('.charges_expandable').slideDown('fast');
      tippy('[data-toggle="tippy"]', {animation: 'perspective'});
    });

    $(document).on('click', '.charges_expand.expanded', function() {
      var container = $(this).closest('.charges_container');
      container.find('.charges_expandable').hide();
      $(this).removeClass('text-warning expanded mdi-equal').addClass('text-success not_expanded mdi-menu-right');
      $(this).attr('data-tippy-content', 'Show Charges Breakdown')
      container.removeClass('col-md-7').addClass('col-md-2');
      tippy('[data-toggle="tippy"]', {animation: 'perspective'});
    });

    $('#supplier_select').selectize({
      placeholder: 'Suppliers...'
    });
    // Collects the values from Supplier Select and converts them to a simple array for query parameter filtering
    $(document).on('change', '#supplier_select', function() {
      $('#suppliers').val($(this).find('option:selected').toArray().map(item => item.value).join());
    });

    $('#retailer_select').selectize({
      placeholder: 'Retailers...'
    });
    // Collects the values from Retailer Select and converts them to a simple array for query parameter filtering
    $(document).on('change', '#retailer_select', function() {
      $('#retailers').val($(this).find('option:selected').toArray().map(item => item.value).join());
    });

    $(document).on('export_by_sku_table_loaded', function() {
      var sku_table = 'exportBySku';
      var sku_table_filename = $('#' + sku_table + 'Table').data('date') + 'Revenue Report SKU Exports';
      var table = $('#' + sku_table + 'Table').DataTable({
        'retrieve': true,
        'processing': true,
        'order': [],
        'oLanguage': { 'sZeroRecords': 'No records to display for this filter' },
        'dom': '<"row m-0"<"col-sm-10 dataTableToolbar ' + sku_table + '"><"col-sm-2"B>>t<"row m-0 dataTableBottomRow"<"col-md-2 ml-3"i><"col-md-9"p>>',
        'buttons': [
          {
            extend: 'excelHtml5',
            title: sku_table_filename,
            // Only exports columns that are currently visible.  Adjusted by the 'Visibility' dropdown and Filtered text.
            exportOptions: {columns: ':visible'},
            className: 'dtButtonHide'
          },
          {
            extend: 'csv',
            title: sku_table_filename,
            exportOptions: {columns: ':visible'},
            className: 'dtButtonHide'
          },
          {
            extend: 'pdfHtml5',
            title: sku_table_filename,
            orientation: 'landscape',
            exportOptions: {columns: ':visible'},
            className: 'dtButtonHide'
          },
          {
            extend: 'copyHtml5',
            title: sku_table_filename,
            exportOptions: {columns: ':visible'},
            className: 'dtButtonHide'
          }, 
          {
            extend: 'print',
            autoPrint: false,
            title: sku_table_filename,
            exportOptions: {columns: ':visible'},
            customize: function (win) {
                $(win.document.body).find('table').addClass('display').css('font-size', '12px');
                $(win.document.body).find('tr:nth-child(odd) td').each(function(index){
                    $(this).css('background-color','#D0D0D0');
                });
                $(win.document.body).find('h1').css('text-align','center');
            },
            className: 'dtButtonHide'
          }
        ]
      });
    
      function DataTableToolbar () {
        return '' +
        '<ul class="nav ml-2 mb-3">' +
        '  <li>' +
        '    <div class="dataTables_length" id="' + sku_table + 'Table_length">' +
        '      <select name="' + sku_table + 'Table_length" aria-controls="' + sku_table + 'Table" class="form-control form-control-sm tableLength ' + sku_table + '" title="Number of records to show">' +
        '        <option value="5">5</option>' +
        '        <option value="10">10</option>' +
        '        <option value="25">25</option>' +
        '        <option value="50">50</option>' +
        '        <option value="100">100</option>' +
        '        <option value="250">250</option>' +
        '        <option selected="selected" value="-1">All</option>' +
        '      </select>' +
        '    </div>' +
        '  </li>' +
        '  <li>' +
        '    <div class="tableClickerDiv dropdown">' +
        '      <button class="btn btn-outline-primary btn-sm dropdown-toggle" title="Export Options" type="button" id="tableClickerExports" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="fas fa-file-export"></i> Export</button>' +
        '      <div class="dropdown-menu" aria-labelledby="tableClickerExports">' +
        '        <div class="text-primary" style="font-size:11px;padding:0.25rem 1.5rem;">' +
        '          <i class="fas fa-exclamation-triangle text-danger"></i> Only the currently visible columns & rows will be exported' +
        '        </div>' +
        '        <a class="dropdown-item tableClickerExcel"><i class="fas fa-file-excel file-icon-color"></i> Excel <i class="fas fa-download text-primary"></i></a>' +
        '        <a class="dropdown-item tableClickerCsv"><i class="fas fa-file-csv file-icon-color"></i> CSV <i class="fas fa-download text-primary"></i></a>' +
        '        <a class="dropdown-item tableClickerPdf"><i class="fas fa-file-pdf file-icon-color"></i> PDF <i class="fas fa-download text-primary"></i></a>' +
        '        <a class="dropdown-item tableClickerCopy"><i class="fas fa-copy file-icon-color"></i> Copy</a>' +
        '        <a class="dropdown-item tableClickerPrint"><i class="fas fa-print file-icon-color"></i> Print/Preview</a>' +
        '      </div>' +
        '    </div>' +
        '  </li>' +
        '  <li class="filter-section ml-2">' +
        '    <div id="' + sku_table + 'Table_filter" class="dataTables_filter mr-2">' +
        '      <input id="' + sku_table + 'Table_tableSearch" class="form-control form-control-sm tableSearch ' + sku_table + '" title="right-click to clear" oncontextmenu="return false" aria-controls="' + sku_table + 'Table" placeholder="Search Entire Table...">' +
        '    </div>' +
        '    <span id="supplier-select-dropdown" class="filter-span-select"></span>' +
        '  </li>' +
        '</ul>' +
        '';
      }
    
      table.page.len(-1).draw();
    
      $('div.dataTableToolbar.' + sku_table).html(DataTableToolbar());
    
      $('.tableLength.' + sku_table).on('change', function () {
        table.page.len($(this).find('option:selected').attr('value')).draw();
      });
    
      $('.tableSearch.' + sku_table).on('keyup', function () {
          table.search($(this).val()).draw();
      });

      yadcf.init(table,
        [
          { column_number : 1, // Supplier
            filter_container_id : "supplier-select-dropdown",
            filter_default_label : "Suppliers... ↓↓↓",
            filter_match_mode : "contains",
            filter_type: 'multi_select',
            select_type: 'select2',
            data: $('#sku_params_span').data('suppliers_dropdown')
          },
        ],
        { filters_position : 'footer' },
        { externally_triggered : true }
      );
  
      // This block executes any query string searches that may be present in the URL
      function filterTable(columnIndex, filterValue){
        yadcf.exFilterColumn(table, [
          [columnIndex, filterValue],
        ]);
      }
      const queryStringParams = window.location.search.substring(1).split('&');
  
      queryStringParams.forEach(param => {
        if (param.indexOf('supplier') !== -1) {
          filterTable(1, $('#params_span').data('supplier'));
        }
      });




    });

  }

  //--------------------------------------------------------------------------//
 //---------- ITEM REVENUE REPORT BATCHES INDEX -----------------------------//
//--------------------------------------------------------------------------//

  if ($('.item_revenue_report_batches_index_div').length > 0) {
  
    var MyDate = new Date();
    var date;
    MyDate.setDate(MyDate.getDate());
    date = MyDate.getFullYear() + '-' + ('0' + (MyDate.getMonth() + 1)).slice(-2) + '-' + ('0' + MyDate.getDate()).slice(-2);
    var filename = 'Item Revenue Report Batches Export, ' + date;
    var table = $('#itemRevenueReportBatchesDataTable').DataTable({
      'retrieve': true,
      'processing': true,
      'serverSide': true,
      'ajax': {
        'url': $('#itemRevenueReportBatchesDataTable').data('source')
      },
      'columns': [
        {data: 'id', visible: false, searchable: false},
        {data: 'report_count', visible: false, searchable: false, orderable: false},
        {data: 'item_count', visible: false, searchable: false, orderable: false},
        {data: 'batch_identifier', visible: false},
        {data: 'revenue_type'},
        {data: 'start_date'},
        {data: 'unit_sales', searchable: false, orderable: false},
        {data: 'gross_revenue', searchable: false, orderable: false},
        // {data: 'total_charges', searchable: false, orderable: false},
        // {data: 'shipping_charges', searchable: false, orderable: false},
        {data: 'ad_spend_charges', searchable: false, orderable: false},
        // {data: 'returns_charges', searchable: false, orderable: false},
        // {data: 'remittance_chargebacks', searchable: false, orderable: false},
        // {data: 'net_revenue', searchable: false, orderable: false},
        {data: 'actions', searchable: false, orderable: false}
      ],
      'order': [[ 0, 'desc' ]],
      'oLanguage': { 'sZeroRecords': 'No records to display for this filter' },
      'dom': '<"row"<"col-sm-10 dataTableToolbar"><"col-sm-2"B>>t<"row dataTableBottomRow"<"col-md-2"i><"col-md-10"p>>',
      'buttons': [
        {
          extend: 'excelHtml5',
          title: filename,
          // Only exports columns that are currently visible.  Adjusted by the 'Visibility' dropdown and Filtered text.
          exportOptions: {columns: ':visible'},
          className: 'dtButtonHide'
        },
        {
          extend: 'csv',
          title: filename,
          exportOptions: {columns: ':visible'},
          className: 'dtButtonHide'
        },
        {
          extend: 'pdfHtml5',
          title: filename,
          orientation: 'landscape',
          exportOptions: {columns: ':visible'},
          className: 'dtButtonHide'
        },
        {
          extend: 'copyHtml5',
          title: filename,
          exportOptions: {columns: ':visible'},
          className: 'dtButtonHide'
        }, 
        {
          extend: 'print',
          autoPrint: false,
          title: filename,
          exportOptions: {columns: ':visible'},
          customize: function (win) {
              $(win.document.body).find('table').addClass('display').css('font-size', '12px');
              $(win.document.body).find('tr:nth-child(odd) td').each(function(index){
                  $(this).css('background-color','#D0D0D0');
              });
              $(win.document.body).find('h1').css('text-align','center');
          },
          className: 'dtButtonHide'
        }
      ]
    });
  
    function dataTableToolbar() {
      return '' +
      '<ul class="nav" style="margin-bottom:10px">' +
      '  <li>' +
      '    <span class="options-label">Options:</span>' +
      '  </li>' +
      '  <li>' +
      '    <div class="dataTables_length" id="ItemRevenueReportBatchesDataTable_length">' +
      '      <select name="ItemRevenueReportBatchesDataTable_length" aria-controls="ItemRevenueReportBatchesDataTable" class="form-control form-control-sm tableLength" title="Number of records to show">' +
      '        <option value="5">5</option>' +
      '        <option value="10">10</option>' +
      '        <option value="25">25</option>' +
      '        <option selected="selected" value="50">50</option>' +
      '        <option value="100">100</option>' +
      '        <option value="250">250</option>' +
      '        <option value="1000">1000</option>' +
      '      </select>' +
      '    </div>' +
      '  </li>' +
      '  <li>' +
      '    <div class="tableClickerDiv dropdown">' +
      '      <button class="btn btn-outline-primary btn-sm dropdown-toggle" title="Column visibility options" type="button" id="tableClickerColvis" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="fas fa-columns"></i> Columns</button>' +
      '      <div class="dropdown-menu card-shadow" aria-labelledby="tableClickerColvis">' +
      '        <a class="dropdown-item" id="colvisClicker-0">Batch ID</a>' +
      '        <a class="dropdown-item" id="colvisClicker-1">Report Count</a>' +
      '        <a class="dropdown-item" id="colvisClicker-2">Item Count</a>' +
      '        <a class="dropdown-item" id="colvisClicker-3">Batch Identifier</a>' +
      '        <a class="dropdown-item" id="colvisClicker-4">Revenue Type</a>' +
      '        <a class="dropdown-item" id="colvisClicker-5">Start Date</a>' +
      '        <a class="dropdown-item" id="colvisClicker-6">Unit Sales</a>' +
      '        <a class="dropdown-item" id="colvisClicker-7">Gross Revenue</a>' +
      // '        <a class="dropdown-item" id="colvisClicker-8">Total Charges</a>' +
      // '        <a class="dropdown-item" id="colvisClicker-9">Shipping Charges</a>' +
      '        <a class="dropdown-item" id="colvisClicker-8">Ad Spend</a>' +
      // '        <a class="dropdown-item" id="colvisClicker-11">Returns</a>' +
      // '        <a class="dropdown-item" id="colvisClicker-12">Chargebacks</a>' +
      // '        <a class="dropdown-item" id="colvisClicker-13">Net Revenue</a>' +
      '        <a class="dropdown-item" id="colvisClicker-9">Actions</a>' +
      '      </div>' +
      '    </div>' +
      '  </li>' +
      '  <li>' +
      '    <div class="tableClickerDiv dropdown">' +
      '      <button class="btn btn-outline-primary btn-sm dropdown-toggle" title="Export Options" type="button" id="tableClickerExports" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="fas fa-file-export"></i> Export</button>' +
      '      <div class="dropdown-menu" aria-labelledby="tableClickerExports">' +
      '        <div class="text-primary" style="font-size:11px;padding:0.25rem 1.5rem;">' +
      '          <i class="fas fa-exclamation-triangle text-danger"></i> Only the currently visible columns & rows will be exported' +
      '        </div>' +
      '        <a class="dropdown-item tableClickerExcel"><i class="fas fa-file-excel file-icon-color"></i> Excel <i class="fas fa-download text-primary"></i></a>' +
      '        <a class="dropdown-item tableClickerCsv"><i class="fas fa-file-csv file-icon-color"></i> CSV <i class="fas fa-download text-primary"></i></a>' +
      '        <a class="dropdown-item tableClickerPdf"><i class="fas fa-file-pdf file-icon-color"></i> PDF <i class="fas fa-download text-primary"></i></a>' +
      '        <a class="dropdown-item tableClickerCopy"><i class="fas fa-copy file-icon-color"></i> Copy</a>' +
      '        <a class="dropdown-item tableClickerPrint"><i class="fas fa-print file-icon-color"></i> Print/Preview</a>' +
      '      </div>' +
      '    </div>' +
      '  </li>' +
      '  <li class="filter-section mt-2" style="width:100%">' +
      '    <span class="filter-label">Filters:</span>' +
      '    <div id="ItemRevenueReportBatchesDataTable_filter" class="dataTables_filter mr-2">' +
      '      <input id="itemRevenueReportsTableSearch" class="form-control form-control-sm tableSearch" title="right-click to clear" oncontextmenu="return false" aria-controls="ItemRevenueReportBatchesDataTable" placeholder="Search Entire Table...">' +
      '    </div>' +
      // '    <span id="revenue-type-dropdown" class="filter-span-select colvisSearch-2"></span>' +
      '    <form action="/item_revenue_report_batches" accept-charset="UTF-8" method="get">' +
      '      <span class="filter-span-range" data-toggle="tippy" data-tippy-delay="[500,10]" data-tippy-content="Revenue Report Batch Date Range Search (leaving start or end date blank will assume infinity)">' +
      '        <div>' +
      '          <div class="yadcf-filter-wrapper">' +
      '            <div class="yadcf-filter-wrapper-inner">' +
      '              <input type="date" name="start_date" id="start_date" placeholder="From" class="yadcf-filter-range-date yadcf-filter-range yadcf-filter-range-start hasDatepicker" oncontextmenu="return false" style="width:150px;">' +
      '              <input type="date" name="end_date" id="end_date" placeholder="To" class="yadcf-filter-range-date yadcf-filter-range yadcf-filter-range-end hasDatepicker" oncontextmenu="return false" style="width:150px;margin-left:-3px;">' +
      '            </div>' +
      '            <button type="submit" class="yadcf-filter-reset-button submit-date-range" title="Note that Date Range search will clear all other filters">Go</button>' +
      '          </div>' +
      '        </div>' +
      '      </span>' +
      '    </form>' +
      '  </li>' +
      '</ul>' +
      '';
    }
  
    table.page.len(50).draw();

    $('div.dataTableToolbar').html(dataTableToolbar());
  
    var search = $.fn.dataTable.util.throttle(
      function (val) {
        table.search(val).draw();
      }, 500);
     
    $('.tableSearch').on('keyup', function () {
      search( this.value );
    });
  
    $('.tableLength').on('change', function () {
      table.page.len($(this).find('option:selected').attr('value')).draw();
    });
  
    function colVisButtons(index) {
      var button = $('#colvisClicker-'+index);
      button.on('click', function(e) {
        e.stopPropagation();
        if (table.column(index).visible() === true) {
          table.column(index).visible(false);
          button.addClass('button-highlight');
          $('.colvisSearch-'+index).hide();
        }
        else {
          table.column(index).visible(true);
          button.removeClass('button-highlight');
          $('.colvisSearch-'+index).show();
        }
      });
    }
    let colVisIndex = -1;
    do { colVisIndex += 1;
         colVisButtons(colVisIndex);
    } while (colVisIndex < 9);
  
    yadcf.init(table,
      [
        // { column_number : 4, // Revenue Type
        //   filter_container_id : 'revenue-type-dropdown',
        //   style_class : 'form-control form-control-sm',
        //   filter_default_label : 'Revenue Type...',
        //   select_type: 'select',
        //   data: [
        //     {value: '1', label : 'Monthly Sales'},
        //     {value: '2', label : 'Weekly Sales'},
        //     {value: '3', label : 'Monthly Returns'},
        //     {value: '5', label : 'Weekly Returns'},
        //     {value: '6', label : 'Monthly Remittance'},
        //     {value: '4', label : 'Weekly Remittance'},
        //   ]
        // },
      ],
      { filters_position : 'footer' },
      { autocomplete : false },
      { externally_triggered : true }
    );
  
    // This block executes any query string searches that may be present in the URL
    function filterTable(columnIndex, filterValue){
      yadcf.exFilterColumn(table, [
        [columnIndex, filterValue],
      ]);
    }
    const queryStringParams = window.location.search.substring(1).split('&');
    queryStringParams.forEach(param => {
      if (param.indexOf('revenue_type') !== -1) {
        filterTable(5, $('#params_span').data('revenue_type'));
      }
    });

    $("#start_date").val($("#start_date_hidden").attr("value"));
    $("#end_date").val($("#end_date_hidden").attr("value"));
  
    $('.item-revenue-report-batch-delete').on('ajax:success', function() {
      var parent = $(this).closest('tr');
      parent.addClass('hidden');
    });
  
    $('body').tooltip({selector: '[data-toggle="tooltip"]'});

  }

  //--------------------------------------------------------------------------//
 //---------- ITEM REVENUE REPORTS INDEX ------------------------------------//
//--------------------------------------------------------------------------//

  if ($('.item_revenue_reports_index_div').length > 0) {
  
    var MyDate = new Date();
    var date;
    MyDate.setDate(MyDate.getDate());
    date = MyDate.getFullYear() + '-' + ('0' + (MyDate.getMonth() + 1)).slice(-2) + '-' + ('0' + MyDate.getDate()).slice(-2);
    var filename = 'Item Revenue Reports Export, ' + date;
    var table = $('#itemRevenueReportsDataTable').DataTable({
      'retrieve': true,
      'processing': true,
      'serverSide': true,
      'ajax': {
        'url': $('#itemRevenueReportsDataTable').data('source')
      },
      'columns': [
        {data: 'id', searchable: false},
        {data: 'batch_id', searchable: false},
        {data: 'revenue_type'},
        {data: 'start_date', searchable: false},
        {data: 'supplier'},
        {data: 'sku'},
        {data: 'retailer'},
        {data: 'gross_revenue', searchable: false},
        {data: 'unit_sales', searchable: false},
        {data: 'ad_spend', searchable: false, orderable: false},
        {data: 'notes'},
        {data: 'associated_orders', searchable: false, orderable: false},
        {data: 'actions', searchable: false, orderable: false}
      ],
      'order': [[ 0, 'desc' ]],
      'oLanguage': { 'sZeroRecords': 'No records to display for this filter' },
      'dom': '<"row"<"col-sm-10 dataTableToolbar"><"col-sm-2"B>>t<"row dataTableBottomRow"<"col-md-2"i><"col-md-10"p>>',
      'buttons': [
        {
          extend: 'excelHtml5',
          title: filename,
          // Only exports columns that are currently visible.  Adjusted by the 'Visibility' dropdown and Filtered text.
          exportOptions: {columns: ':visible'},
          className: 'dtButtonHide'
        },
        {
          extend: 'csv',
          title: filename,
          exportOptions: {columns: ':visible'},
          className: 'dtButtonHide'
        },
        {
          extend: 'pdfHtml5',
          title: filename,
          orientation: 'landscape',
          exportOptions: {columns: ':visible'},
          className: 'dtButtonHide'
        },
        {
          extend: 'copyHtml5',
          title: filename,
          exportOptions: {columns: ':visible'},
          className: 'dtButtonHide'
        }, 
        {
          extend: 'print',
          autoPrint: false,
          title: filename,
          exportOptions: {columns: ':visible'},
          customize: function (win) {
              $(win.document.body).find('table').addClass('display').css('font-size', '12px');
              $(win.document.body).find('tr:nth-child(odd) td').each(function(index){
                  $(this).css('background-color','#D0D0D0');
              });
              $(win.document.body).find('h1').css('text-align','center');
          },
          className: 'dtButtonHide'
        }
      ]
    });
  
    function dataTableToolbar() {
      return '' +
      '<ul class="nav" style="margin-bottom:10px">' +
      '  <li>' +
      '    <span class="options-label">Options:</span>' +
      '  </li>' +
      '  <li>' +
      '    <div class="dataTables_length" id="itemRevenueReportsDataTable_length">' +
      '      <select name="itemRevenueReportsDataTable_length" aria-controls="itemRevenueReportsDataTable" class="form-control form-control-sm tableLength" title="Number of records to show">' +
      '        <option value="5">5</option>' +
      '        <option value="10">10</option>' +
      '        <option value="25">25</option>' +
      '        <option selected="selected" value="50">50</option>' +
      '        <option value="100">100</option>' +
      '        <option value="250">250</option>' +
      '        <option value="1000">1000</option>' +
      '      </select>' +
      '    </div>' +
      '  </li>' +
      '  <li>' +
      '    <div class="tableClickerDiv dropdown">' +
      '      <button class="btn btn-outline-primary btn-sm dropdown-toggle" title="Column visibility options" type="button" id="tableClickerColvis" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="fas fa-columns"></i> Columns</button>' +
      '      <div class="dropdown-menu card-shadow" aria-labelledby="tableClickerColvis">' +
      '        <a class="dropdown-item" id="colvisClicker-0">Record ID</a>' +
      '        <a class="dropdown-item" id="colvisClicker-1">Batch ID</a>' +
      '        <a class="dropdown-item" id="colvisClicker-2">Revenue Type</a>' +
      '        <a class="dropdown-item" id="colvisClicker-3">Start Date</a>' +
      '        <a class="dropdown-item" id="colvisClicker-4">Supplier</a>' +
      '        <a class="dropdown-item" id="colvisClicker-5">SKU</a>' +
      '        <a class="dropdown-item" id="colvisClicker-6">Retailer</a>' +
      '        <a class="dropdown-item" id="colvisClicker-7">Gross Revenue</a>' +
      '        <a class="dropdown-item" id="colvisClicker-8">Unit Sales</a>' +
      '        <a class="dropdown-item" id="colvisClicker-9">Ad Spend</a>' +
      '        <a class="dropdown-item" id="colvisClicker-10">Notes</a>' +
      '        <a class="dropdown-item" id="colvisClicker-11">Associated Orders</a>' +
      '        <a class="dropdown-item" id="colvisClicker-12">Actions</a>' +
      '      </div>' +
      '    </div>' +
      '  </li>' +
      '  <li>' +
      '    <div class="tableClickerDiv dropdown">' +
      '      <button class="btn btn-outline-primary btn-sm dropdown-toggle" title="Export Options" type="button" id="tableClickerExports" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="fas fa-file-export"></i> Export</button>' +
      '      <div class="dropdown-menu" aria-labelledby="tableClickerExports">' +
      '        <div class="text-primary" style="font-size:11px;padding:0.25rem 1.5rem;">' +
      '          <i class="fas fa-exclamation-triangle text-danger"></i> Only the currently visible columns & rows will be exported' +
      '        </div>' +
      '        <a class="dropdown-item tableClickerExcel"><i class="fas fa-file-excel file-icon-color"></i> Excel <i class="fas fa-download text-primary"></i></a>' +
      '        <a class="dropdown-item tableClickerCsv"><i class="fas fa-file-csv file-icon-color"></i> CSV <i class="fas fa-download text-primary"></i></a>' +
      '        <a class="dropdown-item tableClickerPdf"><i class="fas fa-file-pdf file-icon-color"></i> PDF <i class="fas fa-download text-primary"></i></a>' +
      '        <a class="dropdown-item tableClickerCopy"><i class="fas fa-copy file-icon-color"></i> Copy</a>' +
      '        <a class="dropdown-item tableClickerPrint"><i class="fas fa-print file-icon-color"></i> Print/Preview</a>' +
      '      </div>' +
      '    </div>' +
      '  </li>' +
      '  <li class="filter-section mt-2" style="width:100%">' +
      '    <span class="filter-label">Filters:</span>' +
      '    <div id="itemRevenueReportsDataTable_filter" class="dataTables_filter mr-2">' +
      '      <input id="itemRevenueReportsTableSearch" class="form-control form-control-sm tableSearch" title="right-click to clear" oncontextmenu="return false" aria-controls="itemRevenueReportsDataTable" placeholder="Search Entire Table...">' +
      '    </div>' +
      '    <span id="revenue-type-dropdown" class="filter-span-select colvisSearch-2"></span>' +
      '    <form action="/item_revenue_reports" accept-charset="UTF-8" method="get">' +
      '      <span class="filter-span-range" data-toggle="tippy" data-tippy-delay="[500,10]" data-tippy-content="Revenue Report Date Range Search (leaving start or end date blank will assume infinity)">' +
      '        <div>' +
      '          <div class="yadcf-filter-wrapper">' +
      '            <div class="yadcf-filter-wrapper-inner">' +
      '              <input type="date" name="start_date" id="start_date" placeholder="From" class="yadcf-filter-range-date yadcf-filter-range yadcf-filter-range-start hasDatepicker" oncontextmenu="return false" style="width:150px;">' +
      '              <input type="date" name="end_date" id="end_date" placeholder="To" class="yadcf-filter-range-date yadcf-filter-range yadcf-filter-range-end hasDatepicker" oncontextmenu="return false" style="width:150px;margin-left:-3px;">' +
      '            </div>' +
      '            <button type="submit" class="yadcf-filter-reset-button submit-date-range" title="Note that Date Range search will clear all other filters">Go</button>' +
      '          </div>' +
      '        </div>' +
      '      </span>' +
      '    </form>' +
      '  </li>' +
      '</ul>' +
      '';
    }
  
    table.page.len(50).draw();

    $('div.dataTableToolbar').html(dataTableToolbar());
  
    var search = $.fn.dataTable.util.throttle(
      function (val) {
        table.search(val).draw();
      }, 500);
     
    $('.tableSearch').on('keyup', function () {
      search( this.value );
    });
  
    $('.tableLength').on('change', function () {
      table.page.len($(this).find('option:selected').attr('value')).draw();
    });
  
    function colVisButtons(index) {
      var button = $('#colvisClicker-'+index);
      button.on('click', function(e) {
        e.stopPropagation();
        if (table.column(index).visible() === true) {
          table.column(index).visible(false);
          button.addClass('button-highlight');
          $('.colvisSearch-'+index).hide();
        }
        else {
          table.column(index).visible(true);
          button.removeClass('button-highlight');
          $('.colvisSearch-'+index).show();
        }
      });
    }
    let colVisIndex = -1;
    do { colVisIndex += 1;
         colVisButtons(colVisIndex);
    } while (colVisIndex < 12);
  
    yadcf.init(table,
      [
        { column_number : 2, // Revenue Type
          filter_container_id : 'revenue-type-dropdown',
          style_class : 'form-control form-control-sm',
          filter_default_label : 'Revenue Type...',
          select_type: 'select',
          data: [
            {value: '1', label : 'Monthly Sales'},
            {value: '2', label : 'Weekly Sales'},
            {value: '3', label : 'Monthly Returns'},
            {value: '5', label : 'Weekly Returns'},
            {value: '6', label : 'Monthly Remittance'},
            {value: '4', label : 'Weekly Remittance'},
          ]
        },
      ],
      { filters_position : 'footer' },
      { autocomplete : false },
      { externally_triggered : true }
    );
  
    // This block executes any query string searches that may be present in the URL
    function filterTable(columnIndex, filterValue){
      yadcf.exFilterColumn(table, [
        [columnIndex, filterValue],
      ]);
    }
    const queryStringParams = window.location.search.substring(1).split('&');
    queryStringParams.forEach(param => {
      if (param.indexOf('revenue_type') !== -1) {
        filterTable(5, $('#params_span').data('revenue_type'));
      }
    });

    $("#start_date").val($("#start_date_hidden").attr("value"));
    $("#end_date").val($("#end_date_hidden").attr("value"));
  
    $('.item-revenue-report-delete').on('ajax:success', function() {
      var parent = $(this).closest('tr');
      parent.addClass('hidden');
    });
  
    $('body').tooltip({selector: '[data-toggle="tooltip"]'});

  }

  //--------------------------------------------------------------------------//
 //---------- REVENUE REPORT SUPPLIER CHARGEBACK EDIT -----------------------//
//--------------------------------------------------------------------------//

  if ($('.revenue_report_supplier_chargeback-validate').length > 0) {

    $(".revenue_report_supplier_chargeback-validate").validate({
      ignore: ".ignore",
  		rules: {
  		// 	"supplier[brand_name]": {required: true, maxlength: 80},
  		},
  		messages: {
  		// 	"supplier[brand_name]": "a Brand Name is required",
  		},
      onfocusout: function(element) {$(element).valid()},
      focusCleanup: true,
      errorClass: "myErrorClass",
      errorPlacement: function (error, element) {
        error.insertAfter(element.parent());
      },
      highlight: function (element, errorClass, validClass) {
        var elem = $(element);
        if (elem.hasClass("select2-hidden-accessible")) {
          elem.parent().find(".select2-selection").addClass(errorClass);
        } 
        else {
          elem.addClass(errorClass);
        }
      },    
      unhighlight: function (element, errorClass, validClass) {
        var elem = $(element);
        if (elem.hasClass("select2-hidden-accessible")) {
          elem.parent().find(".select2-selection").removeClass(errorClass);
          elem.parent().next("label").remove();
        }
        else {
          elem.removeClass(errorClass);
          elem.parent().next("label").remove();
        }
      },
  		invalidHandler: function(e, validator) {
  			var errors = validator.numberOfInvalids();
  			if (errors) {
  				var message = errors == 1
  					? 'You missed 1 required field. Check below tabs for highlighted fields'
  					: 'You missed ' + errors + ' required fields.  Check below tabs for highlighted fields';
  				$("div.validatorErrorList span").html(message);
  				$("div.validatorErrorList").show();
  			}
  			else {
  				$("div.validatorErrorList").hide();
  			}
  		}
    });

    $("#chargebackEditFirstSave").on("click", function(e) {
      e.preventDefault();
      if ($(".revenue_report_supplier_chargeback-validate").valid()) {
        window.onbeforeunload = null;
        $(".loading-overlay").show();
        $("#chargebackEditSave").click();
      } else {
        return false;
      }
    });

    $("#chargebackEditFirstSubmit").on("click", function(e) {
      e.preventDefault();
      if ($(".revenue_report_supplier_chargeback-validate").valid()) {
        window.onbeforeunload = null;
        $(".loading-overlay").show();
        $("#chargebackEditSubmit").click();
      }
      else {
        return false;
      }
    });


    $.fn.currencyInput = function() {
      this.each(function() {
        $(this).change(function() {
          var min = parseFloat($(this).attr("min"));
          var max = parseFloat($(this).attr("max"));
          var value = this.valueAsNumber;
          if(value < min)
            value = min;
          else if(value > max)
            value = max;
          $(this).val(value.toFixed(2)); 
        });
      });
    };

    $('#revenue_report_supplier_chargeback_charge_amount').currencyInput();

    $('.ship_charges_select').selectize();

    var ship_cost_array = [];

    $('.ship_charge_checkbox').each(function() {
      var tr = $(this).closest('tr');
      var ship_cost = tr.find('.line_item_shipping_cost').attr('data-shipping_cost');
      if ($(this).is(':checked')) {
        tr.addClass('green-highlight');
        tr.find('.line_item_shipping_cost').addClass('checked');
        ship_cost_array.push(ship_cost);
      }
    });

    $(document).on('click', '.select_all_ship_charges', function() {
      var checkboxes = $(this).closest('table').find('.ship_charge_checkbox');
      checkboxes.each(function() {
        if ($(this).is(':checked')) {
          return;
        } else {
          $(this).click();
        }
      });
    });

    function collectShippingCost() {
      var totalShippingCost = 0;
      $('.line_item_shipping_cost').each(function() {
        var cost = parseFloat($(this).data('shipping_cost'));
        totalShippingCost += cost;
      });
      return totalShippingCost;
    }

    $('#expected_total_field').val(collectShippingCost().toFixed(2));

    // Every time a shipping_charge checkbox is checked, this changes the
    // shipping charge sum field to match the currently selected shipping charges
    $(document).on('change', '.ship_charge_checkbox', function() {
      var id = $(this).attr('value');
      var tr = $(this).closest('tr');
      var sum_field = $('#revenue_report_supplier_chargeback_charge_amount');
      if ($(this).is(':checked')) {
        // highlight the row, activate the record in the secondary select box,
        // and update the shipping charges sum
        tr.addClass('green-highlight');
        tr.find('.line_item_shipping_cost').addClass('checked');
        $('.ship_charges_select')[0].selectize.addItem(id, true);
      } else {
        // remove all above mentioned attributes
        tr.removeClass('green-highlight');
        tr.find('.line_item_shipping_cost').removeClass('checked');
        $('.ship_charges_select')[0].selectize.removeItem(id, true);
      }
      // ship_cost_array collects the shipping charges of all currently selected
      // items, and then the each call below takes those values and sums them
      ship_cost_array = [];
      $('.line_item_shipping_cost.checked').each(function() {
        ship_cost_array.push($(this).attr('data-shipping_cost'));
      });
      var ship_cost_sum = 0;
      $.each(ship_cost_array, function(i,v) {
        ship_cost_sum += parseFloat(v);
      });
      sum_field.val(parseFloat(ship_cost_sum).toFixed(2));
      sum_field.currencyInput();
    });








  }

});
