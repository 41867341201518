/*global $*/
/*global bootbox*/
/*global yadcf*/

import 'stylesheets/item_costs-global.scss';

  
$(document).on("turbolinks:load", function() {

  //--------------------------------------------------------------------------//
 //---------- ITEM COST BATCHES FORM RELATED --------------------------------//
//--------------------------------------------------------------------------//

  if ($('.item_cost_batch-validate').length > 0) {

    $('label.required').append('<strong> *</strong>'); // adds a "required" star next to each Inputs label
    $('label.required-s span').append('<strong> *</strong>'); // adds a "required" star next to each Selects label
    $("#cost-type-default-select option:first-child").attr("disabled", true);
    $('#cost-type-default-select').val('1');

    $(".add_notes_button").on("click", function() {
      $(this).slideUp("fast");
      $(".batch-notes-div").slideDown("slow");
    });
  
    $(".add_item_cost_button").on("click", function() {
      $(".add_child_item_cost").click();
    });
    $(".add_10_item_costs_button").on("click", function() {
      for (var i = 0; i < 10; i++){
        $(".add_child_item_cost").click();
      }
    });
  
    // Child Fields Template Loader inherited from inventory_adjustment_batches_helper.rb for Line Items
    $(document).on('click', 'a.add_child_item_cost', function() {
      var association, new_id, regexp, template, fields;
      association = $(this).attr('data-association');
      template = $('#' + association + '_fields_template').html();
      regexp = new RegExp('new_' + association, 'g');
      new_id = (new Date).getTime();
      $(this).parent().before(template.replace(regexp, new_id));
      // The "jstemplates" hidden field needs the ".ignore" class to ensure it
      // doesn't get picked up by Jquery Validator.  This line removes it.
      fields = $(this).parent().prev(".fields");
      fields.find("select").removeClass("ignore");
      fields.find('.productSearch').select2({
        selectOnClose: true,
        allowClear: true,
        placeholder: "Select Item...",
        ajax: {
          url: "/json_dropdown_products_all.json",
          dataType: "json",
          type: "GET",
          processResults: function (data, params) {
            params.page = data.page || 1;
            return {
              results: $.map(data.items, function (item) {
                return {
                  text: item.sku + " | " + item.desc,
                  id: item.id,
                  quantity_on_hand: item.qty
                };
              }),
              pagination: {
                more: data.current_page
              }
            };
          },
          cache: true
        },
        templateSelection: function(container) { // adds the current quantity on hand to the items 'data-quantity' attribute
            $(container.element).attr("data-quantity", container.quantity_on_hand);
            return container.text;
        }
      });

      fields.find('.item_cost_retailers').selectize();
      
      fields.find(".item_cost_type").val($('#cost-type-default-select').find("option:selected").val());
      fields.find(".item_cost_start_date").val($('#start-date-default-select').val());
      fields.find(".item_cost_end_date").val($('#end-date-default-select').val());

      $(".scroll").bind('wheel', function() {});
  
      return false;
    });
  

    $(document).on("click", "a.remove_child_item_cost", function() { // Triggers the removal of the relevant "Item Cost Select" table row when clicked
      var tr = $(this).closest("tr");
      var hidden_field = $(this).prev("input[type=hidden]")[0];
      tr.find(".productSearch").val([]).trigger("change");
      if(hidden_field) {
        hidden_field.value = "1";
      }
      $(this).parents(".fields").remove();
      return false;
    });
  
    $(".item_cost_batch-validate").validate({
      ignore: ".ignore",
  		rules: {
  		// 	"supplier[brand_name]": {required: true, maxlength: 80},
  		},
  		messages: {
  		// 	"supplier[brand_name]": "a Brand Name is required",
  		},
      onfocusout: function(element) {$(element).valid()},
      focusCleanup: true,
      errorClass: "myErrorClass",
      errorPlacement: function (error, element) {
        error.insertAfter(element.parent());
      },
      highlight: function (element, errorClass, validClass) {
        var elem = $(element);
        if (elem.hasClass("select2-hidden-accessible")) {
          elem.parent().find(".select2-selection").addClass(errorClass);
        } 
        else {
          elem.addClass(errorClass);
        }
      },    
      unhighlight: function (element, errorClass, validClass) {
        var elem = $(element);
        if (elem.hasClass("select2-hidden-accessible")) {
          elem.parent().find(".select2-selection").removeClass(errorClass);
          elem.parent().next("label").remove();
        }
        else {
          elem.removeClass(errorClass);
          elem.parent().next("label").remove();
        }
      },
  		invalidHandler: function(e, validator) {
  			var errors = validator.numberOfInvalids();
  			if (errors) {
  				var message = errors == 1
  					? 'You missed 1 required field. Check below tabs for highlighted fields'
  					: 'You missed ' + errors + ' required fields.  Check below tabs for highlighted fields';
  				$("div.validatorErrorList span").html(message);
  				$("div.validatorErrorList").show();
  			}
  			else {
  				$("div.validatorErrorList").hide();
  			}
  		}
    });

    $("#itemCostBatchFirstSave").on("click", function(e) {
      e.preventDefault();
      if ($(".item_cost_batch-validate").valid()) {
        window.onbeforeunload = null;
        $("#jstemplates").html("");
        $('.productSearch option:selected[value=""]').closest('tr').remove();
        $(".loading-overlay").show();
        $("#itemCostBatchSave").click();
      }
      else {
        return false;
      }
    });

    $("#itemCostBatchFirstSubmit").on("click", function(e) {
      e.preventDefault();
      if ($(".item_cost_batch-validate").valid()) {
        window.onbeforeunload = null;
        $("#jstemplates").html("");
        $('.productSearch option:selected[value=""]').closest('tr').remove();
        $(".loading-overlay").show();
        $("#itemCostBatchSubmit").click();
      }
      else {
        return false;
      }
    });

    $(document).on('click', '.item_cost_amount', function() {
      if ($(this).val() == '0.00') {
        $(this).select();
      }
    });
  
    // on first focus (bubbles up to document), open the menu
    $(document).on('focus', '.select2-selection.select2-selection--single', function (e) {
      $(this).closest(".select2-container").siblings('select:enabled').select2('open');
    });
    
    // steal focus during close - only capture once and stop propogation
    $('select.select2').on('select2:closing', function (e) {
      $(e.target).data("select2").$selection.one('focus focusin', function (e) {
        e.stopPropagation();
      });
    });
  
    // monkeypatch to fix issue of search field not being selected on open.  https://github.com/select2/select2/issues/2311#issuecomment-822473913
    $(document).on('select2:open', e => {
      const id = e.target.id;
      const target = document.querySelector(`[aria-controls=select2-${id}-results]`);
      target.focus();
    });
  
//---- Edit Related ----------------------------------------------------------//

    $('.productEditSearch').select2({
      selectOnClose: true,
      allowClear: true,
      placeholder: 'Select Item...',
      ajax: {
        url: '/json_dropdown_products_all.json',
        dataType: 'json',
        type: 'GET',
        processResults: function (data, params) {
          params.page = data.page || 1;
          return {
            results: $.map(data.items, function (item) {
              return {
                  text: item.sku + ' | ' + item.desc,
                  id: item.id,
                  quantity_on_hand: item.qty,
              };
            }),
            pagination: {
              more: data.current_page
            }
          };
        },
        cache: true
      },
      templateSelection: function(container) { // adds the current quantity on hand to the items 'data-quantity' attribute
          $(container.element).attr('data-quantity', container.quantity_on_hand).attr('data-child_count', container.child_count);
          return container.text;
      }
    });
  
    // This populates the 'Product Search' dropdown on the 'Edit' view
    var productSelect = $('.productEditSearch');
    productSelect.each(function() {
      var t = $(this);
      $.ajax({
        url: '/products/' + t.data('selected') + '.json',
        dataType: 'json',
        type: 'GET',
      }).then(function (data) {
        var option = $('<option />')
                      .val(data.productSelect.id)
                      .text(data.productSelect.sku + ' | ' + data.productSelect.desc)
                      .prop('selected', true)
                      .attr('data-quantity', data.productSelect.qty);
        t.append(option).trigger('change');
        t.trigger({
          type: 'select2:select',
          params: {
            data: data
          }
        });
      });
    });

    $('.itemCostBatchSelectTable.edit').find('.item_cost_retailers').selectize();

    $(".fields").each(function() {
      $(this).next('input').attr('data-item', $(this).data('item')).addClass('item_sort');
    });
  
    // grabs the 'Customer Name' field from each order, then sorts alphabetically.
    // Used mostly for help in processing Amazon DC orders where different PO's can be joined by Customer.
    $(document).on('click', '.sort_by_item_button', function() {
      var mylist = $('.item_cost_fields');
      var listitems = mylist.children('.item_sort').get();
      listitems.sort(function(a, b) {
         return $(a).data('item').toUpperCase().localeCompare($(b).data('item').toUpperCase());
      });
      $.each(listitems, function(idx, itm) { mylist.append(itm); });
    });

    $(document).on('click', '.item_cost_edit_remove_button', function() {
      var fields = $(this).closest('.fields');
      bootbox.confirm('Are you sure you want to delete this cost?', function(result){
        if (result === true) {
          fields.find('.remove_child_edit_item_cost').attr('value', 'true');
          fields.slideUp('slow');
        }
      });
    });

    // This facilitates deleting an association via AJAX
    $(".item-cost-delete").on("ajax:success", function() {
      var parent = $(this).closest("tr");
      parent.next("input").remove();
      parent.slideUp( "fast", function() { parent.remove() } );
    });

    // monkeypatch to re-enable scrollwheel on number fields
    $(".scroll").bind('wheel', function() {});

    window.onbeforeunload = function() {
    	return 'Are you sure you want to leave?';
    };

  }

  //--------------------------------------------------------------------------//
 //---------- ITEM COST BATCHES INDEX RELATED -------------------------------//
//--------------------------------------------------------------------------//

  if ($('.item_cost_batches_index_div').length > 0) {
    
    var MyDate = new Date();
    var date;
    MyDate.setDate(MyDate.getDate());
    date = MyDate.getFullYear() + "-" + ("0" + (MyDate.getMonth() + 1)).slice(-2) + "-" + ("0" + MyDate.getDate()).slice(-2);
    var filename = "Item Cost Batches Export, " + date;
    var table = $("#item_cost_batches_datatable").DataTable({
      "retrieve": true,
      "oLanguage": { "sZeroRecords": "No categories to display for this filter" },
      "dom": "<'row'<'col-sm-10 dataTableToolbar'><'col-sm-2'B>>t<'row dataTableBottomRow'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-6'p>>",
      "buttons": [
        {
          extend: "excelHtml5",
          title: filename,
          // Only exports columns that are currently visible.  Adjusted by the "Visibility" dropdown and Filtered text.
          exportOptions: {columns: ":visible"},
          className: "dtButtonHide"
        },
        {
          extend: "csv",
          title: filename,
          exportOptions: {columns: ":visible"},
          className: "dtButtonHide"
        },
        {
          extend: "pdfHtml5",
          title: filename,
          orientation: "landscape",
          exportOptions: {columns: ":visible"},
          className: "dtButtonHide"
        },
        {
          extend: "copyHtml5",
          title: filename,
          exportOptions: {columns: ":visible"},
          className: "dtButtonHide"
        }, 
        {
          extend: "print",
          autoPrint: false,
          title: filename,
          exportOptions: {columns: ":visible"},
          customize: function (win) {
              $(win.document.body).find('table').addClass('display').css('font-size', '12px');
              $(win.document.body).find('tr:nth-child(odd) td').each(function(index){
                  $(this).css('background-color','#D0D0D0');
              });
              $(win.document.body).find('h1').css('text-align','center');
          },
          className: "dtButtonHide"
        }
      ],
      "columnDefs": [
        {
        "targets": 0,
        "visible": false,
        }
      ],
      "order": [[1, "asc"]],
    });
    
  
    function dataTableToolbar() {
      return "" +
      "<ul class='nav' style='margin-bottom:10px'>" +
      "  <li>" +
      "    <div id='item_cost_batches_datatable_filter' class='dataTables_filter mr-2'>" +
      "      <input id='categoriesTableSearch' class='form-control form-control-sm tableSearch' title='right-click to clear' oncontextmenu='return false' aria-controls='item_cost_batches_datatable' placeholder='Search Table...'>" +
      "    </div>" +
      "  <li>" +
      "    <div class='dataTables_length' id='item_cost_batches_datatable_length'>" +
      "      <select name='item_cost_batches_datatable_length' aria-controls='item_cost_batches_datatable' class='form-control form-control-sm tableLength' title='Number of records to show'>" +
      "        <option value='5'>5</option>" +
      "        <option value='10'>10</option>" +
      "        <option value='25'>25</option>" +
      "        <option value='50'>50</option>" +
      "        <option value='100'>100</option>" +
      "        <option selected='selected' value='-1'>All</option>" +
      "      </select>" +
      "    </div>" +
      "  </li>" +
      "  <li>" +
      "    <div class='tableClickerDiv dropdown'>" +
      "      <button class='btn btn-outline-primary btn-sm dropdown-toggle' type='button' id='tableClickerExports' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'><i class='fas fa-file-export'></i> Export</button>" +
      "      <div class='dropdown-menu' aria-labelledby='tableClickerExports'>" +
      "        <div class='text-primary' style='font-size:11px;padding:0.25rem 1.5rem;'>" +
      "          <i class='fas fa-exclamation-triangle text-danger'></i> Only the currently visible columns & rows will be exported" +
      "        </div>" +
      "        <a class='dropdown-item tableClickerExcel'><i class='fas fa-file-excel file-icon-color'></i> Excel <i class='fas fa-download text-primary'></i></a>" +
      "        <a class='dropdown-item tableClickerCsv'><i class='fas fa-file-csv file-icon-color'></i> CSV <i class='fas fa-download text-primary'></i></a>" +
      "        <a class='dropdown-item tableClickerPdf'><i class='fas fa-file-pdf file-icon-color'></i> PDF <i class='fas fa-download text-primary'></i></a>" +
      "        <a class='dropdown-item tableClickerCopy'><i class='fas fa-copy file-icon-color'></i> Copy</a>" +
      "        <a class='dropdown-item tableClickerPrint'><i class='fas fa-print file-icon-color'></i> Print/Preview</a>" +
      "      </div>" +
      "    </div>" +
      "  </li>" +
      "  <li>" +
      "    <div class='tableClickerDiv dropdown'>" +
      "      <button class='btn btn-outline-primary btn-sm dropdown-toggle' type='button' id='tableClickerColvis' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'><i class='fas fa-columns'></i> Columns</button>" +
      "      <div class='dropdown-menu' aria-labelledby='tableClickerColvis'>" +
      "        <a class='dropdown-item button-highlight' id='colvisClicker-0'>ID</a>" +
      "        <a class='dropdown-item' id='colvisClicker-1'>Batch Identifier</a>" +
      "        <a class='dropdown-item' id='colvisClicker-2'>Supplier</a>" +
      "        <a class='dropdown-item' id='colvisClicker-3'>Associated Costs</a>" +
      "        <a class='dropdown-item' id='colvisClicker-4'>Actions</a>" +
      "      </div>" +
      "    </div>" +
      "  </li>" +
      "</ul>" +
      "";
    }
  
    table.page.len(-1).draw();
  
    $("div.dataTableToolbar").html(dataTableToolbar());
  
    $(".tableSearch").keyup(function () {
      table.search($(this).val()).draw();
    });
  
    $(".tableLength").on("change", function () {
      table.page.len($(this).find("option:selected").attr("value")).draw();
    });
  
    function colVisButtons(index) {
      var button = $("#colvisClicker-"+index);
      button.on("click", function(e) {
        e.stopPropagation();
        if (table.column(index).visible() === true) {
          table.column(index).visible(false);
          button.addClass("button-highlight");
        }
        else {
          table.column(index).visible(true);
          button.removeClass("button-highlight");
        }
      });
    }
    let colVisIndex = -1;
    do { colVisIndex += 1;
         colVisButtons(colVisIndex);
    } while (colVisIndex < 4);
  
    $(".dt-buttons").hide(); // Hides default Colvis & Export buttons

    $('body').tooltip({selector: '[data-toggle="tooltip"]'});

  }

  //--------------------------------------------------------------------------//
 //---------- ITEM COST BATCHES SHOW RELATED --------------------------------//
//--------------------------------------------------------------------------//

  if ($('.item_cost_batch_show_div').length > 0) {

    $(document).on('click', '.ots-clicker', function() {
      $(this).closest('span').find('.on_the_spot_editing').click();
    });
  
    // This facilitates deleting an association via AJAX
    $(".item-cost-delete").on("ajax:success", function() {
      var parent = $(this).closest("tr");
      parent.next("input").remove();
      parent.slideUp( "fast", function() { parent.remove() } );
    });

    // Item Costs Table
    var MyDate = new Date();
    var date;
    MyDate.setDate(MyDate.getDate());
    date = MyDate.getFullYear() + "-" + ("0" + (MyDate.getMonth() + 1)).slice(-2) + "-" + ("0" + MyDate.getDate()).slice(-2);
    var filename = "Item Costs Export, " + date;
    var table = $(".item_costs_table").DataTable({
      "retrieve": true,
      "processing": true,
      // "order": [[ 1, "asc" ], [ 10, "desc" ], [ 9, "desc" ], [ 8, "desc" ], [ 7, "desc" ]],
      "oLanguage": { "sZeroRecords": "No records to display for this filter" },
      "dom": "<'row'<'col-sm-10 dataTableToolbar'><'col-sm-2'B>>t<'row dataTableBottomRow'<'col-md-2 ml-3'i><'col-md-9'p>>",
      "buttons": [
        {
          extend: "excelHtml5",
          title: filename,
          // Only exports columns that are currently visible.  Adjusted by the "Visibility" dropdown and Filtered text.
          exportOptions: {columns: ":visible"},
          className: "dtButtonHide"
        },
        {
          extend: "csv",
          title: filename,
          exportOptions: {columns: ":visible"},
          className: "dtButtonHide"
        },
        {
          extend: "pdfHtml5",
          title: filename,
          orientation: "landscape",
          exportOptions: {columns: ":visible"},
          className: "dtButtonHide"
        },
        {
          extend: "copyHtml5",
          title: filename,
          exportOptions: {columns: ":visible"},
          className: "dtButtonHide"
        }, 
        {
          extend: "print",
          autoPrint: false,
          title: filename,
          exportOptions: {columns: ":visible"},
          customize: function (win) {
              $(win.document.body).find('table').addClass('display').css('font-size', '12px');
              $(win.document.body).find('tr:nth-child(odd) td').each(function(index){
                  $(this).css('background-color','#D0D0D0');
              });
              $(win.document.body).find('h1').css('text-align','center');
          },
          className: "dtButtonHide"
        }
      ]
    });
  
    function dataTableToolbar() {
      return "" +
      "<ul class='nav ml-2 mb-3'>" +
      "  <li>" +
      "    <div class='dataTables_length' id='item_costs_table_length'>" +
      "      <select name='item_costs_table_length' aria-controls='item_costs_table' class='form-control form-control-sm tableLength' title='Number of records to show'>" +
      "        <option value='5'>5</option>" +
      "        <option value='10'>10</option>" +
      "        <option selected='selected' value='25'>25</option>" +
      "        <option value='50'>50</option>" +
      "        <option value='100'>100</option>" +
      "        <option value='250'>250</option>" +
      "        <option value='-1'>All</option>" +
      "      </select>" +
      "    </div>" +
      "  </li>" +
      "  <li>" +
      "    <div class='tableClickerDiv dropdown'>" +
      "      <button class='btn btn-outline-primary btn-sm dropdown-toggle' title='Export Options' type='button' id='tableClickerExports' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'><i class='fas fa-file-export'></i> Export</button>" +
      "      <div class='dropdown-menu' aria-labelledby='tableClickerExports'>" +
      "        <div class='text-primary' style='font-size:11px;padding:0.25rem 1.5rem;'>" +
      "          <i class='fas fa-exclamation-triangle text-danger'></i> Only the currently visible columns & rows will be exported" +
      "        </div>" +
      "        <a class='dropdown-item tableClickerExcel'><i class='fas fa-file-excel file-icon-color'></i> Excel <i class='fas fa-download text-primary'></i></a>" +
      "        <a class='dropdown-item tableClickerCsv'><i class='fas fa-file-csv file-icon-color'></i> CSV <i class='fas fa-download text-primary'></i></a>" +
      "        <a class='dropdown-item tableClickerPdf'><i class='fas fa-file-pdf file-icon-color'></i> PDF <i class='fas fa-download text-primary'></i></a>" +
      "        <a class='dropdown-item tableClickerCopy'><i class='fas fa-copy file-icon-color'></i> Copy</a>" +
      "        <a class='dropdown-item tableClickerPrint'><i class='fas fa-print file-icon-color'></i> Print/Preview</a>" +
      "      </div>" +
      "    </div>" +
      "  </li>" +
      "  <li class='filter-section ml-3'>" +
      "    <div id='item_costs_table_filter' class='dataTables_filter mr-2'>" +
      "      <input id='item_costs_table_tableSearch' class='form-control form-control-sm tableSearch' title='right-click to clear' oncontextmenu='return false' aria-controls='item_costs_table' placeholder='Search Entire Table...'>" +
      "    </div>" +
      "  </li>" +
      "</ul>" +
      "";
    }
  
    table.page.len(50).draw();
  
    $("div.dataTableToolbar").html(dataTableToolbar());
  
    $(".tableLength").on("change", function () {
      table.page.len($(this).find("option:selected").attr("value")).draw();
    });
  
    var search = $.fn.dataTable.util.throttle(
      function (val) {
        table.search(val).draw();
      }, 500);
     
    $('.tableSearch').on('keyup', function () {
      search( this.value );
    });

  }

  //--------------------------------------------------------------------------//
 //---------- ITEM COSTS INDEX RELATED --------------------------------------//
//--------------------------------------------------------------------------//

  if ($('.item_costs_index_div').length > 0) {
  
    var MyDate = new Date();
    var date;
    MyDate.setDate(MyDate.getDate());
    date = MyDate.getFullYear() + "-" + ("0" + (MyDate.getMonth() + 1)).slice(-2) + "-" + ("0" + MyDate.getDate()).slice(-2);
    var filename = "Item Costs Export, " + date;
    var table = $("#itemCostsDataTable").DataTable({
      "retrieve": true,
      "processing": true,
      "serverSide": true,
      "ajax": {
        "url": $("#itemCostsDataTable").data("source")
      },
      "columns": [
        {data: "id"},
        {data: "batch_id"},
        {data: "batch_identifier"},
        {data: "supplier"},
        {data: "sku"},
        {data: "cost_type"},
        {data: "cost_amount", searchable: false},
        {data: "start_date"},
        {data: "end_date"},
        {data: "retailers", orderable: false, searchable: false},
        {data: "notes"},
        {data: "actions", orderable: false}
      ],
      "order": [[ 0, "desc" ]],
      "oLanguage": { "sZeroRecords": "No records to display for this filter" },
      "dom": "<'row'<'col-sm-10 dataTableToolbar'><'col-sm-2'B>>t<'row dataTableBottomRow'<'col-md-2'i><'col-md-10'p>>",
      "buttons": [
        {
          extend: "excelHtml5",
          title: filename,
          // Only exports columns that are currently visible.  Adjusted by the "Visibility" dropdown and Filtered text.
          exportOptions: {columns: ":visible"},
          className: "dtButtonHide"
        },
        {
          extend: "csv",
          title: filename,
          exportOptions: {columns: ":visible"},
          className: "dtButtonHide"
        },
        {
          extend: "pdfHtml5",
          title: filename,
          orientation: "landscape",
          exportOptions: {columns: ":visible"},
          className: "dtButtonHide"
        },
        {
          extend: "copyHtml5",
          title: filename,
          exportOptions: {columns: ":visible"},
          className: "dtButtonHide"
        }, 
        {
          extend: "print",
          autoPrint: false,
          title: filename,
          exportOptions: {columns: ":visible"},
          customize: function (win) {
              $(win.document.body).find('table').addClass('display').css('font-size', '12px');
              $(win.document.body).find('tr:nth-child(odd) td').each(function(index){
                  $(this).css('background-color','#D0D0D0');
              });
              $(win.document.body).find('h1').css('text-align','center');
          },
          className: "dtButtonHide"
        }
      ]
    });
  
    function dataTableToolbar() {
      return "" +
      "<ul class='nav' style='margin-bottom:10px'>" +
      "  <li>" +
      "    <span class='options-label'>Options:</span>" +
      "  </li>" +
      "  <li>" +
      "    <div class='dataTables_length' id='itemCostsDataTable_length'>" +
      "      <select name='itemCostsDataTable_length' aria-controls='itemCostsDataTable' class='form-control form-control-sm tableLength' title='Number of records to show'>" +
      "        <option value='5'>5</option>" +
      "        <option value='10'>10</option>" +
      "        <option value='25'>25</option>" +
      "        <option selected='selected' value='50'>50</option>" +
      "        <option value='100'>100</option>" +
      "        <option value='250'>250</option>" +
      "        <option value='1000'>1000</option>" +
      "      </select>" +
      "    </div>" +
      "  </li>" +
      "  <li>" +
      "    <div class='tableClickerDiv dropdown'>" +
      "      <button class='btn btn-outline-primary btn-sm dropdown-toggle' title='Column visibility options' type='button' id='tableClickerColvis' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'><i class='fas fa-columns'></i> Columns</button>" +
      "      <div class='dropdown-menu card-shadow' aria-labelledby='tableClickerColvis'>" +
      "        <a class='dropdown-item' id='colvisClicker-0'>Item Cost ID</a>" +
      "        <a class='dropdown-item' id='colvisClicker-1'>Batch ID</a>" +
      "        <a class='dropdown-item' id='colvisClicker-2'>Batch Identifier</a>" +
      "        <a class='dropdown-item' id='colvisClicker-3'>Supplier</a>" +
      "        <a class='dropdown-item' id='colvisClicker-4'>SKU #</a>" +
      "        <a class='dropdown-item' id='colvisClicker-5'>Cost Type</a>" +
      "        <a class='dropdown-item' id='colvisClicker-6'>Amount</a>" +
      "        <a class='dropdown-item' id='colvisClicker-7'>Start Date</a>" +
      "        <a class='dropdown-item' id='colvisClicker-8'>End Date</a>" +
      "        <a class='dropdown-item' id='colvisClicker-9'>Retailers</a>" +
      "        <a class='dropdown-item' id='colvisClicker-10'>Notes</a>" +
      "        <a class='dropdown-item' id='colvisClicker-11'>Actions</a>" +
      "      </div>" +
      "    </div>" +
      "  </li>" +
      "  <li>" +
      "    <div class='tableClickerDiv dropdown'>" +
      "      <button class='btn btn-outline-primary btn-sm dropdown-toggle' title='Export Options' type='button' id='tableClickerExports' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'><i class='fas fa-file-export'></i> Export</button>" +
      "      <div class='dropdown-menu' aria-labelledby='tableClickerExports'>" +
      "        <div class='text-primary' style='font-size:11px;padding:0.25rem 1.5rem;'>" +
      "          <i class='fas fa-exclamation-triangle text-danger'></i> Only the currently visible columns & rows will be exported" +
      "        </div>" +
      "        <a class='dropdown-item tableClickerExcel'><i class='fas fa-file-excel file-icon-color'></i> Excel <i class='fas fa-download text-primary'></i></a>" +
      "        <a class='dropdown-item tableClickerCsv'><i class='fas fa-file-csv file-icon-color'></i> CSV <i class='fas fa-download text-primary'></i></a>" +
      "        <a class='dropdown-item tableClickerPdf'><i class='fas fa-file-pdf file-icon-color'></i> PDF <i class='fas fa-download text-primary'></i></a>" +
      "        <a class='dropdown-item tableClickerCopy'><i class='fas fa-copy file-icon-color'></i> Copy</a>" +
      "        <a class='dropdown-item tableClickerPrint'><i class='fas fa-print file-icon-color'></i> Print/Preview</a>" +
      "      </div>" +
      "    </div>" +
      "  </li>" +
      "  <li class='filter-section mt-2' style='width:100%'>" +
      "    <span class='filter-label'>Filters:</span>" +
      "    <div id='itemCostsDataTable_filter' class='dataTables_filter mr-2'>" +
      "      <input id='itemCostsTableSearch' class='form-control form-control-sm tableSearch' title='right-click to clear' oncontextmenu='return false' aria-controls='itemCostsDataTable' placeholder='Search Entire Table...'>" +
      "    </div>" +
      "    <span id='supplier-select-input' class='filter-span-input colvisSearch-2'></span>" +
      "    <span id='sku-select-input' class='filter-span-input colvisSearch-3'></span>" +
      "    <span id='cost-type-dropdown' class='filter-span-input colvisSearch-4'></span>" +
      "  </li>" +
      "</ul>" +
      "";
    }
  
    table.page.len(50).draw();

    $("div.dataTableToolbar").html(dataTableToolbar());
  
    var search = $.fn.dataTable.util.throttle(
      function (val) {
        table.search(val).draw();
      }, 500);
     
    $('.tableSearch').on('keyup', function () {
      search( this.value );
    });
  
    $(".tableLength").on("change", function () {
      table.page.len($(this).find("option:selected").attr("value")).draw();
    });
  
    function colVisButtons(index) {
      var button = $("#colvisClicker-"+index);
      button.on("click", function(e) {
        e.stopPropagation();
        if (table.column(index).visible() === true) {
          table.column(index).visible(false);
          button.addClass("button-highlight");
          $(".colvisSearch-"+index).hide();
        }
        else {
          table.column(index).visible(true);
          button.removeClass("button-highlight");
          $(".colvisSearch-"+index).show();
        }
      });
    }
    let colVisIndex = -1;
    do { colVisIndex += 1;
         colVisButtons(colVisIndex);
    } while (colVisIndex < 11);
  
    yadcf.init(table,
      [
  
        { column_number : 3, // Supplier
          filter_type: "text",
          filter_container_id : "supplier-select-input",
          style_class : "form-control form-control-sm",
          filter_default_label : "Search Supplier..."
        },
  
        { column_number : 4, // SKU #
          filter_type: "text",
          filter_container_id : "sku-select-input",
          style_class : "form-control form-control-sm",
          filter_default_label : "Search SKU #..."
        },
  
        { column_number : 5, // Cost Type
          filter_container_id : "cost-type-dropdown",
          style_class : "form-control form-control-sm",
          filter_default_label : "Cost Type...",
          data: [
            {value: "1", label : "Ecomm Cost"},
            {value: "2", label : "Suggested Retail"}
          ]
        },
  
      ],
      { filters_position : "footer" },
      { externally_triggered : true }
    );
  
    // This facilitates deleting a file via AJAX
    $(".item-cost-delete").on("ajax:success", function() {
      var parent = $(this).closest("tr");
      parent.addClass("hidden");
    });
  
    $('body').tooltip({selector: '[data-toggle="tooltip"]'});

  }

  //--------------------------------------------------------------------------//
 //---------- ITEM COST TYPES FORM RELATED ----------------------------------//
//--------------------------------------------------------------------------//

  if ($('.item_cost_type-validate').length > 0) {

    $('label.required').append('<strong> *</strong>'); // adds a "required" star next to each Inputs label
  
    $(".item_cost_type-validate").validate({
      ignore: ".ignore",
      onkeyup: function(element) {$(element).valid()},
      errorClass: "myErrorClass",
      errorPlacement: function (error, element) {
        error.insertAfter(element.parent());
      },
      highlight: function (element, errorClass, validClass) {
        $(element).addClass(errorClass);
      },    
      unhighlight: function (element, errorClass, validClass) {
        $(element).removeClass(errorClass);
        $(element).parent().next("label").remove();
      },
  		invalidHandler: function(e, validator) {
  			var errors = validator.numberOfInvalids();
  			if (errors) {
  				var message = errors == 1
  					? 'You missed 1 required field. It has been highlighted above'
  					: 'You missed ' + errors + ' required fields.  They have been highlighted above';
  				$("div.validatorErrorList span").html(message);
  				$("div.validatorErrorList").show();
  			}
  			else {
  				$("div.validatorErrorList").hide();
  			}
  		}
    });
  
    $("#itemCostTypeFirstSubmit").on("click", function(e) {
      e.preventDefault();
      if ($(".item_cost_type-validate").valid()) {
        $("#itemCostTypeSubmit").click();
      }
      else {
        return false;
      }
    });

  }

  //--------------------------------------------------------------------------//
 //---------- ITEM COST TYPES INDEX RELATED ---------------------------------//
//--------------------------------------------------------------------------//

  if ($('.item_cost_types_index_div').length > 0) {
    
    var MyDate = new Date();
    var date;
    MyDate.setDate(MyDate.getDate());
    date = MyDate.getFullYear() + "-" + ("0" + (MyDate.getMonth() + 1)).slice(-2) + "-" + ("0" + MyDate.getDate()).slice(-2);
    var filename = "Item Cost Types Export, " + date;
    var table = $("#itemCostTypesDataTable").DataTable({
      "retrieve": true,
      "oLanguage": { "sZeroRecords": "No cost types to display for this filter" },
      "dom": "<'row'<'col-sm-10 dataTableToolbar'><'col-sm-2'B>>t<'row dataTableBottomRow'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-6'p>>",
      "buttons": [
        {
          extend: "excelHtml5",
          title: filename,
          // Only exports columns that are currently visible.  Adjusted by the "Visibility" dropdown and Filtered text.
          exportOptions: {columns: ":visible"},
          className: "dtButtonHide"
        },
        {
          extend: "csv",
          title: filename,
          exportOptions: {columns: ":visible"},
          className: "dtButtonHide"
        },
        {
          extend: "pdfHtml5",
          title: filename,
          orientation: "landscape",
          exportOptions: {columns: ":visible"},
          className: "dtButtonHide"
        },
        {
          extend: "copyHtml5",
          title: filename,
          exportOptions: {columns: ":visible"},
          className: "dtButtonHide"
        }, 
        {
          extend: "print",
          autoPrint: false,
          title: filename,
          exportOptions: {columns: ":visible"},
          customize: function (win) {
              $(win.document.body).find('table').addClass('display').css('font-size', '12px');
              $(win.document.body).find('tr:nth-child(odd) td').each(function(index){
                  $(this).css('background-color','#D0D0D0');
              });
              $(win.document.body).find('h1').css('text-align','center');
          },
          className: "dtButtonHide"
        }
      ],
      "columnDefs": [
        {
        "targets": 0,
        "visible": false,
        }
      ],
      "order": [[1, "asc"]],
    });
    
  
    function dataTableToolbar() {
      return "" +
      "<ul class='nav' style='margin-bottom:10px'>" +
      "  <li>" +
      "    <div id='itemCostTypesDataTable_filter' class='dataTables_filter mr-2'>" +
      "      <input id='itemCostTypesTableSearch' class='form-control form-control-sm tableSearch' title='right-click to clear' oncontextmenu='return false' aria-controls='item_cost_batches_datatable' placeholder='Search Table...'>" +
      "    </div>" +
      "  <li>" +
      "    <div class='dataTables_length' id='itemCostTypesDataTable_length'>" +
      "      <select name='itemCostTypesDataTable_length' aria-controls='itemCostTypesDataTable'class='form-control form-control-sm tableLength' title='Number of records to show'>" +
      "        <option value='5'>5</option>" +
      "        <option value='10'>10</option>" +
      "        <option value='25'>25</option>" +
      "        <option value='50'>50</option>" +
      "        <option value='100'>100</option>" +
      "        <option selected='selected' value='-1'>All</option>" +
      "      </select>" +
      "    </div>" +
      "  </li>" +
      "  <li>" +
      "    <div class='tableClickerDiv dropdown'>" +
      "      <button class='btn btn-outline-primary btn-sm dropdown-toggle' type='button' id='tableClickerExports' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'><i class='fas fa-file-export'></i> Export</button>" +
      "      <div class='dropdown-menu' aria-labelledby='tableClickerExports'>" +
      "        <div class='text-primary' style='font-size:11px;padding:0.25rem 1.5rem;'>" +
      "          <i class='fas fa-exclamation-triangle text-danger'></i> Only the currently visible columns & rows will be exported" +
      "        </div>" +
      "        <a class='dropdown-item tableClickerExcel'><i class='fas fa-file-excel file-icon-color'></i> Excel <i class='fas fa-download text-primary'></i></a>" +
      "        <a class='dropdown-item tableClickerCsv'><i class='fas fa-file-csv file-icon-color'></i> CSV <i class='fas fa-download text-primary'></i></a>" +
      "        <a class='dropdown-item tableClickerPdf'><i class='fas fa-file-pdf file-icon-color'></i> PDF <i class='fas fa-download text-primary'></i></a>" +
      "        <a class='dropdown-item tableClickerCopy'><i class='fas fa-copy file-icon-color'></i> Copy</a>" +
      "        <a class='dropdown-item tableClickerPrint'><i class='fas fa-print file-icon-color'></i> Print/Preview</a>" +
      "      </div>" +
      "    </div>" +
      "  </li>" +
      "  <li>" +
      "    <div class='tableClickerDiv dropdown'>" +
      "      <button class='btn btn-outline-primary btn-sm dropdown-toggle' type='button' id='tableClickerColvis' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'><i class='fas fa-columns'></i> Columns</button>" +
      "      <div class='dropdown-menu' aria-labelledby='tableClickerColvis'>" +
      "        <a class='dropdown-item button-highlight' id='colvisClicker-0'>ID</a>" +
      "        <a class='dropdown-item' id='colvisClicker-1'>Name</a>" +
      "        <a class='dropdown-item' id='colvisClicker-2'>Description</a>" +
      "        <a class='dropdown-item' id='colvisClicker-3'>Actions</a>" +
      "      </div>" +
      "    </div>" +
      "  </li>" +
      "</ul>" +
      "";
    }
  
    table.page.len(25).draw();
  
    $("div.dataTableToolbar").html(dataTableToolbar());
  
    $(".tableSearch").keyup(function () {
      table.search($(this).val()).draw();
    });
  
    $(".tableLength").on("change", function () {
      table.page.len($(this).find("option:selected").attr("value")).draw();
    });
  
    function colVisButtons(index) {
      var button = $("#colvisClicker-"+index);
      button.on("click", function(e) {
        e.stopPropagation();
        if (table.column(index).visible() === true) {
          table.column(index).visible(false);
          button.addClass("button-highlight");
        }
        else {
          table.column(index).visible(true);
          button.removeClass("button-highlight");
        }
      });
    }
    let colVisIndex = -1;
    do { colVisIndex += 1;
         colVisButtons(colVisIndex);
    } while (colVisIndex < 3);

  }

});
