/*global $*/
/*global Option*/

import 'stylesheets/invoices-global.scss';

$(document).on('turbolinks:load', function() {

//----------------------------------------------------------------------------//
//---------- INVOICES FORM RELATED -------------------------------------------//
//----------------------------------------------------------------------------//

  if ($('.invoice-validate').length > 0) {
  
    $('label.required').append('<strong> *</strong>'); // adds a 'required' star next to each Inputs label
    $('label.required-s span').append('<strong> *</strong>'); // adds a 'required' star next to each Selects label
    
    $.fn.currencyInput = function() {
      this.each(function() {
        $(this).change(function() {
          var min = parseFloat($(this).attr("min"));
          var max = parseFloat($(this).attr("max"));
          var value = this.valueAsNumber;
          if(value < min)
            value = min;
          else if(value > max)
            value = max;
          $(this).val(value.toFixed(2)); 
        });
      });
    };
  
    $('.charge_amount, #invoice_invoice_total').currencyInput();
  
    $(document).on('click', '.charge_amount', function() {
      if ($(this).val() == '0.00') {
        $(this).select();
      }
    });
  
    $('.invoice_status_select').select2({
      selectOnClose: true,
      placeholder: 'Invoice Status...',
    });
  
    $('.invoice_supplier_select').select2({
      selectOnClose: true,
      allowClear: true,
      placeholder: 'Supplier...',
    });
  
    $('.invoice_orders_select').selectize();
  
    $(document).on('change', '.invoice_check', function() {
      var id = $(this).attr('value');
      var tr = $(this).closest('tr');
      if ($(this).is(':checked')) {
        tr.addClass('green-highlight');
        tr.find('.line_item_shipping_cost').addClass('checked');
        $('.invoice_orders_select')[0].selectize.addItem(id, true);
        $('.invoice_check[value="'+id+'"]').prop('checked', true);
        $('.invoice_check[value="'+id+'"]:checked').click().click();
      } else {
        tr.removeClass('green-highlight');
        tr.find('.line_item_shipping_cost').removeClass('checked');
        $('.invoice_orders_select')[0].selectize.removeItem(id, true);
        $('.invoice_check[value="'+id+'"]:checked').click();
      }
    });


    // $(document).on('change', '.invoice_check', function() {
    //   // $('.invoice_check[value="'+id+'"]').prop('checked', true);
    //   // $('.invoice_check').each(function() {
    //   //   console.log(id);
    //   //   // $(this).on('click', function() {
    //       if ($(this).is(':checked')) {
    //         $('#sum_invoice_total').click();
    //       } else {
    //         $('#sum_invoice_total').click();
    //       }
    //   //   // });
    //   // });

    // });





  
    $(document).on('change keyup mousewheel click', '.charge_amount, .charge_quantity, .invoice_check, #invoice_surcharge, #invoice_discount', function() {
      $('#sum_invoice_total').click();
    });
  
    $(".invoice-validate").validate({
      ignore: ".ignore",
  		rules: {
  		// 	"product[model_number]": {required: true, maxlength: 80},
  		// 	"product[supplier_part_number]": {required: true, maxlength: 80},
  		},
  		messages: {
  		// 	"product[model_number]": "If the Model Number & the Manufacturer Part Number are the same, enter the same SKU in both fields."
  		},
      onfocusout: function(element) {$(element).valid()},
      focusCleanup: true,
      errorClass: "myErrorClass",
      errorPlacement: function (error, element) {
        error.insertAfter(element.parent());
      },
      highlight: function (element, errorClass, validClass) {
        var elem = $(element);
        var tabId = elem.closest(".tab-pane").attr("aria-labelledby");
        if (elem.hasClass("select2-hidden-accessible")) {
          elem.parent().find(".select2-selection").addClass(errorClass);
          elem.closest(".card-body").find(".nav-link[id='"+tabId+"']").addClass(errorClass);
        } 
        else {
          elem.addClass(errorClass);
          elem.closest(".card-body").find(".nav-link[id='"+tabId+"']").addClass(errorClass);
        }
      },    
      unhighlight: function (element, errorClass, validClass) {
        var elem = $(element);
        var tabId = elem.closest(".tab-pane").attr("aria-labelledby");
        if (elem.hasClass("select2-hidden-accessible")) {
          elem.parent().find(".select2-selection").removeClass(errorClass);
          elem.parent().next("label").remove();
          elem.closest(".card-body").find(".nav-link[id='"+tabId+"']").removeClass(errorClass);
        }
        else {
          elem.removeClass(errorClass);
          elem.parent().next("label").remove();
          elem.closest(".card-body").find(".nav-link[id='"+tabId+"']").removeClass(errorClass);
        }
      },
  		invalidHandler: function(e, validator) {
  			var errors = validator.numberOfInvalids();
  			if (errors) {
  				var message = errors == 1
  					? 'You missed 1 required field. Check below tabs for highlighted fields'
  					: 'You missed ' + errors + ' required fields.  Check below tabs for highlighted fields';
  				$("div.validatorErrorList span").html(message);
  				$("div.validatorErrorList").slideDown("slow");
  			}
  			else {
  				$("div.validatorErrorList").slideUp("slow");
  			}
  		}
    });
  
    // Monkeypatch so all select2 fields actually clear to 'nil' when the 'X' is clicked
    $(document).on('select2:clear', '.select2-hidden-accessible', function (e) {
      $(this).append('<option value="" selected="selected"></option>');
    });
  
    $("#invoiceFirstSubmit").on("click", function(e) {
      e.preventDefault();
      if ($(".invoice-validate").valid()) {
        $("#jstemplates_invoice_charges").html("");
        // $(".orders_table_body").html("");
        $("#invoiceSubmit").click();
      }
      else {
        return false;
      }
    });
  
    $(document).on('change', '.invoice_status_select', function() {
      if ($(this).find('option:selected').val() == 'paid') {
        $('.invoice_paid_input').show();
      } else {
        $('#invoice_paid_date').val('');
        $('.invoice_paid_input').hide();
      }
    });
  
    // This populates the 'Supplier Search' dropdown on the 'Edit' view
    var supplierSelect = $('.invoice_supplier_select');
    if (supplierSelect.val()) {
      $.ajax({
        url: '/suppliers/' + supplierSelect.data('selected') + '.json',
        dataType: 'json',
        type: 'GET',
      }).then(function (data) {
        var option = new Option(data.supplierSelect.brand_name, data.supplierSelect.id, true, true);
        supplierSelect.append(option).trigger('change');
        supplierSelect.trigger({
          type: 'select2:select',
          params: {
            data: data
          }
        });
      });
    }

    $('.recipient_address_select').select2({
      selectOnClose: true,
      allowClear: true,
      placeholder: "Supplier Billing Address...",
    });

    $(document).on('click', '#oc_load_orders', function() {
      var startdate = $('#oc_startdate').val();
      var enddate = $('#oc_enddate').val();
      var supplier = $('.invoice_supplier_select').find('option:selected').val();
      window.history.replaceState(null, null, '?oc_startdate='+startdate+'&oc_enddate='+enddate+'&invoice_supplier_select='+supplier);
      window.location.reload(false);
    });
  
    var ship_cost_array = [];
    $(document).on('click', '#sum_invoice_total', function() {
      $('.line_item_shipping_cost.checked').each(function() {
        ship_cost_array.push([ $(this).data('shipping_cost') ]);
      });
      $('.charge_amount').each(function() {
        if ($(this).val() > 0) {
          ship_cost_array.push([ $(this).val()*$(this).closest('.invoice_charge_table').find('.charge_quantity').val() ]);
        } else {
          ship_cost_array.push('0');
        }
      });
      // function for summing all visible shipping charges & invoice charges
      var total = ship_cost_array.reduce(function(a,b) {
        return parseFloat(a) + parseFloat(b);
      });
      var surcharge = $('#invoice_surcharge').val();
      var discount = $('#invoice_discount').val();
  
      if (total > 0) {
        $('#invoice_invoice_total').val(parseFloat(total + (surcharge * total / 100) - (discount * total / 100)).toFixed(2));
      } else {
        $('#invoice_invoice_total').val('0.00');
      }
      ship_cost_array.length = 0;
    });

  //---- Invoice Charges Related -------------------------------------------------//
  
    $(".add_invoice_charge_button").on("click", function() {
      $(".add_child_invoice_charge").click();
    });
  
    // Child Fields Template Loader inherited from invoices_helper.rb for Invoice Charges
    $(document).on('click', 'a.add_child_invoice_charge', function() {
      var association, new_id, regexp, template, field;
      association = $(this).attr('data-association');
      template = $('#' + association + '_fields_template').html();
      regexp = new RegExp('new_' + association, 'g');
      new_id = (new Date).getTime();
      $(this).parent().before(template.replace(regexp, new_id));
      field = $(this).parent().prev('.fields_invoice_charge');
      // The "jstemplates" hidden field needs the ".ignore" class to ensure it
      // doesn't get picked up by Jquery Validator.  This line removes it.
      // monkeypatch to re-enable scrollwheel on number fields
      $(".scroll").bind('mousewheel', function() {});
      $('.charge_amount').currencyInput();
      
      field.hide().slideDown('fast');
      
      return false;
    });
  
    $(document).on("click", "a.remove_child_invoice_charge", function() { // Triggers the removal of the relevant "Item Select" table row when clicked
      var hidden_field = $(this).prev("input[type=hidden]")[0];
      if(hidden_field) {
        hidden_field.value = "1";
      }
      $(this).parents(".fields_invoice_charge").remove();
      return false;
    });
  
    $(document).on("click", ".invoice_charge_edit_remove_button", function() {
      var fields = $(this).closest(".invoice_charge_table");
      bootbox.confirm("Are you sure you want to remove this charge?", function(result){
        if (result === true) {
          fields.find(".remove_child_edit_invoice_charge").attr("value", "true");
          fields.hide();
        }
      });
    });

  }

//----------------------------------------------------------------------------//
//---------- INVOICES INDEX RELATED ------------------------------------------//
//----------------------------------------------------------------------------//

  if ($('.invoices_index_div').length > 0) {
  
    var MyDate = new Date();
    var date;
    MyDate.setDate(MyDate.getDate());
    date = MyDate.getFullYear() + '-' + ('0' + (MyDate.getMonth() + 1)).slice(-2) + '-' + ('0' + MyDate.getDate()).slice(-2);
    var filename = 'Invoices, ' + date + ' Export';
    var table = $('#invoicesDataTable').DataTable({
      'retrieve': true,
      'processing': true,
      'serverSide': true,
      'ajax': {
        'url': $('#invoicesDataTable').data('source')
      },
      'columns': [
  /*0 */{data: 'id', searchable: false, visible: false},
  /*1 */{data: 'invoice_date'},
  /*2 */{data: 'invoice_type'},
  /*3 */{data: 'supplier'},
  /*4 */{data: 'recipient'},
  /*5 */{data: 'invoice_number'},
  /*6 */{data: 'order_charges'},
  /*7 */{data: 'invoice_charges'},
  /*8 */{data: 'invoice_status'},
  /*9 */{data: 'discount', searchable: false},
  /*10*/{data: 'total'},
  /*11*/{data: 'due_date'},
  /*12*/{data: 'paid', searchable: false, visible: false},
  /*13*/{data: 'paid_date'},
  /*14*/{data: 'created_by'},
  /*15*/{data: 'edited_by'},
  /*16*/{data: 'actions', orderable: false}
      ],
      // 'order': [[ 1, 'asc' ], [ 10, 'desc' ], [ 9, 'desc' ], [ 8, 'desc' ], [ 7, 'desc' ]],
      'oLanguage': { 'sZeroRecords': 'No records to display for this filter' },
      'dom': "<'row'<'col-sm-10 dataTableToolbar'><'col-sm-2'B>>t<'row dataTableBottomRow'<'col-md-2'i><'col-md-10'p>>",
      'buttons': [
        {
          extend: 'excelHtml5',
          title: filename,
          // Only exports columns that are currently visible.  Adjusted by the 'Visibility' dropdown and Filtered text.
          exportOptions: {columns: ':visible'},
          className: 'dtButtonHide'
        },
        {
          extend: 'csv',
          title: filename,
          exportOptions: {columns: ':visible'},
          className: 'dtButtonHide'
        },
        {
          extend: 'pdfHtml5',
          title: filename,
          orientation: 'landscape',
          exportOptions: {columns: ':visible'},
          className: 'dtButtonHide'
        },
        {
          extend: 'copyHtml5',
          title: filename,
          exportOptions: {columns: ':visible'},
          className: 'dtButtonHide'
        }, 
        {
          extend: 'print',
          autoPrint: false,
          title: filename,
          exportOptions: {columns: ':visible'},
          customize: function (win) {
              $(win.document.body).find('table').addClass('display').css('font-size', '12px');
              $(win.document.body).find('tr:nth-child(odd) td').each(function(index){
                  $(this).css('background-color','#D0D0D0');
              });
              $(win.document.body).find('h1').css('text-align','center');
          },
          className: 'dtButtonHide'
        }
      ]
    });
  
    function dataTableToolbar(model) {
      return '' +
      '<ul class="nav" style="margin-bottom:10px">' +
      '  <li>' +
      '    <span class="options-label">Options:</span>' +
      '  </li>' +
      '  <li>' +
      '    <div class="dataTables_length" id="' + model + 'DataTable_length">' +
      '      <select name="' + model + 'DataTable_length" aria-controls="' + model + 'DataTable" id="' + model + 'TableLength" class="form-control form-control-sm tableLength" title="Number of records to show">' +
      '        <option value="5">5</option>' +
      '        <option value="10">10</option>' +
      '        <option selected="selected" value="25">25</option>' +
      '        <option value="50">50</option>' +
      '        <option value="100">100</option>' +
      '        <option value="250">250</option>' +
      '      </select>' +
      '    </div>' +
      '  </li>' +
      '  <li>' +
      '    <div class="tableClickerDiv dropdown">' +
      '      <button class="btn btn-outline-primary btn-sm dropdown-toggle" title="Column visibility options" type="button" id="tableClickerColvis" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="fas fa-columns"></i> Columns</button>' +
      '      <div class="dropdown-menu card-shadow" aria-labelledby="tableClickerColvis">' +
      '        <a class="dropdown-item button-highlight" id="colvisClicker-0">Record ID</a>' +
      '        <a class="dropdown-item" id="colvisClicker-1">Invoice Date</a>' +
      '        <a class="dropdown-item" id="colvisClicker-2">Invoice Number</a>' +
      '        <a class="dropdown-item" id="colvisClicker-3">Invoice Type</a>' +
      '        <a class="dropdown-item" id="colvisClicker-4">Supplier</a>' +
      '        <a class="dropdown-item" id="colvisClicker-5">Recipient</a>' +
      '        <a class="dropdown-item" id="colvisClicker-6">Discount/Surcharge %</a>' +
      '        <a class="dropdown-item" id="colvisClicker-7">Invoice Total</a>' +
      '        <a class="dropdown-item" id="colvisClicker-8">Created By</a>' +
      '        <a class="dropdown-item" id="colvisClicker-9">Last Edited By</a>' +
      '        <a class="dropdown-item" id="colvisClicker-10">Invoice Status</a>' +
      '        <a class="dropdown-item" id="colvisClicker-11">Due Date</a>' +
      '        <a class="dropdown-item button-highlight" id="colvisClicker-12">Paid?</a>' +
      '        <a class="dropdown-item" id="colvisClicker-13">Paid Date</a>' +
      '        <a class="dropdown-item" id="colvisClicker-14">Actions</a>' +
      '      </div>' +
      '    </div>' +
      '  </li>' +
      '  <li>' +
      '    <div class="tableClickerDiv dropdown">' +
      '      <button class="btn btn-outline-primary btn-sm dropdown-toggle" title="Export Options" type="button" id="tableClickerExports" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="fas fa-file-export"></i> Export</button>' +
      '      <div class="dropdown-menu" aria-labelledby="tableClickerExports">' +
      '        <div class="text-primary" style="font-size:11px;padding:0.25rem 1.5rem;">' +
      '          <i class="fas fa-exclamation-triangle text-danger"></i> Only the currently visible columns & rows will be exported' +
      '        </div>' +
      '        <a class="dropdown-item tableClickerExcel"><i class="fas fa-file-excel file-icon-color"></i> Excel <i class="fas fa-download text-primary"></i></a>' +
      '        <a class="dropdown-item tableClickerCsv"><i class="fas fa-file-csv file-icon-color"></i> CSV <i class="fas fa-download text-primary"></i></a>' +
      '        <a class="dropdown-item tableClickerPdf"><i class="fas fa-file-pdf file-icon-color"></i> PDF <i class="fas fa-download text-primary"></i></a>' +
      '        <a class="dropdown-item tableClickerCopy"><i class="fas fa-copy file-icon-color"></i> Copy</a>' +
      '        <a class="dropdown-item tableClickerPrint"><i class="fas fa-print file-icon-color"></i> Print/Preview</a>' +
      '      </div>' +
      '    </div>' +
      '  </li>' +
      '  <li class="filter-section" style="width:100%">' +
      '    <span class="filter-label">Filters:</span>' +
      '    <div id="' + model + 'DataTable_filter" class="dataTables_filter mr-2">' +
      '      <input id="' + model + 'TableSearch" class="form-control form-control-sm tableSearch" title="right-click to clear" oncontextmenu="return false" aria-controls="' + model + 'DataTable" placeholder="Search Entire Table...">' +
      '    </div>' +
      // '    <span id="status-dropdown" class="filter-span-select colvisSearch-1"></span>' +
      // '    <span id="supplier-select-input" class="filter-span-input colvisSearch-2"></span>' +
      // '    <span id="carrier-select-input" class="filter-span-input colvisSearch-3"></span>' +
      // '    <span id="shipment-type-dropdown" class="filter-span-select colvisSearch-4"></span>' +
      // '    <span id="po-number-select-input" class="filter-span-input colvisSearch-5"></span>' +
      // '    <span id="container-number-select-input" class="filter-span-input colvisSearch-6"></span>' +
      '  </li>' +
      '</ul>' +
      '';
    }
  
    table.page.len(25).draw();

    $('div.dataTableToolbar').html(dataTableToolbar('invoices'));
  
    var search = $.fn.dataTable.util.throttle(
      function (val) {
        table.search(val).draw();
      }, 500);
     
    $('.tableSearch').on('keyup', function () {
      search( this.value );
    });
  
    $('.tableLength').on('change', function () {
      table.page.len($(this).find('option:selected').attr('value')).draw();
    });
  
    function colVisButtons(index) {
      var button = $('#colvisClicker-'+index);
      button.on('click', function(e) {
        e.stopPropagation();
        if (table.column(index).visible() === true) {
          table.column(index).visible(false);
          button.addClass('button-highlight');
          $('.colvisSearch-'+index).hide();
        }
        else {
          table.column(index).visible(true);
          button.removeClass('button-highlight');
          $('.colvisSearch-'+index).show();
        }
      });
    }
    let colVisIndex = -1;
    do { colVisIndex += 1;
         colVisButtons(colVisIndex);
    } while (colVisIndex < 14);
  
    // yadcf.init(table,
    //   [
  
    //     { column_number : 1, // Status
    //       filter_container_id : 'status-dropdown',
    //       style_class : 'form-control form-control-sm',
    //       filter_default_label : 'Shipment Status...',
    //       data: [
    //         {value: '1', label : 'Pending'},
    //         {value: '2', label : 'In Transit'},
    //         {value: '3', label : 'Delivered'},
    //       ]
    //     },
  
    //     { column_number : 2, // Supplier
    //       filter_type: 'text',
    //       filter_container_id : 'supplier-select-input',
    //       style_class : 'form-control form-control-sm',
    //       filter_default_label : 'Search Supplier...'
    //     },
  
    //     { column_number : 3, // Carrier
    //       filter_type: 'text',
    //       filter_container_id : 'carrier-select-input',
    //       style_class : 'form-control form-control-sm',
    //       filter_default_label : 'Search Carrier...'
    //     },
  
    //     { column_number : 4, // Shipment Type
    //       filter_container_id : 'shipment-type-dropdown',
    //       style_class : 'form-control form-control-sm',
    //       filter_default_label : 'Shipment Type...',
    //       data: [
    //         {value: '1', label : 'Container'},
    //         {value: '2', label : 'Truck'},
    //         {value: '3', label : 'Small Parcel'},
    //       ]
    //     },
  
    //     { column_number : 5, // PO Number
    //       filter_type: 'text',
    //       filter_container_id : 'po-number-select-input',
    //       style_class : 'form-control form-control-sm',
    //       filter_default_label : 'Search PO #...'
    //     },
  
    //     { column_number : 6, // Container Number
    //       filter_type: 'text',
    //       filter_container_id : 'container-number-select-input',
    //       style_class : 'form-control form-control-sm',
    //       filter_default_label : 'Search Container Number...'
    //     },
  
    //   ],
    //   { filters_position : 'footer' },
    //   { externally_triggered : true }
    // );
  
    $('body').tooltip({selector: '[data-toggle="tooltip"]'});

  }  

//----------------------------------------------------------------------------//
//---------- INVOICES PDF EXPORT RELATED -------------------------------------//
//----------------------------------------------------------------------------//

  if ($('.pdf_export_div').length > 0) {

    var subtotal = [];
    // grabs each line item charge amount in dollars
    $('.cost_value').each(function() {
      subtotal.push([ $(this).data('cost_value') ]);
    });

    // function for subtotaling all line item charges
    var total = subtotal.reduce(function(a,b) {
      return parseFloat(a) + parseFloat(b);
    });

    // function used for discount & surcharge columns
    function round(value, decimals) {
      return Number(Math.round(value+'e'+decimals)+'e-'+decimals);
    }

    var discount = $('.discount_value').data('discount_value'); // discount value as a percentage, IE: 0.06 for 6%
    var discount_dollar = round(total*(discount/100), 2); // discount converted to dollars from subtotal
    var surcharge = $('.surcharge_value').data('surcharge_value');
    var surcharge_dollar = round(total*surcharge/100, 2);

    // populate the Subtotal, Discount, and Surcharge fields
    if (total > 0) {
      $('.subtotal').text(total);
      $('.discount_calc').text(discount_dollar.toFixed(2));
      $('.surcharge_calc').text(surcharge_dollar.toFixed(2));
    } else {
      $('.subtotal').val('0.00');
    }
    subtotal.length = 0;

  }

//----------------------------------------------------------------------------//
//---------- INVOICES SHOW RELATED -------------------------------------------//
//----------------------------------------------------------------------------//

  if ($('.invoice_show_div').length > 0) {
  
    var MyDate = new Date();
    var date;
    MyDate.setDate(MyDate.getDate());
    date = MyDate.getFullYear() + "-" + ("0" + (MyDate.getMonth() + 1)).slice(-2) + "-" + ("0" + MyDate.getDate()).slice(-2);
    var filename = "Invoice Order Reference, " + date + " Export";
    var table = $(".orders_referenced_table").DataTable({
      "retrieve": true,
      "processing": true,
      // "order": [[ 1, "asc" ], [ 10, "desc" ], [ 9, "desc" ], [ 8, "desc" ], [ 7, "desc" ]],
      "oLanguage": { "sZeroRecords": "No records to display for this filter" },
      "dom": "<'row'<'col-sm-10 dataTableToolbar'><'col-sm-2'B>>t<'row dataTableBottomRow'<'col-md-2 ml-3'i><'col-md-9'p>>",
      "buttons": [
        {
          extend: "excelHtml5",
          title: filename,
          // Only exports columns that are currently visible.  Adjusted by the "Visibility" dropdown and Filtered text.
          exportOptions: {columns: ":visible"},
          className: "dtButtonHide"
        },
        {
          extend: "csv",
          title: filename,
          exportOptions: {columns: ":visible"},
          className: "dtButtonHide"
        },
        {
          extend: "pdfHtml5",
          title: filename,
          orientation: "landscape",
          exportOptions: {columns: ":visible"},
          className: "dtButtonHide"
        },
        {
          extend: "copyHtml5",
          title: filename,
          exportOptions: {columns: ":visible"},
          className: "dtButtonHide"
        }, 
        {
          extend: "print",
          autoPrint: false,
          title: filename,
          exportOptions: {columns: ":visible"},
          customize: function (win) {
              $(win.document.body).find('table').addClass('display').css('font-size', '12px');
              $(win.document.body).find('tr:nth-child(odd) td').each(function(index){
                  $(this).css('background-color','#D0D0D0');
              });
              $(win.document.body).find('h1').css('text-align','center');
          },
          className: "dtButtonHide"
        }
      ]
    });
  
    function dataTableToolbar() {
      return "" +
      "<ul class='nav ml-2 mb-3'>" +
      "  <li>" +
      "    <div class='tableClickerDiv dropdown'>" +
      "      <button class='btn btn-outline-primary btn-sm dropdown-toggle' title='Export Options' type='button' id='tableClickerExports' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'><i class='fas fa-file-export'></i> Export</button>" +
      "      <div class='dropdown-menu' aria-labelledby='tableClickerExports'>" +
      "        <div class='text-primary' style='font-size:11px;padding:0.25rem 1.5rem;'>" +
      "          <i class='fas fa-exclamation-triangle text-danger'></i> Only the currently visible columns & rows will be exported" +
      "        </div>" +
      "        <a class='dropdown-item tableClickerExcel'><i class='fas fa-file-excel file-icon-color'></i> Excel <i class='fas fa-download text-primary'></i></a>" +
      "        <a class='dropdown-item tableClickerCsv'><i class='fas fa-file-csv file-icon-color'></i> CSV <i class='fas fa-download text-primary'></i></a>" +
      "        <a class='dropdown-item tableClickerPdf'><i class='fas fa-file-pdf file-icon-color'></i> PDF <i class='fas fa-download text-primary'></i></a>" +
      "        <a class='dropdown-item tableClickerCopy'><i class='fas fa-copy file-icon-color'></i> Copy</a>" +
      "        <a class='dropdown-item tableClickerPrint'><i class='fas fa-print file-icon-color'></i> Print/Preview</a>" +
      "      </div>" +
      "    </div>" +
      "  </li>" +
      "  <li class='filter-section ml-3'>" +
      "    <div id='orders_referenced_table_filter' class='dataTables_filter mr-2'>" +
      "      <input id='orders_referenced_tableSearch' class='form-control form-control-sm tableSearch' title='right-click to clear' oncontextmenu='return false' aria-controls='orders_referenced_table' placeholder='Search Entire Table...'>" +
      "    </div>" +
      "  </li>" +
      "</ul>" +
      "";
    }
  
    table.page.len(25).draw();

    $("div.dataTableToolbar").html(dataTableToolbar());
  
    var search = $.fn.dataTable.util.throttle(
      function (val) {
        table.search(val).draw();
      }, 500);
     
    $('.tableSearch').on('keyup', function () {
      search( this.value );
    });
  
  }

});
