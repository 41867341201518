/*global $*/
/*global yadcf*/
/*global toastr*/

import 'stylesheets/transactions-global.scss';

$(document).on('turbolinks:load', function() {

//----------------------------------------------------------------------------//
//---------- TRANSACTION BATCHES FORM RELATED --------------------------------//
//----------------------------------------------------------------------------//

  if ($('.transaction_batch-validate').length > 0) {

    $('label.required').append('<strong> *</strong>'); // adds a 'required' star next to each Inputs label
    $('label.required-s span').append('<strong> *</strong>'); // adds a 'required' star next to each Selects label
  
    $(".add_notes_button").on("click", function() {
      $(this).slideUp("fast");
      $(".batch-notes-div").slideDown("slow");
    });
  
    $('.transaction_batch-validate').validate({
      ignore: '.ignore',
  		rules: {
  		// 	'product[model_number]': {required: true, maxlength: 80},
  		// 	'product[supplier_part_number]': {required: true, maxlength: 80},
  		},
  		messages: {
  		// 	'product[supplier_part_number]': 'If the Manufacturer Part Number & the Model Number are the same, enter the same SKU in both fields.',
  		// 	'product[model_number]': 'If the Model Number & the Manufacturer Part Number are the same, enter the same SKU in both fields.'
  		},
      onfocusout: function(element) {$(element).valid()},
      focusCleanup: true,
      errorClass: 'myErrorClass',
      errorPlacement: function (error, element) {
        error.insertAfter(element.parent());
      },
      highlight: function (element, errorClass, validClass) {
        var elem = $(element);
        var tabId = elem.closest('.tab-pane').attr('aria-labelledby');
        if (elem.hasClass('select2-hidden-accessible')) {
          elem.parent().find('.select2-selection').addClass(errorClass);
          elem.closest('.card-body').find('.nav-link[id="'+tabId+'"]').addClass(errorClass);
        } 
        else {
          elem.addClass(errorClass);
          elem.closest('.card-body').find('.nav-link[id="'+tabId+'"]').addClass(errorClass);
        }
      },    
      unhighlight: function (element, errorClass, validClass) {
        var elem = $(element);
        var tabId = elem.closest('.tab-pane').attr('aria-labelledby');
        if (elem.hasClass('select2-hidden-accessible')) {
          elem.parent().find('.select2-selection').removeClass(errorClass);
          elem.parent().next('label').remove();
          elem.closest('.card-body').find('.nav-link[id="'+tabId+'"]').removeClass(errorClass);
        }
        else {
          elem.removeClass(errorClass);
          elem.parent().next('label').remove();
          elem.closest('.card-body').find('.nav-link[id="'+tabId+'"]').removeClass(errorClass);
        }
      },
  		invalidHandler: function(e, validator) {
  			var errors = validator.numberOfInvalids();
  			if (errors) {
  				var message = errors == 1
  					? 'You missed 1 required field. Check below tabs for highlighted fields'
  					: 'You missed ' + errors + ' required fields.  Check below tabs for highlighted fields';
  				$('div.validatorErrorList span').html(message);
  				$('div.validatorErrorList').slideDown('fast');
  			}
  			else {
  				$('div.validatorErrorList').slideUp('slow');
  			}
  		}
    });
  
    $('#transactionBatchFirstSave').on('click', function(e) {
      e.preventDefault();
      if ($('.transaction_batch-validate').valid()) {
        window.onbeforeunload = null;
        $('#jstemplates_transaction').html('');
        $('.loading-overlay').show();
        $('#transactionBatchSave').click();
      }
      else {
        return false;
      }
    });
  
    $('#transactionBatchFirstSubmit').on('click', function(e) {
      e.preventDefault();
      if ($('.transaction_batch-validate').valid()) {
        window.onbeforeunload = null;
        $('#jstemplates_transaction').html('');
        $('.loading-overlay').show();
        $('#transactionBatchSubmit').click();
      }
      else {
        return false;
      }
    });

    // on first focus (bubbles up to document), open the menu
    $(document).on('focus', '.select2-selection.select2-selection--single', function (e) {
      $(this).closest('.select2-container').siblings('select:enabled').select2('open');
    });
  
    // steal focus during close - only capture once and stop propogation
    $('select.select2').on('select2:closing', function (e) {
      $(e.target).data('select2').$selection.one('focus focusin', function (e) {
        e.stopPropagation();
      });
    });
    
    $(document).on('change keyup', '.field-highlightable', function() {
      if ($(this).val()) {
        $(this).removeClass('field-highlight');
      }
      else {
        $(this).addClass('field-highlight');
      }
    });
    
    $('.field-highlightable').each(function() {
      if ($(this).val()) {
        $(this).removeClass('field-highlight');
      }
    });

//---- New Transaction Related -----------------------------------------------//
  
    // Child Fields Template Loader inherited from transaction_batches_helper.rb for Associated Attachments
    $(document).on('click', 'a.add_child_transaction', function() {
      var association, new_id, regexp, template, fields;
      association = $(this).attr('data-association');
      template = $('#' + association + '_fields_template').html();
      regexp = new RegExp('new_' + association, 'g');
      new_id = (new Date).getTime();
      $(this).parent().before(template.replace(regexp, new_id));
      fields = $(this).parent().prev('.fields_transaction');
      // The 'jstemplates' hidden field needs the '.ignore' class to ensure it
      // doesn't get picked up by Jquery Validator.  This line removes it.
      fields.find('input').removeClass('ignore');
  
      fields.find('.transactionSupplierSearch').select2({
        selectOnClose: true,
        allowClear: true,
        placeholder: 'Supplier...',
      }).val($('#supplier-default-select').find('option:selected').val()).trigger('change');
  
      fields.find('.transactionRetailerSearch').select2({
        selectOnClose: true,
        allowClear: true,
        placeholder: 'Retailer...',
      }).val($('#retailer-default-select').find('option:selected').val()).trigger('change');

      fields.find('.transactionClassSelect').select2({
        selectOnClose: true,
        placeholder: 'Transaction Class...',
      }).val($('#transaction-class-default-select').find('option:selected').val()).trigger('change');

      fields.find('.transactionTypeSearch').select2({
        selectOnClose: true,
        allowClear: true,
        placeholder: 'Transaction Type...',
      }).val($('#transaction-type-default-select').find('option:selected').val()).trigger('change');
  
      fields.find('.orderSearch').select2({
        selectOnClose: true,
        allowClear: true,
        placeholder: "Order Association...",
        ajax: {
          url: "/json_dropdown_orders.json",
          dataType: "json",
          type: "GET",
          processResults: function (data, params) {
            params.page = data.page || 1;
            return {
              results: $.map(data.items, function (item) {
                return {
                  text: item.po_number,
                  id: item.id
                };
              }),
              pagination: {
                more: data.current_page
              }
            };
          },
          cache: true
        }
      });

      // monkeypatch to fix issue of search field not being selected on open.  https://github.com/select2/select2/issues/2311#issuecomment-822473913
      $(document).on('select2:open', e => {
        const id = e.target.id;
        const target = document.querySelector(`[aria-controls=select2-${id}-results]`);
        target.focus();
      });
  
      // on first focus (bubbles up to document), open the menu
      $(document).on('focus', '.select2-selection.select2-selection--single', function (e) {
        $(this).closest('.select2-container').siblings('select:enabled').select2('open');
      });
    
      // steal focus during close - only capture once and stop propogation
      $('select.select2').on('select2:closing', function (e) {
        $(e.target).data('select2').$selection.one('focus focusin', function (e) {
          e.stopPropagation();
        });
      });
  
      fields.find('[data-toggle="tooltip"]').tooltip({trigger: 'hover'});
  

      fields.hide().slideDown('fast');
  
      return false;
    });
  
    $(document).on('mousedown', '.transaction-date-select', function() {
      if (event.which === 2) {
        $(this).val($(this).data('today'));
        $(this).removeClass('field-highlight');
      } else if (event.which === 3) {
        $(this).val('');
        $(this).addClass('field-highlight');
      }
    });
  
    $(document).on('click', 'a.remove_child_transaction', function() {
      var tr = $('.fields_transaction').length;
      if (tr == 2) {
        $('#transactionBatchFirstSubmit').hide();
        $('#transactionBatchFirstSave').hide();
      }
      var hidden_field = $(this).prev('input[type=hidden]')[0];
      var fields = $(this).parents('.fields_transaction');
      if(hidden_field) {
        hidden_field.value = '1';
      }
      $(this).parents('.fields_transaction').slideUp('slow', function() {
        fields.remove();
      });
      return false;
    });
  
    // Monkeypatch so all select2 fields actually clear to 'nil' when the 'X' is clicked
    $(document).on('select2:clear', '.select2-hidden-accessible', function (e) {
      $(this).append('<option value="" selected="selected"></option>');
    });
  
    $('.add_transaction_button').on('click', function() {
      $('.add_child_transaction').click();
      $('#transactionBatchFirstSave').show();
      $('#transactionBatchFirstSubmit').show();
      $('#transactionBatchEditFirstSave').show();
      $('#transactionBatchEditFirstSubmit').show();
      if ( $('.fields_container').find('.offscreen_check:last').is(':off-screen') ) {
        toastr.options = {
          "closeButton": false,
          "debug": false,
          "newestOnTop": false,
          "progressBar": true,
          "positionClass": "toast-bottom-center",
          "preventDuplicates": false,
          "onclick": null,
          "showDuration": "5",
          "hideDuration": "5",
          "timeOut": "5",
          "extendedTimeOut": "5",
          "showEasing": "linear",
          "hideEasing": "swing",
          "showMethod": "fadeIn",
          "hideMethod": "slideUp"
        };
        toastr.info('Transaction Added Below');
      }
    });

  //---- Edit View Related -------------------------------------------------------//
  
    $('#transactionBatchEditFirstSave').on('click', function(e) {
      e.preventDefault();
      if ($('.transaction_batch-validate').valid()) {
        window.onbeforeunload = null;
        $('#jstemplates_transaction').html('');
        $('.loading-overlay').show();
        $('#transactionBatchEditSave').click();
      }
      else {
        return false;
      }
    });
  
    $('#transactionBatchEditFirstSubmit').on('click', function(e) {
      e.preventDefault();
      if ($('.transaction_batch-validate').valid()) {
        window.onbeforeunload = null;
        $('#jstemplates_transaction').html('');
        $('.loading-overlay').show();
        $('#transactionBatchEditSubmit').click();
      }
      else {
        return false;
      }
    });
  
    $('.transactionEditSupplierSearch').select2({
      selectOnClose: true,
      allowClear: true,
      placeholder: 'Supplier...',
    });

    $('.transactionEditRetailerSearch').select2({
      selectOnClose: true,
      allowClear: true,
      placeholder: 'Retailer...',
    });

    $('.transactionEditTransactionClassSelect').select2({
      selectOnClose: true,
      placeholder: 'Transaction Class...',
    });

    $('.transactionEditTransactionTypeSearch').select2({
      selectOnClose: true,
      allowClear: true,
      placeholder: 'Transaction Type...',
    });

    $('.transactionEditTransactionTypeSearch').select2({
      selectOnClose: true,
      allowClear: true,
      placeholder: 'Transaction Type...',
      ajax: {
        url: '/json_dropdown_transaction_types.json',
        dataType: 'json',
        type: 'GET',
        processResults: function (data, params) {
          params.page = data.page || 1;
          return {
            results: $.map(data.items, function (item) {
              return {
                text: item.name,
                id: item.id
              };
            }),
            pagination: {
              more: data.current_page
            }
          };
        },
        cache: true
      }
    });
  
    $('.transactionEditOrderSearch').select2({
      selectOnClose: true,
      allowClear: true,
      placeholder: 'Select Order...',
      ajax: {
        url: '/json_dropdown_orders.json',
        dataType: 'json',
        type: 'GET',
        processResults: function (data, params) {
          params.page = data.page || 1;
          return {
            results: $.map(data.items, function (item) {
              return {
                  text: item.po_number,
                  id: item.id
              };
            }),
            pagination: {
              more: data.current_page
            }
          };
        },
        cache: true
      }
    });
  
    // This populates the 'Supplier' dropdown on the 'Edit' view
    var supplierSelect = $('.transactionEditSupplierSearch');
    supplierSelect.each(function() {
      var t = $(this);
      $.ajax({
        url: '/suppliers/' + t.data('selected') + '.json',
        dataType: 'json',
        type: 'GET',
      }).then(function (data) {
        var option = new Option(data.supplierSelect.brand_name, data.supplierSelect.id, true, true);
        t.append(option).trigger('change');
        t.trigger({
          type: 'select2:select',
          params: {
            data: data
          }
        });
      });
    });
  
    // This populates the 'Retailer' dropdown on the 'Edit' view
    var retailerSelect = $('.transactionEditRetailerSearch');
    retailerSelect.each(function() {
      var t = $(this);
      $.ajax({
        url: '/retailers/' + t.data('selected') + '.json',
        dataType: 'json',
        type: 'GET',
      }).then(function (data) {
        var option = new Option(data.retailerSelect.name, data.retailerSelect.id, true, true);
        t.append(option).trigger('change');
        t.trigger({
          type: 'select2:select',
          params: {
            data: data
          }
        });
      });
    });
  
    // This populates the 'Order Reference' dropdown on the 'Edit' view
    var orderSelect = $('.transactionEditOrderSearch');
    orderSelect.each(function() {
      var t = $(this);
      $.ajax({
        url: '/orders/' + t.data('selected') + '.json',
        dataType: 'json',
        type: 'GET',
      }).then(function (data) {
        var option = new Option(data.orderSelect.po_number, data.orderSelect.id, true, true);
        t.append(option).trigger('change');
        t.trigger({
          type: 'select2:select',
          params: {
            data: data
          }
        });
      });
    });
  
    // This populates the 'Transaction Type Search' dropdown on the 'Edit' view
    var transactionTypeSelect = $('.transactionEditTransactionTypeSearch');
    transactionTypeSelect.each(function() {
      var t = $(this);
      $.ajax({
        url: '/transaction_types/' + t.data('selected') + '.json',
        dataType: 'json',
        type: 'GET',
      }).then(function (data) {
        var option = new Option(data.transactionTypeSelect.name, data.transactionTypeSelect.id, true, true);
        t.append(option).trigger('change');
        t.trigger({
          type: 'select2:select',
          params: {
            data: data
          }
        });
      });
    });
  
    $(document).on('click', '.transaction_edit_remove_button', function() {
      var fields = $(this).closest('.fields_transaction');
      bootbox.confirm('Are you sure you want to remove this transaction?', function(result){
        if (result === true) {
          fields.find('.remove_child_edit_transaction').attr('value', 'true');
          fields.slideUp('slow');
        }
      });
    });
  
    $('.scroll').bind('mousewheel', function() {});

    $(document).on('click', '.show_only_returns_button', function() {
      $('.transactionTypeSearch').each(function() {
        if ($(this).val() != '6') {
          $(this).closest('.transactionTable').slideUp('fast');
        }
      });
      $('.transactionEditTransactionTypeSearch').each(function() {
        if ($(this).val() != '6') {
          $(this).closest('.transactionTable').slideUp('fast');
        }
      });
    });
  
    $(document).on('click', '.show_all_transaction_fields_button', function() {
      $('.transactionTable').slideDown('fast');
    });
  
    window.onbeforeunload = function() {
    	return 'Are you sure you want to leave?';
    };

  }

//----------------------------------------------------------------------------//
//---------- TRANSACTION BATCHES SHOW RELATED --------------------------------//
//----------------------------------------------------------------------------//

  if ($('.transaction_batch_show_div').length > 0) {

    $(document).on('click', '.tab_collapse.up', function() {
      var div = $(this).closest('.payment_number_tab');
      $(this).addClass('down').removeClass(['text-info', 'up']);
      $(this).attr('data-tippy-content', 'Expand This Payment');
      tippy('[data-toggle="tippy"]', {animation: 'perspective'});
      div.find('.tab_collapse_body').slideUp('fast');
      $(this).hide();
      div.find('.tab_collapse.down').show();
      $('.expand_all_tabs').show();
      $('.hide_all_tabs').hide();
    });

    $(document).on('click', '.tab_collapse.down', function() {
      var div = $(this).closest('.payment_number_tab');
      $(this).addClass(['up', 'text-info']).removeClass('down');
      $(this).attr('data-tippy-content', 'Collapse This Payment');
      tippy('[data-toggle="tippy"]', {animation: 'perspective'});
      div.find('.tab_collapse_body').slideDown('fast');
      $(this).hide();
      div.find('.tab_collapse.up').show();
      $('.expand_all_tabs').hide();
      $('.hide_all_tabs').show();
    });

    $(document).on('click', '.header-collapsed', function() {
      var div = $(this).closest('.payment_number_tab');
      div.find('.tab_collapse.down').click();
      div.find('.header-not-collapsed').show();
      $(this).hide();
    });

    $(document).on('click', '.hide_all_tabs', function() {
      $('.tab_collapse.up').click();
      $(this).hide();
      $('.expand_all_tabs').show();
    });

    $(document).on('click', '.expand_all_tabs', function() {
      $('.tab_collapse.down').click();
      $(this).hide();
      $('.hide_all_tabs').show();
    });

    $(document).on('click', '.payment_nav', function() {
      var payment_number = $(this).data('payment_number');
      $('.tab_collapse.up').click();
      $('#payment_div_' + payment_number).find('.tab_collapse.down').click();
      $('#payment_div_' + payment_number).scrollIntoView({behavior: 'smooth'});
    });

    // Transactions Export Table
    var MyDate = new Date();
    var date;
    MyDate.setDate(MyDate.getDate());
    date = MyDate.getFullYear() + "-" + ("0" + (MyDate.getMonth() + 1)).slice(-2) + "-" + ("0" + MyDate.getDate()).slice(-2);
    var filename = "Current Costing Export, " + date;
    var table = $(".transactions_table").DataTable({
      "retrieve": true,
      "processing": true,
      // "order": [[ 1, "asc" ], [ 10, "desc" ], [ 9, "desc" ], [ 8, "desc" ], [ 7, "desc" ]],
      "oLanguage": { "sZeroRecords": "No records to display for this filter" },
      "dom": "<'row'<'col-sm-10 dataTableToolbar'><'col-sm-2'B>>t<'row dataTableBottomRow'<'col-md-2 ml-3'i><'col-md-9'p>>",
      "buttons": [
        {
          extend: "excelHtml5",
          title: filename,
          // Only exports columns that are currently visible.  Adjusted by the "Visibility" dropdown and Filtered text.
          exportOptions: {columns: ":visible"},
          className: "dtButtonHide"
        },
        {
          extend: "csv",
          title: filename,
          exportOptions: {columns: ":visible"},
          className: "dtButtonHide"
        },
        {
          extend: "pdfHtml5",
          title: filename,
          orientation: "landscape",
          exportOptions: {columns: ":visible"},
          className: "dtButtonHide"
        },
        {
          extend: "copyHtml5",
          title: filename,
          exportOptions: {columns: ":visible"},
          className: "dtButtonHide"
        }, 
        {
          extend: "print",
          autoPrint: false,
          title: filename,
          exportOptions: {columns: ":visible"},
          customize: function (win) {
              $(win.document.body).find('table').addClass('display').css('font-size', '12px');
              $(win.document.body).find('tr:nth-child(odd) td').each(function(index){
                  $(this).css('background-color','#D0D0D0');
              });
              $(win.document.body).find('h1').css('text-align','center');
          },
          className: "dtButtonHide"
        }
      ]
    });
  
    function dataTableToolbar() {
      return "" +
      "<ul class='nav ml-2 mb-3'>" +
      "  <li>" +
      "    <div class='dataTables_length' id='transactions_table_length'>" +
      "      <select name='transactions_table_length' aria-controls='transactions_table' class='form-control form-control-sm tableLength' title='Number of records to show'>" +
      "        <option value='5'>5</option>" +
      "        <option value='10'>10</option>" +
      "        <option value='25'>25</option>" +
      "        <option selected='selected' value='50'>50</option>" +
      "        <option value='100'>100</option>" +
      "        <option value='250'>250</option>" +
      "        <option value='-1'>All</option>" +
      "      </select>" +
      "    </div>" +
      "  </li>" +
      "  <li>" +
      "    <div class='tableClickerDiv dropdown'>" +
      "      <button class='btn btn-outline-primary btn-sm dropdown-toggle' title='Export Options' type='button' id='tableClickerExports' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'><i class='fas fa-file-export'></i> Export</button>" +
      "      <div class='dropdown-menu' aria-labelledby='tableClickerExports'>" +
      "        <div class='text-primary' style='font-size:11px;padding:0.25rem 1.5rem;'>" +
      "          <i class='fas fa-exclamation-triangle text-danger'></i> Only the currently visible columns & rows will be exported" +
      "        </div>" +
      "        <a class='dropdown-item tableClickerExcel'><i class='fas fa-file-excel file-icon-color'></i> Excel <i class='fas fa-download text-primary'></i></a>" +
      "        <a class='dropdown-item tableClickerCsv'><i class='fas fa-file-csv file-icon-color'></i> CSV <i class='fas fa-download text-primary'></i></a>" +
      "        <a class='dropdown-item tableClickerPdf'><i class='fas fa-file-pdf file-icon-color'></i> PDF <i class='fas fa-download text-primary'></i></a>" +
      "        <a class='dropdown-item tableClickerCopy'><i class='fas fa-copy file-icon-color'></i> Copy</a>" +
      "        <a class='dropdown-item tableClickerPrint'><i class='fas fa-print file-icon-color'></i> Print/Preview</a>" +
      "      </div>" +
      "    </div>" +
      "  </li>" +
      "  <li class='filter-section ml-3'>" +
      "    <div id='transactions_table_filter' class='dataTables_filter mr-2'>" +
      "      <input id='transactions_table_tableSearch' class='form-control form-control-sm tableSearch' title='right-click to clear' oncontextmenu='return false' aria-controls='transactions_table' placeholder='Search Entire Table...'>" +
      "    </div>" +
      "  </li>" +
      "</ul>" +
      "";
    }
  
    table.page.len(50).draw();
  
    $("div.dataTableToolbar").html(dataTableToolbar());
  
    $(".tableLength").on("change", function () {
      table.page.len($(this).find("option:selected").attr("value")).draw();
    });
  
    var search = $.fn.dataTable.util.throttle(
      function (val) {
        table.search(val).draw();
      }, 500);
     
    $('.tableSearch').on('keyup', function () {
      search( this.value );
    });

  }

//----------------------------------------------------------------------------//
//---------- TRANSACTIONS INDEX RELATED --------------------------------------//
//----------------------------------------------------------------------------//

  if ($('.transactions_index_div').length > 0) {
    
    $('.loading-overlay').show();

    var MyDate = new Date();
    var date;
    MyDate.setDate(MyDate.getDate());
    date = MyDate.getFullYear() + '-' + ('0' + (MyDate.getMonth() + 1)).slice(-2) + '-' + ('0' + MyDate.getDate()).slice(-2);
    var filename = 'Transactions, ' + date + ' Export';
    var table = $('#transactionsDataTable').DataTable({
      'retrieve': true,
      'processing': true,
      'serverSide': true,
      'initComplete': function( settings, json ) {
        $('.loading-overlay').hide();
      },
      'ajax': {
        'url': $('#transactionsDataTable').data('source')
      },
      'columns': [
  /*0 */{data: 'id', searchable: false, visible: false},
  /*1 */{data: 'batch_id', searchable: false, visible: false},
  /*2 */{data: 'transaction_date'},
  /*3 */{data: 'transaction_class'},
  /*4 */{data: 'transaction_type'},
  /*5 */{data: 'payment_number'},
  /*6 */{data: 'amount'},
  /*7 */{data: 'quantity', visible: false},
  /*8 */{data: 'order_association'},
  /*9 */{data: 'customer_name'},
  /*10*/{data: 'order_expected', searchable: false, orderable: false},
  /*11*/{data: 'supplier'},
  /*12*/{data: 'retailer'},
  /*13*/{data: 'notes'},
  /*14*/{data: 'actions', orderable: false},
      ], // Transaction Date, Payment Number, Transaction Class, Transaction Type
      'order': [[2, 'desc'], [5, 'asc'], [3, 'asc'], [4, 'desc']],
      'oLanguage': { 'sZeroRecords': 'No records to display for this filter' },
      'dom': '<"row"<"col-sm-12 dataTableToolbar"><"col-sm-2"B>>t<"row dataTableBottomRow"<"col-md-2"i><"col-md-10"p>>',
      'buttons': [
        {
          extend: 'excelHtml5',
          title: filename,
          // Only exports columns that are currently visible.  Adjusted by the 'Visibility' dropdown and Filtered text.
          exportOptions: {columns: ':visible'},
          className: 'dtButtonHide'
        },
        {
          extend: 'csv',
          title: filename,
          exportOptions: {columns: ':visible'},
          className: 'dtButtonHide'
        },
        {
          extend: 'pdfHtml5',
          title: filename,
          orientation: 'landscape',
          exportOptions: {columns: ':visible'},
          className: 'dtButtonHide'
        },
        {
          extend: 'copyHtml5',
          title: filename,
          exportOptions: {columns: ':visible'},
          className: 'dtButtonHide'
        }, 
        {
          extend: 'print',
          autoPrint: false,
          title: filename,
          exportOptions: {columns: ':visible'},
          customize: function (win) {
              $(win.document.body).find('table').addClass('display').css('font-size', '12px');
              $(win.document.body).find('tr:nth-child(odd) td').each(function(index){
                  $(this).css('background-color','#D0D0D0');
              });
              $(win.document.body).find('h1').css('text-align','center');
          },
          className: 'dtButtonHide'
        }
      ]
    });
  
    function dataTableToolbar() {
      return '' +
      '<ul class="nav" style="margin-bottom:-10px">' +
      '  <li>' +
      '    <span class="options-label">Options:</span>' +
      '  </li>' +
      '  <li>' +
      '    <div class="dataTables_length" id="transactionsDataTable_length">' +
      '      <select name="transactionsDataTable_length" aria-controls="transactionsDataTable" class="form-control form-control-sm tableLength" title="Number of records to show">' +
      '        <option value="5">5</option>' +
      '        <option value="10">10</option>' +
      '        <option value="25">25</option>' +
      '        <option value="50">50</option>' +
      '        <option selected="selected" value="100">100</option>' +
      '        <option value="250">250</option>' +
      '        <option value="500">500</option>' +
      '        <option value="750">750</option>' +
      '        <option value="1000">1000</option>' +
      '      </select>' +
      '    </div>' +
      '  </li>' +
      '  <li>' +
      '    <div class="tableClickerDiv dropdown">' +
      '      <button class="btn btn-outline-primary btn-sm dropdown-toggle" title="Column visibility options" type="button" id="tableClickerColvis" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="fas fa-columns"></i> Columns</button>' +
      '      <div class="dropdown-menu no-select card-shadow" aria-labelledby="tableClickerColvis">' +
      '        <a class="dropdown-item button-highlight" id="colvisClicker-0">Record ID</a>' +
      '        <a class="dropdown-item button-highlight" id="colvisClicker-1">Batch ID</a>' +
      '        <a class="dropdown-item" id="colvisClicker-2">Transaction Date</a>' +
      '        <a class="dropdown-item" id="colvisClicker-3">Transaction Class</a>' +
      '        <a class="dropdown-item" id="colvisClicker-4">Transaction Type</a>' +
      '        <a class="dropdown-item" id="colvisClicker-5">Payment Number</a>' +
      '        <a class="dropdown-item" id="colvisClicker-6">Amount</a>' +
      '        <a class="dropdown-item button-highlight" id="colvisClicker-7">Qty</a>' +
      '        <a class="dropdown-item" id="colvisClicker-8">Order Association</a>' +
      '        <a class="dropdown-item" id="colvisClicker-9">Customer Name</a>' +
      '        <a class="dropdown-item" id="colvisClicker-10">Expected Credits</a>' +
      '        <a class="dropdown-item" id="colvisClicker-11">Supplier</a>' +
      '        <a class="dropdown-item" id="colvisClicker-12">Retailer</a>' +
      '        <a class="dropdown-item" id="colvisClicker-13">Notes</a>' +
      '        <a class="dropdown-item" id="colvisClicker-14">Actions</a>' +
      '      </div>' +
      '    </div>' +
      '  </li>' +
      '  <li>' +
      '    <div class="tableClickerDiv dropdown">' +
      '      <button class="btn btn-outline-primary btn-sm dropdown-toggle" title="Export Options" type="button" id="tableClickerExports" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="fas fa-file-export"></i> Export</button>' +
      '      <div class="dropdown-menu" aria-labelledby="tableClickerExports">' +
      '        <div class="text-primary" style="font-size:11px;padding:0.25rem 1.5rem;">' +
      '          <i class="fas fa-exclamation-triangle text-danger"></i> Only the currently visible columns & rows will be exported' +
      '        </div>' +
      '        <a class="dropdown-item tableClickerExcel"><i class="fas fa-file-excel file-icon-color"></i> Excel <i class="fas fa-download text-primary"></i></a>' +
      '        <a class="dropdown-item tableClickerCsv"><i class="fas fa-file-csv file-icon-color"></i> CSV <i class="fas fa-download text-primary"></i></a>' +
      '        <a class="dropdown-item tableClickerPdf"><i class="fas fa-file-pdf file-icon-color"></i> PDF <i class="fas fa-download text-primary"></i></a>' +
      '        <a class="dropdown-item tableClickerCopy"><i class="fas fa-copy file-icon-color"></i> Copy</a>' +
      '        <a class="dropdown-item tableClickerPrint"><i class="fas fa-print file-icon-color"></i> Print/Preview</a>' +
      '      </div>' +
      '    </div>' +
      '  </li>' +
      '  <li class="filter-section mt-2" style="width:100%">' +
      '    <span class="filter-label">Filters:</span>' +
      '    <div id="transactionsDataTable_filter" class="dataTables_filter mr-2">' +
      '      <input id="ordersTableSearch" class="form-control form-control-sm tableSearch" title="right-click to clear" oncontextmenu="return false" aria-controls="transactionsDataTable" placeholder="Search Entire Table...">' +
      '    </div>' +
      '    <span id="transaction-class-dropdown" class="filter-span-select colvisSearch-3"></span>' +
      '    <span id="transaction-type-dropdown" class="filter-span-select colvisSearch-4"></span>' +
      '    <span id="supplier-select-dropdown" class="filter-span-select colvisSearch-9"></span>' +
      '    <span id="retailer-select-dropdown" class="filter-span-select colvisSearch-5"></span>' +
      '    <form action="/transactions" accept-charset="UTF-8" method="get">' +
      '      <span class="filter-span-range colvisSearch-2" title="Transaction Date Range Search (leaving start or end date blank will assume infinity)">' +
      '        <div>' +
      '          <div class="yadcf-filter-wrapper">' +
      '            <div class="yadcf-filter-wrapper-inner">' +
      '              <input type="date" name="startdate" id="startdate" placeholder="From" class="yadcf-filter-range-date yadcf-filter-range yadcf-filter-range-start hasDatepicker" oncontextmenu="return false" style="width:150px;">' +
      '              <input type="date" name="enddate" id="enddate" placeholder="To" class="yadcf-filter-range-date yadcf-filter-range yadcf-filter-range-end hasDatepicker" oncontextmenu="return false" style="width:150px;margin-left:-3px;">' +
      '            </div>' +
      '            <button type="submit" name="commit" class="yadcf-filter-reset-button" title="Note that Date Range search will clear all other filters">Go</button>' +
      '          </div>' +
      '        </div>' +
      '      </span>' +
      '    </form>' +
      '  </li>' +
      '</ul>' +
      '';
    }

    table.page.len(100).draw();

    $('div.dataTableToolbar').html(dataTableToolbar());

    var search = $.fn.dataTable.util.throttle(
      function (val) {
        table.search(val).draw();
      }, 500);
     
    $('.tableSearch').on('keyup', function () {
      search( this.value );
    });
  
    $('.tableLength').on('change', function () {
      table.page.len($(this).find('option:selected').attr('value')).draw();
    });
  
    function colVisButtons(index) {
      var button = $('#colvisClicker-'+index);
      button.on('click', function(e) {
        e.stopPropagation();
        if (table.column(index).visible() === true) {
          table.column(index).visible(false);
          button.addClass('button-highlight');
          $('.colvisSearch-'+index).hide();
        }
        else {
          table.column(index).visible(true);
          button.removeClass('button-highlight');
          $('.colvisSearch-'+index).show();
        }
      });
    }
    let colVisIndex = -1;
    do { colVisIndex += 1;
         colVisButtons(colVisIndex);
    } while (colVisIndex < 14);
  
    yadcf.init(table,
      [
  
        { column_number : 3, // Transaction Class
          filter_container_id : 'transaction-class-dropdown',
          style_class : 'form-control form-control-sm',
          filter_default_label : 'Transaction Class...',
          data: [
            {value: '1', label : 'Credit'},
            {value: '2', label : 'Chargeback'},
            {value: '3', label : 'Payment'}
          ]
        },

        { column_number : 4, // Transaction Type
          filter_container_id : 'transaction-type-dropdown',
          filter_default_label : 'Transaction Type... ↓↓↓',
          filter_match_mode : "exact",
          filter_type: 'multi_select',
          select_type: 'select2',
          data: $('#params_span').data('transaction_types_dropdown')
        },

        { column_number : 11, // Supplier
          filter_container_id : "supplier-select-dropdown",
          filter_default_label : "Suppliers... ↓↓↓",
          filter_match_mode : "exact",
          filter_type: 'multi_select',
          select_type: 'select2',
          data: $('#params_span').data('suppliers_dropdown')
        },

        { column_number : 12, // Retailer
          filter_container_id : "retailer-select-dropdown",
          filter_default_label : "Retailers... ↓↓↓",
          filter_match_mode : "exact",
          filter_type: 'multi_select',
          select_type: 'select2',
          data: $('#params_span').data('retailers_dropdown')
        },
  
      ],
      { filters_position : 'footer' },
      { autocomplete : false },
      { externally_triggered : true }
    );
  
    // This block executes any query string searches that may be present in the URL
    function filterTable(columnIndex, filterValue){
      yadcf.exFilterColumn(table, [
        [columnIndex, filterValue],
      ], true);
    }
    const queryStringParams = window.location.search.substring(1).split('&');

    queryStringParams.forEach(param => {

      if (param.indexOf('supplier') !== -1) {
        filterTable(10, $('#params_span').data('supplier'));
      } else if (param.indexOf('retailer') !== -1) {
        filterTable(11, [$('#params_span').data('retailer')]);
      } else if (param.indexOf('transaction_class') !== -1) {
        filterTable(3, [$('#params_span').data('transaction_class')]);
      } else if (param.indexOf('transaction_type') !== -1) {
        filterTable(4, $('#params_span').data('transaction_type'));
      }

    });

    // Text column searches need to be executed a second time to trigger the actual search
    yadcf.exFilterColumn(table, [
      [4, yadcf.exGetColumnFilterVal(table,4)]
    ], true);

    $('#startdate').val($('#startdate_h').attr('value'));
    $('#enddate').val($('#enddate_h').attr('value'));
  
  }
  
//----------------------------------------------------------------------------//
//---------- TRANSACTIONS QUICKBOOKS EXPORT RELATED --------------------------//
//----------------------------------------------------------------------------//

  if ($('.transactions_quickbooks_export_div').length > 0) {

    $('.loading-overlay').show();

    var MyDate = new Date();
    var date;
    MyDate.setDate(MyDate.getDate());
    date = MyDate.getFullYear() + '-' + ('0' + (MyDate.getMonth() + 1)).slice(-2) + '-' + ('0' + MyDate.getDate()).slice(-2);
    var filename = 'Quickbooks Transactions Export, ' + date;
    var table = $('#transactionsQuickbooksExportDataTable').DataTable({
      'retrieve': true,
      'processing': true,
      'serverSide': true,
      'initComplete': function( settings, json ) {
        $('.loading-overlay').hide();
      },
      'ajax': {
        'url': $('#transactionsQuickbooksExportDataTable').data('source'),
      },
      'columns': [
  /*0 */{data: 'invoice_no'},
  /*1 */{data: 'customer'},
  /*2 */{data: 'invoice_date', searchable: false},
  /*3 */{data: 'deposit_to', searchable: false, orderable: false},
  /*4 */{data: 'location'},
  /*5 */{data: 'memo', searchable: false, orderable: false},
  /*6 */{data: 'item'},
  /*7 */{data: 'item_description'},
  /*8 */{data: 'item_quantity', searchable: false, orderable: false},
  /*9 */{data: 'item_rate', searchable: false, orderable: false},
  /*10*/{data: 'item_amount', searchable: false, orderable: false},
  /*11*/{data: 'discount', searchable: false, orderable: false},
      ], // Service Date, Invoice Number, Item
      'order': [[2,'desc'], [0, 'asc']],

      'oLanguage': { 'sZeroRecords': 'No records to display for this filter' },
      'dom': '<"row"<"col-sm-10 dataTableToolbar"><"col-sm-2"B>>t<"row dataTableBottomRow"<"col-md-2"i><"col-md-10"p>>',
      'buttons': [
        {
          extend: 'excelHtml5',
          title: filename,
          // Only exports columns that are currently visible.  Adjusted by the 'Visibility' dropdown and Filtered text.
          exportOptions: {columns: ':visible'},
          className: 'dtButtonHide',
        },
        {
          extend: 'csv',
          title: filename,
          exportOptions: {columns: ':visible'},
          className: 'dtButtonHide'
        },
        {
          extend: 'pdfHtml5',
          title: filename,
          orientation: 'landscape',
          exportOptions: {columns: ':visible'},
          className: 'dtButtonHide'
        },
        {
          extend: 'copyHtml5',
          title: filename,
          exportOptions: {columns: ':visible'},
          className: 'dtButtonHide'
        }, 
        {
          extend: 'print',
          autoPrint: false,
          title: filename,
          exportOptions: {columns: ':visible'},
          customize: function (win) {
              $(win.document.body).find('table').addClass('display').css('font-size', '12px');
              $(win.document.body).find('tr:nth-child(odd) td').each(function(index){
                  $(this).css('background-color','#D0D0D0');
              });
              $(win.document.body).find('h1').css('text-align','center');
          },
          className: 'dtButtonHide'
        }
      ]
    });

    function dataTableToolbar() {
      return '' +
      '<ul class="nav" style="margin-bottom:10px">' +
      '  <li class="filter-section mt-2" style="width:100%">' +
      '    <span class="filter-label">Options:</span>' +
      '    <div class="dataTables_length" id="transactionsQuickbooksExportDataTable_length">' +
      '      <select name="transactionsQuickbooksExportDataTable_length" aria-controls="transactionsQuickbooksExportDataTable" class="form-control form-control-sm tableLength" title="Number of records to show">' +
      '        <option value="5">5</option>' +
      '        <option value="10">10</option>' +
      '        <option value="25">25</option>' +
      '        <option value="50">50</option>' +
      '        <option value="100">100</option>' +
      '        <option selected="selected" value="250">250</option>' +
      '        <option value="500">500</option>' +
      '        <option value="1000">1000</option>' +
      '      </select>' +
      '    </div>' +
      '    <div class="tableClickerDiv dropdown mr-2">' +
      '      <button class="btn btn-outline-primary btn-sm dropdown-toggle" title="Export Options" type="button" id="tableClickerExports" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="fas fa-file-export"></i> Export</button>' +
      '      <div class="dropdown-menu" aria-labelledby="tableClickerExports">' +
      '        <div class="text-primary" style="font-size:11px;padding:0.25rem 1.5rem;">' +
      '          <i class="fas fa-exclamation-triangle text-danger"></i> Only the currently visible rows will be exported' +
      '        </div>' +
      '        <a class="dropdown-item tableClickerExcel"><i class="fas fa-file-excel file-icon-color"></i> Excel <i class="fas fa-download text-primary"></i></a>' +
      '        <a class="dropdown-item tableClickerCsv"><i class="fas fa-file-csv file-icon-color"></i> CSV <i class="fas fa-download text-primary"></i></a>' +
      '        <a class="dropdown-item tableClickerPdf"><i class="fas fa-file-pdf file-icon-color"></i> PDF <i class="fas fa-download text-primary"></i></a>' +
      '        <a class="dropdown-item tableClickerCopy"><i class="fas fa-copy file-icon-color"></i> Copy</a>' +
      '        <a class="dropdown-item tableClickerPrint"><i class="fas fa-print file-icon-color"></i> Print/Preview</a>' +
      '      </div>' +
      '    </div>' +
      '    <div id="transactionsQuickbooksExportDataTable_filter" class="dataTables_filter mr-2">' +
      '      <input id="quickbooksExportTableSearch" class="form-control form-control-sm tableSearch" title="Searchable Columns: InvoiceNo, Customer, Class, Item, ItemDescription" oncontextmenu="return false" aria-controls="transactionsQuickbooksExportDataTable" placeholder="Search...">' +
      '    </div>' +
      '    <span id="retailer-select-dropdown" class="filter-span-select"></span>' +
      '    <span id="transaction-type-dropdown" class="filter-span-select"></span>' +
      '    <form action="/transactions/quickbooks_export" accept-charset="UTF-8" method="get">' +
      '      <span class="filter-span-range colvisSearch-2" title="Transaction Date Range Search (leaving start or end date blank will assume infinity)">' +
      '        <div>' +
      '          <div class="yadcf-filter-wrapper">' +
      '            <div class="yadcf-filter-wrapper-inner">' +
      '              <input type="date" name="startdate" id="startdate" placeholder="From" class="yadcf-filter-range-date yadcf-filter-range yadcf-filter-range-start hasDatepicker" oncontextmenu="return false" style="width:150px;">' +
      '              <input type="date" name="enddate" id="enddate" placeholder="To" class="yadcf-filter-range-date yadcf-filter-range yadcf-filter-range-end hasDatepicker" oncontextmenu="return false" style="width:150px;margin-left:-3px;">' +
      '            </div>' +
      '            <button type="submit" class="yadcf-filter-reset-button submit-date-range" title="Note that Date Range search will clear all other filters">Go</button>' +
      '          </div>' +
      '        </div>' +
      '      </span>' +
      '    </form>' +
      '    <div class="tableClickerDiv dropdown mr-2">' +
      '      <button class="btn btn-outline-primary btn-sm dropdown-toggle" title="Month Quick Select" type="button" id="tableClickerMonthSelect" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="fas fa-file-export"></i> Month</button>' +
      '      <div class="dropdown-menu" aria-labelledby="tableClickerMonthSelect">' +
      '        <a class="dropdown-item" id="month0122" title="01">January 2022</a>' +
      '        <a class="dropdown-item" id="month0222" title="02">February 2022</a>' +
      '        <a class="dropdown-item" id="month0322" title="03">March 2022</a>' +
      '        <a class="dropdown-item" id="month0422" title="04">April 2022</a>' +
      '        <a class="dropdown-item" id="month0522" title="05">May 2022</a>' +
      '        <a class="dropdown-item" id="month0622" title="06">June 2022</a>' +
      '        <a class="dropdown-item" id="month0722" title="07">July 2022</a>' +
      '        <a class="dropdown-item" id="month0822" title="08">August 2022</a>' +
      '        <a class="dropdown-item" id="month0922" title="09">September 2022</a>' +
      '        <a class="dropdown-item" id="month1022" title="10">October 2022</a>' +
      '        <a class="dropdown-item" id="month1122" title="11">November 2022</a>' +
      '        <a class="dropdown-item" id="month1222" title="12">December 2022</a>' +
      '        <a class="dropdown-item" id="month0123" title="12">January 2023</a>' +
      '      </div>' +
      '    </div>' +
      '  </li>' +
      '</ul>' +
      '';
    }

    table.page.len(150).draw();

    $('div.dataTableToolbar').html(dataTableToolbar());

    var search = $.fn.dataTable.util.throttle(
      function (val) {
        table.search(val).draw();
      }, 500);

    $('.tableSearch').on('keyup', function () {
      search( this.value );
    });

    $('.tableLength').on('change', function () {
      table.page.len($(this).find('option:selected').attr('value')).draw();
    });

    function daterange_shortcut(e, start, end) {
      $(document).on('click', e, function() {
        $(this).closest('.filter-section').find('#startdate').val(start);
        $(this).closest('.filter-section').find('#enddate').val(end);
        $('.submit-date-range').click();
      });
    }

    daterange_shortcut('#month0122', '2022-01-01', '2022-01-31');
    daterange_shortcut('#month0222', '2022-02-01', '2022-02-28');
    daterange_shortcut('#month0322', '2022-03-01', '2022-03-31');
    daterange_shortcut('#month0422', '2022-04-01', '2022-04-30');
    daterange_shortcut('#month0522', '2022-05-01', '2022-05-31');
    daterange_shortcut('#month0622', '2022-06-01', '2022-06-30');
    daterange_shortcut('#month0722', '2022-07-01', '2022-07-31');
    daterange_shortcut('#month0822', '2022-08-01', '2022-08-31');
    daterange_shortcut('#month0922', '2022-09-01', '2022-09-30');
    daterange_shortcut('#month1022', '2022-10-01', '2022-10-31');
    daterange_shortcut('#month1122', '2022-11-01', '2022-11-30');
    daterange_shortcut('#month1222', '2022-12-01', '2022-12-31');

    $('#startdate').val($('#startdate_h').attr('value'));
    $('#enddate').val($('#enddate_h').attr('value'));

    yadcf.init(table,
      [
  
        { column_number : 6, // Transaction Type
          filter_container_id : 'transaction-type-dropdown',
          style_class : 'form-control form-control-sm',
          filter_default_label : 'Transaction Type...',
          data: [
            {value: 'cash_discount', label : 'Cash Discount'},
            {value: 'trade_discount', label : 'Trade Discount'},
            {value: 'item_credit', label : 'Item Credit'},
            {value: 'return', label : 'Return'},
            {value: 'return_customer_choice', label : 'Return (Customer Choice)'},
            {value: 'return_damaged_goods', label : 'Return (Damaged Goods)'},
            {value: 'replacement_part', label : 'Replacement Part'},
            {value: 'remittance', label : 'Remittance'},
            {value: 'damage_allowance', label : 'Damage Allowance'},
            {value: 'other', label : 'Other'}
          ]
        },
 
        { column_number : 4, // Class (populated with Retailer)
          filter_container_id : 'retailer-select-dropdown',
          style_class : 'form-control form-control-sm',
          filter_default_label : 'Retailer...',
          data: [
            {value: 'Home Depot', label : 'Home Depot'},
            {value: 'Wayfair', label : 'Wayfair'}
          ]
        },
 
      ],
      { filters_position : 'footer' },
      { autocomplete : false },
      { externally_triggered : true }
    );


    $(document).on('mousedown', 'input.yadcf-filter-range-date', function() {
      if (event.which === 3) {
        $(this).val('');
      }
    });

  }

//----------------------------------------------------------------------------//
//---------- TRANSACTION TYPES FORM RELATED ----------------------------------//
//----------------------------------------------------------------------------//

  if ($('.transaction_type-validate').length > 0) {

    $('label.required').append('<strong> *</strong>'); // adds a "required" star next to each Inputs label
  
    $(".transaction_type-validate").validate({
      ignore: ".ignore",
      onkeyup: function(element) {$(element).valid()},
      errorClass: "myErrorClass",
      errorPlacement: function (error, element) {
        error.insertAfter(element.parent());
      },
      highlight: function (element, errorClass, validClass) {
        $(element).addClass(errorClass);
      },    
      unhighlight: function (element, errorClass, validClass) {
        $(element).removeClass(errorClass);
        $(element).parent().next("label").remove();
      },
  		invalidHandler: function(e, validator) {
  			var errors = validator.numberOfInvalids();
  			if (errors) {
  				var message = errors == 1
  					? 'You missed 1 required field. It has been highlighted above'
  					: 'You missed ' + errors + ' required fields.  They have been highlighted above';
  				$("div.validatorErrorList span").html(message);
  				$("div.validatorErrorList").show();
  			}
  			else {
  				$("div.validatorErrorList").hide();
  			}
  		}
    });
  
    $("#transactionTypeFirstSubmit").on("click", function(e) {
      e.preventDefault();
      if ($(".transaction_type-validate").valid()) {
        $(".loading-overlay").show();
        $("#transactionTypeSubmit").click();
      }
      else {
        return false;
      }
    });

  }

//----------------------------------------------------------------------------//
//---------- TRANSACTION TYPES INDEX RELATED ---------------------------------//
//----------------------------------------------------------------------------//

  if ($('.transaction_types_index_div').length > 0) {
    
    var MyDate = new Date();
    var date;
    MyDate.setDate(MyDate.getDate());
    date = MyDate.getFullYear() + "-" + ("0" + (MyDate.getMonth() + 1)).slice(-2) + "-" + ("0" + MyDate.getDate()).slice(-2);
    var filename = "Transaction Types Export, " + date;
    var table = $("#transactionTypesDataTable").DataTable({
      "retrieve": true,
      "oLanguage": { "sZeroRecords": "No transaction types to display for this filter" },
      "dom": "<'row'<'col-sm-10 dataTableToolbar'><'col-sm-2'B>>t<'row dataTableBottomRow'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-6'p>>",
      "buttons": [
        {
          extend: "excelHtml5",
          title: filename,
          // Only exports columns that are currently visible.  Adjusted by the "Visibility" dropdown and Filtered text.
          exportOptions: {columns: ":visible"},
          className: "dtButtonHide"
        },
        {
          extend: "csv",
          title: filename,
          exportOptions: {columns: ":visible"},
          className: "dtButtonHide"
        },
        {
          extend: "pdfHtml5",
          title: filename,
          orientation: "landscape",
          exportOptions: {columns: ":visible"},
          className: "dtButtonHide"
        },
        {
          extend: "copyHtml5",
          title: filename,
          exportOptions: {columns: ":visible"},
          className: "dtButtonHide"
        }, 
        {
          extend: "print",
          autoPrint: false,
          title: filename,
          exportOptions: {columns: ":visible"},
          customize: function (win) {
              $(win.document.body).find('table').addClass('display').css('font-size', '12px');
              $(win.document.body).find('tr:nth-child(odd) td').each(function(index){
                  $(this).css('background-color','#D0D0D0');
              });
              $(win.document.body).find('h1').css('text-align','center');
          },
          className: "dtButtonHide"
        }
      ],
      "columnDefs": [
        {
        "targets": 0,
        "visible": false,
        }
      ],
      "order": [[1, "asc"]],
    });
    
  
    function dataTableToolbar() {
      return "" +
      "<ul class='nav' style='margin-bottom:10px'>" +
      "  <li>" +
      "    <div id='transactionTypesDataTable_filter' class='dataTables_filter mr-2'>" +
      "      <input id='transactionTypesTableSearch' class='form-control form-control-sm tableSearch' title='right-click to clear' oncontextmenu='return false' aria-controls='transactionTypesDataTable' placeholder='Search Table...'>" +
      "    </div>" +
      "  <li>" +
      "    <div class='dataTables_length' id='transactionTypesDataTable_length'>" +
      "      <select name='transactionTypesDataTable_length' aria-controls='transactionTypesDataTable' class='form-control form-control-sm tableLength' title='Number of records to show'>" +
      "        <option value='5'>5</option>" +
      "        <option value='10'>10</option>" +
      "        <option value='25'>25</option>" +
      "        <option value='50'>50</option>" +
      "        <option value='100'>100</option>" +
      "        <option selected='selected' value='-1'>All</option>" +
      "      </select>" +
      "    </div>" +
      "  </li>" +
      "  <li>" +
      "    <div class='tableClickerDiv dropdown'>" +
      "      <button class='btn btn-outline-primary btn-sm dropdown-toggle' type='button' id='tableClickerExports' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'><i class='fas fa-file-export'></i> Export</button>" +
      "      <div class='dropdown-menu' aria-labelledby='tableClickerExports'>" +
      "        <div class='text-primary' style='font-size:11px;padding:0.25rem 1.5rem;'>" +
      "          <i class='fas fa-exclamation-triangle text-danger'></i> Only the currently visible columns & rows will be exported" +
      "        </div>" +
      "        <a class='dropdown-item tableClickerExcel'><i class='fas fa-file-excel file-icon-color'></i> Excel <i class='fas fa-download text-primary'></i></a>" +
      "        <a class='dropdown-item tableClickerCsv'><i class='fas fa-file-csv file-icon-color'></i> CSV <i class='fas fa-download text-primary'></i></a>" +
      "        <a class='dropdown-item tableClickerPdf'><i class='fas fa-file-pdf file-icon-color'></i> PDF <i class='fas fa-download text-primary'></i></a>" +
      "        <a class='dropdown-item tableClickerCopy'><i class='fas fa-copy file-icon-color'></i> Copy</a>" +
      "        <a class='dropdown-item tableClickerPrint'><i class='fas fa-print file-icon-color'></i> Print/Preview</a>" +
      "      </div>" +
      "    </div>" +
      "  </li>" +
      "  <li>" +
      "    <div class='tableClickerDiv dropdown'>" +
      "      <button class='btn btn-outline-primary btn-sm dropdown-toggle' type='button' id='tableClickerColvis' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'><i class='fas fa-columns'></i> Columns</button>" +
      "      <div class='dropdown-menu' aria-labelledby='tableClickerColvis'>" +
      "        <a class='dropdown-item button-highlight' id='colvisClicker-0'>ID</a>" +
      "        <a class='dropdown-item' id='colvisClicker-1'>Name</a>" +
      "        <a class='dropdown-item' id='colvisClicker-2'>Description</a>" +
      "        <a class='dropdown-item' id='colvisClicker-3'>Actions</a>" +
      "      </div>" +
      "    </div>" +
      "  </li>" +
      "</ul>" +
      "";
    }
  
    table.page.len(25).draw();
  
    $("div.dataTableToolbar").html(dataTableToolbar());
  
    $(".tableSearch").keyup(function () {
      table.search($(this).val()).draw();
    });
  
    $(".tableLength").on("change", function () {
      table.page.len($(this).find("option:selected").attr("value")).draw();
    });
  
    function colVisButtons(index) {
      var button = $("#colvisClicker-"+index);
      button.on("click", function(e) {
        e.stopPropagation();
        if (table.column(index).visible() === true) {
          table.column(index).visible(false);
          button.addClass("button-highlight");
        }
        else {
          table.column(index).visible(true);
          button.removeClass("button-highlight");
        }
      });
    }
    let colVisIndex = -1;
    do { colVisIndex += 1;
         colVisButtons(colVisIndex);
    } while (colVisIndex < 3);

  }

//----------------------------------------------------------------------------//
//---------- TRANSACTION TYPES SHOW RELATED ----------------------------------//
//----------------------------------------------------------------------------//

  if ($('.transaction_type_show_div').length > 0) {

    // Transactions Table
    var MyDate = new Date();
    var date;
    MyDate.setDate(MyDate.getDate());
    date = MyDate.getFullYear() + "-" + ("0" + (MyDate.getMonth() + 1)).slice(-2) + "-" + ("0" + MyDate.getDate()).slice(-2);
    var filename = "Current Costing Export, " + date;
    var table = $(".transactions_table").DataTable({
      "retrieve": true,
      "processing": true,
      // "order": [[ 1, "asc" ], [ 10, "desc" ], [ 9, "desc" ], [ 8, "desc" ], [ 7, "desc" ]],
      "oLanguage": { "sZeroRecords": "No records to display for this filter" },
      "dom": "<'row'<'col-sm-10 dataTableToolbar'><'col-sm-2'B>>t<'row dataTableBottomRow'<'col-md-2 ml-3'i><'col-md-9'p>>",
      "buttons": [
        {
          extend: "excelHtml5",
          title: filename,
          // Only exports columns that are currently visible.  Adjusted by the "Visibility" dropdown and Filtered text.
          exportOptions: {columns: ":visible"},
          className: "dtButtonHide"
        },
        {
          extend: "csv",
          title: filename,
          exportOptions: {columns: ":visible"},
          className: "dtButtonHide"
        },
        {
          extend: "pdfHtml5",
          title: filename,
          orientation: "landscape",
          exportOptions: {columns: ":visible"},
          className: "dtButtonHide"
        },
        {
          extend: "copyHtml5",
          title: filename,
          exportOptions: {columns: ":visible"},
          className: "dtButtonHide"
        }, 
        {
          extend: "print",
          autoPrint: false,
          title: filename,
          exportOptions: {columns: ":visible"},
          customize: function (win) {
              $(win.document.body).find('table').addClass('display').css('font-size', '12px');
              $(win.document.body).find('tr:nth-child(odd) td').each(function(index){
                  $(this).css('background-color','#D0D0D0');
              });
              $(win.document.body).find('h1').css('text-align','center');
          },
          className: "dtButtonHide"
        }
      ]
    });
  
    function dataTableToolbar() {
      return "" +
      "<ul class='nav ml-2 mb-3'>" +
      "  <li>" +
      "    <div class='dataTables_length' id='transactions_table_length'>" +
      "      <select name='transactions_table_length' aria-controls='transactions_table' class='form-control form-control-sm tableLength' title='Number of records to show'>" +
      "        <option value='5'>5</option>" +
      "        <option value='10'>10</option>" +
      "        <option selected='selected' value='25'>25</option>" +
      "        <option value='50'>50</option>" +
      "        <option value='100'>100</option>" +
      "        <option value='250'>250</option>" +
      "        <option value='-1'>All</option>" +
      "      </select>" +
      "    </div>" +
      "  </li>" +
      "  <li>" +
      "    <div class='tableClickerDiv dropdown'>" +
      "      <button class='btn btn-outline-primary btn-sm dropdown-toggle' title='Export Options' type='button' id='tableClickerExports' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'><i class='fas fa-file-export'></i> Export</button>" +
      "      <div class='dropdown-menu' aria-labelledby='tableClickerExports'>" +
      "        <div class='text-primary' style='font-size:11px;padding:0.25rem 1.5rem;'>" +
      "          <i class='fas fa-exclamation-triangle text-danger'></i> Only the currently visible columns & rows will be exported" +
      "        </div>" +
      "        <a class='dropdown-item tableClickerExcel'><i class='fas fa-file-excel file-icon-color'></i> Excel <i class='fas fa-download text-primary'></i></a>" +
      "        <a class='dropdown-item tableClickerCsv'><i class='fas fa-file-csv file-icon-color'></i> CSV <i class='fas fa-download text-primary'></i></a>" +
      "        <a class='dropdown-item tableClickerPdf'><i class='fas fa-file-pdf file-icon-color'></i> PDF <i class='fas fa-download text-primary'></i></a>" +
      "        <a class='dropdown-item tableClickerCopy'><i class='fas fa-copy file-icon-color'></i> Copy</a>" +
      "        <a class='dropdown-item tableClickerPrint'><i class='fas fa-print file-icon-color'></i> Print/Preview</a>" +
      "      </div>" +
      "    </div>" +
      "  </li>" +
      "  <li class='filter-section ml-3'>" +
      "    <div id='transactions_table_filter' class='dataTables_filter mr-2'>" +
      "      <input id='transactions_table_tableSearch' class='form-control form-control-sm tableSearch' title='right-click to clear' oncontextmenu='return false' aria-controls='transactions_table' placeholder='Search Entire Table...'>" +
      "    </div>" +
      "  </li>" +
      "</ul>" +
      "";
    }

    table.page.len(50).draw();

    $("div.dataTableToolbar").html(dataTableToolbar());
  
    $(".tableLength").on("change", function () {
      table.page.len($(this).find("option:selected").attr("value")).draw();
    });
  
    // clears the global Table Search column on right-click
    var search = $.fn.dataTable.util.throttle(
      function (val) {
        table.search(val).draw();
      }, 500);
     
    $('.tableSearch').on('keyup', function () {
      search( this.value );
    });
  
  }

});