/*global $*/

// import 'stylesheets/inventory_adjustments/inventory_adjustments-global.scss';

$(document).on("turbolinks:load", function() {

//----------------------------------------------------------------------------//
//---------- INVENTORY ADJUSTMENTS IMPORT RELATED ----------------------------//
//----------------------------------------------------------------------------//

  if ($('.inventory_import_div').length > 0) {

    // monkeypatch to fix issue of search field not being selected on open.  https://github.com/select2/select2/issues/2311#issuecomment-822473913
    $(document).on('select2:open', e => {
      const id = e.target.id;
      const target = document.querySelector(`[aria-controls=select2-${id}-results]`);
      target.focus();
    });
  
    // on first focus (bubbles up to document), open the menu
    $(document).on('focus', '.select2-selection.select2-selection--single', function (e) {
      $(this).closest(".select2-container").siblings('select:enabled').select2('open');
    });
  
    // steal focus during close - only capture once and stop propogation
    $('select.select2').on('select2:closing', function (e) {
      $(e.target).data("select2").$selection.one('focus focusin', function (e) {
        e.stopPropagation();
      });
    });
  
    $(document).on('click', '.inventoryImportSubmit', function() {
      $(".loading-overlay").show();
    });
  
    $(document).on("change keyup", "#import_type_select", function() {
      if ($(this).find("option:selected").text() == "CommerceHub")  {
        $("#file").attr("disabled", false);
        $('#batch_number_select').val('Home Depot Orders');
        $(".import-warning-text").hide();
      }
      else if ($(this).find("option:selected").text() == "Shopify")  {
        $("#file").attr("disabled", false);
        $('#batch_number_select').val('Shopify Orders');
      }
      else {
        $("#file").attr("disabled", true);
        $(".import-warning-text").show();
      }
    });


    $(document).on('change', '#file', function() {
      $('.filename').trigger('change');
    });

    // parses common filenames to determine import type
    $(document).on('change', '.filename', function() {
      if ($(this).val().indexOf('search110207011') != -1) { // RP CommerceHub
        $('#import_type_select').val('CommerceHub').trigger('change');
        $('#batch_number_select').val('RP Home Depot Orders');
      } else if ($(this).val().indexOf('search111542003') != -1) { // SRS CommerceHub
        $('#import_type_select').val('CommerceHub').trigger('change');
        $('#batch_number_select').val('SRS Home Depot Orders');
      } else if ($(this).val().indexOf('search114611002') != -1) { // Bodo CommerceHub
        $('#import_type_select').val('CommerceHub').trigger('change');
        $('#batch_number_select').val('Bodo Home Depot Orders');
      } else if ($(this).val().indexOf('orders_export') != -1) { // RP Shopify
        $('#import_type_select').val('Shopify').trigger('change');
        $('#batch_number_select').val('RP Shopify Orders');
      }
    });

  }

});